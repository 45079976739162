function getPropertySearchUrl(
  locationSlug: string,
  checkin_date?: Date | null,
  checkout_date?: Date | null,
  adult_count?: number,
  child_count?: number,
  selectedVillaSlug?: string,
) {
  const isBrowser = typeof window !== "undefined",
    [destination, ...rest] = locationSlug.split("-"),
    location = rest.join("-");
  let url = new URL(
    `/villas/${destination}/${location}`,
    isBrowser ? window.location.origin : "https://example.com",
  );

  if (selectedVillaSlug) {
    url = new URL(
      `/villas/${destination}/${location}/${selectedVillaSlug}`,
      isBrowser ? window.location.origin : "https://example.com",
    );
  }

  if (checkin_date) {
    url.searchParams.set("checkin_date", checkin_date.toISOString());
  }
  if (checkout_date) {
    url.searchParams.set("checkout_date", checkout_date.toISOString());
  }
  if (adult_count) {
    url.searchParams.set("adult_count", adult_count.toString());
  }
  if (child_count) {
    url.searchParams.set("child_count", child_count.toString());
  }

  return url.pathname + url.search;
}

export { getPropertySearchUrl };
