import React from "react";
import {useField} from "../../../../hooks/use-form";
import NativeSelect, {SelectProps as NativeSelectProps} from "../../../Select";

interface SelectProps extends NativeSelectProps {
  form: string;
  name: string;
  handlechangeparent?: (e: React.ChangeEvent<any>) => void;
  iconMobile?: string;
  iconDesktop?: string;
  isHomeDestination?:boolean;
  viewlayout?:boolean;
}

function Select(props: SelectProps) {
  const {
    form,
    name,
    handlechangeparent,
    iconMobile,
    iconDesktop,
    isHomeDestination,
    viewlayout,
  } = props, {
    value,
    error,
    handleBlur,
    handleChange,
  } = useField(form, name),
  handleChangeSelect = (e: React.ChangeEvent<any>) => {
    handleChange(e);
    handlechangeparent && handlechangeparent(e);
  };

  return (
    <NativeSelect
      {...props}
      value={value}
      viewlayout={viewlayout}
      iconMobile={iconMobile}
      iconDesktop={iconDesktop}
      error={error as string}
      isHomeDestination={isHomeDestination}
      onChange={handleChangeSelect}
      onBlur={handleBlur}
    />
  )
}

export default Select;
