import React from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./exclusive-coupon-tnc.module.css";
import CLOSE_ICON from "../../assets/icons/close.svg";
import BACK_ARROW_ICON from "../../assets/icons/chevron-left-dark-icon.svg";

interface ExclusiveCouponTNCProps {
  className?: string;
  listingCoupon?: ListingOffersType;
  detailCoupon?: Coupon;
  isOffersModal?: boolean;
  onSubmit?: (out: any) => void;
  parentHeight?: number;
  onCloseParentModal?: () => void;
  tncClicked?:boolean;
}

function ExclusiveCouponTNC(props: ExclusiveCouponTNCProps) {
  const { listingCoupon, detailCoupon, className, onSubmit, parentHeight, onCloseParentModal, tncClicked } = props,
    ccx = useClx(clxs.couponCard, className),

    /**
     * Handles closing the popup:
     * - If `onCloseParentModal` is provided, it closes the parent modal.
     * - Otherwise, it falls back to `onSubmit` to close the current modal.
     */
    handleClosePopup = () => {
      if (onCloseParentModal) {
        onCloseParentModal();
      } else if (onSubmit) {
        onSubmit(null);
      }
    };

  return (
    <div 
      className={ccx}
      style={{ height: parentHeight ? `${parentHeight}px` : "auto" }}
    >
      <div className={clxs.couponHeader}>
        {!tncClicked && (
          <img
            src={BACK_ARROW_ICON}
            alt="close"
            className={clxs.backArrow}
            onClick={onSubmit?.bind(null, null)}
          />
        )}
        <div className={clxs.couponCode}>Terms and Conditions</div>
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          onClick={handleClosePopup}
        />
      </div>
      <div
        className={clxs.termsListConatiner}
      >
        <div className={clxs.termsListTitle}>{detailCoupon?.description || listingCoupon?.description}</div>
        {listingCoupon?.discount_text && (
          <div className={clxs.discountBox}>
            {listingCoupon.discount_text}
          </div>
        )}

        <ul className={clxs.termsList}>
          {listingCoupon?.terms?.map((term, index) => (
            <li key={index}>{term}</li>
          ))}

          {detailCoupon?.terms?.map((term, index) => (
            <li key={index}>{term}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ExclusiveCouponTNC;

