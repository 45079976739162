import { useEffect, useState } from "react";
import { useAtomState } from "./use-atom";
import { PROPERTY_LOCATION_KEY } from "../../core/constants";
import usePropertyService from "./use-property-service";
import { useStorageString } from "./use-storage";
// import usePrefetch from "./use-prefetch";

function usePropertyLocations (
  collection_slug?: string,
  isHomePage?: boolean,
) {
  const { service } = usePropertyService(),
  {
    value: user_location,
    setValue: setCoordinates,
  } = useStorageString("sessionStorage", "user_location"),
    // { hasPrefetchKey } = usePrefetch(),
    [
      propertyLocations,
      setPropertyLocations,
    ] = useAtomState<PropertyLocation[]>(PROPERTY_LOCATION_KEY, []),
    [closeClickCount, setCloseClickCount] = useState(0),
    handleGetPropertyLocations = async (collection_slug?: string, latitude?: number, longitude?: number) => {
      // if (hasPrefetchKey(PROPERTY_LOCATION_KEY)) {
      //   return;
      // }

      const { error, response } = await service.getPropertyLocations(collection_slug, latitude, longitude);

      if (error || !response) {
        return;
      }

      setPropertyLocations(response);
    },
    requestLocationPermission = async () => {
      if (navigator.geolocation && !user_location && isHomePage) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            // console.log(`location obtained ${latitude} ${longitude}`);
            setCoordinates(JSON.stringify(position.coords));
            handleGetPropertyLocations(collection_slug, latitude, longitude);
            return;
          },
          (error) => {
            console.error("Error obtaining location", error);
            // For other errors, try to use stored location if available
            if(user_location.length) {
              const { latitude, longitude } = JSON.parse(user_location);
              handleGetPropertyLocations(collection_slug, latitude, longitude);
            }
            return;
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          },
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        // Only call API without coordinates if geolocation is not supported
        if(user_location) {
          const { latitude, longitude } = JSON.parse(user_location);
          handleGetPropertyLocations(collection_slug, latitude, longitude);
        }else {
          handleGetPropertyLocations(collection_slug);
        }
      }

      // Increment the show nearby locations button click count
      setCloseClickCount((prev) => prev + 1);
    };

  useEffect(
    () => {
      requestLocationPermission();
    },
    [collection_slug],
  );

  return { 
    propertyLocations: propertyLocations, 
    requestLocationPermission: requestLocationPermission,
    closeClickCount,
  };
}

export default usePropertyLocations;
