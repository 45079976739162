import React, { ChangeEvent, useMemo, useState } from "react";
import CLOSE_ICON from "../../assets/icons/close.svg";
import { useCoupons } from "../../hooks/use-coupons";
import clxs from "./coupon-suggestion.module.css";
import { useQueryParamValue } from "../../hooks/use-query-param";
import { useLocation } from "react-router-dom";
// import useReservationService from "../../hooks/use-reservation-service";
// import { useSnackbar } from "../../hooks/use-snackbar";

interface CouponSuggestionProps {
  propertySlug?: string,
  checkinDate?: string,
  checkoutDate?: string,
  adultCount?: number,
  childCount?: number,
  onSubmit?: (couponCode: string | null) => void;
  configs?: PropertyConfigArg[];
}

function CouponSuggestion (props: CouponSuggestionProps) {
  const [manual, setManual] = useState<string>(""),
  tier = useQueryParamValue("string", "tier"),
  { pathname } = useLocation(),
  isCheckoutPage = pathname?.includes("checkout"),
  // { service } = useReservationService(),
  // enqueueSnackbar = useSnackbar(),
    {
      propertySlug,
      checkinDate,
      checkoutDate,
      adultCount,
      childCount,
      onSubmit,
      configs,
    } = props,
    { coupons } = useCoupons(
      propertySlug,
      checkinDate,
      checkoutDate,
      adultCount,
      childCount,
      configs, //Configs was needed in checkout page.
    ),
    tierCoupon = useMemo(() => {

      if (!tier || !isCheckoutPage || tier == "no_tier") return null;

      const coupon: Coupon = {
        code: tier.toUpperCase(),
        description: "Apply " + tier.toUpperCase() + " benefits.",
        name: tier.toUpperCase() + " tier coupon.",
        terms:[""],
      }

      return coupon;
    },[tier,isCheckoutPage]),
    handleManualChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement,
        { value } = target;

      setManual(value);
    },
    // checkIfCouponValid = async (coupon_code: string) => {
    //   const { error, response } = await service.getCoupon(coupon_code);
    //   let isCouponValid: boolean = false;

    //   if (error || !response) {
    //     isCouponValid = false;
    //   }

    //   if (response && response.code == coupon_code) {
    //     isCouponValid = true;
    //   }

    //   return isCouponValid;
    // },
    handleCouponSelected = (coupon: string) => {
      // const isValidCoupon = checkIfCouponValid(coupon);

      // if(coupon && isValidCoupon) {
        return onSubmit?.(coupon);
      // } else {
        // enqueueSnackbar("Invalid Coupon", "error")
      // }
      
    };

  return (
    <div className={clxs.container}>
      <div className={clxs.title}>
        Apply coupon
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          onClick={onSubmit?.bind(null, null)}
        />
      </div>
      <div className={clxs.manualContainer}>
        <input
          type="text"
          className={clxs.manual}
          name="coupon_code"
          placeholder="Type coupon code here"
          value={manual}
          onChange={handleManualChange}
        />
        <button
          className={clxs.manualApply}
          onClick={handleCouponSelected.bind(null, manual)}
        >
          Apply
        </button>
      </div>
     <div className={clxs.couponContainer}>
     {coupons.length ? (
        coupons.map(
          (each, i) => {
            const { name, code, description } = each;

            return (
              <div
                key={i}
                className={clxs.coupon}
              >
                <div className={clxs.couponTitle}>{code || name}</div>
                <button
                  className={clxs.couponApply}
                  onClick={handleCouponSelected.bind(null, code)}
                >
                  Apply
                </button>
                <div>{description}</div>
              </div>
            );
          },
        )
      ) : <></>} 

      {/* {(tierCoupon) ? (
        (
          <div
            className={clxs.coupon}
            data-discount-type={tier}
          >
            <div className={clxs.couponTitle}>{tierCoupon?.name || tierCoupon?.code}</div>
            <button
              className={clxs.couponApply}
              onClick={handleCouponSelected.bind(null, tierCoupon?.code)}
            >
              Apply
            </button>
            <div>{tierCoupon?.description}</div>
          </div>)
      ) : <></> } */}

      {(!tierCoupon && !coupons.length) ? (
      <div className={clxs.noOffer}>
        There are no offers available for this villa on the selected dates.
      </div>) : <></>}
     </div>

    </div>
  );
}

export default CouponSuggestion;
