import React, { useMemo } from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./loyalty-point-ticker.module.css";

interface LoyaltyPointShortTickerProps {
  method?: string;
  className?: string;
  discountPercentage?: number | string;
  discountType?:string;
  currencySymbol?:string;
}

function LoyaltyPointShortTicker (props: LoyaltyPointShortTickerProps) {
  const { className, method, discountPercentage = 0 ,discountType,currencySymbol} = props;

  const content = useMemo(() => {
    switch (discountType) {
        case "free_night":
            return `${discountPercentage} Night Free`;
        case "percentage_discount":
            return `${discountPercentage}% OFF`;
        case "flat_discount":
            return `${currencySymbol}${discountPercentage.toLocaleString("en-IN")} OFF`;
        default:
            return `${discountPercentage}% OFF`;
    }
   }, [discountType, discountPercentage]);

  const ccx = useClx(clxs.container, className);

  // const isHidden = useMemo(
  //   () => !method || method === "coupon",
  //   [method],
  // );

  // if (isHidden) {
  //   return null;
  // }

  return (
    <div
      data-discount-type={method}
      className={ccx}
    >
      {content}
    </div>
  );
}

export default LoyaltyPointShortTicker;
