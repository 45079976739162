import React from "react";
import clxs from "./tax-tooltip.module.css";
import Tooltip from "../../ToolTip";

interface TaxTooltipProps {
  tax_data: TaxBreakdown[];
  currency_symbol: string;
  hideSymbole?:boolean;
  title?:string;
  showCloseIcon?:boolean;
}

function TaxTooltip(props: TaxTooltipProps) {
  const { tax_data, currency_symbol, hideSymbole, title, showCloseIcon} = props;

  if(!tax_data || !tax_data.length){
    return null;
  }

  const taxBreakdownContent = (
    <div className={clxs.taxContainer}>
      {title && <div className={clxs.title}>{title}</div>}
      {tax_data.map((each, key) => (
        <div 
          className={ each.description === "Total Payable"?  `${clxs.taxBreakdown} ${clxs.taxBreakdownDark}`: clxs.taxBreakdown } 
          key={key}
        >
          <div className={clxs.description}>{each.description}</div>
          <div className={clxs.subTitle}>{hideSymbole ? each.sub_title : `${currency_symbol} ${each.sub_title}`} </div>
          <div className={ each.description === "Discount"?  `${clxs.taxes} ${clxs.taxGreen}`: clxs.taxes }> {each.description === "Discount"? "-" : ""}{`${currency_symbol} ${each.taxes.toLocaleString("en-IN")}`}</div>
        </div>
      ))}
    </div>
  );

  return (
    <Tooltip 
      content={taxBreakdownContent}
      showCloseIcon={showCloseIcon} 
    />
  );
}

export default TaxTooltip;
