import React, { useCallback, useMemo, useState } from "react";
import Collapse from "../../../Collapse";
import clxs from "./activities.module.css";
import useClx from "../../../../hooks/use-clx";
import BIKE_RIDE from "../../../../assets/lohono-black/atv-ride.jpg"
import SEGWAY from "../../../../assets/lohono-black/segway-ride.jpg";
import HORSE_RIDES from "../../../../assets/lohono-black/horse ride.jpg"
import CYCLING from "../../../../assets/lohono-black/cycling.jpg";
import STARGAZING from "../../../../assets/lohono-black/stargazing.jpg";
import PLANET_EXPLORATION from "../../../../assets/lohono-black/planet-exploration.jpg";
import FISH_FEEDING from "../../../../assets/lohono-black/fish-feeding.jpg";
import FISHING from "../../../../assets/lohono-black/fishing.jpg";
import POOL from "../../../../assets/lohono-black/pool.jpg";
import FOOSBALL from "../../../../assets/lohono-black/foosball.jpg";
import BADMINTON from "../../../../assets/lohono-black/badminton.jpg";
import FOOTBALL from "../../../../assets/lohono-black/football.jpg";
import BIRD_FEEDING from "../../../../assets/lohono-black/Bird feeding.jpg";
import DARTS from "../../../../assets/lohono-black/darts.jpg";
import SHOOTING from "../../../../assets/lohono-black/shooting.jpg";
import BUGGY_RIDE from "../../../../assets/lohono-black/Buggy ride.jpg";
import FEED_DOG from "../../../../assets/lohono-black/feed-dog.jpg";
import TENNIS from "../../../../assets/lohono-black/tennis.jpg";
import CRICKET from "../../../../assets/lohono-black/cricket.jpg";
import TABLE_TENNIS from "../../../../assets/lohono-black/table tennis.jpg";
import BASKETBALL from "../../../../assets/lohono-black/basketball.jpg";
import ARCHERY from "../../../../assets/lohono-black/archery.jpg";
import TRAMPOLINE from "../../../../assets/lohono-black/trampoline.jpg";
import BILLIARDS from "../../../../assets/lohono-black/billiards.jpg";
import CARROM from "../../../../assets/lohono-black/carrom.jpg";
import POKER from "../../../../assets/lohono-black/poker-card.jpg";
import BOARD_GAMES from "../../../../assets/lohono-black/board-game.jpg";
import CHESS from "../../../../assets/lohono-black/chess.jpg";
import GARDEN_TRAIL from "../../../../assets/lohono-black/garden trail.jpg";
import BIRD_WATCHING from "../../../../assets/lohono-black/birdwatching.jpg";
import PADDLE_COURT from "../../../../assets/lohono-black/paddle-court.webp";

interface Activities {
  maxVisible: number;
  className?: string;
}

function Activities(props: Activities) {
  const { className, maxVisible } = props,
    [expanded, setExpanded] = useState<boolean>(false),
    primary = useMemo(
      () => TEMPLATE.slice(0, maxVisible),
      [maxVisible],
    ),
    secondary = useMemo(
      () => TEMPLATE.slice(maxVisible),
      [maxVisible],
    ),
    handleToggle = useCallback(
      () => setExpanded(collapsed => !collapsed),
      [setExpanded],
    ),
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div className={clxs.title}>
        Activities
      </div>
      <div className={clxs.activities}>
        {primary.map(
          (each, key) => (
            <div
              key={key}
              className={clxs.activity}
            >
              <img
                src={each.thumbnail}
                alt={each.title}
                className={clxs.activityThumbnail}
              />
              <div className={clxs.activityTitle}>
                {each.title}
              </div>
            </div>
          ),
        )}
      </div>
      {secondary.length ? (
        <div>
          <Collapse
            expanded={expanded}
            className={clxs.activities}
          >
            {secondary.map(
              (each, key) => (
                <div
                  key={key}
                  className={clxs.activity}
                >
                  <img
                    src={each.thumbnail}
                    alt={each.title}
                    className={clxs.activityThumbnail}
                  />
                  <div className={clxs.activityTitle}>
                    {each.title}
                  </div>
                </div>
              ),
            )}
          </Collapse>
          <button
            className={clxs.showActivities}
            onClick={handleToggle}
          >
            {expanded ? "Show Less Activities" : "Show All Activities"}
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default Activities;

const TEMPLATE = [
  {
    title: "ATV bike rides",
    thumbnail: BIKE_RIDE,
  },
  {
    title: "Segway rides",
    thumbnail: SEGWAY,
  },
  {
    title: "Horse rides",
    thumbnail: HORSE_RIDES,
  },
  {
    title: "Cycling",
    thumbnail: CYCLING,
  },
  {
    title: "Stargazing from glass house",
    thumbnail: STARGAZING,
  },
  {
    title: "Moon & planet exploration",
    thumbnail: PLANET_EXPLORATION,
  },
  {
    title: "Koi pond fish feeding",
    thumbnail: FISH_FEEDING,
  },
  {
    title: "Fresh water fishing",
    thumbnail: FISHING,
  },
  {
    title: "Pool",
    thumbnail: POOL,
  },
  {
    title: "Foosball",
    thumbnail: FOOSBALL,
  },
  {
    title: "Badminton",
    thumbnail: BADMINTON,
  },
  {
    title: "Football",
    thumbnail: FOOTBALL,
  },
  {
    title: "Duck & Guinea hen feeding",
    thumbnail: BIRD_FEEDING,
  },
  {
    title: "Darts",
    thumbnail: DARTS,
  },
  {
    title: "Air gun aim shooting",
    thumbnail: SHOOTING,
  },
  {
    title: "Buggy ride",
    thumbnail: BUGGY_RIDE,
  },
  {
    title: "Bathe & Feed Dog",
    thumbnail: FEED_DOG,
  },
  {
    title: "Tennis",
    thumbnail: TENNIS,
  },
  {
    title: "Cricket",
    thumbnail: CRICKET,
  },
  {
    title: "Table Tennis",
    thumbnail: TABLE_TENNIS,
  },
  {
    title: "Basketball",
    thumbnail: BASKETBALL,
  },
  {
    title: "Archery",
    thumbnail: ARCHERY,
  },
  {
    title: "Trampoline",
    thumbnail: TRAMPOLINE,
  },
  {
    title: "Billiards",
    thumbnail: BILLIARDS,
  },
  {
    title: "Carrom",
    thumbnail: CARROM,
  },
  {
    title: "Poker & cards",
    thumbnail: POKER,
  },
  {
    title: "Board games",
    thumbnail: BOARD_GAMES,
  },
  {
    title: "Chess",
    thumbnail: CHESS,
  },
  {
    title: "Garden trail",
    thumbnail: GARDEN_TRAIL,
  },
  {
    title: "Birdwatching",
    thumbnail: BIRD_WATCHING,
  },
  {
    title: "Paddle Court",
    thumbnail: PADDLE_COURT,
  },
];

