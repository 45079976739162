import React from "react";
import { useNavigate } from "react-router-dom";
import decodeLocationSlug from "../../../utils/decode-location-slug";
import AppStoreAlert from "./AppStoreAlert";
// import AppStoreBanner from "./AppStoreBanner";
import PropertyCarousel from "./PropertyCarousel";
import PropertySearch from "./PropertySearch";
import { getPropertySearchUrl } from "../../../utils/property-search";
// import VillaOverHotel from "./VillaOverHotel";
import PropertyLocation from "./PropertyLocation";
import Recommendation from "../Recommendation";
//import LohonoBlack from "./LohonoBlack";
import Homeowner from "./Homeowner";
import usePropertyTrending from "../../hooks/use-property-trending";
import useSendRequest from "../../hooks/use-send-request";
import useAnalytics from "../../hooks/use-analytics";
import LoyaltyPointBanner from "./LoyaltyPointBanner";
import clxs from "./home.module.css";
import ReferralPoints from "../ReferralPoints";
import useLoyaltyPointsBalance from "../../hooks/use-loyalty-points-balance";
import { useUser } from "../../hooks/use-session";
import CollectionsGallery from "./Collections";

function Home() {
  const { showSendRequestModal } = useSendRequest(),
    push = useNavigate(),
    { track } = useAnalytics(),
    { trendingProperties } = usePropertyTrending(),
    trendingCarouselId = "trending-properties",
    user = useUser(),
    { points } = useLoyaltyPointsBalance(),
    handlePropertySearch = (values: any) => {
      const {
        destination: locationSlug,
        stay: {
          start: checkin_date,
          end: checkout_date,
        },
        occupancy: {
          adult_count,
          child_count,
        },
        villa_slug,
      } = values;

      const { destination, location } = decodeLocationSlug(locationSlug),
        url = getPropertySearchUrl(
          locationSlug,
          checkin_date,
          checkout_date,
          adult_count,
          child_count,
          villa_slug,
        );

      track(
        "home_property_search_clicked",
        {
          location: location,
          destination: destination,
          adult_count: adult_count,
          child_count: child_count,
        },
      );

      return push(url);
    },
    handleHomeownerContact = async () => {
      await showSendRequestModal({ interest: "development" });

      push("/homeowner");
    },
    handleCollectionClick = (collection_slug: string) => {
      push({pathname: `/villas/collections/${collection_slug}`})
    };

  return (
    <>
      <PropertyCarousel className={clxs.carousel}>
        <PropertySearch
          className={clxs.propertySearch}
          onPropertySearch={handlePropertySearch}
        />
      </PropertyCarousel>
      {(user) && (
        <div className={clxs.section}>
          <ReferralPoints 
            isKnowMore={true} 
            points={points} 
            user={user}
          />
        </div>
      )}
      {/* <VillaOverHotel
        className={clxs.section}
        titleClassName={clxs.sectionTitle}
        containerClassName={clxs.sectionContent}
      /> */}
      <PropertyLocation
        className={clxs.section}
        titleClassName={clxs.sectionTitle}
        subtitleClassName={clxs.sectionSubtitle}
        containerClassName={clxs.sectionContent}
      />
      <CollectionsGallery
        title="Collections For You"
        subtitle="Discover our curated collections of stunning villas for luxurious and unforgettable escapes!"
        onCollectionClick={handleCollectionClick} 
        className={clxs.homeCollection}
      />
      <LoyaltyPointBanner className={clxs.section} />
      {/* <AppStoreBanner className={clxs.section} /> */}
      {/* <LohonoBlack className={clxs.section} /> */}
      <Recommendation
        title="Trending this season"
        subtitle="Our homes have been reviewed by a number of critics and publications. Check out our private villas trending this summer!"
        carouselId={trendingCarouselId}
        properties={trendingProperties}
        className={clxs.section}
        titleClassName={clxs.sectionTitle}
        subtitleClassName={clxs.sectionSubtitle}
        containerClassName={clxs.sectionContentCollapse}
        tracking="home_trending_clicked"
      />
      <Homeowner
        className={clxs.section}
        onContactUs={handleHomeownerContact}
      />
      <div className={clxs.spacer}>&nbsp;</div>
      <AppStoreAlert />
    </>
  );
}

export default Home;


