import React, { createElement, MouseEventHandler, ReactNode, useMemo, useRef } from "react";
import clxs from "./property-multi-config-item.module.css";
import CHEVRON_RIGHT_PRIMARY1 from "../../assets/icons/black-right-arrow-icon.svg";
import decodeLocationSlug from "../../../utils/decode-location-slug";
import useClx from "../../hooks/use-clx";
import CHEVRON_RIGHT_PRIMARY2 from "../../assets/lohono-black/icons/chevron-right-primary.svg";
import LOHONO_BLACK_LOGO from "../../assets/lohono-black/logo/lohono-black.svg";
import { format } from "date-fns";
import useIsMobile from "../../hooks/use-is-mobile";
import { useSearchParams } from "react-router-dom";
import RatingCounts from "../PropertyDetail/RatingCounts";
import Carousel from "../Carousel";
import Item from "../Carousel/Item";
import ScrollCrumb from "../Home/PropertyCarousel/ScrollCrumb";
import VillaTag from "../PropertyItem/VillaTag";
import LoyaltyPointShortTicker from "../LoyaltyPointShortTicker";

interface PropertyMultiConfigItemProps {
  property: PropertyItem;
  routable?: boolean;
  className?: string;
  subscript?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler;
}

function PropertyMultiConfigItem(props: PropertyMultiConfigItemProps) {
  const {
    property,
    routable = true,
    className,
    // subscript = "/night",
    children,
    onClick,
  } = props,
    {
      view_layout,
      location_slug,
      property_slug,
      accommodation,
      bath_count,
      pool_count,
      name,
      address,
      currency_symbol,
      sold_out,
      upcoming,
      status,
      brand,
      next_availability,
      configs,
      discount_method,
      images,
      builder,
      bedroom_count,
      average_ratings,
      ratings_count,
      bundeled_offer,
    } = property,
    [search] = useSearchParams(),
    carouselRef = useRef<HTMLDivElement | null>(null),
    carouselId = useMemo(
      () => `${CAROUSEL_ID}-${property_slug}`,
      [property_slug],
    ),
    guest_count = useMemo(() => {
      const guest = accommodation.split(" ");
      const count = parseInt(guest[guest.length - 1], 10);
      return count;
    }, [accommodation]),
    specs = useMemo(
      () => {
        const specs: string[] = [
          `${accommodation} ${guest_count > 1 ? "Guests" : "Guset"}`,
        ];

        if (view_layout === "multi_config") {
          specs.push(`${bedroom_count} ${bedroom_count > 1 ? "Bedrooms" : "Bedroom"}`);
        } else if (configs?.length) {
          const partial = configs?.map(each => each.bedrooms).join(", ");

          specs.push(`${partial} Bedrooms`);
        }

        if (bath_count) {
          specs.push(`${bath_count} ${bath_count > 1 ? "Bathrooms" : "Bathroom"}`);
        }

        if (pool_count) {
          specs.push(`${pool_count} ${pool_count > 1 ? "Pools" : "Pool"}`);
        }

        return specs;
      },
      [
        view_layout,
        accommodation,
        bath_count,
        pool_count,
        bedroom_count,
        configs,
      ],
    ),
    { isMobile } = useIsMobile(),
    { location, destination } = useMemo(
      () => decodeLocationSlug(location_slug),
      [location_slug],
    ),
    isUnavailable = useMemo(
      () => status === "unavailable" || upcoming,
      [status, upcoming],
    ),
    secondary = useMemo(
      () => {
        const limit = isMobile ? 2 : 3;
        return configs.slice(0, limit);
      },
      [configs],
    ),
    isBlack = useMemo(
      () => brand === "lohono_black",
      [brand],
    ),
    ccx = useClx(
      clxs.container,
      isBlack ? clxs.black : null,
      className,
    ),
    rootElement = useMemo(
      () => {
        if (!routable) {
          return "div";
        }
        return "a";
      },
      [routable],
    ),
    nextAvailableDateNode = useMemo(
      () => {
        if (!sold_out) {
          return null;
        }

        const { checkin_date, checkout_date } = next_availability;

        if (!checkin_date || !checkout_date) {
          return null;
        }

        const checkin = format(new Date(checkin_date), "d LLL"),
          checkout = format(new Date(checkout_date), "d LLL");

        return (
          <div className={clxs.datesContainer}>
            {isMobile ? "Next Available Dates :" : "Next Available Dates"}
            <div className={clxs.dates}>
              {`${checkin} - ${checkout}`}
            </div>
          </div>
        );

      },
      [
        next_availability,
        sold_out,
        clxs.datesContainer,
        clxs.dates,
      ],
    ),
    tickerNode = useMemo(
      () => {
        if (upcoming) {
          return (
            <div className={clxs.upcoming}>
              Coming soon
            </div>
          );
        }

        if (sold_out) {
          return (
            <div className={clxs.soldOut}>
              Sold out
            </div>
          );
        }

        if (isUnavailable) {
          return (
            <div className={clxs.unavailable}>
              Unavailable
            </div>
          );
        }

        return null;
      },
      [upcoming, sold_out, isUnavailable],
    ),
    builderBadge = useMemo(
      () => {
        if (isBlack) {
          return (
            <img
              src={LOHONO_BLACK_LOGO}
              alt="lohono-black"
              className={clxs.badge}
            />
          );
        }

        return null;
      },
      [builder, isBlack],
    ),
    cProps = useMemo(
      () => {
        const baseProps = {
          className: ccx,
          onClick: onClick,
        };

        if (!routable) {
          return baseProps;
        }

        const updatedSearchParams = new URLSearchParams(search);

        if (!updatedSearchParams.has("adult_count")) {
          updatedSearchParams.set("adult_count", "2");
        }

        if (
          sold_out
          && next_availability.checkin_date
          && next_availability.checkin_date
        ) {
          updatedSearchParams.set(
            "checkin_date",
            new Date(next_availability.checkin_date).toISOString(),
          );

          updatedSearchParams.set(
            "checkout_date",
            new Date(next_availability.checkout_date).toISOString(),
          );
        }

        const safeSearch = updatedSearchParams.toString(),
          path = `/villas/${destination}/${location}/${property_slug}`,
          url = safeSearch ? `${path}?${safeSearch}` : path;

        return {
          ...baseProps,
          href: url,
          title: `View details of ${name}`,
        };
      },
      [
        routable,
        name,
        location,
        destination,
        property_slug,
        next_availability,
        sold_out,
        ccx,
        onClick,
        search,
      ],
    ),
    preventRedirect = (e: React.MouseEvent) => {
      e.preventDefault();
    };

  return createElement(
    rootElement,
    cProps,
    <>
      <div
        className={clxs.thumbnailContainer}
        onClick={preventRedirect}
      >
        { images?.length ?
        <Carousel
          className={clxs.thumbnail}
          id={carouselId}
          threshold={0.98}
          forwardRef={carouselRef}
        >
           {images?.map((each, key) => {
            const { url, alt } = each;
            return (
              <Item 
                className={clxs.item} 
                key={key}
              >
                <img 
                  src={url} 
                  alt={alt} 
                  className={clxs.imgSelect} 
                />
              </Item>
            );
          })}
        </Carousel>  : []
        }
        <ScrollCrumb
          carouselId={carouselId}
          count={images?.length}
          className={clxs.scrollCrumb}
          isShowArrows={true}
          isFilledArrows={true}
        />
      </div>
      <h3
        className={clxs.title}
        title={name}
      >
        {name}
      </h3>
      <div
        className={clxs.soloSoldout}
        data-sold-out={sold_out}
      >
        {tickerNode}
      </div>
      {/* Check if both ratings_count and average_ratings */}
      <div className={clxs.subtitle}>
        {address}
      </div>
      <div className={clxs.specContainer}>
        {specs?.map(
          (each, i) => (
            <div
              key={i}
              className={clxs.spec}
            >
              {each} <span className={clxs.dot}>&#x2022;</span>
            </div>
          ),
        )}
      </div>
      {bundeled_offer ?
        <div className={clxs.additionalBenefitsContainer}>
          <div className={clxs.additionalBenefits}>Additional benefits on</div>
          <div className={clxs.benefitsContainer}>
            <img
              // src={isMobile ? fork_spoon_color_icon : fork_spoon_icon}
              src={bundeled_offer.icon}
              alt="Lohono"
              className={clxs.benefitsIcon}
            />
            <span className={clxs.benefitsContent}>{bundeled_offer.name}</span>
          </div>
        </div>
        : null
      }
      <div className={clxs.configurations}>
        {secondary?.map((each, key) => {

          if (key !== 0 && isMobile) return null;
          const {
            room_name,
            max_occupancy,
            bed_type,
            price,
            discounted_price,
            discount_type,
            discount_value_config,
            // tier_discount_percentage,
          } = each,
            strikePrice = `${currency_symbol} ${price?.toLocaleString("en-IN", { maximumFractionDigits: 0 })}`,
            isShowDiscountedPrice = discounted_price && price && (discounted_price < price),
            finalPrice = `${currency_symbol} ${(discounted_price || price)?.toLocaleString("en-IN", { maximumFractionDigits: 0 })}`;

          return (
            <div
              data-discount-type={discount_method}
              className={clxs.configuration}
              key={key}
              title={room_name}
            >
              {discount_value_config ?
                <LoyaltyPointShortTicker
                  discountType={discount_type}
                  discountPercentage={discount_value_config}
                  method={discount_method}
                  className={clxs.llpTicker}
                  currencySymbol={currency_symbol}
                />
                :
                null
              }

              <div className={clxs.configTitle}>
                {room_name}
              </div>
              <div className={clxs.configSize}>
                <div className={clxs.configMobileTitle}>
                  {room_name}
                </div>
                <div className={clxs.fitsContainer}>{`Fits ${max_occupancy} (${bed_type})`}</div>
                {isUnavailable ? (<div>&nbsp;</div>) : (
                  <div className={clxs.pricingContainer}>
                    <div className={clxs.startFrom}>
                      {(isShowDiscountedPrice && strikePrice) ? (
                        <span className={clxs.strike}>
                          {strikePrice}
                        </span>
                      ) : null}
                      <span className={clxs.amount}>
                        {finalPrice}
                      </span>
                    </div>
                    <div className={clxs.excTax}>
                      Per night excluding taxes*
                    </div>
                  </div>
                )}
              </div>
              {nextAvailableDateNode}
              {builderBadge}
            </div>
          );
        })}
      </div>
      <div className={clxs.viewMore}>+ View More</div>
      {ratings_count && average_ratings && (
        <RatingCounts
          className={clxs.ratingCounts}
          average_ratings={average_ratings}
          ratings_count={ratings_count}
          theme="propertyItemRating"
        />
      )}
      <div
        className={clxs.view}
        data-sold-out={sold_out}
      >
        <span>View details</span>
        <img
          src={isBlack ? CHEVRON_RIGHT_PRIMARY2 : CHEVRON_RIGHT_PRIMARY1}
          alt="jump"
        />
      </div>

      <div className={clxs.VillaTagContainer}>
        <VillaTag
          tag="Award Winning"
          className=""
        />
      </div>
      {children}
    </>,
  );
}

export default PropertyMultiConfigItem;

const CAROUSEL_ID = "property-details-image";