import { MouseEvent as ME, RefObject, useCallback, useEffect, useState } from "react";
// import useIsMobile from "./use-is-mobile";

function useDialogToggle<T extends HTMLElement> (
  ref: RefObject<T>,
  disabled?: boolean,
  onOpenDialog?:()=>void,
  isDoneClicked?: boolean,
  setIsDoneClicked?: (value: boolean) => void,
  onSelectClicked?:()=>void,
) {
  const [open, setOpen] = useState<boolean>(false);
  // const { isMobile } = useIsMobile();
  
  const boundingClientRect = ref?.current?.getBoundingClientRect();
  const { top = 0 } = boundingClientRect ? boundingClientRect : {},

    handleOpen = useCallback(
      (e?: ME<HTMLDivElement>) => {

        if(e) {
          e.stopPropagation();
        }

        if (disabled) {
          return;
        }

        // const {scrollY} = window;

        // if(scrollY < 100) {
        //   window.scrollTo({top: 150, behavior: "smooth"});
        // }
        
        setOpen(true);
        onSelectClicked && onSelectClicked();
        setIsDoneClicked && setIsDoneClicked(false);
      },
      [setOpen, disabled],
    ),
    handleClose = useCallback(
      () => {
        setOpen(false);
      },
      [setOpen],
    ),
    handleToggle = useCallback(
      () => {
        setOpen(value => !value);
      },
      [setOpen],
    ),
    handleCloseOnScroll = useCallback(
      () => {
        // Check if the reference to the target element is available
        if (!ref.current) {
          return;
        }
    
        /*
         On scroll, hide the date component when the user scrolls up,
         and make it visible again when they scroll back down.
        `top` is assumed to be the vertical scroll position or an offset value.
    
         If the top value is less than 85 (indicating the user scrolled up)
         and the device is not a mobile device, close the component by setting `setOpen(false)`.
        */
        if (top < 85) {
          setOpen(false);
        }
    
        // If the top value is greater than 84 (indicating the user scrolled back down)
        // and the device is not a mobile device, reopen the component by setting `setOpen(true)`.
        if (top > 85 && !isDoneClicked) {
          setOpen(true);
        }
      },
      [setOpen, top, isDoneClicked], // Dependencies for useMemo, ensuring the function is recomputed only when these values change
    ),    
    configureAutoDismiss = useCallback(
      () => {
        if (!document) {
          return;
        }

        if (!ref.current) {
          return;
        }

        const handler = (e: MouseEvent) => {
          const target = e.target as HTMLElement;

          const { dismiss } = target.dataset;

          if (dismiss) {
            setOpen(false);

            return;
          }

          const isChild = ref.current?.contains(target) ?? false;

          if (isChild) {
            return;
          }

          const isInPortal = target.closest("[data-portal='true']") !== null;

          if (isInPortal) {
            return;
          }


          onOpenDialog && onOpenDialog();

          setOpen(false);

          // Updating the status that done was clicked.
          setIsDoneClicked && setIsDoneClicked(true);
        };

        document.addEventListener("click", handler);

        return () => {
          document.removeEventListener("click", handler);
        };
      },
      [setOpen, ref.current],
    );

  useEffect(configureAutoDismiss, [configureAutoDismiss]);

  return {
    open: open,
    handleOpen: handleOpen,
    handleClose: handleClose,
    handleToggle: handleToggle,
    handleCloseOnScroll:handleCloseOnScroll,
  };
}

export default useDialogToggle;
