import React from "react";
import useClx from "../../../hooks/use-clx";
import { Track } from "../../AnalyticsManager";
// import ScrollDown from "../../ScrollDown";
import NetworkImg from "../../NetworkImg";
import clxs from "./property-banner.module.css";
// import ISPRAVA_LOGO from "../../../assets/logo/isprava-built.png";
// import PARTNER_HOME_ICON from "../../../assets/partner-home-handshake.svg";
import Carousel from "../../Carousel";
import ScrollCrumb from "../../Home/PropertyCarousel/ScrollCrumb";
import Item from "../../Carousel/Item";
import ShareMenu from "../LohonoLayout/ShareMenu";
import FILLED_LEFT_ICON from "../../../assets/icons/filled-left-arrow.svg";
import usePathParam from "../../../hooks/use-path-params";
// import useIsMobile from "../../../hooks/use-is-mobile";

interface PropertyBannerProps {
  className?: string;
  banner: PropertyImage;
  allImages?: PropertyImage[];
  name: string;
  address?: string;
  builder?: string;
  onViewPhotos: () => void;
  videoAnchorId?: string;
  showVideoLink?: boolean;
  onShare: () => Promise<boolean>;
}

function PropertyBanner(props: PropertyBannerProps) {
  const {
      allImages,
      className,
      onViewPhotos,
      onShare,
    } = props,
    ccx = useClx(clxs.container, className),
    cscx = useClx(clxs.carousel, "carousel"),
    location = usePathParam(PATH, "location"),
    destination = usePathParam(PATH, "destination"),
    locationUrl = "/villas/" + destination + "/" + location.trim().toLowerCase().replace(/\s+/g, "");

  return (
    <div className={ccx}>
      <Track event="property_detail_view_photos_clicked">
      <div 
        className={clxs.largeImage} 
        onClick={onViewPhotos}
      >
        <NetworkImg
          loading="lazy"
          src={allImages ? allImages[0].url : ""}
          alt={allImages ? allImages[0].alt : "No Image found"}
          title={allImages ? allImages[0].caption : ""}
          mobileQuality={25}
          desktopQuality={75}
          onClick={onViewPhotos}
        />
      </div>
      </Track>
      <Track event="property_detail_view_photos_clicked">
      <div className={clxs.smallImages}>
        {allImages &&
          allImages.slice(1, 5).map((oImage: PropertyImage, index) => (
            <div 
              key={index}
              className={clxs.smallImage} 
              onClick={onViewPhotos}
            >
              <NetworkImg
                loading="lazy"
                src={oImage.url}
                alt={oImage.alt}
                title={oImage.caption || undefined}
                mobileQuality={25}
                desktopQuality={75}
                onClick={onViewPhotos}
              />
              {index === 3 && (
                <div className={clxs.overlay}>
                  <div className={clxs.overlayText}>
                    <div style={{fontSize: 24}}> +{allImages.length - 5} </div>
                    <div>
                       More Photos
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      </Track>
      {/* <div
        className={bdcx}
        onClick={onViewPhotos}
      >
        &nbsp;
      </div> */}
      {/* {builderBadge} */}
      {/* <div className={btcx}>
        {showVideoLink && (
          <Track event="property_detail_view_video_clicked">
            <a
              className={vvcx}
              href={`#${videoAnchorId}`}
            >
              View video
            </a>
          </Track>
        )}
        <Track event="property_detail_share_clicked">
          <button
            className={shcx}
            onClick={handleShare}
          >
            {shareText}
          </button>
        </Track>
        <Track event="property_detail_view_photos_clicked">
          <button
            className={vpcx}
            onClick={onViewPhotos}
          >
            View photos
          </button>
        </Track>
      </div>
      <ScrollDown className={sdcx} /> */}
      {allImages && (
        <div className={clxs.carouselContainer}>
          <Carousel 
            className={cscx} 
            id={CAROUSEL_ID} 
            threshold={0.98}
          >
            {allImages.map((each, key) => {
              const { url, alt } = each;
              return (
                <Item 
                  className={clxs.imageItem} 
                  key={key}
                >
                  <img 
                    src={url} 
                    alt={alt} 
                    className={clxs.imgSelect} 
                  />
                </Item>
              );
            })}
          </Carousel>
          <ScrollCrumb
            carouselId={CAROUSEL_ID}
            count={allImages.length - 1}
            className={clxs.scrollCrumb}
            isShowArrows={true}
            isFilledArrows={true}
          />
          <Track event="property_detail_share_clicked">
          <ShareMenu
            onShare={onShare}
            buttonClassName={clxs.shareButton}
            className={clxs.shareContainer}
          ></ShareMenu>
          </Track>
          <a
            className={clxs.backButton} 
            href={locationUrl}
          >
            <img 
              src={FILLED_LEFT_ICON} 
              alt={""} 
              className={clxs.backIcon} 
            />
          </a>
          <Track event="property_detail_view_photos_clicked">
          <div className={clxs.moreImageContainer}>
            {allImages &&
              allImages.slice(0, 2).map((oImage: PropertyImage, index) => (
                <div
                  key={index}
                  className={clxs.smallImage}
                  onClick={onViewPhotos}
                >
                  <NetworkImg
                    loading="lazy"
                    src={oImage.url}
                    alt={oImage.alt}
                    title={oImage.caption || undefined}
                    mobileQuality={25}
                    desktopQuality={75}
                    onClick={onViewPhotos}
                  />
                  <div className={clxs.overlay}>
                    {index === 0 && (
                      <div className={clxs.overlayText}>
                        <div> +{allImages.length - 5} more</div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
          </Track>
        </div>
      )}
    </div>
  );
}

export default PropertyBanner;

const CAROUSEL_ID = "Property-details-image";

const PATH = "/villas/:destination/:location/:property";