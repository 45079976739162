import format from "date-fns/format";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPropertySearchUrl } from "../../../../utils/property-search";
import urlSearchParamsFromAny from "../../../../utils/url-search-params-from-any";
import useClx from "../../../hooks/use-clx";
import Modal, { useShowModal } from "../../../hooks/use-modal";
import usePathParams from "../../../hooks/use-path-params";
import { useQueryParamValue, useQuerySearch } from "../../../hooks/use-query-param";
import Button from "../../Button";
import DateRangeInput from "../../DateRangeInput";
import OccupancySelect from "../../Occupancy/Select";
import PropertySearchMobile from "../../PropertySearchMobile";
import Sort from "../Sort/Mobile";
import clxs from "./property-search.module.css";
import SEARCH_ICON from "../../../assets/icons/searchIcon.svg";
import { useStorageDate, useStorageNumber, useStorageString } from "../../../hooks/use-storage";
import useLocationAndVillaListing from "../../../hooks/use-location-and-villa-listing";
import SearchSelect from "../../SearchSelect";
import searchParamsFromFormValues from "../../PropertyDetail/LohonoLayout/PriceCalculator/search-params-from-form-values";
import { generatePropertyConfigKey, usePropertyConfigValues } from "../../../hooks/use-property-config";
interface PropertySearchProps {
  path: string;
  sort?: string;
  onSortChange?: (nextDirection: string) => void;
  className?: string;
  filterDesktopClassName?: string;
  filterMobileClassName?: string;
  propertyCount?: number;
  isLoadingBanner?: boolean;
  onSearchClose?: (values: any) => void;
  selectedValue?: string;
  propertyDetails?: Property;
}

function PropertySearch(props: PropertySearchProps) {
  const push = useNavigate(),
    showPropertySearchModal = useShowModal(PROPERTY_SEARCH_MODAL_ID),
    [_, setQuerySearch] = useQuerySearch(),
    {
      path,
      filterDesktopClassName,
      filterMobileClassName,
      sort,
      onSortChange,
      onSearchClose,
      selectedValue,
      propertyDetails,
    } = props,
    fdcx = useClx(clxs.filters, clxs.filtersDesktop, filterDesktopClassName),
    scx = useClx(clxs.propertySearchMobileContainer, filterMobileClassName),

    destination = usePathParams(path, "destination"),
    location = usePathParams(path, "location"),
    property = usePathParams(path, "property"),
    { setValue: setDestination } = useStorageString("sessionStorage", "destination"),
    { setValue: setCheckinDate } = useStorageDate("sessionStorage", "checkin_date"),
    { setValue: setCheckoutDate } = useStorageDate("sessionStorage", "checkout_date"),
    { setValue: setAdultCount } = useStorageNumber("sessionStorage", "adult_count"),
    { setValue: setChildCount } = useStorageNumber("sessionStorage", "child_count"),
    { value: recentSearches, setValue: setRecentSearches } = useStorageString("sessionStorage", "recent_searches"),

    location_slug = useMemo(() => {
      //handled exception for north-goa location
      if (location === "north-goa") {
        return `${destination}-goa`
      } else {
        return `${destination}-${location}`
      }
    }, [destination, location]),

    packageOptions = useMemo(
      () => {
        if (!propertyDetails) {
          return []
        }
        const options: any[] = propertyDetails.configs.map(
          config => {
            const key = generatePropertyConfigKey(propertyDetails.slug, config);

            return {
              label: `${config.room_name}`,
              value: key,
            };
          },
        );

        return options;
      },
      [propertyDetails],
    ),

    // Retrieving query parameters for date and guest count
    checkin_date = useQueryParamValue("date", "checkin_date"),
    checkout_date = useQueryParamValue("date", "checkout_date"),
    adult_count = useQueryParamValue("integer", "adult_count"),
    child_count = useQueryParamValue("integer", "child_count"),
    userSearch = useQueryParamValue("string", "search"),
    // coupon_code = useQueryParamValue("string", "coupon_code"),

    [selectedDestination, setSelectedDestination] = useState<string>(location_slug),
    [selectedVillaSlug, setSelectedVillaSlug] = useState<string>(property),
    [selectedCheckInDate, setSelectedCheckInDate] = useState<Date | null>(checkin_date),
    [selectedCheckOutDate, setSelectedCheckOutDate] = useState<Date | null>(checkout_date),
    [selectedAdultCount, setSelectedAdultCount] = useState<number>(adult_count > 2 ? adult_count : 2), // Ensure minimum 2 adults
    [selectedChildCount, setSelectedChildCount] = useState<number>(child_count),
    [recentSearchOptions, setRecentSearchOptions] = useState<Option[]>(),
    [searchText, setSearchText] = useState<string>(() => userSearch),
    [showDestinationDialog, setShowDestinationDialog] = useState<boolean>(false),
    [showDateDialog, setShowDateDialog] = useState<boolean>(false),
    [showDateOnScroll, setShowDateOnScroll] = useState<boolean>(false),
    [showOccupancyDialog, setShowOccupancyDialog] = useState<boolean>(false),
    {
      values: config_args_search_values,
      configArgs,
    } = usePropertyConfigValues(property, propertyDetails?.configs || []),
    // [startDate, setStartDate] = useState<Date>(),
    // [endDate, setEndDate] = useState<Date>(),

    // Add function to close other dropdowns
    closeOtherDropdowns = (activeDropdown: "destination" | "date" | "occupancy") => {
      if (activeDropdown !== "destination") {
        setShowDestinationDialog(false);
      }
      if (activeDropdown !== "date") {
        setShowDateDialog(false);
        setShowDateOnScroll(false);
      }
      if (activeDropdown !== "occupancy") {
        setShowOccupancyDialog(false);
      }
    },

    { propertyLocationsAndVilla: locationAndVilla } = useLocationAndVillaListing(searchText),

    // Computed location options from fetched villa listing
    locationOptions = useMemo(
      () => locationAndVilla.reduce(
        (compiled, each) => {
          const { name, slug, type, address, location_value } = each,

            option = {
              label: name,
              value: slug,
              type: type,
              address: address,
              location_value: location_value,
            };

          return [
            ...compiled,
            option,
          ];
        },
        [] as { label: string; value: string, type: string, address: string, location_value: string }[],
      ),
      [locationAndVilla],
    ),

    // Computed display of selected destination
    destinationFriendly = useMemo(() => {
      const found = locationOptions.find(each => {
        if (property) {
          return each.value === property;
        } else {
          return each.value === location_slug;
        }

      });
      return found?.label ?? "";

    }, [locationOptions, location_slug]),

    // Memoized occupancy data
    occupancy = useMemo(() => {
      return {
        adult_count: adult_count,
        child_count: child_count,
      };

    }, [adult_count, child_count]),

    // Formatted stay dates for display
    stayFriendly = useMemo(() => {
      const friendly: string[] = [];

      if (checkin_date) {
        friendly.push(
          format(checkin_date, "d LLL yyyy"),
        );
      }

      if (checkout_date) {
        friendly.push(
          format(checkout_date, "d LLL yyyy"),
        );
      }

      return friendly.join(" - ");

    }, [checkin_date, checkout_date]),

    stayFriendlyMobile = useMemo(() => {
      if (!checkin_date || !checkout_date) return "";

      const checkinDay = format(checkin_date, "d"),
        checkoutDay = format(checkout_date, "d"),
        checkinMonth = format(checkin_date, "LLL"),
        checkoutMonth = format(checkout_date, "LLL"),
        checkinYear = format(checkin_date, "yy"),
        checkoutYear = format(checkout_date, "yy");

      // Case 1: Same month and year → "3-7 Jun"
      if (checkinMonth === checkoutMonth && checkinYear === checkoutYear) {
        return `${checkinDay}-${checkoutDay} ${checkinMonth}`;
      }

      // Case 2: Different month but same year → "30 Jun - 7 Jul"
      if (checkinYear === checkoutYear) {
        return `${checkinDay} ${checkinMonth} - ${checkoutDay} ${checkoutMonth}`;
      }

      // Case 3: Different years → "27 Dec, 25 - 1 Jan, 26"
      return `${checkinDay} ${checkinMonth}, ${checkinYear} - ${checkoutDay} ${checkoutMonth}, ${checkoutYear}`;

    }, [checkin_date, checkout_date]),

    // Computed total guests string
    guestsFriendly = useMemo(() => {
      let totalGuests = 0;

      if (adult_count) {
        totalGuests += adult_count;
      }

      if (child_count) {
        totalGuests += child_count;
      }

      if (!totalGuests) {
        return "";
      }

      return `${totalGuests} guests`;
    }, [adult_count, child_count]),

    defaultValue = useMemo(() => {
      const packageSlug = inferPackageSlugFromConfigArgs(config_args_search_values);

      return {
        destination: location_slug,
        stay: {
          start: checkin_date,
          end: checkout_date,
        },
        occupancy: {
          adult_count: adult_count,
          child_count: child_count,
        },
        price_package: "",
        package_slug: packageSlug,
        config_args: configArgs,
      };
    },
      [
        location_slug,
        checkin_date, checkout_date
        ,
        adult_count, child_count,
      ],
    ),

    /**
     * Handles Destination / location value change
     */
    handleDestinationChange = (e: any) => {
      const target = e.target,
        destination = target.value,
        villaSlug = target.villa_slug;

      if (destination) {

        // Retrieve the existing searches from sessionStorage
        const existingSearches = JSON.parse(recentSearches || "[]");

        // Remove the search if it already exists (to avoid duplicates)
        const filteredSearches = existingSearches.filter((search: string) => search !== destination && search !== villaSlug);

        // Add the appropriate search value to the beginning
        let updatedSearches: string[] = [];
        if (villaSlug) {
          updatedSearches = [villaSlug, ...filteredSearches]; // Add villaSlug only if present
        } else if (destination) {
          updatedSearches = [destination, ...filteredSearches]; // Add destination if no villaSlug
        }

        // Keep only the last 3 searches
        if (updatedSearches.length > 3) {
          updatedSearches.pop();
        }

        // Save back to sessionStorage
        setRecentSearches(JSON.stringify(updatedSearches));

        setShowDateDialog(true);
        setShowDateOnScroll(true);

        setDestination(destination);

        setSelectedDestination(destination)
        setSelectedVillaSlug(villaSlug);
      }
    },

    /**
     * Handle date range changes
     */
    handleStayChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement,
        { start: checkin_date, end: checkout_date } = target.value as any,

        isOnlyOneDateAdded = (checkin_date && !checkout_date) || (!checkin_date && checkout_date);

      if (isOnlyOneDateAdded) {
        return;
      }

      // setStartDate(checkin_date);
      // setEndDate(checkout_date);

      if (isOnlyOneDateAdded) {
        return;
      }

      // setStartDate(checkin_date);
      // setEndDate(checkout_date);

      setCheckinDate(checkin_date);
      setCheckoutDate(checkout_date);

      setSelectedCheckInDate(checkin_date);
      setSelectedCheckOutDate(checkout_date);
    },

    /**
     * Handle Adult and child component changes
     */
    handleOccupancyChange = (e: ChangeEvent<any>) => {
      const target = e.target,
        { adult_count, child_count } = target.value,
        value = {
          adult_count: adult_count > 2 ? adult_count : 2,
          child_count: child_count,
        };

      setAdultCount(value.adult_count);
      setChildCount(child_count);

      setSelectedAdultCount(value.adult_count);
      setSelectedChildCount(value.child_count);
    },

    handleDateDoneClicked = () => {
      // Close date dialog first
      setShowDateDialog(false);
      setShowDateOnScroll(false);
      // Then open occupancy dialog
      setTimeout(() => {
        setShowOccupancyDialog(true);
      }, 0);
    },
    /**
     * Website search button click
     */
    handleSearch = () => {
      setShowOccupancyDialog(false);

      if (!selectedDestination) {
        setShowDestinationDialog(true);
        return;
      }

      const url = getPropertySearchUrl(
        selectedDestination,
        selectedCheckInDate,
        selectedCheckOutDate,
        selectedAdultCount,
        selectedChildCount,
        selectedVillaSlug,
      );

      if (onSearchClose) {
        onSearchClose({ pathname: url });
      }

      // For detail page reloading the entire page.
      if (selectedVillaSlug) {
        window.location.replace(url);
      } else {
        // For location changes, use replace to avoid history stack issues
        window.location.replace(url);
      }
    },

    handlePropertyConfigChange = (values: any) => {
      if (!propertyDetails) {
        return;
      }

      const { view_layout, configs } = propertyDetails;

      const payload = searchParamsFromFormValues(property, view_layout, configs, values);
      // // Retain previously added coupon code.
      // if(coupon_code && coupon_code.length) {
      //   payload["coupon_code"] = [coupon_code];
      // }

      setQuerySearch(() => payload, { replace: true });
    },

    /**
     * Mobile Search Change
     */
    handleChange = async () => {
      // Trigger config value change before opening popup
      const currentValues = {
        destination: location_slug,
        stay: {
          start: checkin_date,
          end: checkout_date,
        },
        occupancy: {
          adult_count: adult_count,
          child_count: child_count,
        },
        price_package: "",
      };
      handlePropertyConfigChange(currentValues);

      const values: any = await showPropertySearchModal(
        { defaultValue: defaultValue },
      );

      if (!values) {
        return;
      }

      // This component was opened from detail page so only changing the query params instead of redirecting.
      if (property.length > 0) {
        handlePropertyConfigChange(values);
        return;
      }

      const {
        destination,
        villa_slug,
        stay = { start: null, end: null },
        occupancy = { adult_count: 2, child_count: 0 },
        price_package = "",
      } = values;

      const { start: newCheckinDate, end: newCheckoutDate } = stay;
      const { adult_count: newAdultCount, child_count: newChildCount } = occupancy;

      const path = getPropertySearchUrl(destination, undefined, undefined, undefined, undefined, villa_slug);
      const search = urlSearchParamsFromAny({
        checkin_date: newCheckinDate,
        checkout_date: newCheckoutDate,
        adult_count: newAdultCount > 2 ? newAdultCount : 2,
        child_count: newChildCount,
        price_package: price_package,
      }).toString();

      return push({ pathname: path, search: search });
    },

    /**
     * Handle Adult/ Child count Search Change
     */
    handleDoneClick = () => {
      // if (!startDate || !endDate) {
      //   setShowDateOnScroll(true);
      //   setShowDateDialog(true);
      // }

      handleSearch();
    };

  useEffect(() => {
    setSearchText(userSearch)

    if (showDateOnScroll && (!checkin_date || !checkout_date)) {
      setShowDateOnScroll(true);
      setShowDateDialog(true);
    }

    // setShowDateOnScroll(false);
    // setShowDateDialog(false);

  }, [checkin_date, checkout_date],
  );

  useEffect(() => {
    const recent_searches = JSON.parse(recentSearches || "[]"),

      recentSearchOptions = locationOptions.filter(item => recent_searches.includes(item.value)),

      searchOptions = [...recentSearchOptions];

    setRecentSearchOptions(searchOptions);

  }, [recentSearches, locationOptions]);

  useEffect(() => {
    if (!selectedValue) {
      setShowDestinationDialog(false);
      setShowDateOnScroll(false);
      setShowDateDialog(false);
      setShowOccupancyDialog(false);
    } else if (selectedValue === "destination") {
      setShowDateOnScroll(false);
      setShowDateDialog(false);
      setShowOccupancyDialog(false);
      setTimeout(() => {
        setShowDestinationDialog(true);
      }, 0);
    } else if (selectedValue === "dateRange") {
      setShowDestinationDialog(false);
      setShowOccupancyDialog(false);
      setTimeout(() => {
        setShowDateOnScroll(true);
        setShowDateDialog(true);
      }, 0);
    } else if (selectedValue === "adultCount") {
      setShowDestinationDialog(false);
      setShowDateOnScroll(false);
      setShowDateDialog(false);
      setTimeout(() => {
        setShowOccupancyDialog(true);
      }, 0);
    }
  }, [selectedValue]);

  // Add new useEffect to handle config value changes when popup opens
  // useEffect(() => {
  //   const currentValues = {
  //     destination: location_slug,
  //     stay: {
  //       start: checkin_date,
  //       end: checkout_date,
  //     },
  //     occupancy: {
  //       adult_count: adult_count,
  //       child_count: child_count,
  //     },
  //     price_package: "",
  //   };
  //   handlePropertyConfigChange(currentValues);
  // }, [location_slug, checkin_date, checkout_date, adult_count, child_count]);

  return (
    <>
      <div className={fdcx}>
        <SearchSelect
          options={locationOptions}
          label="Location/ Villa"
          value={location_slug}
          default_property={property}
          viewlayout={true}
          className={clxs.destination}
          placeholder="Select your Destination*"
          defaultValue=""
          onChange={handleDestinationChange}
          recentSearchOptions={recentSearchOptions}
          searchable={true}
          isShowSelect={showDestinationDialog}
          selectedVillaSlug={selectedVillaSlug}
          onFocus={() => closeOtherDropdowns("destination")}
        />
        <DateRangeInput
          className={clxs.stay}
          start={checkin_date}
          end={checkout_date}
          onChange={handleStayChange}
          labels={["Check In", "Check Out"]}
          placeholder={["Add Date", "Add Date"]}
          calendarDialogProps={{ monthCount: 2 }}
          isShowDialog={showDateDialog}
          isShowDateOnScroll={showDateOnScroll}
          onOpenDialog={() => closeOtherDropdowns("date")}
          handleDateDoneClicked={handleDateDoneClicked}
        />
        <OccupancySelect
          value={occupancy}
          className={clxs.occupancy}
          label="Guests"
          onChange={handleOccupancyChange}
          isShowOccupancyDialog={showOccupancyDialog}
          occupancySuccessText="Apply & Search"
          handleDoneClick={handleDoneClick}
          onOpenDialog={() => closeOtherDropdowns("occupancy")}
        />
        <Button
          className={clxs.searchButton}
          onClick={handleSearch}
        >
          <img
            src={SEARCH_ICON}
            alt="Lohono"
            className={clxs.serachIcon}
          />
          Search
        </Button>

        {/* Mobile Property Search */}
        <div className={scx}>
          <div
            className={clxs.propertySearchMobile}
            onClick={handleChange}
          >
            <div
              className={clxs.destinationFriendlyConatiner}
              data-populated={Boolean(destinationFriendly).valueOf()}
            >
              {destinationFriendly}
            </div>
            <div className={clxs.addGuestDateConatiner}>
              <div
                className={clxs.friendly}
                data-populated={Boolean(stayFriendly).valueOf()}
              >
                {stayFriendlyMobile || "Add Date"}
              </div>
              <span className={clxs.dot}>&#x2022;</span>
              <div
                className={clxs.friendly}
                data-populated={Boolean(guestsFriendly).valueOf()}
              >
                {guestsFriendly || "Add Guests"}
              </div>
            </div>
          </div>
          {onSortChange &&
            <Sort
              className={clxs.sortButton}
              value={sort || ""}
              onChange={onSortChange}
              isShowIcon={true}
            />
          }
        </div>
      </div>
      <Modal
        id={PROPERTY_SEARCH_MODAL_ID}
        scrollBlock={false}
      >
        <PropertySearchMobile
          viewLayout="standard"
          formId={PROPERTY_SEARCH_MOBILE_FORM_ID}
          hidden={HIDDEN_FIELDS}
          maxAdultCount={24}
          maxChildCount={8}
          configs={[]}
          location_slug={location_slug}
          propertySlug={property}
          packageOptions={packageOptions}
        />
      </Modal>
    </>
  );
}

export default PropertySearch;

const PROPERTY_SEARCH_MODAL_ID = "property-search-modal";

const PROPERTY_SEARCH_MOBILE_FORM_ID = "property-search-mobile";

const HIDDEN_FIELDS: any = ["price_package"];

type Option = { label: string; value: string, type: string, address?: string, location_value?: string };

function inferPackageSlugFromConfigArgs(
  configArgs: Record<string, number>,
) {
  for (const [configKey, requiredRooms] of Object.entries(configArgs)) {
    if (requiredRooms > 0) {
      return configKey;
    }
  }

  return "";
}
