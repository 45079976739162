import React, { useMemo } from "react";
import useCountryCode from "../../../hooks/use-country-code";
import withLohonoBlackTheme from "../../LohonoBlack/Theme";
import PropertyBanner from "../PropertyBanner";
import Specification from "../Specification";
import Header from "../../LohonoBlack/Header";
import Elevated from "./Elevated";
import GalleryPreview from "./GalleryPreview";
import Amenities from "./Amenities";
import Activities from "./Activities";
import HouseRule from "../HouseRule";
import ContactUs from "../../LohonoBlack/ContactUs";
import SendRequest from "../../SendRequestNeedHelp";
import ReadMore from "../ReadMore";
import clxs from "./lohono-black-layout.module.css";
import CHEVRON_RIGHT_PRIMARY from "../../../assets/lohono-black/icons/chevron-right-primary.svg";
import useIsMobile from "../../../hooks/use-is-mobile";

interface LohonoBlackLayoutProps {
  propertyDetails: Property;
  onShowGallery: () => void;
  onShare: () => Promise<boolean>;
}

const LohonoBlackLayout = withLohonoBlackTheme(
  function LohonoBlackLayoutCore(
    props: LohonoBlackLayoutProps,
  ) {
    const { propertyDetails, onShowGallery, onShare } = props,
      { isMobile } = useIsMobile(),
      { countryCode } = useCountryCode(),
      pricingContent = useMemo(
        () => {
          if (countryCode === "IN") {
            return "₹ 2,50,000";
          }

          return "£ 2,600";
        },
        [countryCode],
      ),
      {
        name,
        address,
        banner,
        accommodation,
        bedroom_count,
        bath_count,
        pool_count,
        description,
        images,
        house_rules,
        cancellation_policy,
        builder,
      } = propertyDetails,
      configs = useMemo(
        () => {
          const pkg: PropertyConfig = {
            price: 0,
            config_id: "",
            room_name: `${bedroom_count}`,
            bedrooms: 0,
            bed_type: "",
            discounted_method: "",
            discount_value_config:0,
            discount_type:"",
            tier_discount_percentage: "",
            available_inventory: 0,
            max_occupancy: 0,
            room_extra_occupancy: "",
            discounted_price: 0,
            images: [],
            amenities: [],
            faqs: [],
          };

          return [pkg];
        },
        [bedroom_count],
      );

    return (
      <>
        <Header />
        <div className={clxs.container}>
          <PropertyBanner
            banner={banner}
            builder={builder}
            name={name}
            address={address}
            className={clxs.banner}
            onViewPhotos={onShowGallery}
            onShare={onShare}
          />
          <div className={clxs.titleContainer}>
            <h1 className={clxs.title}>
              {name}
            </h1>
            <div className={clxs.startFromContainer}>
              Starts from <br />
              <span className={clxs.price}>
                {pricingContent}
              </span>
              {" / Night"}
            </div>
            <div className={clxs.subtitle}>
              {address}
            </div>
          </div>
          <div className={clxs.calculatorContainer}>
            <SendRequest
              className={clxs.sendRequest}
              interest="rental"
              brand={5}
              title="Join the Guest List"
              subtitle="Please share your details so our Lohono Black villa specialist can help you design your getaway"
            />
          </div>
          <Specification
            bedroomCount={0}
            viewLayout="standard"
            accommodation={accommodation}
            configs={configs}
            bathCount={bath_count}
            poolCount={pool_count}
          />
          <hr className={clxs.divider} />
          <ReadMore
            className={clxs.description}
            contentClassName={clxs.readMoreCollapsed}
            readMoreIcon={CHEVRON_RIGHT_PRIMARY}
          >
            {description}
          </ReadMore>
          <hr className={clxs.divider} />
          <Elevated className={clxs.elevated} />
          <hr className={clxs.divider} />
          <GalleryPreview
            images={images}
            onShowGallery={onShowGallery}
          />
          <hr className={clxs.divider} />
          <Amenities />
          <hr className={clxs.divider} />
          <Activities
            className={clxs.twoColumn}
            maxVisible={12}
          />
          {house_rules.length ? (
            <div className={clxs.houseRulesContainer}>
              <hr className={clxs.dividerTwoColumn} />
              <HouseRule
                title="House Rules"
                houseRules={house_rules}
                maxVisible={isMobile ? 0 : 2}
                className={clxs.houseRules}
                isSticky={isMobile}
              />
            </div>
          ) : null}
          {cancellation_policy.length ? (
            <div className={clxs.cancellationPolicyContainer}>
              <hr className={clxs.dividerTwoColumn} />
              <HouseRule
                title="Cancellation Policies"
                houseRules={cancellation_policy}
                maxVisible={isMobile ? 0 : 2}
                className={clxs.houseRules}
                isSticky={isMobile}
              />
            </div>
          ) : null}
          <ContactUs />
        </div>
      </>
    );
  },
);

export default LohonoBlackLayout;
