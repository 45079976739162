import format from "date-fns/format";
import React, {
  createElement,
  MouseEventHandler,
  ReactNode,
  useMemo,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import clxs from "./collection-property-item.module.css";
import CHEVRON_RIGHT_PRIMARY1 from "../../../assets/icons/chevron-right-primary.svg";
import PET_FRIENDLY from "../../../assets/icons/pet-friendly.svg";
import WELLNESS_ICON from "../../../assets/icons/wellness.svg";
import CHEVRON_RIGHT_PRIMARY2 from "../../../assets/lohono-black/icons/chevron-right-primary.svg";
import decodeLocationSlug from "../../../../utils/decode-location-slug";
import useClx from "../../../hooks/use-clx";
//import LoyaltyPointShortTicker from "../../LoyaltyPointShortTicker";
//import CollectionAmenities from "../CollectionAmenities";
import Carousel from "../../Carousel";
import Item from "../../Carousel/Item";
import ScrollCrumb from "../../Home/PropertyCarousel/ScrollCrumb";
//import CustomDiscount from "../CustomDiscount";
import LoyaltyPointShortTicker from "../../LoyaltyPointShortTicker";
import RatingCounts from "../../PropertyDetail/RatingCounts";
import useIsMobile from "../../../hooks/use-is-mobile";
// import RIGHT_ICON from "../../../assets/icons/right-arrow.svg";
// import { useCarouselScroller } from "../../../hooks/use-carousel";

interface CollectionPropertyItemProps {
  property: PropertyItem;
  className?: string;
  routable?: boolean;
  subscript?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler;
}

function CollectionPropertyItem(props: CollectionPropertyItemProps) {
  const [search] = useSearchParams(),
    carouselRef = useRef<HTMLDivElement | null>(null),
    {
      property,
      routable = true,
      className,
      subscript = "/night",
      children,
      onClick,
    } = props,
    {
      location_slug,
      property_slug,
      name,
      address,
      accommodation,
      pool_count,
      bath_count,
      currency_symbol,
      bedroom_count,
      rate,
      discounted_rate,
      sold_out,
      upcoming,
      status,
      brand,
      pet_friendly,
      configs,
      next_availability,
      discount_method,
      discount_value,
      discount_type,
      //loyalty_point_discount_percentage,
      images,
      view_layout,
      //amenities,
      //custom_discount_percentage,
      average_ratings,
      ratings_count,
    } = property,
    carouselId = useMemo(
      () => `${CAROUSEL_ID}-${property_slug}`,
      [property_slug],
    ),
    { isMobile } = useIsMobile(),
    // { scrollRight } = useCarouselScroller(CAROUSEL_ID),
    // { visibleSet } = useCarouselScroller(carouselId),
    // [visible, setVisible] = useState<PropertyImage | null>(null),
    // handleVisibleChange = (visibleSet: Set<number>) => {
    //   const visible = [...visibleSet];

    //   if (!visible.length) {
    //     return;
    //   }

    //   if(!images?.length) {
    //     return;
    //   }

    //   setVisible(images[visible[0]]);
    // },
    // { scrollLeft, scrollRight } = useCarouselScroller(CAROUSEL_ID),
    specs = useMemo(() => {
      const specs: string[] = [`${accommodation} Guests`];

      if (view_layout === "multi_config") {
        specs.push(
          `${bedroom_count} ${bedroom_count > 1 ? "Bedrooms" : "bedroom"}`,
        );
      } else if (configs.length) {
        const partial = configs.map((each) => each.bedrooms).join(", ");

        specs.push(`${partial} ${partial == "1" ? "Bedroom" : "Bedrooms"}`);
      }

      if (bath_count) {
        specs.push(
          `${bath_count} ${bath_count > 1 ? "Bathrooms" : "Bathroom"}`,
        );
      }

      if (pool_count) {
        specs.push(`${pool_count} ${pool_count > 1 ? "Pools" : "Pool"}`);
      }

      return specs;
    }, [accommodation, bath_count, pool_count, configs]),
    strikePrice = useMemo(() => {
      if (!discounted_rate) {
        return "";
      }
      if (discounted_rate == rate) {
        return "";
      }
      return `${currency_symbol}${rate.toLocaleString("en-IN")}`;
    }, [discounted_rate, rate, currency_symbol]),
    finalPrice = useMemo(() => {
      const finalPrice = discounted_rate || rate;

      return `${currency_symbol}${finalPrice.toLocaleString("en-IN")}`;
    }, [discounted_rate, rate, currency_symbol]),
    { location, destination } = useMemo(
      () => decodeLocationSlug(location_slug),
      [location_slug],
    ),
    isUnavailable = useMemo(
      () => status === "unavailable" || upcoming,
      [status, upcoming],
    ),
    isBlack = useMemo(() => brand === "lohono_black", [brand]),
    properties = [
      "ishavilas",
      "ishavilas-7",
      "villa-azul",
      "isp-villa-verde",
      "isp-villa-branco",
      "ishanya",
    ],
    hasWellnessPromotion = useMemo(
      () => properties.includes(property_slug),
      [property_slug],
    ),
    ccx = useClx(clxs.container, isBlack ? clxs.black : null, className),
    rootElement = useMemo(() => {
      if (!routable) {
        return "div";
      }
      return "a";
    }, [routable]),
    nextAvailableDateNode = useMemo(() => {
      if (!sold_out) {
        return null;
      }

      const { checkin_date, checkout_date } = next_availability;

      if (!checkin_date || !checkout_date) {
        return null;
      }

      const checkin = format(new Date(checkin_date), "d LLL"),
        checkout = format(new Date(checkout_date), "d LLL");

      return (
        <div className={clxs.datesContainer}>
          <div className={clxs.nextDate}>{isMobile ? "- Next Available Dates:" : "Next Available Dates:" }</div>
          <div className={clxs.dates}>{`${checkin} - ${checkout}`}</div>
        </div>
      );
    }, [next_availability, sold_out, clxs.datesContainer, clxs.dates]),
    tickerNode = useMemo(() => {
      if (upcoming) {
        return <div className={clxs.upcoming}>Coming soon</div>;
      }

      if (sold_out) {
        return <div className={clxs.sold}>Sold out</div>;
      }

      if (isUnavailable) {
        return <div className={clxs.unavailable}>Unavailable</div>;
      }

      return null;
    }, [upcoming, sold_out, isUnavailable]),
    renderSoldOut = useMemo(() => {
      //If soldout or upcomming is true and nextAvailableDate is not present then
      const condition1 = (upcoming || sold_out) && !nextAvailableDateNode;

      //If sold_out ,upcoming ,isUnavailable is true
      const condition2 = sold_out || upcoming || isUnavailable;

      if (condition1) {
        return <div className={clxs.soloSoldOut}>{tickerNode}</div>;
      } else if (condition2) {
        return (
          <div className={clxs.soldOut}>
            {tickerNode}
            {nextAvailableDateNode}
          </div>
        );
      } else {
        return null;
      }
    }, [sold_out, upcoming, nextAvailableDateNode, isUnavailable]),
    cProps = useMemo(() => {
      const baseProps = {
        className: ccx,
        onClick: onClick,
      };

      if (!routable) {
        return baseProps;
      }

      const updatedSearchParams = new URLSearchParams(search);

      if (!updatedSearchParams.has("adult_count")) {
        updatedSearchParams.set("adult_count", "2");
      }

      if (
        sold_out &&
        next_availability.checkin_date &&
        next_availability.checkin_date
      ) {
        updatedSearchParams.set(
          "checkin_date",
          new Date(next_availability.checkin_date).toISOString(),
        );

        updatedSearchParams.set(
          "checkout_date",
          new Date(next_availability.checkout_date).toISOString(),
        );
      }

      const safeSearch = updatedSearchParams.toString(),
        path = `/villas/${destination}/${location}/${property_slug}`,
        url = safeSearch ? `${path}?${safeSearch}` : path;

      return {
        ...baseProps,
        href: url,
        title: `View details of ${name}`,
      };
    }, [
      routable,
      name,
      location,
      destination,
      property_slug,
      next_availability,
      sold_out,
      ccx,
      onClick,
      search,
    ]),
    preventRedirect = (e: React.MouseEvent) => {
      e.preventDefault();
    };

  // useEffect(() => {
  //   handleVisibleChange(visibleSet);
  // }, [visibleSet]);

  return createElement(
    rootElement,
    cProps,
    <>
      <div 
        className={clxs.thumbnailContainer} 
        onClick={preventRedirect}
      >
        <Carousel
          className={clxs.thumbnail}
          id={carouselId}
          threshold={0.98}
          forwardRef={carouselRef}
        >
          {images.map((each, key) => {
            const { url, alt } = each;
            return (
              <Item 
                className={clxs.item} 
                key={key}
              >
                <img 
                  src={url} 
                  alt={alt} 
                  className={clxs.imgSelect} 
                />
              </Item>
            );
          })}
        </Carousel>
        <ScrollCrumb
          carouselId={carouselId}
          count={images.length}
          className={clxs.scrollCrumb}
          isShowArrows={true}
        />
        {/* <img
          src={RIGHT_ICON}
          alt="icon"
          className={clxs.arrowIcon}
          onClick={scrollRight}
        /> */}
      </div>
      <h3
        className={
          sold_out || upcoming || isUnavailable ? clxs.title : clxs.full_title
        }
        title={name}
      >
        {name}
      </h3>
      <div className={clxs.subtitle}>{address}</div>
      <div className={clxs.specContainer}>
        {specs.map((each, i) => (
          <div 
            key={i} 
            className={clxs.spec}
          >
            {each}
          </div>
        ))}
      </div>
      {renderSoldOut}
      {/* <CollectionAmenities
        className={clxs.amenities}
        amenities={amenities}
      /> */}
      {isUnavailable ? (
        <div>&nbsp;</div>
      ) : (
        <div className={clxs.pricingContainer}>
          {/* <CustomDiscount
            discountPercentage={custom_discount_percentage}
            method={discount_method}
            className={clxs.customDiscount}
          />  */}
          <div className={clxs.price}>
            {discount_value ?
            <LoyaltyPointShortTicker
              discountType={discount_type}
              discountPercentage={discount_value}
              method={discount_method}
              currencySymbol={currency_symbol}
              className={clxs.llpTicker}
            />
            : null
            }
            
            {strikePrice ? (
              <span className={clxs.strike}>{strikePrice}</span>
            ) : null}
            <span className={clxs.amount}>{finalPrice}</span>
            <span className={clxs.subscript}>{subscript}</span>
            <sup className={clxs.subscript}>*</sup>
          </div>
          <div className={clxs.excTax}>* Exclusive of taxes</div>
          <div 
            className={clxs.view} 
            data-sold-out={sold_out}
          >
            <span>know more</span>
            <img
              src={isBlack ? CHEVRON_RIGHT_PRIMARY2 : CHEVRON_RIGHT_PRIMARY1}
              alt="jump"
            />
          </div>
        </div>
      )}
      {pet_friendly && (
        <div className={clxs.petFriendly}>
          <img 
            src={PET_FRIENDLY} 
            alt="icon" 
          />
          <div>Pet Friendly villa</div>
        </div>
      )}
      {/* Check if both ratings_count and average_ratings */}
      {ratings_count && average_ratings ?
        <RatingCounts
          className={clxs.ratingCounts}
          average_ratings={average_ratings}
          ratings_count={ratings_count}
          theme= "collectionItemRating" // for color theme
        /> : null }
      {hasWellnessPromotion && (
        <img 
          src={WELLNESS_ICON} 
          alt="icon" 
          className={clxs.wellness} 
        />
      )}
      {children}
    </>,
  );
}

export default CollectionPropertyItem;

const CAROUSEL_ID = "room-details-image";
