import React from "react";
import { useLocation } from "react-router-dom";
import clxs from "./bread-crumbs.module.css";
import usePathParam from "../../../../hooks/use-path-params";

interface BreadCrumbsProps {
    propertyName: string,
    address: string,
}

function BreadCrumbs(props: BreadCrumbsProps) {
    const { pathname } = useLocation(),
    { propertyName, address } = props,
    location = usePathParam(PATH, "location"),
    destination = usePathParam(PATH, "destination"),
    locationUrl = "/villas/" + destination + "/" + location.trim().toLowerCase().replace(/\s+/g, "");
    
    return (
        <div>
          <a 
            href="/" 
            className={clxs.link}
          >
            Home
          </a>

          <span className={clxs.divider}>/</span>

          <a 
            href={locationUrl}
            className={clxs.link}
          >
            {location}
          </a>

          <span className={clxs.divider}>/</span>

          <a 
            href={pathname}
            className={clxs.activeLink}
          >
            {propertyName}
            <span style={{fontWeight: 300, fontSize: "14px"}}>{" • "}</span>
            {address}
          </a>
        </div>
    )
}

export default BreadCrumbs;


const PATH = "/villas/:destination/:location/:property";