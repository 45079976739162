import debounce from "lodash.debounce";
import React, {
  ChangeEvent,
  HTMLAttributes,
  InputHTMLAttributes,
  KeyboardEvent,
  LabelHTMLAttributes,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useClx from "../../hooks/use-clx";
import ExpandIcon from "./ExpandIcon";
import clxs from "./searchSelect.module.css";
import SEARCH_LOCATION from "../../assets/icons/search-location.svg";
import SEARCH_VILLA from "../../assets/icons/search-villa.svg";
import RECENT_SEARCH_LOCATION from "../../assets/icons/recent-search-location.svg";
import RECENT_SEARCH_VILLA from "../../assets/icons/recent-search-villa.svg";

export interface SelectProps extends InputHTMLAttributes<HTMLInputElement> {
  options: Option[];
  containerProps?: Omit<HTMLAttributes<HTMLDivElement>, "className">;
  label?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  searchable?: boolean;
  defaultValue?: string;
  value?: string;
  default_property?: string;
  error?: string;
  isShowSelect?: boolean;
  iconMobile?: string;
  iconDesktop?: string;
  isHomeDestination?: boolean;
  viewlayout?: boolean;
  recentSearchOptions?: Option[];
  selectedVillaSlug?: string;
}

/**
 * This New select option component is drop down which supports search select and normal select features.
 * @param props 
 */
function SearchSelect(props: SelectProps) {
  const {
    containerProps = {},
    label,
    labelProps = {},
    options: allOptions,
    recentSearchOptions,
    searchable = true,
    defaultValue = "",
    onChange,
    onBlur,
    onFocus,
    error,
    iconMobile,
    iconDesktop,
    placeholder: _placeholder,
    isShowSelect,
    isHomeDestination,
    viewlayout,
    selectedVillaSlug,
    ...rest
  } = props,
    { value: _value, name, disabled = false, default_property } = rest,
    optionsRef = useRef<HTMLDivElement>(null), // Reference for options dropdown
    inputRef = useRef<HTMLInputElement>(null), // Reference for input field
    [value, setValue] = useState<string>(defaultValue), // State for selected value
    placeholder = useMemo(
      () => _placeholder || "", // Memoize placeholder to avoid recalculation
      [_placeholder],
    ),
    [text, setText] = useState<string>(""), // State for the input text
    [focused, setFocused] = useState<boolean>(false), // State to track if input is focused
    [mouseOver, setMouseOver] = useState<boolean>(false), // State to track mouse over options
    [selectedIdx, setSelectedIdx] = useState<number>(() =>
      Math.max(
        allOptions.findIndex(({ value: v }) => value === v), // Find index of the selected value
        0,
      ),
    ),
    [options, setOptions] = useState<Option[]>(allOptions), // State for all options
    [filteredOptions, setFilteredOptions] = useState<Option[]>(), // State for filtered options
    { className: _ccx } = rest,
    { className: _lcx } = labelProps,
    ccx = useClx(clxs.container, _ccx),
    lcx = useClx(clxs.label, "label", _lcx),
    icx = useClx(clxs.input, "input"),
    iccx = useClx(clxs.expandIcon, "expand-icon"),

    /**
     * This method filters the options based on search input text.
     * @param allOptions - the full list of options
     * @param text - the search text entered by the user
     * @returns void
     */
    handleSearchChange = (allOptions: Option[], text: string) => {

      if (!text && filteredOptions) {
        setOptions(filteredOptions); // Reset options if no text is entered

        return;
      }

      const options = allOptions.filter(
        (each) => each.label.toLowerCase().includes(text.toLowerCase()), // Filter options based on search text
      );

      setOptions(options); // Update state with filtered options
    },

    /**
     * This method handles input text changes.
     * @param e - the change event from the input field
     * @returns void
     */
    handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target; // Get input value

      setText(value); // Update input text

      // Call debounce function to filter options based on search
      _dOnSearchChange(handleSearchChange, allOptions, value);

      // Clear selection if input is empty this was commented for value change.
      // if (!value.length) {
      //   handleValueChange(value);
      //   handleChange({ label: "", value: "", type: "" });
      // }

      // Automatically open options when user types
      if (value && !focused) {
        setFocused(true);
      }
    },

    /**
     * This method handles focus events for the input field.
     * @returns void
     */
    handleFocus = () => {
      setFocused(true);

      const target = { name: name, id: name, value: value },
        payload = { target: target, currentTarget: target };

      if (!onFocus) {
        return;
      }

      onFocus(payload as any);
    },

    /**
     * This method updates the selected value and corresponding text based on the input value.
     * @param value - the new value to set as the selected option
     * @returns void
     */
    handleValueChange = (value?: string) => {
      if (value === undefined) {
        return;
      }

      setValue(value);

      if (value == "") {
        setText(""); // Clear input text if value is empty
        setOptions(options); // Reset options
        return;
      }

      // Find the index of the selected value
      const selectedIdx = Math.max(
        options.findIndex(({ value: v }) => value === v),
        0,
      );

      setSelectedIdx(selectedIdx); // Update selected index
      setText(options[selectedIdx]?.label || _placeholder || ""); // Set input text to selected option's label

      setOptions(options); // Reset options
      // TODO : handleScrollIntoViewOption(selectedIdx); // Scroll selected option into view
    },

    /**
     * This method scrolls the selected option into view
     * @param idx - the index of the option to scroll to
     * @returns void
     */
    handleScrollIntoView = (idx: number) => {
      const { current: parent } = optionsRef;

      if (!parent) {
        return; // Return if parent is not defined
      }

      if (idx < 0 || idx > parent.childElementCount - 1) {
        return; // Return if index is out of bounds
      }

      const parentTop = parent.scrollTop,
        parentBottom = parentTop + parent.clientHeight,
        element = parent.children[idx], // Get the selected option
        { top, bottom } = element.getBoundingClientRect(); // Get element dimensions

      // Check if element is already in view
      if (top < parentBottom && bottom > parentTop) {
        return;
      }

      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },

    /**
     * This method handles option selection.
     * @param option - the selected option object
     * @returns void
     */
    handleChange = (option: { label: string, value: string, type: string, location_value?: string }) => {
      if (!option) {
        return;
      }

      if (option) {
        setValue(option.value);
        setText(option.label);
      }

      const target = {
        name: name,
        id: name,
        value: option.type === "property" ? option.location_value : option.value,
        villa_slug: option.type === "property" ? option.value : "",
      },
        payload = { target: target, currentTarget: target };

      if (!onChange) {
        return;
      }

      onChange(payload as any); // Pass the new value to the parent
    },

    /**
     * This method handles keyboard interactions in the input field.
     * @param e - the keyboard event
     * @returns void
     */
    handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      const { keyCode } = e;

      if (!focused) {
        // space
        if (keyCode === 32) {
          e.preventDefault();
          setFocused(true);
          return;
        }

        if (keyCode > 64 && keyCode < 91) {
          setFocused(true);
          return;
        }

        return;
      }

      // up
      if (keyCode === 38) {
        e.preventDefault();
        e.stopPropagation();
        const idx = Math.max(0, selectedIdx - 1);
        setSelectedIdx(idx);
        handleScrollIntoView(idx);
        return;
      }

      // down
      if (keyCode === 40) {
        e.preventDefault();
        e.stopPropagation();
        const idx = Math.min(options.length - 1, selectedIdx + 1);
        setSelectedIdx(idx);
        handleScrollIntoView(idx);
        return;
      }

      // esc
      if (keyCode === 27) {
        e.stopPropagation();
        setFocused(false);
        return;
      }

      // enter
      if (keyCode === 13) {
        e.preventDefault();
        setFocused(false);
        handleChange(options[selectedIdx]);
        return;
      }
    },

    /**
     * This method handles blur events for the input field.
     * @returns void
     */
    handleBlur = () => {
      setFocused(false);
      const target = { name: name, id: name, value: value },
        payload = { target: target, currentTarget: target };

      handleValueChange(value);

      if (!onBlur) {
        return;
      }

      onBlur(payload as any);
    },

    /**
     * This method handles option selection when an option is clicked.
     * @param option - the selected option object
     * @param e - the mouse event
     * @returns void
     */
    handleOptionClick = (
      option: { label: string; value: string, type: string },
      e: MouseEvent<HTMLDivElement>,
    ) => {
      e.preventDefault();

      setFocused(false);

      //Remove the focus from the input element so as to close the keyboard.
      inputRef?.current?.blur();

      handleChange(option);
    },

    /**
     * This method handles click events on the input field.
     * @returns void
     */
    handleClick = () => {
      if (!disabled) {
        setFocused(true);
        inputRef.current?.focus();
      }
    },

    /**
     * This method sets the initial value for the input field and options.
     * @param value - the initial value to set
     * @returns void
     */
    setInitialValue = (value?: string, _default_property?: string) => {
      if (value === undefined) {
        return;
      }

      if (_default_property) {
        setValue(_default_property);
      } else {
        setValue(value);
      }

      if (value == "") {
        setText("");
        return;
      }

      const selectedIdx = Math.max(
        allOptions.findIndex(({ value: v }) => {
          if (_default_property) {
            return _default_property === v;
          } else {
            return value === v;
          }

        }),
        0,
      );

      setSelectedIdx(selectedIdx);

      setText(allOptions[selectedIdx]?.label || _placeholder || "");
    },
    handleLabelClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!disabled) {
        setFocused(true);
        inputRef.current?.focus();
      }
    };

  // useEffect(() => {
  //   handleSearchChange(allOptions, ""), setInitialValue(_value, default_property);
  // }, [allOptions]);

  useEffect(() => handleValueChange(_value), [_value]);

  useEffect(() => {
    if (isShowSelect) {
      handleFocus();
      setFocused(true);
    } else {
      setFocused(false);
    }
  }, [isShowSelect]);

  useEffect(() => {
    handleSearchChange(allOptions, "");

    // Set initial filtered options based on the condition
    const filteredOptions = allOptions.filter(
      (option) => option.type === "location",
    );

    setOptions(filteredOptions); // Set the filtered options to the state
    setFilteredOptions(filteredOptions)

    if (selectedVillaSlug) {
      // setOptions(allOptions);
      setInitialValue(_value, selectedVillaSlug); // Set initial value if available
    } else {
      setInitialValue(_value, default_property); // Set initial value if available
    }

  }, [allOptions]);

  return (
    <div
      {...containerProps}
      data-focus={focused}
      data-disabled={disabled}
      data-highlight={viewlayout}
      className={ccx}
      onKeyDown={handleKeyDown}
    >
      {label && (
        <label
          {...labelProps}
          htmlFor={name}
          className={isHomeDestination ? `${clxs.homeLableIcon} ${lcx}` : lcx}
          onClick={handleLabelClick}
        >
          {label}
        </label>
      )}
      <input
        {...rest}
        id={name}
        type="text"
        name={name}
        value={text}
        ref={inputRef}
        className={isHomeDestination ? `${clxs.homeInput} ${icx}` : icx}
        autoComplete="off"
        data-error={Boolean(error).valueOf()}
        readOnly={!searchable}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleTextChange}
        onFocus={handleFocus}
        onClick={handleClick}
        suppressHydrationWarning={true}
      />
      {error && <div className={clxs.error}>{error}</div>}
      <ExpandIcon
        className={isHomeDestination ? `${clxs.homeExpandicon} ${iccx}` : iccx}
      />
      {focused && (
        <div
          data-hover={mouseOver}
          // className={focused ? `${clxs.options} ${clxs.showOptions}` : `${clxs.options} ${clxs.hideOptions}` }
          className={clxs.optionsContainer}
          onMouseEnter={setMouseOver.bind(null, true)}
          onMouseLeave={setMouseOver.bind(null, false)}
          ref={optionsRef}
        >
          {recentSearchOptions && recentSearchOptions.length > 0 ?
            <div className={clxs.recentSearches}>
              <span className={clxs.searchHeaders}>RECENT SEARCHES</span>
              <div className={clxs.searchTags}>
                {recentSearchOptions.map((each, index) => (
                  <div
                    key={index}
                    className={clxs.searchTag}
                    data-value={each.value}
                    onMouseDown={handleOptionClick.bind(null, each)}
                  >
                    {each.type === "location" ?
                      <img
                        src={RECENT_SEARCH_LOCATION}
                        alt="recent-search-location-icon"
                      />
                      : (each.type === "property" ?
                        <img
                          src={RECENT_SEARCH_VILLA}
                          alt="recent-search-property-icon"
                        />
                        : null
                      )
                    }
                    {each.label}
                  </div>
                ))}
              </div>
            </div>
            : null
          }
          <div className={clxs.searchContainer}>
            <span className={clxs.searchHeaders}>ALL LOCATIONS</span>
            {options.length ?
              options.map((option, key) => (
                <div
                  key={key}
                  className={clxs.option}
                  data-value={option.value}
                  data-focused={option.value === value} // Change to compare option.value with selected value
                  onMouseDown={handleOptionClick.bind(null, option)}
                >
                  {option.type === "location" ?
                    <img
                      src={SEARCH_LOCATION}
                      alt="search-location-icon"
                      className={clxs.searchIcon}
                    />
                    : (option.type === "property" ?
                      <img
                        src={SEARCH_VILLA}
                        alt="search-property-icon"
                        className={clxs.searchIcon}
                      />
                      : null
                    )
                  }
                  {(iconMobile || iconDesktop) ?
                    ((iconMobile ?
                      <img
                        src={iconMobile}
                        alt="icon"
                        className={clxs.iconMobile}
                      /> :
                      <img
                        src={iconDesktop}
                        alt="icon"
                        className={clxs.iconDesktop}
                      />))
                    : null
                  }

                  <div className={clxs.optionText}>
                    <div className={clxs.optionTitle}>{option.label}</div>
                    <div className={clxs.optionSubtitle}>{option.address}</div>
                  </div>

                </div>
              )) : (
                <div className={clxs.noOption}>
                  No options
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchSelect;

const _dOnSearchChange = debounce(
  (
    cb: (options: Option[], value: string) => void,
    options: Option[],
    text: string,
  ) => cb(options, text),
  500,
  { trailing: true },
);

type Option = { label: string; value: string, type: string, address?: string, location_value?: string };
