import React, { useEffect, useMemo, useState } from "react";
import differenceInDays from "date-fns/differenceInDays";
import Modal, { useShowModal } from "../../../../../hooks/use-modal";
import { useQueryParamValue, useQuerySearch } from "../../../../../hooks/use-query-param";
import useClx from "../../../../../hooks/use-clx";
import Button from "../../../../Button";
import Breakdown from "../../../../Breakdown";
import PropertySearchMobile from "../../../../PropertySearchMobile";
import { usePriceCalculatorValue } from "../../../../../hooks/use-price-calculator";
import clxs from "./price-calculator.module.css";
// import INFO_GREY_ICON from "../../../../../assets/icons/info-grey.svg";
// import EDIT_ICON from "../../../../../assets/icons/edit.svg";
import PRICE_CALCULATOR_SCHEMA from "./price-calculator-schema";
import { generatePropertyConfigKey, usePropertyConfigValues } from "../../../../../hooks/use-property-config";
import searchParamsFromFormValues from "../search-params-from-form-values";
import { useUser } from "../../../../../hooks/use-session";
import { useLoginModal } from "../../../../../hooks/use-login-modal";
import useAnalytics from "../../../../../hooks/use-analytics";
// import PreBookMeals from "../../MealPlanOptions/PreBookMeals";
import { getDay } from "date-fns";
import { useSnackbar } from "../../../../../hooks/use-snackbar";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import { getAnalyticsVillaLayout } from "../../../../../../utils/get-analytics-property-type";
import LoyaltyPointShortTicker from "../../../../LoyaltyPointShortTicker";
import TaxTooltip from "../../../../Checkout/TaxTooltip";

interface PriceCalculatorProps {
  viewLayout: string;
  id: string;
  propertySlug: string;
  couponCodeKey: string;
  checkinDateKey: string;
  checkoutDateKey: string;
  adultCountKey: string;
  childCountKey: string;
  maxAdultCount: number;
  maxChildCount: number;
  className?: string;
  configs: PropertyConfig[];
  onSubmit: (value: any) => void;
  onSendRequest: (value: any) => void;
  onChange?: (value: any) => void;
  mealRef?:React.RefObject<HTMLDivElement>;
  isVegOrNonVegAvailable?: boolean;
  average_ratings?: number;
  reviews_count?: number;
  villa_name?: string;
}

function PriceCalculator(props: PriceCalculatorProps) {
  const [_, setQuerySearch] = useQuerySearch(),
    // showBreakdownModal = useShowModal(BREAKDOWN_MODAL_ID),
    showPropertySearchModal = useShowModal(PROPERTY_SEARCH_MODAL_ID),
    {
      viewLayout,
      id,
      propertySlug,
      checkinDateKey,
      checkoutDateKey,
      adultCountKey,
      childCountKey,
      maxAdultCount,
      maxChildCount,
      configs,
      couponCodeKey,
      // mealRef,
      // isVegOrNonVegAvailable,
      onSubmit,
      onSendRequest,
      onChange,
      average_ratings,
      reviews_count,
      villa_name,
    } = props,
    isMobile = useIsMobile(),
    checkin_date = useQueryParamValue("date", checkinDateKey),
    checkout_date = useQueryParamValue("date", checkoutDateKey),
    adult_count = useQueryParamValue("integer", adultCountKey),
    child_count = useQueryParamValue("integer", childCountKey),
    preBookMeals = useQueryParamValue("boolean", queryKey),
    couponCode = useQueryParamValue("string", couponCodeKey),
    enqueueSnackbar = useSnackbar(),
    {
      values: config_args_search_values,
      configMaxCount,
      configsSelectedCount,
    } = usePropertyConfigValues(propertySlug, configs),
    packageOptions = useMemo(
      () => {
        const options: any[] =  configs.map(
          config => {
            const key = generatePropertyConfigKey(propertySlug, config);
            
            return {
              label: `${config.room_name}`,
              value: key,
            };
          },
        );
        
        return options;
      },
      [propertySlug, configs],
    ),
    { track } = useAnalytics(),
    showLoginFlowModal = useLoginModal(),
    user = useUser(),
    { tentativePrice } = usePriceCalculatorValue(id),
    symbol = useMemo(
      () => tentativePrice?.currency_symbol || "",
      [tentativePrice?.currency_symbol],
    ),
    { className } = props,
    [isDisableBooking,setIsDisableBooking] = useState<boolean>(false),
    average_non_discounted_rate = useMemo(
      () => {
        const average_non_discounted_rate = tentativePrice?.average_non_discounted_rate || 0;

        if (!average_non_discounted_rate) {
          setIsDisableBooking(true);
          return "-";
        }

        setIsDisableBooking(false);
        return `${symbol} ${average_non_discounted_rate.toLocaleString("en-IN")}`;
      },
      [tentativePrice],
    ),
    average_base_rate = useMemo(
      () => {
        const average_base_rate = tentativePrice?.average_base_rate;

        if (average_base_rate === undefined) {
          return null;
        }

        const total = tentativePrice?.average_non_discounted_rate || 0;

        if (average_base_rate === total) {
          return null;
        }

        return `${symbol} ${average_base_rate.toLocaleString("en-IN")}`;
      },
      [tentativePrice],
    ),
    // total = useMemo(
    //   () => {        
    //     const total = tentativePrice?.total || 0;

    //     if (!total) {
    //       setIsDisableBooking(true);
    //       return "-";
    //     }

    //     setIsDisableBooking(false);
    //     return `${symbol} ${total.toLocaleString("en-IN")}`;
    //   },
    //   [tentativePrice],
    // ),
    // discounted = useMemo(
    //   () => {
    //     const discountedTotal = tentativePrice?.discounted_total;

    //     if (discountedTotal === undefined) {
    //       return null;
    //     }

    //     const total = tentativePrice?.total || 0;

    //     if (discountedTotal === total) {
    //       return null;
    //     }

    //     return `${symbol} ${discountedTotal.toLocaleString("en-IN")}`;
    //   },
    //   [tentativePrice],
    // ),
    // totalGuests = useMemo(
    //   () => {
    //     let totalGuests = 0;

    //     if (adult_count) {
    //       totalGuests += adult_count;
    //     }

    //     if (child_count) {
    //       totalGuests += child_count;
    //     }

    //     if (!totalGuests) {
    //       return "";
    //     }

    //     return `${totalGuests} guests`;
    //   },
    //   [adult_count, child_count],
    // ),
    totalNights = useMemo(
      () => {
        if (!checkin_date || !checkout_date) {
          return "";
        }

        const diff = differenceInDays(checkout_date, checkin_date);

        return `${diff} nights`;
      },
      [checkin_date, checkout_date],
    ),
    superTitle = useMemo(
      () => {
        if (checkin_date || checkout_date) {
          return "";
        }
        return "Starts from";
      },
      [checkin_date, checkout_date],
    ),
    // subtitle = useMemo(
    //   () => {
    //     const subtitle: string[] = [];

    //     if (totalGuests) {
    //       subtitle.push(totalGuests);
    //     }

    //     if (totalNights) {
    //       subtitle.push(totalNights);
    //     }

    //     if (!subtitle.length) {
    //       subtitle.push("select dates");
    //     }

    //     return `(${subtitle.join(" and ")})`;
    //   },
    //   [totalGuests, totalNights],
    // ),
    isInvalid = useMemo(
      () => !tentativePrice,
      [tentativePrice],
    ),
    isUnavailable = useMemo(
      () => tentativePrice?.status === "unavailable",
      [tentativePrice],
    ),
    canCheckout = useMemo(
      () => {
        const can = Boolean(
          adult_count &&
          checkin_date &&
          checkout_date &&
          configsSelectedCount > 0,
        ).valueOf();

        return can;
      },
      [adult_count, checkin_date, checkout_date, tentativePrice?.sold_out],
    ),
    checkoutActionText = useMemo(
      () => {
        if (isUnavailable) {
          return "Enquire now";
        }

        if (tentativePrice?.sold_out) {
          return "Change Dates";
        }

        if (!checkin_date || !checkout_date) {
          return "Select dates";
        }

        if (!canCheckout) {
          return "Book";
        }

        return "Book Now";
      },
      [tentativePrice, isUnavailable, canCheckout, checkin_date, checkout_date],
    ),
    ccx = useClx(clxs.container, className),
    discountValue = useMemo(() => {
      switch (tentativePrice.breakdown.discount_type) {
          case "free_night":
              return `(${tentativePrice.discount_value} Night)`;
          case "percentage_discount":
              return `(${tentativePrice.discount_value}%)`;
          case "flat_discount":
              return `(${tentativePrice.currency_symbol}${tentativePrice.discount_value.toLocaleString("en-IN")})`;
          default:
              return "";
      }
     }, [tentativePrice]),
    taxTemplate = useMemo(
      () => {
        if (!tentativePrice) {
          return [];
        }
        const template: TaxBreakdown[] = [
          {
            taxes: tentativePrice.breakdown?.gross_base_rate || 0,
            sub_title: `${average_non_discounted_rate || average_base_rate}  x ${totalNights ? totalNights : "1" }`,
            description: "Stay charges",
          },
          {
            taxes: tentativePrice.tier_discount ? tentativePrice.tier_discount : tentativePrice.discount,
            sub_title: `${couponCode === "NONE" ? "" : couponCode} ${
              tentativePrice.tier_discount_percentage > 0 
                ? `(${tentativePrice.tier_discount_percentage}%)` 
                : tentativePrice.discount_value > 0 
                ? discountValue 
                : ""
            }`,
            description: "Discount",
          },
          {
            taxes: tentativePrice.breakdown?.config_taxes || 0,
            sub_title: `${tentativePrice.tax_percentage}%`,
            description: tentativePrice.currency_symbol === "₹" ? "GST" :"tax",
          },
          {
            taxes: tentativePrice.breakdown?.config_total || 0,
            sub_title: "",
            description: "Total Payable",
          },
        ]
        
        return template as TaxBreakdown[];
      },
      [tentativePrice],
    ),
    // handleShowBreakdown = async () => {
    //   if (!tentativePrice) {
    //     return;
    //   }

    //   const { breakdown } = tentativePrice;

    //   await showBreakdownModal({ breakdown: breakdown });
    // },
    handleChange = (values: any) => {
      const payload = searchParamsFromFormValues(propertySlug, viewLayout, configs, values);
      // Retaining previously selected coupon code.
      // if (couponCode && couponCode.length) {
      //   payload[couponCodeKey] = [couponCode];
      // }

      setQuerySearch(() => payload, { replace: true });

      onChange?.(values);
    },
    handleEdit = async () => {
      const packageSlug = inferPackageSlugFromConfigArgs(config_args_search_values);

      const defaultValue = {
          stay: {
            start: checkin_date,
            end: checkout_date,
          },
          occupancy: {
            adult_count: adult_count,
            child_count: child_count,
          },
          config_args: config_args_search_values,
          package_slug: packageSlug,
          pre_book_meals: preBookMeals,
        },
        value = await showPropertySearchModal(
          { defaultValue: defaultValue, is_edit_clicked: true },
        );

      if (!value) {
        return;
      }

      return handleChange(value);
    },
    getTrackPricePayloadObj = () => {
      return tentativePrice;
    },
    getTotalRooms = (configArgs: any) => {
      let totalRooms = 0;
      
      if(viewLayout === "multi_config") {
        Object.keys(configArgs).forEach((key) => {
          totalRooms = totalRooms + configArgs[key];
        });
      } else {
        configArgs.forEach((oConfig:any) => {
          totalRooms = totalRooms + oConfig.bedrooms;
        })
      }

      return totalRooms;
    },
    checkoutFunction = async () => {
      if (isInvalid) {
        return;
      }

      if (isUnavailable) {
        return onSendRequest({
          stay: [
            checkin_date,
            checkout_date,
          ],
          occupancy: {
            adult_count: adult_count,
            child_count: child_count,
          },
          configs: configs,
        });
      }

      if (!canCheckout || (tentativePrice?.sold_out ?? false)) {
        return handleEdit();
      }

      const trackingPayload = {
        tentativePrice: getTrackPricePayloadObj(),
        property_type: viewLayout,
        totalNights: totalNights,
        totalRooms: getTotalRooms(configs),
        meal_plan_opt: preBookMeals,
        ratings: average_ratings ? average_ratings : null,
        reviews_count: reviews_count ? reviews_count : null,
      };

      const ratingsTrackingPayload = {
        ratings: average_ratings ? average_ratings : null,
        reviews_count: reviews_count ? reviews_count : null,
      }

      // localStorage.setItem("trackingPayload", JSON.stringify(trackingPayload));
      localStorage.setItem("ratingsPayload", JSON.stringify(ratingsTrackingPayload));
      track("book_now_property_clicked", trackingPayload);
      

      return onSubmit({
        stay: {
          start: checkin_date,
          end: checkout_date,
        },
        occupancy: {
          adult_count: adult_count,
          child_count: child_count,
        },
        config_args: config_args_search_values,
        pre_book_meals: preBookMeals,
      });
    },
    isSingleWeekend = useMemo(() =>{
      if (!checkin_date || !checkout_date) {
        return true;
      }
      const periodInDays = differenceInDays(checkout_date,checkin_date);
      
      if (periodInDays !== 1) {
        return true;
      }

      const checkinDay = getDay(checkin_date ),
        checkoutDay = getDay( checkout_date);
       const isWeekend = !(checkinDay === 0 || checkinDay === 6
          || checkoutDay === 0 || checkoutDay === 6);
        return isWeekend ;
      },
      [checkin_date, checkout_date],
    ),  
    handleCheckout = () => {
      
      if(!canCheckout) {
        handleEdit();
        return;
      }
      
      //Check if the dates are for weekend and if yes don't allow user to proceed if only one day is selected and block them there only. REturn and exit the method. 
      if(!isSingleWeekend){
        enqueueSnackbar("Minimum 2-nights booking is required for weekend stays.","error");
        handleEdit();
        return;
      }

      //If the user is not loggedin show login popup then allow to move to checkout page.
      if((!user) || (user && (!user?.full_name || !user?.email))) {
        const modalProps = {
          className: "",
          onSubmit: checkoutFunction,
        }

        return showLoginFlowModal(modalProps).then((success: any) => {
          if(success) {
            checkoutFunction();
          }
        })
      } else {
        return checkoutFunction();
      }
    },
    // handleMealOptChange = (isChecked: boolean) => {

    //   if(isChecked) {
    //     const trackingPayload = {
    //       tentativePrice: getTrackPricePayloadObj(),
    //       property_type: getAnalyticsVillaLayout(viewLayout),
    //       totalNights: totalNights,
    //       totalRooms: getTotalRooms(configs),
    //       meal_plan_opt: isChecked,
    //       ratings: average_ratings ? average_ratings : null,
    //       reviews_count: reviews_count ? reviews_count : null,
    //     };
  
    //     track("meals_interested", trackingPayload)
    //   }
    // },
    handleVillaViewedEvent = () => {
      const trackingPayload = {
        tentativePrice: getTrackPricePayloadObj(),
        property_type: getAnalyticsVillaLayout(viewLayout),
        totalNights: totalNights,
        totalRooms: getTotalRooms(configs),
        meal_plan_opt: preBookMeals,
        ratings: average_ratings ? average_ratings : null,
        reviews_count: reviews_count ? reviews_count : null,
        property_name: villa_name,
      };

      if(isMobile) {
        track("villa_viewed",trackingPayload, [], "PROPERTY_DETAIL");
      } 
    };

  useEffect(handleVillaViewedEvent, []);

  return (
    <>
    {/* {isVegOrNonVegAvailable?
      <PreBookMeals
        queryKey={queryKey}
        className={clxs.preBookMeals}
        theme="priceCalculator"
        form={PROPERTY_SEARCH_MOBILE_FORM_ID}
        mealRef={mealRef}
        handleMealChange={handleMealOptChange}
      /> : null } */}
      <div className={ccx}>
        {isUnavailable && (
          <div className={clxs.unavailable}>
            Unavailable
          </div>
        )}
        {(tentativePrice?.sold_out && !isUnavailable) ?
        (<div className={clxs.soldOutContainer}>
          Sold out
        </div>) : null}
        <Button
          className={clxs.checkout}
          onClick={handleCheckout}
          disabled={isDisableBooking}
        >
          {checkoutActionText}
        </Button>
        {!isUnavailable && (
          <>
            <div className={clxs.price}>
               <div className={clxs.discountedContainer}>
                 <div className={clxs.startsFrom}>
                   {superTitle}
                 </div>
                 {average_base_rate && (<span className={clxs.strike}>
                   {average_non_discounted_rate}
                 </span>)}
                 {average_base_rate && (<div className={clxs.excTax}>
                 <LoyaltyPointShortTicker
                   discountType={tentativePrice?.breakdown.discount_type}
                   method={tentativePrice?.breakdown.discount_method}
                   discountPercentage={tentativePrice.discount_value ?  tentativePrice.discount_value : tentativePrice?.breakdown.loyalty_points_discount_percentage}
                   className={clxs.llpShortTicker}
                   currencySymbol= {tentativePrice.currency_symbol}
                 />
                 </div>)}
               </div>
              <div className={clxs.actualPricingContainer}>
                <span className={clxs.rate}>
                  {average_base_rate || average_non_discounted_rate} 
                </span>
                <div className={clxs.subscript}>/ night excluding taxes*</div>
                <TaxTooltip 
                  tax_data={taxTemplate} 
                  currency_symbol= {tentativePrice.currency_symbol}
                  hideSymbole={true}
                  title="Total Summary"
                  showCloseIcon={true}
                />
                {/* <img
                  src={INFO_GREY_ICON}
                  alt="breakdown"
                  className={clxs.info}
                  onClick={handleShowBreakdown}
                /> */}
              </div>
            </div>
            {/* <div className={clxs.subtitle}>
              {subtitle}
              <img
                src={EDIT_ICON}
                alt="edit"
                className={clxs.edit}
                onClick={handleEdit}
              />
            </div>
            <div className={clxs.excTax}>* Exclusive of taxes</div> */}
          </>
        )}
      </div>
      <Modal id={BREAKDOWN_MODAL_ID}>
        <Breakdown currencySymbol={symbol} />
      </Modal>
      <Modal
        id={PROPERTY_SEARCH_MODAL_ID}
        scrollBlock={false}
      >
        <PropertySearchMobile
          viewLayout={viewLayout}
          configMaxCount={configMaxCount}
          formId={PROPERTY_SEARCH_MOBILE_FORM_ID}
          hidden={HIDDEN_FIELDS}
          maxAdultCount={maxAdultCount}
          maxChildCount={maxChildCount}
          configs={configs}
          propertySlug={propertySlug}
          validationSchema={PRICE_CALCULATOR_SCHEMA}
          packageOptions={packageOptions}
          onSubmit={handleChange}
        />
      </Modal>
    </>
  );
}

export default PriceCalculator;

const BREAKDOWN_MODAL_ID = "breakdown-modal";

const PROPERTY_SEARCH_MODAL_ID = "property-search-modal";

const PROPERTY_SEARCH_MOBILE_FORM_ID = "property-search-mobile";

const HIDDEN_FIELDS: any = ["destination"];

const queryKey = "pre_book_meals";

function inferPackageSlugFromConfigArgs(
  configArgs: Record<string, number>,
) {
  for (const [configKey, requiredRooms] of Object.entries(configArgs)) {
    if (requiredRooms > 0) {
      return configKey;
    }
  }

  return "";
}