import React, { cloneElement, DialogHTMLAttributes, ReactElement } from "react";
import Portal from "../../components/Portal";
import useClx from "../use-clx";
import stl from "./modal.module.css";
import { SubmitProps } from "./use-modal";
import useModalState from "./use-modal-state";

type ModalProps<P extends SubmitProps<T>, T> = {
  children: ReactElement<P>;
  id: string;
  scrollBlock?: boolean;
  isPopupOverlapped?: boolean;
} & BaseModalProps;

function Modal<P extends SubmitProps<V>, V = any>(
  props: ModalProps<P, V>,
) {
  const { scrollBlock = true, children, isPopupOverlapped, ...nativeProps } = props;

  const { id, className } = nativeProps;

  const { childProps, open, handleClose } = useModalState<P, V>(id);

  // When popup opens on top of popup "isPopupOverlapped" flag can handle background colour
  const dcx = useClx(stl.backdrop, isPopupOverlapped ? stl.backdropColour : "", className);

  return (
    <Portal scrollBlock={scrollBlock && open}>
      <dialog
        {...nativeProps}
        className={dcx}
        onClick={handleClose}
        open={open}
        data-open={open}
      >
        {cloneElement(
          children,
          {
            ...(children.props ?? {}),
            ...childProps,
            open: open,
          },
        )}
      </dialog>
    </Portal>
  );
}

export default Modal;

type BaseModalProps = Omit<DialogHTMLAttributes<HTMLDialogElement>, "open" | "children">;
