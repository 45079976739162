import React from "react";
import useClx from "../../../hooks/use-clx";
import fwclxs from "../../../styles/full-width.module.css";
import clxs from "./banners.module.css";
import GENERIC_BANNER from "../../../assets/listing-page-generic.jpg";


interface BannersProps {
    className?: string;
    banners?:ListingBannerType[];
    isLoadingBanner?: boolean;
  }

function ListingBanner(props : BannersProps) {
    const {className,banners} = props,
    bcx = useClx(fwclxs.container, clxs.container, className);
  return (
    <div className={bcx}>
      <img
        loading="lazy"
        src={banners?.[0]?.desktop_url || defaultBanners?.[0]?.desktop_url}
        alt="banner"
        className={clxs.background}
      />
      <div className={clxs.overlay}>&nbsp;</div>
      <div className={clxs.title}>
        <div className={clxs.header}>
          {banners?.[0]?.title || defaultBanners?.[0]?.title}
        </div>
        <div className={clxs.bannerTitle}>
          {banners?.[0]?.subtitle || defaultBanners?.[0]?.subtitle}
        </div>
      </div>
      <h1 className={clxs.subtitle}>
        {banners?.[0]?.description || defaultBanners?.[0]?.description}
      </h1>
    </div>
  );
}

export default ListingBanner;

const defaultBanners: ListingBannerType[] = [
  {
    title: "Explore Stunning Villas",
    subtitle: "Exclusive villas for rent in pristine\ndestinations!",
    image_url: GENERIC_BANNER, // Placeholder image
    desktop_url: GENERIC_BANNER,
    redirect_url: "/villas",
    alt: "Default Banner",
    description: "Discover luxury stays in beautiful locations.",
  },
];
