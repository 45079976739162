export const ENV_KEY = "ENV";

export const PHONE_EXT_KEY = "PHONE_EXT";

export const PROPERTY_LOCATION_KEY = "PROPERTY_LOCATIONS";

export const PROPERTY_TRENDING_KEY = "TRENDING";

export const PROPERTY_LISTING_DISCOUNT_KEY = "PROPERTY_LISTING_DISCOUNT";

export const PROPERTY_LISTING_KEY = "PROPERTY_LISTING";

export const BANNER_LISTING_KEY = "BANNER_LISTING";

export const OFFERS_LISTING_KEY = "OFFERS_LISTING";

export const LOCATION_AND_VILLA_LISTING_KEY = "LOCATION_AND_VILLA_LISTING";

export const COLLECTION_LISTING_KEY = "COLLECTION_LISTING";

export const PROPERTY_LISTING_PAGINATION_KEY = "PROPERTY_LISTING_PAGINATION";

export const PROPERTY_DETAIL_KEY = "PROPERTY_DETAIL";

export const PROPERTY_SIMILAR_KEY = "PROPERTY_SIMILAR";

export const TNC_KEY = "TNC";

export const PRIVACY_KEY = "PRIVACY";

export const FAQ_KEY = "FAQ";

export const SITEMAP_KEY = "SITEMAP";

export const LOYALTY_POINTS_POLICY_KEY = "LOYALTY_POINTS_POLICY";

export const BRAND_LOGO_KEY = "BRAND_LOGO";

export const INFINITY_LOGO_KEY = "INFINITY_LOGO";

export const STATIC_DATA_KEY = "BANNER_COLLECTION_STATIC_DATA";

export const REFERRAL_PROGRAM_POLICY_KEY = "REFERRAL_PROGRAM_POLICY";
