import { useQueryParamValue } from "./use-query-param";
import { useEffect } from "react";
import { useAtomState } from "./use-atom";
import { useStorageString } from "./use-storage";

const UTM_KEY = "UTM",
  UTM_SOURCE_KEY = "utm_source",
  UTM_MEDIUM_KEY = "utm_medium",
  UTM_CAMPAIGN = "utm_campaign",
  UTM_CONTENT = "utm_content",
  UTM_GCLID_KEY = "gclid",
  UTM_FBID_KEY = "fb_id",
  UTM_AD_GROUP_KEY = "utm_adgroup",
  UTM_TERM_KEY = "utm_term";

type Utm = {
  source: string;
  medium: string;
  campaign: string;
  content: string;
  gclid: string;
  fb_id: string;
  ad_group: string;
  term: string;
};

const DEFAULT_UTM: Utm = {
  source: "",
  medium: "",
  campaign: "",
  content: "",
  fb_id: "",
  gclid: "",
  ad_group: "",
  term: "",
};

//PErsisist UTM_GCLID. 
// change from localstorage to sessionstorage

function useUtm() {
  const {
      value: defaultSource,
      setValue: setStorageSource,
    } = useStorageString("localStorage", UTM_SOURCE_KEY), {
      value: defaultMedium,
      setValue: setStorageMedium,
    } = useStorageString("localStorage", UTM_MEDIUM_KEY), {
      value: defaultCampaign,
      setValue: setStorageCampaign,
    } = useStorageString("localStorage", UTM_CAMPAIGN), {
      value: defaultContent,
      setValue: setStorageContent,
    } = useStorageString("localStorage", UTM_CONTENT),  {
      value: defaultGclid,
      setValue: setStorageGclid,
    } = useStorageString("localStorage",UTM_FBID_KEY), {
      value: defaultFbId,
      setValue: setStorageFbId,
    } = useStorageString("localStorage",UTM_GCLID_KEY), {
      value: defaultAdGroup,
      setValue: setStorageAdGroup,
    } = useStorageString("localStorage", UTM_AD_GROUP_KEY), {
      value: defaultTerm,
      setValue: setStorageTerm,
    } = useStorageString("localStorage", UTM_TERM_KEY),
    source = useQueryParamValue("string", UTM_SOURCE_KEY),
    medium = useQueryParamValue("string", UTM_MEDIUM_KEY),
    campaign = useQueryParamValue("string", UTM_CAMPAIGN),
    content = useQueryParamValue("string", UTM_CONTENT),
    fb_id = useQueryParamValue("string", UTM_FBID_KEY),
    gclid = useQueryParamValue("string", UTM_GCLID_KEY),
    ad_group = useQueryParamValue("string", UTM_AD_GROUP_KEY),
    term = useQueryParamValue("string", UTM_TERM_KEY),
    [utm, setUtm] =
      useAtomState<Utm>(
        UTM_KEY,
        () => {
          if (typeof window === "undefined") {
            return DEFAULT_UTM;
          }

          const utm: Utm = {
            source: source || defaultSource || "",
            medium: medium || defaultMedium || "",
            campaign: campaign || defaultCampaign || "",
            content: content || defaultContent || "",
            fb_id: fb_id || defaultFbId || "",
            gclid: gclid || defaultGclid || "",
            ad_group: ad_group || defaultAdGroup || "",
            term: term || defaultTerm || "",
          };

          return utm;
        },
      ),
    handleUtmChange = (
      source: string,
      medium: string,
      campaign: string,
      content: string,
      gclid: string,
      ad_group: string,
      term: string,
      fb_id: string,
    ) => {
      if (typeof window === "undefined") {
        return;
      }

      const changed = [source, medium, campaign, content,gclid, ad_group, term, fb_id].some(e => Boolean(e));

      if (!changed) {
        return;
      }

      setStorageSource(source);
      setStorageMedium(medium);
      setStorageCampaign(campaign);
      setStorageContent(content);
      setStorageGclid(gclid);
      setStorageAdGroup(ad_group);
      setStorageTerm(term);
      setStorageFbId(fb_id);

      const utm: Utm = {
        source: source || "",
        medium: medium || "",
        campaign: campaign || "",
        content: content || "",
        gclid: gclid || "",
        ad_group: ad_group || "",
        term: term || "",
        fb_id: fb_id || "",
      };

      setUtm(utm);
    };

  useEffect(
    () => handleUtmChange(source, medium, campaign, content,gclid, ad_group, term, fb_id),
    [source, medium, campaign, content,gclid,ad_group,term,fb_id ],
  );

  return {
    utm: utm,
  };
}

export default useUtm;
