import { useEffect, useState } from "react";

export function useIsIosMobile() {
  const [isIosMobile, setIsIosMobile] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipad|ipod/.test(userAgent);
    const isMobile = /mobile|tablet/.test(userAgent);
    
    setIsIosMobile(isIos && isMobile);
  }, []);

  return {isIosMobile: isIosMobile};
}

export default useIsIosMobile;