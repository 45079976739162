import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import CHEVRON_LEFT_GREY_ICON from "../../assets/icons/chevron-left-grey.svg";
import Button from "../Button";
import Form from "../Form";
import {
  AsyncDateRange,
  DateRange,
  PropertyConfig,
  Occupancy,
  Select,
} from "../Form/Fields";
import clxs from "./property-search-mobile.module.css";
import { useStorageDate, useStorageNumber, useStorageString } from "../../hooks/use-storage";
import { useQueryParamValue } from "../../hooks/use-query-param";
import useLocationAndVillaListing from "../../hooks/use-location-and-villa-listing";
import SearchSelect from "../SearchSelect";
// import { generatePropertyConfigKey } from "../../hooks/use-property-config";

interface PropertySearchMobileProps {
  viewLayout: string;
  formId: string;
  onSubmit?: (values: any) => void;
  packageOptions?: Option[];
  defaultValue?: {
    destination?: string;
    stay?: {
      start: Date | null,
      end: Date | null,
    };
    occupancy?: { adult_count: number; child_count: number; };
    price_package?: string;
    config_args?: PropertyConfigArg[];
    package_slug?: string;
  };
  configs: PropertyConfig[];
  disabled?: ("destination" | "stay" | "occupancy" | "price_package")[];
  hidden?: ("destination" | "stay" | "occupancy" | "price_package")[];
  maxAdultCount?: number;
  maxChildCount?: number;
  validationSchema?: any | (() => any),
  propertySlug?: string;
  configMaxCount?: Record<string, number>;
  is_edit_clicked?: boolean;
  location_slug?: string;
}

function PropertySearchMobile(props: PropertySearchMobileProps) {
  const {
    viewLayout,
    formId,
    defaultValue: _defaultValue = {},
    disabled: _disabled = [],
    hidden: _hidden = [],
    onSubmit,
    maxAdultCount = 12,
    maxChildCount = 8,
    validationSchema,
    configs,
    propertySlug = "",
    configMaxCount,
    packageOptions,
    is_edit_clicked,
    location_slug,
  } = props,

    userSearch = useQueryParamValue("string", "search"),
    checkin_date = useQueryParamValue("date", "checkin_date"),
    { setValue: setDestination } = useStorageString("sessionStorage", "destination"),
    { setValue: setCheckinDate } = useStorageDate("sessionStorage", "checkin_date"),
    { setValue: setCheckoutDate } = useStorageDate("sessionStorage", "checkout_date"),
    { setValue: setAdultCount } = useStorageNumber("sessionStorage", "adult_count"),
    { setValue: setChildCount } = useStorageNumber("sessionStorage", "child_count"),
    { value: recentSearches, setValue: setRecentSearches } = useStorageString("sessionStorage", "recentSearches"),

    [recentSearchOptions, setRecentSearchOptions] = useState<Option[]>(),
    [searchText, setSearchText] = useState<string>(() => userSearch),
    [selectedDestination, setSelectedDestination] = useState<string>(""),
    [selectedVillaSlug, setSelectedVillaSlug] = useState<string>(""),
    [showDateDialog, setShowDateDialog] = useState<boolean>(false),

    { propertyLocationsAndVilla: locationAndVilla } = useLocationAndVillaListing(searchText),

    // Computed location options from fetched villa listing
    locationOptions = useMemo(() => {
      if (!Array.isArray(locationAndVilla)) {
        console.error("locationAndVilla is not an array:", locationAndVilla);
        return [];
      }
      return locationAndVilla.reduce((compiled, each) => {
        const { name, slug, type, address, location_value } = each;
        return [...compiled, { label: name, value: slug, type, address, location_value }];
      }, [] as { label: string; value: string; type: string; address: string; location_value: string }[]);
    }, [locationAndVilla]),
    disabled = useMemo(
      () => new Set(_disabled),
      [_disabled],
    ),
    hidden = useMemo(
      () => new Set(_hidden),
      [_hidden],
    ),
    defaultValue = useMemo(
      () => {
        return {
          ...DEFAULT_VALUE,
          ..._defaultValue,
          // config_args: configs?.reduce(
          //   (compiled, each) => {
          //     const key = generatePropertyConfigKey(propertySlug, each.config_id);
          //     return {
          //       ...compiled,
          //       [key]: 0, // Initialize with 0 since required_rooms is not available
          //     };
          //   },
          //   {},
          // ),
        };
      },
      [_defaultValue, configs, propertySlug],
    ),

    /**
     * Handle Adult and child component changes
     */
    handleOccupancyChange = (e: ChangeEvent<any>) => {
      const target = e.target,
        { adult_count, child_count } = target.value;

      if (!propertySlug) {
        setAdultCount(adult_count);
        setChildCount(child_count);
      }
    },

    /**
     * Handles Destination / location value change
     */
    handleDestinationChange = (e: any) => {
      const target = e.target,
        destination = target.value,
        villaSlug = target.villa_slug;

      if (destination) {

        setShowDateDialog(true);

        // Retrieve the existing searches from sessionStorage
        const existingSearches = JSON.parse(recentSearches || "[]");

        // Remove the search if it already exists (to avoid duplicates)
        const filteredSearches = existingSearches.filter((search: string) => search !== destination);

        // Add the new search at the beginning
        const updatedSearches = [destination, ...filteredSearches];

        // Keep only the last 3 searches
        if (updatedSearches.length > 3) {
          updatedSearches.pop();
        }

        // Save back to sessionStorage
        setRecentSearches(JSON.stringify(updatedSearches))

        setDestination(destination);
        setSelectedDestination(destination);
        setSelectedVillaSlug(villaSlug);
      }
    },

    /**
     * Handle date range changes
     */
    handleStayChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement,
        { start: checkin_date, end: checkout_date } = target.value as any;


      if (!propertySlug) {
        setCheckinDate(checkin_date);
        setCheckoutDate(checkout_date);
      }
    },

    /**
     * Handle Form submit / Select button click
     */
    _onSubmit = (values: any) => {
      if (onSubmit) {
        const finalValues = {
          ...values,
          destination: selectedDestination || values.destination,
          ...(selectedVillaSlug ? { villa_slug: selectedVillaSlug } : {}),
        };

        onSubmit(finalValues);
      }
    };

  useEffect(() => {

    setSearchText(userSearch)

    if (hidden.has("destination") && !checkin_date && is_edit_clicked) {
      setShowDateDialog(true);
    }
  }, [is_edit_clicked]);

  useEffect(() => {
    const recent_searches = JSON.parse(recentSearches || "[]"),

      recentSearchOptions = locationOptions.filter(item => recent_searches.includes(item.value)),

      searchOptions = [...recentSearchOptions];

    setRecentSearchOptions(searchOptions);

  }, [recentSearches, locationOptions]);

  return (
    <Form
      id={formId}
      defaultValue={defaultValue}
      className={clxs.container}
      onSubmit={_onSubmit}
      validationSchema={validationSchema}
    >
      <div className={clxs.header}>
        <img
          src={CHEVRON_LEFT_GREY_ICON}
          alt="back"
          onClick={() => {
            setShowDateDialog(false);
            onSubmit?.(null);
          }}
          className={clxs.back}
        />
        <div className={clxs.title}>Select stay details</div>
      </div>

      {!hidden.has("destination") && (
        <SearchSelect
          form={formId}
          name="destination"
          options={locationOptions}
          label="Location/ Villa"
          value={location_slug}
          viewlayout={true}
          className={clxs.destination}
          searchable={true}
          disabled={disabled.has("destination")}
          onChange={handleDestinationChange}
          recentSearchOptions={recentSearchOptions}
        />
      )}
      {!hidden.has("stay") && (
        //Checks if property slug if present i.e. it is a property detail page. 
        propertySlug ? (
          <AsyncDateRange
            form={formId}
            name="stay"
            labels={["Check In", "Check Out"]}
            placeholder={["Select Date", "Select Date"]}
            disabled={disabled.has("stay")}
            propertySlug={propertySlug}
            handlechangeparent={handleStayChange}
            isShowCalendarDialog={showDateDialog}
            calendarDialogProps={{ monthCount: 3 }}
            className={clxs.stay}
            isTwoMonthMobileDialog={true}
          />
        )
          : (
            <DateRange
              form={formId}
              name="stay"
              labels={["Check In", "Check Out"]}
              placeholder={["Select Date", "Select Date"]}
              disabled={disabled.has("stay")}
              handlechangeparent={handleStayChange}
              isShowCalendarDialog={showDateDialog}
              className={clxs.stay}
              isTwoMonthMobileDialog={true}
            />
          )
      )}
      {!hidden.has("occupancy") && (
        <div className={clxs.occupancyContainer}>
          <div className={clxs.addGuest}>
            Add Guests *
          </div>
          <div className={clxs.separator}></div>
          <Occupancy
            form={formId}
            name="occupancy"
            maxAdultCount={maxAdultCount}
            maxChildCount={maxChildCount}
            disabled={disabled.has("occupancy")}
            onOccupancyChange={handleOccupancyChange}
          />
        </div>
      )}
      {viewLayout === "multi_config" ? (
        <div className={clxs.propertyConfigContainer}>
          <div className={clxs.addRooms}>
            Select Rooms
          </div>
          <PropertyConfig
            form={formId}
            className={clxs.propertyConfig}
            name="config_args"
            configs={configs}
            propertySlug={propertySlug}
            maxCount={configMaxCount}
            defaultValue={defaultValue?.config_args}
          />
        </div>
      ) : null}

      {viewLayout === "package" && packageOptions && packageOptions.length > 0 ? (
        <Select
          form={formId}
          label="Package *"
          name="package_slug"
          placeholder="Select room"
          options={packageOptions}
          value={defaultValue?.package_slug}
          searchable={false}
          className={clxs.destination}
        />
      ) : null}
      <Button
        className={clxs.done}
        type="submit"
      >
        Done
      </Button>
    </Form>
  );
}

export default PropertySearchMobile;

const DEFAULT_VALUE = {
  destination: "india-goa",
  stay: { start: null, end: null },
  occupancy: { adult_count: 2, child_count: 0 },
  config_args: [],
  package_slug: "",
};

type Option = { label: string; value: string, type: string, address?: string, location_value?: string };
