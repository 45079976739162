import React from "react";
import clxs from "./meal-options.module.css";
// import useIsMobile from "../../../../hooks/use-is-mobile";

interface MealOptionsProps {
  mealOptions: MealOptions;
  theme?: string; // for css change
}

function MealOptions(props: MealOptionsProps) {
  const { mealOptions, theme } = props,
    // { isMobile } = useIsMobile(),
    {
      icon,
      additional_text,
      veg,
      non_veg,
      menu_url,
    } = mealOptions;

  return (
    <>
      {/* css for meal plan option component  */}
      {theme == "mealPlan" ?
        <div className={clxs.mealPlanContainer}>
          <div className={clxs.mealContent}>
            {/* different content in desktop and mobile  */}
            {/* {isMobile ? 
        <p>Explore our meal plans and A-la-carte options from our extensive in-villa menu</p>
        :
        <p>Take a look at our meal plan menu and A-la-carte options from our wide selection of in-villa menu.</p> 
        } */}
            <p>
              Explore our meal plan and A-la-carte options from the in-villa menu.
              <p className={clxs.gst}>*All the prices are excluding GST</p>
            </p>
            {menu_url && (
              <a
                href={menu_url}
                target="_blank"
                rel="noreferrer noopener"
                className={clxs.viewMenu}
              >
                View Menu
              </a>
            )}
          </div>
        </div>
        : <>
          <div className={clxs.container}>
            <img
              src={icon}
              className={clxs.mealIcon}
              alt="Meal Options"
            />
            <div className={clxs.content}>
              <span className={clxs.mealPlan}>
                {veg && (
                  <span
                    className={clxs.veg}
                    data-key="veg"
                  >
                    Veg
                  </span>
                )}
                {veg && non_veg && (
                  <span key="and">
                    {" and "}
                  </span>
                )}
                {non_veg && (
                  <span
                    className={clxs.nonVeg}
                    data-key="non-veg"
                  >
                    Non-Veg
                  </span>
                )}
                {` ${additional_text}`}
              </span>
              {menu_url && (
                <a
                  href={menu_url}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={clxs.mealPlanLink}
                >
                  View Meal Plan
                </a>
              )}
            </div>
          </div> </>
      }
    </>
  );
}

export default MealOptions;
