import React, { ReactNode, useMemo } from "react";
import { localEquivalent } from "../../../../utils/date-tz";
import useClx from "../../../hooks/use-clx";
import format from "date-fns/format";
import clxs from "./summary.module.css";
import MEAL_ICON from "../../../assets/icons/ramen-dining.svg"

interface SummaryProps {
  viewLayout: string;
  className?: string;
  propertyName: string;
  propertyAddress: string;
  checkinDate: Date | null;
  checkoutDate: Date | null;
  adultCount: number;
  childCount: number;
  preBookMeals:boolean;
  thumbnail: string;
  configArgs: PropertyConfigArg[];
  configMap: Record<number, PropertyConfig>;
  onDateChange?: () => void;
}

function Summary(props: SummaryProps) {
  const { className, configArgs, configMap, viewLayout } = props,
    ccx = useClx(clxs.container, className),
    mtcx = useClx(clxs.title, clxs.mobile),
    dtcx = useClx(clxs.title, clxs.desktop),
    dscx = useClx(clxs.subtitle, clxs.desktop),
    {
      thumbnail,
      propertyName,
      propertyAddress,
      checkinDate,
      checkoutDate,
      adultCount,
      preBookMeals,
      childCount,
      onDateChange,
    } = props,
    { checkin, checkout } = useMemo(
      () => {
        const checkin = checkinDate
          ? format(localEquivalent(checkinDate), "ccc, do LLL")
          : "",
          checkout = checkoutDate
            ? format(localEquivalent(checkoutDate), "ccc, do LLL")
            : "";

        return {
          checkin: checkin,
          checkout: checkout,
        };
      },
      [checkinDate, checkoutDate],
    ),
    sumOfRooms = useMemo(
      () => configArgs.reduce(
        (compiled, each) => {
          return each.required_rooms + compiled;
        },
        0,
      ),
      [configArgs],
    );

  return (
    <div className={ccx}>
      <div className={clxs.header}>Booking Summary</div>
      <img
        src={thumbnail}
        alt={"thumbnail"}
        className={clxs.thumbnail}
      />
      <div className={clxs.titlePartial}>
        <div className={clxs.title}>{propertyName}</div>
        <div className={clxs.subtitle}>{propertyAddress}</div>
        <div className={clxs.occupancyContainer}>
          <div className={clxs.occupancyAdults}>
            <b>{adultCount}</b>Adults
          </div>
          <div className={clxs.occupancy}>
            <b>{childCount}</b>Children
          </div>
        </div>
      </div>
      <div className={clxs.datePartial}>
        <div className={dscx}>
          Check-in
        </div>
        <div className={dscx}>
          Check-out
        </div>
        <div className={dtcx}>{checkin}</div>
        <div className={dtcx}>{checkout}</div>
        <div className={mtcx}>{`${checkin} - ${checkout}`}</div>
        <div
          onClick={onDateChange}
          className={clxs.changeDate}
        >
          Edit Booking
        </div>
      </div>
      {viewLayout === "multi_config" && (
        <>
          <div className={clxs.configRooms}>
            {`${sumOfRooms} rooms`}
          </div>
          <div className={clxs.configsContainer}>
            {configArgs.reduce(
              (compiled, each, key) => {
                const {
                    property_config_id,
                    required_rooms,
                  } = each,
                  { room_name = "" } = configMap[property_config_id] ?? {};
                if (!required_rooms) {
                  return compiled;
                }
                return [
                  ...compiled,
                  <div
                    className={clxs.configRoom}
                    key={key}
                  >
                    {`${room_name} x ${required_rooms}`}
                  </div>,
                ];
              },
              [] as ReactNode[],
            )}
          </div>
          <div
            onClick={onDateChange}
            className={clxs.changeSelection}
          >
            Change Room Selection
          </div>
        </>
      )}
      {preBookMeals == true ?
       <div className={clxs.mealContainer}>
       <img
         src={MEAL_ICON}
         className={clxs.mealIcon}
         alt="Meal Options"
       />
       <span className={clxs.mealPlan}>
          I’m interested to pre-book meals
        </span>
      </div> : null }
    </div>
  );
}

export default Summary;
