import React, { useEffect, useState } from "react";
import clxs from "./linear-loader.module.css"

interface LinearLoaderProps {
  isLoading: boolean;
}

function LinearLoader(props: LinearLoaderProps) {
  const { isLoading } = props;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let animationFrame: number | undefined;
    let startTime: number | undefined;

    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;

      // Simulate progress over 2 seconds
      const duration = 2000;
      const newProgress = Math.min((elapsed / duration) * 100, 98);

      setProgress(newProgress);

      if (isLoading && newProgress < 98) {
        animationFrame = requestAnimationFrame(animate);
      }
    };

    if (isLoading) {
      animationFrame = requestAnimationFrame(animate);
    } else {
      setProgress(100);
      // Reset after completion animation
      const timeout = setTimeout(() => {
        setProgress(0);
      }, 200);
      return () => clearTimeout(timeout);
    }

    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [isLoading]);

  if (!isLoading && progress === 0) return null;

  return (
    <div className={clxs.linearloader}>
      <div className={clxs.linearloadertrack}>
        <div
          className={`${clxs.linearloaderbar} ${
            isLoading ? `${clxs.linearloaderbarloading}` : ""
          } ${progress === 100 ? `${clxs.linearloaderbarcompleting}` : ""}`}
          style={{
            width: `${progress}%`,
            transition: progress === 100 ? "all 200ms ease-out" : "none",
          }}
        />
      </div>
    </div>
  );
}

export default LinearLoader;
