import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import useClx from "../../../hooks/use-clx";
import { useStorageDate, useStorageNumber, useStorageString } from "../../../hooks/use-storage";
import Button from "../../Button";
import Form from "../../Form";
import { DateRange, OccupancySelect } from "../../Form/Fields";
import PROPERTY_SEARCH_SCHEMA from "./property-search-schema";
import clxs from "./property-search.module.css";
import SearchSelect from "../../SearchSelect";
import useLocationAndVillaListing from "../../../hooks/use-location-and-villa-listing";
import { useQueryParamValue } from "../../../hooks/use-query-param";

interface PropertySearchProps {
  className?: string;
  onPropertySearch: (values: any) => void;
}

function PropertySearch(props: PropertySearchProps) {
  const { className, onPropertySearch } = props,
    formId = "property-search",
    ccx = useClx(clxs.container, className),

    formRef = useRef<HTMLFormElement>(null),

    /** 
     * Retrieve search query from URL parameters
     */
    userSearch = useQueryParamValue("string", "search"),

    { value: destination, setValue: setDestination } = useStorageString("sessionStorage", "destination"),
    { value: checkinDate, setValue: setCheckinDate } = useStorageDate("sessionStorage", "checkin_date"),
    { value: checkoutDate, setValue: setCheckoutDate } = useStorageDate("sessionStorage", "checkout_date"),
    { value: adultCount, setValue: setAdultCount } = useStorageNumber("sessionStorage", "adult_count"),
    { value: childCount, setValue: setChildCount } = useStorageNumber("sessionStorage", "child_count"),
    { value: recentSearches, setValue: setRecentSearches } = useStorageString("sessionStorage", "recent_searches"),

    [selectedDestination, setSelectedDestination] = useState<string>(() => destination || ""),
    [selectedVillaSlug, setSelectedVillaSlug] = useState<string>(""),
    [showDateDialog, setShowDateDialog] = useState<boolean>(false),
    [showDateOnScroll, setShowDateOnScroll] = useState<boolean>(false),
    [showOccupancyDialog, setShowOccupancyDialog] = useState<boolean>(false),
    [showSelectDialog, setShowSelectDialog] = useState<boolean>(false),
    [startDate, setStartDate] = useState<Date>(),
    [endDate, setEndDate] = useState<Date>(),
    [searchText, setSearchText] = useState<string>(() => userSearch),

    /**
     * Fetch locations and villas based on search input
     */
    { propertyLocationsAndVilla: locationAndVilla } = useLocationAndVillaListing(searchText),

    /**
     * Process fetched location and villa options
     */
    locationOptions = useMemo(
      () => locationAndVilla.reduce(
        (compiled, each) => {
          const { name, slug, type, address, location_value } = each,

            option = {
              label: name,
              value: slug,
              type: type,
              address: address,
              location_value: location_value,
            };

          return [
            ...compiled,
            option,
          ];
        },
        [] as { label: string; value: string, type: string, address: string, location_value: string }[],
      ),
      [locationAndVilla],
    ),

    /**
     * Compute default form values based on stored data
     */
    defaultValues = useMemo(
      () => {
        const defaultValue = {
          destination: destination || DEFAULT_VALUES.destination,
          stay: {
            start: checkinDate || DEFAULT_VALUES.stay.start,
            end: checkoutDate || DEFAULT_VALUES.stay.end,
          },
          occupancy: {
            adult_count: adultCount || DEFAULT_VALUES.occupancy.adult_count,
            child_count: childCount || DEFAULT_VALUES.occupancy.child_count,
          },
        };

        return defaultValue;
      },
      [destination, checkinDate, checkoutDate, adultCount, childCount],
    ),

    /**
     * Handle form submission
     */
    handleSubmit = (values: any) => {
      const {
        destination,
        stay: {
          start: checkin_date,
          end: checkout_date,
        },
        occupancy: {
          adult_count,
          child_count,
        },
      } = values;

      if (!destination) {
        setShowSelectDialog(true);
        return;
      }

      // Save selected values to sessionStorage
      setDestination(selectedDestination);
      setCheckinDate(checkin_date);
      setCheckoutDate(checkout_date);
      setAdultCount(adult_count);
      setChildCount(child_count);

      // Check if both dates are either present or both are not present
      const validDates = (!checkin_date && !checkout_date) || (checkin_date && checkout_date);

      if (validDates) {
        const finalValues = {
          ...values,
          destination: selectedDestination,
          ...(selectedVillaSlug ? { villa_slug: selectedVillaSlug } : {}),
        }
        return onPropertySearch(finalValues);
      } else {
        setShowDateOnScroll(true),
          setShowDateDialog(true);
      }
    },

    handleDestinationChangeSelect = (e: React.ChangeEvent<any>) => {
      const target = e.target,
        destination = target.value,
        villaSlug = target.villa_slug;

      if (destination) {

        // Retrieve the existing searches from sessionStorage
        const existingSearches = JSON.parse(recentSearches || "[]");

        // Remove the search if it already exists (to avoid duplicates)
        const filteredSearches = existingSearches.filter((search: string) => search !== destination && search !== villaSlug);

        // Add the appropriate search value to the beginning
        let updatedSearches: string[] = [];
        if (villaSlug) {
          updatedSearches = [villaSlug, ...filteredSearches]; // Add villaSlug only if present
        } else if (destination) {
          updatedSearches = [destination, ...filteredSearches]; // Add destination if no villaSlug
        }

        // Keep only the last 3 searches
        if (updatedSearches.length > 3) {
          updatedSearches.pop();
        }

        // Save back to sessionStorage
        setRecentSearches(JSON.stringify(updatedSearches));

        // Always show date dialog when destination changes
        setShowDateOnScroll(true);
        setShowDateDialog(true);

        setDestination(destination);
        setSelectedDestination(destination);
        setSelectedVillaSlug(villaSlug);
      }
    },

    handleDateChangeSelect = (e: ChangeEvent<any>) => {
      const value = e.target.value;
      setStartDate(value?.start);
      setEndDate(value?.end);
      
      setCheckinDate(value?.start)
      setCheckoutDate(value?.end)
      // Keep the dialog open until Done is clicked
      setShowDateOnScroll(true);
      setShowDateDialog(true);
    },

    handleDateDoneClicked = () => {
      // Close date dialog and open occupancy dialog when Done is clicked
      setShowDateOnScroll(false);
      setShowDateDialog(false);
      setShowOccupancyDialog(true);
    },

    handleAdultCountDoneClick = () => {
      setShowSelectDialog(true);
      formRef.current?.requestSubmit();
    };

  useEffect(() => {
    setSearchText(userSearch)
  }, []);

  // Add effect to handle dialog state
  useEffect(() => {
    if (showDateOnScroll) {
      setShowDateDialog(true);
    }
  }, [showDateOnScroll]);

  // Add effect to prevent dialog from closing when dates are selected
  useEffect(() => {
    if (startDate || endDate) {
      setShowDateOnScroll(true);
      setShowDateDialog(true);
    }
  }, [startDate, endDate]);

  return (
    <Form
      id={formId}
      defaultValue={defaultValues}
      className={ccx}
      validationSchema={PROPERTY_SEARCH_SCHEMA}
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <div className={clxs.title}>
        Handpicked homes, <br />
        paired with unparalleled hospitality
      </div>
      <SearchSelect
        form={formId}
        name="destination"
        label="Where to next"
        placeholder="Select your Destination"
        defaultValue=""
        options={locationOptions}
        searchable={true}
        viewlayout={true}
        value={selectedDestination}
        // isHomeDestination={true}
        className={clxs.destination}
        onChange={handleDestinationChangeSelect}
        isShowSelect={showSelectDialog}
        selectedVillaSlug={selectedVillaSlug}
      />
      <DateRange
        form={formId}
        isShowCalendarDialog={showDateDialog}
        isShowDateOnScroll={showDateOnScroll}
        name="stay"
        labels={["Check in", "Check out"]}
        placeholder={["Select Date", "Select Date"]}
        className={clxs.stay}
        onOpenDialog={() => {
          setShowDateDialog(false);
        }}
        onSelectClicked={() => {
          setShowDateOnScroll(true);
        }}
        calendarDialogProps={{ monthCount: 2 }}
        handlechangeparent={handleDateChangeSelect}
        isTwoMonthMobileDialog={true}
        handleDateDoneClicked={handleDateDoneClicked}
      />
      <OccupancySelect
        form={formId}
        name="occupancy"
        label="Guests"
        className={clxs.occupancy}
        isShowOccupancyDialog={showOccupancyDialog}
        maxAdultCount={24}
        maxChildCount={8}
        occupancySuccessText="Apply & Search"
        handleDoneClick={handleAdultCountDoneClick}
      />
      <div className={clxs.searchContainer}>
        <Button
          type="submit"
          className={clxs.search}
        >
          Search
        </Button>
      </div>
    </Form>
  );
}

export default PropertySearch;

const DEFAULT_VALUES = {
  destination: "",
  stay: { start: null, end: null },
  occupancy: { adult_count: 2, child_count: 0 },
};
