export const BUDGET_OPTIONS = [
  { value: "Rs 10,000 - Rs 25,000", label: "Rs 10,000 - Rs 25,000" },
  { value: "Rs 25,000 - Rs 50,000", label: "Rs 25,000 - Rs 50,000" },
  { value: "Above Rs 50,000", label: "Above Rs 50,000" },
];

export const BUDGET_SOUTH_GOA_OPTIONS = [
  { value: "Rs 1,60,000 - Rs 2,00,000", label: "Rs 1,60,000 - Rs 2,00,000" },
  { value: "Above  Rs 2,00,000", label: "Above  Rs 2,00,000" },
];
