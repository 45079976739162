import React, { DialogHTMLAttributes, useMemo } from "react";
import useIsClientMounted from "../../../../utils/is-client-mounted";
import toISODateString from "../../../../utils/to-iso-date-string";
import useClx from "../../../hooks/use-clx";
import CalendarMonth from "./CalendarMonth";
import { DateDecorator } from "./CalendarMonth/calculate-calendar-template";
import useCalendarCursor, { MonthChangeHandler } from "./use-calendar-cursor";
import clxs from "./calendar-dialog.module.css";
import useIsMobile from "../../../hooks/use-is-mobile";

interface CalendarDialogProps extends BaseDialogProps {
  open: boolean;
  position?: "left" | "right";
  min?: number;
  max?: number;
  initialDate?: Date;
  monthCount?: 1 | 2 | 3;
  startDate: Date | null;
  endDate: Date | null;
  monthClassName?: string;
  dayNameClassname?: string;
  onMonthChange?: MonthChangeHandler;
  onDateDecorate?: DateDecorator;
  // setIsDoneClicked?: (value: boolean) => void;
  isTwoMonthMobileDialog?:boolean;
  handleClear?: (isClearClicked:boolean) => void;
  handleDoneClicked?: (isDoneClicked:boolean) => void;
}

function CalendarDialog (props: CalendarDialogProps) {
  const {
      className,
      position = "left",
      open,
      min,
      max,
      initialDate,
      startDate,
      endDate,
      monthCount = 1,
      monthClassName,
      dayNameClassname,
      isTwoMonthMobileDialog,
      onMonthChange,
      onDateDecorate,
      handleDoneClicked,
      handleClear,
      ...dialogProps
    } = props,
    clientMounted = useIsClientMounted(),
    { isMobile } = useIsMobile(),
    dcx = useClx(clxs.dayNameContainer,dayNameClassname),
    // isMobile = useMemo(
    //   () => {
    //     if (typeof window === "undefined") {
    //       return true;
    //     }

    //     const { innerWidth, innerHeight } = window;

    //     const isMobile = innerHeight > innerWidth;

    //     return isMobile;
    //   },
    //   [],
    // ),
    responsiveMonthCount = useMemo(
      () => {
        if (isMobile) {
          return isTwoMonthMobileDialog ? 3 : 1;
        }

        return monthCount;
      },
      [monthCount, isMobile],
    ),
    { months, handlePrevious, handleNext,handleLoadMore } =
      useCalendarCursor(
        max,
        min,
        responsiveMonthCount,
        initialDate,
        onMonthChange,
        isMobile,
      ),
    ccx = useClx(clxs.container, className);

  if (!clientMounted) {
    return null;
  }

  return (
    <>
      {isMobile && (
        <div
          className={isTwoMonthMobileDialog ? `${clxs.backdrop} ${clxs.whiteBackDrop}` :clxs.backdrop}
          data-open={open}
          data-dismiss={true}
          data-component="backdrop"
        >
          &nbsp;
        </div>
      )}
      <dialog
        {...dialogProps}
        open={open}
        className={ccx}
        data-position={position}
        data-component="dialog"
      >
        {isTwoMonthMobileDialog && (
        <div 
          className={dcx}
          data-component="calendar-day"
        >
          <div className={clxs.dayName}>Sun</div>
          <div className={clxs.dayName}>Mon</div>
          <div className={clxs.dayName}>Tue</div>
          <div className={clxs.dayName}>Wed</div>
          <div className={clxs.dayName}>Thu</div>
          <div className={clxs.dayName}>Fri</div>
          <div className={clxs.dayName}>Sat</div>
        </div>
      )}
        <div
          className={isTwoMonthMobileDialog ? `${clxs.transition} ${clxs.isTwoMonthTransition}`: clxs.transition}
          suppressHydrationWarning={true}
          data-component="calendar"
        >
          {months.map(
            (monthDate) => (
              <CalendarMonth
                key={toISODateString(monthDate)}
                monthDate={monthDate}
                className={monthClassName}
                startDate={startDate}
                endDate={endDate}
                onDateDecorate={onDateDecorate}
                isTwoMonthMobileDialog={isTwoMonthMobileDialog}
              />
            ),
          )}

           {isTwoMonthMobileDialog && 
            <div 
              className={clxs.loadMoreDates}
              onClick={handleLoadMore}
            >
              Load More Dates
            </div>
           }
        </div> 
        <button
          data-action="previous"
          className={isTwoMonthMobileDialog && isMobile ? `${clxs.hideNavigation} `:clxs.navigation}
          onClick={handlePrevious}
          type="button"
        >
          ‹
        </button>
        <button
          data-action="next"
          className={isTwoMonthMobileDialog && isMobile ? `${clxs.hideNavigation} `:clxs.navigation}
          onClick={handleNext}
          type="button"
        >
          ›
        </button>
        <div className={isTwoMonthMobileDialog && isMobile ? `${clxs.actions} ${clxs.mobileDialogActions}`:clxs.actions}>
          {/* <button
            type="button"
            className={clxs.action}
            onClick={handleToday}
          >
            Today
          </button> */}
          <button
            type="button"
            className={isTwoMonthMobileDialog && isMobile ? clxs.clearAction :clxs.action}
            data-action="clear"
            onClick={() => handleClear && handleClear(true)}
          >
            Clear
          </button>
          <button
            type="button"
            className={isTwoMonthMobileDialog && isMobile ? clxs.selectAction : clxs.action}
            data-dismiss={true}
            data-action="done"
            data-selected={Boolean(startDate).valueOf()&& Boolean(endDate).valueOf()}
            onClick={(e) => {
              e.stopPropagation();
              handleDoneClicked && handleDoneClicked(true);
            }}
          >
            {isTwoMonthMobileDialog && isMobile ? "Select Dates":"Done"}
          </button>
        </div>
      </dialog>
    </>
  );
}

export default CalendarDialog;

export type { CalendarDialogProps };

type BaseDialogProps = Omit<DialogHTMLAttributes<HTMLDialogElement>, "open">;
