import differenceInDays from "date-fns/differenceInDays";
import React, { ForwardedRef, forwardRef, useEffect, useMemo, useState } from "react";
import useClx from "../../../../../hooks/use-clx";
import usePathParams from "../../../../../hooks/use-path-params";
import { useQueryParam, useQueryParamValue, useQuerySearch } from "../../../../../hooks/use-query-param";
import { useUser } from "../../../../../hooks/use-session";
import Button from "../../../../Button";
// import CouponCode from "../../../../CouponCode";
import DiscountAlert from "../../../../DiscountAlert";
import LoyaltyPointTicker from "../../../../DiscountAlert/LoyaltyPointTicker";
import Form from "../../../../Form";
import {
  AsyncDateRange,
  PropertyConfigSelect,
  OccupancySelect,
  Select,
} from "../../../../Form/Fields";
import LoyaltyPointShortTicker from "../../../../LoyaltyPointShortTicker";
import { usePriceCalculatorValue } from "../../../../../hooks/use-price-calculator";
import { generatePropertyConfigKey, usePropertyConfigValues } from "../../../../../hooks/use-property-config";
import { createPriceCalculatorSchema } from "./price-calculator-schema";
import clxs from "./price-calculator.module.css";
import searchParamsFromFormValues from "../search-params-from-form-values";
import { useLoginModal } from "../../../../../hooks/use-login-modal";
import useAnalytics from "../../../../../hooks/use-analytics";
import PreBookMeals from "../../MealPlanOptions/PreBookMeals";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import { getAnalyticsVillaLayout } from "../../../../../../utils/get-analytics-property-type";
import TaxTooltip from "../../../../Checkout/TaxTooltip";

interface PriceCalculatorProps {
  viewLayout: string;
  id: string;
  path: string;
  propertySlugKey: string;
  checkinDateKey: string;
  checkoutDateKey: string;
  adultCountKey: string;
  childCountKey: string;
  couponCodeKey: string;
  maxAdultCount: number;
  maxChildCount: number;
  className?: string;
  hideCouponCode?: boolean;
  hideLoyaltyPointsTicker?: boolean;
  configs: PropertyConfig[];
  mealRef?:React.RefObject<HTMLDivElement>;
  isVegOrNonVegAvailable?: boolean;
  onSubmit: (value: any) => void;
  onSendRequest: (value: any) => void;
  average_ratings?: number;
  reviews_count?: number;
  villa_name?: string;
}

const PriceCalculator = forwardRef(
  function PriceCalculatorCore(
    props: PriceCalculatorProps,
    ref: ForwardedRef<HTMLFormElement>,
  ) {
    const {
        viewLayout,
        path,
        id,
        propertySlugKey,
        checkinDateKey,
        checkoutDateKey,
        adultCountKey,
        childCountKey,
        couponCodeKey,
        configs,
        // hideCouponCode = false,
        hideLoyaltyPointsTicker = false,
        className,
        maxAdultCount,
        maxChildCount,
        mealRef,
        isVegOrNonVegAvailable,
        onSubmit,
        onSendRequest,
        average_ratings,
        reviews_count,
        villa_name,
      } = props,
      [_, setQuerySearch] = useQuerySearch(),
      { track } = useAnalytics(),
      isMobile = useIsMobile(),
      propertySlug = usePathParams(path, propertySlugKey),
      checkinDate = useQueryParamValue("date", checkinDateKey),
      checkoutDate = useQueryParamValue("date", checkoutDateKey),
      adultCount = useQueryParamValue("integer", adultCountKey, 2),
      childCount = useQueryParamValue("integer", childCountKey),
      [showDateDialog, setShowDateDialog] = useState<boolean>(false),
      [showDateOnScroll, setShowDateOnScroll] = useState<boolean>(false),
      [showOccupancyDialog, setShowOccupancyDialog] = useState<boolean>(false),
      [showRoomDialog,setShowRoomDialog]  = useState<boolean>(false),
      [formSubmitted, setFormSubmitted] = useState<boolean>(false),
      [isDateChanged, setIsDateChanged] = useState<boolean>(false),
      [isOccupancyChanged, setIsOccupancyChanged] = useState<boolean>(false),
      preBookMeals = useQueryParamValue("boolean", queryKey),
      showLoginFlowModal = useLoginModal(),
      configSelectionDisabled = useMemo(
        () => !checkinDate || !checkoutDate,
        [checkinDate, checkoutDate],
      ),
      [couponCode, setCouponCode] = useQueryParam("string", couponCodeKey),
      {
        values: config_args_search_values,
        configMaxCount,
      } = usePropertyConfigValues(propertySlug, configs),
      packageOptions = useMemo(
        () => {
          const options: any[] =  configs.map(
            config => {
              const key = generatePropertyConfigKey(propertySlug, config);
              
              return {
                label: `${config.room_name}`,
                value: key,
              };
            },
          );
          
          return options;
        },
        [propertySlug, configs],
      ),
      [isDisableBooking,setIsDisableBooking] = useState<boolean>(false),
      defaultValue = useMemo(
        () => {
          /* 
          config_args_search_values = { bastora_5: "1", bastora_7: "0" } -> bastora_5
          */
          const packageSlug = inferPackageSlugFromConfigArgs(config_args_search_values);

          return {
            stay: {
              start: checkinDate,
              end: checkoutDate,
            },
            occupancy: {
              adult_count: adultCount,
              child_count: childCount,
            },
            config_args: config_args_search_values,
            package_slug: packageSlug,
            pre_book_meals: preBookMeals,
          };
        },
        [configs, config_args_search_values],
      ),
      { tentativePrice } = usePriceCalculatorValue(id),
      currency = useMemo(
        () => tentativePrice?.currency_symbol || "",
        [tentativePrice],
      ),
      isInvalid = useMemo(
        () => !tentativePrice,
        [tentativePrice],
      ),
      isUnavailable = useMemo(
        () => tentativePrice?.status === "unavailable",
        [tentativePrice],
      ),
      response_coupon_code = useMemo(
        () => tentativePrice?.breakdown?.coupon_code,
        [tentativePrice],
      ),
      total = useMemo(
        () => {
          const total = tentativePrice?.breakdown?.config_total || 0;

          if (!total) {
            setIsDisableBooking(true);
            return "-";
          }

          setIsDisableBooking(false);
          return `${currency} ${total.toLocaleString("en-IN")}`;
        },
        [tentativePrice],
      ),
      average_non_discounted_rate = useMemo(
        () => {
          const average_non_discounted_rate = tentativePrice?.average_non_discounted_rate || 0;

          if (!average_non_discounted_rate) {
            setIsDisableBooking(true);
            return "-";
          }

          setIsDisableBooking(false);
          return `${currency} ${average_non_discounted_rate.toLocaleString("en-IN")}`;
        },
        [tentativePrice],
      ),
      isShowPricing = useMemo(
        () => !isUnavailable || average_non_discounted_rate,
        [average_non_discounted_rate],
      ),
      average_base_rate = useMemo(
        () => {
          const average_base_rate = tentativePrice?.average_base_rate;

          if (average_base_rate === undefined) {
            return null;
          }

          const total = tentativePrice?.average_non_discounted_rate || 0;

          if (average_base_rate === total) {
            return null;
          }

          // const discount_method = tentativePrice?.breakdown.discount_method;
          // if(discount_method.toLowerCase() != couponCode.toLowerCase()) {
          //   const coupon_code = tentativePrice?.breakdown.coupon_code;
          //   setCouponCode(coupon_code ? coupon_code : "");
          // }

          return `${currency} ${average_base_rate.toLocaleString("en-IN")}`;
        },
        [tentativePrice],
      ),
      // superscript = useMemo(
      //   () => {
      //     if (checkinDate && checkoutDate) {
      //       return "Total payable";
      //     }

      //     return "Starts from";
      //   },
      //   [checkinDate, checkoutDate],
      // ),
      totalNights = useMemo(
        () => {
          if (!checkinDate || !checkoutDate) {
            return 0;
          }

          const diff = differenceInDays(checkoutDate, checkinDate);

          return diff;
        },
        [checkinDate, checkoutDate],
      ),
      subscript = useMemo(
        () => {
          // if ((!checkinDate || !checkoutDate)) {
          //   return "starts from";
          // }

          return "Per night excluding taxes*";
        },
        [checkinDate,checkoutDate],
      ),
      actionText = useMemo(
        () => {
          if (isUnavailable) {
            return "Enquire";
          }

          if (tentativePrice?.sold_out) {
            return "Change Dates";
          }

          if (!checkinDate || !checkoutDate) {
            return "Select dates";
          }

          return "Book";
        },
        [tentativePrice],
      ),
      validationSchema = useMemo(
        () => createPriceCalculatorSchema(
          propertySlug,
          configs,
          tentativePrice?.sold_out ?? false,
          viewLayout,
        ),
        [configs, tentativePrice],
      ),
      user = useUser(),
      llpDiscountMethod = useMemo(
        () => {
          const discountMethod = tentativePrice?.breakdown.discount_method;

          if (discountMethod === "coupon") {
            return user?.loyalty_point_metadata.tier;
          }

          return discountMethod;
        },
        [
          tentativePrice?.breakdown.discount_method,
          user?.loyalty_point_metadata.tier,
        ],
      ),
      ccx = useClx(clxs.container, className),
      discountValue = useMemo(() => {
        switch (tentativePrice.breakdown.discount_type) {
            case "free_night":
                return `(${tentativePrice.discount_value} Night)`;
            case "percentage_discount":
                return `(${tentativePrice.discount_value}%)`;
            case "flat_discount":
                return `(${tentativePrice.currency_symbol}${tentativePrice.discount_value.toLocaleString("en-IN")})`;
            default:
                return "";
        }
       }, [tentativePrice]),
      taxTemplate = useMemo(
        () => {
          if (!tentativePrice) {
            return [];
          }
          const template: TaxBreakdown[] = [
            {
              taxes: tentativePrice.breakdown?.gross_base_rate || 0,
              sub_title: `${average_non_discounted_rate || average_base_rate}  x ${totalNights ? totalNights : "1" }`,
              description: "Stay charges",
            },
            {
              taxes: tentativePrice.tier_discount ? tentativePrice.tier_discount : tentativePrice.discount,
              sub_title: `${couponCode === "NONE" ? "" : couponCode} ${
                tentativePrice.tier_discount_percentage > 0 
                  ? `(${tentativePrice.tier_discount_percentage}%)` 
                  : tentativePrice.discount_value > 0 
                  ? discountValue 
                  : ""
              }`,
              description: "Discount",
            },
            {
              taxes: tentativePrice.breakdown?.config_taxes || 0,
              sub_title: `${tentativePrice.tax_percentage}%`,
              description: tentativePrice.currency_symbol === "₹" ? "GST" :"tax",
            },
            {
              taxes: tentativePrice.breakdown?.config_total || 0,
              sub_title: "",
              description: "Total Payable",
            },
          ]
          
          return template as TaxBreakdown[];
        },
        [tentativePrice],
      ),
      handleChange = (values: any) => {
        // if(formSubmitted) {
        //   return;
        // }
        const payload = searchParamsFromFormValues(
          propertySlug,
          viewLayout,
          configs,
          values,
        );
        // Retaining previously selected coupon code, for the user.
        // if (couponCode && couponCode.length) {
        //   payload[couponCodeKey] = [couponCode];
        // }

        setQuerySearch(() => payload, { replace: true });
      },
      handleCouponCodeChange = (couponCode: string) => {

        // Set tier level in the coupon code if tier present and coupon code is empty.
        // const tierLevel = user?.loyalty_point_metadata?.tier;

        // if(tierLevel?.length && (!couponCode || couponCode === "NONE")) {
        //   couponCode = tierLevel.toUpperCase();
        // }
        
        return setCouponCode(couponCode);
      },
      handleAutoApplyCoupon = () => {
        if (!tentativePrice) {
          return false;
        }

        if (couponCode === "NONE") {
          return true;
        }

        const { coupon_code: cc } = tentativePrice.breakdown;

        if (!cc) {
          return true;
        }

        handleCouponCodeChange(couponCode || cc);

        return true;
      },
      checkoutFunction = (value: any) => {
        if (isInvalid) {
          return;
        }

        if (isUnavailable) {
          return onSendRequest(value);
        }

        return onSubmit(value);
      },
      getTrackPricePayloadObj = () => {
        return tentativePrice;
      },
      getTotalRooms = (configArgs: any) => {
        let totalRooms = 0;
        
        if(viewLayout === "multi_config") {
          Object.keys(configArgs).forEach((key) => {
            totalRooms = totalRooms + configArgs[key];
          });
        } else {
          Object.keys(configArgs).forEach((key) => {
            if(configArgs[key] > 0) {
              const configId = key.split("_")[1];
              configs.forEach((oConfig) => {
                if(oConfig.config_id == configId) {
                  totalRooms = totalRooms + oConfig.bedrooms;
                }
              })
            }
          });
        }

        return totalRooms;
      },
      soldOutElement = useMemo(() => {
        return tentativePrice?.sold_out && !isUnavailable ? (
          <div className={clxs.soldOutContainer}>Sold out</div>
        ) : null;
      }, [tentativePrice, isUnavailable]),
      handleStayChange = () => {
        setIsDateChanged(true); 
        setShowDateOnScroll(false);
      },

      handleAdultCountDoneClick = () => {
        setIsOccupancyChanged(true); 
      },

      /**
       * Handles the submission of booking with appropriate actions based on user  authentication      status.
       * @param value - The booking details object.
      */
      handleSubmit = (value: any) => {

        const trackingPayload = {
          tentativePrice: getTrackPricePayloadObj(),
          property_type: viewLayout,
          totalNights: totalNights,
          totalRooms: getTotalRooms(value.config_args),
          meal_plan_opt: preBookMeals,
          ratings: average_ratings ? average_ratings : null,
          reviews_count: reviews_count ? reviews_count : null,
        };

        const ratingsTrackingPayload = {
          ratings: average_ratings ? average_ratings : null,
          reviews_count: reviews_count ? reviews_count : null,
        }

        // localStorage.setItem("trackingPayload", JSON.stringify(trackingPayload));
        localStorage.setItem("ratingsPayload", JSON.stringify(ratingsTrackingPayload));

        if(trackingPayload.tentativePrice.status === "sold_out"){
          setShowDateDialog(true);
          setShowDateOnScroll(true);
          return;
        }
      // Check if the user is not logged in and show login popup before proceeding to checkout
         if((!user) || (user && (!user?.full_name || !user?.email))) {
          const modalProps = {
            className: "",
            onSubmit: checkoutFunction,
          }

          return showLoginFlowModal(modalProps).then((success: any) => {
            if(success) {
              //Firebase event - book_now
              track("book_now_property_clicked", trackingPayload);
              checkoutFunction(value);
            }
          })
        }else { //If the additional details is already filled is and user already logged in proceed to checkout on successful login
          //Firebase event - book_now
          track("book_now_property_clicked", trackingPayload);
          return checkoutFunction(value);
        }      
      },
      handleMealOptChange = (isChecked: boolean) => {
        //If meal plan is opted, sending an event.
        if(isChecked) {
          const trackingPayload = {
            tentativePrice: getTrackPricePayloadObj(),
            property_type: getAnalyticsVillaLayout(viewLayout),
            totalNights: totalNights,
            totalRooms: getTotalRooms(configs),
            meal_plan_opt: isChecked,
            ratings: average_ratings ? average_ratings : null,
            reviews_count: reviews_count ? reviews_count : null,
            property_name: villa_name,
          };
    
          track("meals_interested", trackingPayload);
        }
      },
      handleVillaViewedEvent = () => {
        const trackingPayload = {
          tentativePrice: getTrackPricePayloadObj(),
          property_type: getAnalyticsVillaLayout(viewLayout),
          totalNights: totalNights,
          totalRooms: getTotalRooms(configs),
          meal_plan_opt: preBookMeals,
          ratings: average_ratings ? average_ratings : null,
          reviews_count: reviews_count ? reviews_count : null,
          property_name: villa_name,
        };

        if(!isMobile) {
          track("villa_viewed",trackingPayload, [], "PROPERTY_DETAIL"); 
        }
      },

      handleSubmitClick = () => {
        setFormSubmitted(true);
      };

    useEffect(handleVillaViewedEvent, []);
      
    useEffect(() => {
      if(formSubmitted && (!checkinDate || !checkoutDate)) {
        setShowDateDialog(true);
        setShowDateOnScroll(true);
        setFormSubmitted(false);
      }
      if(isDateChanged && checkinDate && checkoutDate && (adultCount == 2)) {
        setShowOccupancyDialog(true);
      }

      if((checkinDate && checkoutDate && isOccupancyChanged)) {
        setShowRoomDialog(true);
      }

      // if(checkinDate && checkoutDate) {
      //   setShowDateDialog(false);
      // }

      if(!checkinDate || !checkoutDate) {
        setShowDateDialog(true);
        setShowDateOnScroll(true);
      }
    },[checkinDate, checkoutDate, formSubmitted, isOccupancyChanged]);

    useEffect(() => {
      if(preBookMeals) {
        handleMealOptChange(preBookMeals);
      }
    },[preBookMeals]);  

    // useOnce(handleAutoApplyCoupon, [tentativePrice,response_coupon_code]);

    useEffect(() => {
      handleAutoApplyCoupon();
    },[response_coupon_code]);  

    // useEffect(() => {handleAutoApplyCoupon}, [response_coupon_code]);
    return (
      <Form
        id={FORM_ID}
        className={ccx}
        defaultValue={defaultValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={ref}
        enableReinitialize={true}
      >
        
        {(isShowPricing) && (
            <div className={clxs.pricingContainer}>
             
               <div className={clxs.discountedContainer}>
                 <span className={clxs.startsFrom}>{!checkinDate || !checkoutDate ? "starts from":""}</span>
                 {average_base_rate && (<span className={clxs.strike}>
                   {average_non_discounted_rate}
                 </span>)}
                 {average_base_rate && (<div className={clxs.excTax}>
                 <LoyaltyPointShortTicker           
                   discountType={tentativePrice?.breakdown.discount_type}
                   method={tentativePrice?.breakdown.discount_method}
                   discountPercentage={tentativePrice.discount_value ?  tentativePrice.discount_value : tentativePrice?.breakdown.loyalty_points_discount_percentage}
                   className={clxs.llpShortTicker}
                   currencySymbol= {tentativePrice.currency_symbol}
                 />
                 </div>)}
               </div>
              <div className={clxs.actualPricingContainer}>
                <span className={clxs.rate}>
                  {average_base_rate || average_non_discounted_rate} 
                </span>
                <div className={clxs.subscript}>{subscript}</div>
              </div>

              <div className={clxs.total}>
                Total: {total}
                <TaxTooltip 
                  tax_data={taxTemplate} 
                  currency_symbol= {tentativePrice.currency_symbol}
                  hideSymbole={true}
                  title="Total Summary"
                />
              </div>
            </div>  
        )}
        {soldOutElement}
        <div className={clxs.dateGuestContainer}>
        <AsyncDateRange
          form={FORM_ID}
          name="stay"
          handlechangeparent={handleStayChange}
          labels={["Check in", "Check out"]}
          placeholder={["Select Date", "Select Date"]}
          propertySlug={propertySlug}
          calendarDialogProps={{ monthCount: 2 }}
          isShowCalendarDialog={showDateDialog}
          isShowDateOnScroll={showDateOnScroll}
          onSelectClicked={() => {setShowDateOnScroll(true)}}
          className={clxs.stay}
          isPriceCalculator= {true}
        />
        <OccupancySelect
          form={FORM_ID}
          name="occupancy"
          label="Add Guest"
          className={clxs.occupancy}
          handleDoneClick={handleAdultCountDoneClick}
          // handlechangeparent={handleOccupancyChange}
          maxAdultCount={maxAdultCount}
          maxChildCount={maxChildCount}
          isShowOccupancyDialog={showOccupancyDialog}
          isPriceCalculator= {true}
        />
        </div>
        {viewLayout === "multi_config" && (
          <PropertyConfigSelect
            form={FORM_ID}
            label="Rooms *"
            name="config_args"
            propertySlug={propertySlug}
            configs={configs}
            maxCount={configMaxCount}
            disabled={configSelectionDisabled}
            isShowRoomDialog={showRoomDialog}
          />
        )}
        {viewLayout === "package" ? (
          <Select
            form={FORM_ID}
            viewlayout ={true}
            label="Package *"
            name="package_slug"
            options={packageOptions}
            searchable={false}
            className={clxs.package}
          />
        ) : null}
        {/* {!hideCouponCode && (
          <CouponCode
            value={couponCode}
            propertySlug={propertySlug}
            checkinDate={checkinDate?.toISOString()}
            checkoutDate={checkoutDate?.toISOString()}
            adultCount={adultCount ?? undefined}
            childCount={childCount ?? undefined}
            onChange={handleCouponCodeChange}
            className={clxs.couponCode}
          />
        )} */}
        {isVegOrNonVegAvailable ? 
        <PreBookMeals 
          queryKey={queryKey}
          className={clxs.preBookMeals}
          theme="priceCalculator"
          form={FORM_ID}
          mealRef={mealRef}
        /> : null }
        <div className={clxs.bookContainer}>
          <Button
            className={clxs.book}
            type="submit"
            disabled={isDisableBooking}
            onClick={handleSubmitClick}
          >
            {actionText}
          </Button>
        </div>
        <DiscountAlert
          // message={tentativePrice?.breakdown.discount_message}
          method={tentativePrice?.breakdown.discount_method}
          className={clxs.discount}
          showChildRegardless={true}
        >
          <LoyaltyPointTicker
            className={clxs.llpTicker}
            method={llpDiscountMethod}
            showForMethodCoupon={true}
            visible={!hideLoyaltyPointsTicker}
          />
        </DiscountAlert>
      </Form>
    );
  },
);

export default PriceCalculator;

const FORM_ID = "rate-calculator-desktop";

const queryKey = "pre_book_meals";

function inferPackageSlugFromConfigArgs(
  configArgs: Record<string, number>,
) {
  for (const [configKey, requiredRooms] of Object.entries(configArgs)) {
    if (requiredRooms > 0) {
      return configKey;
    }
  }

  return "";
}
