import React, { ChangeEvent, Fragment, ReactNode, useEffect, useMemo } from "react";
import LLP_STAR from "../../../assets/icons/llp-icon-infinity.svg";
import EXCLAMATION_RED from "../../../assets/icons/exclamation-red-icon.svg";
import CHECK_GREEN from "../../../assets/icons/green-checkmark.svg";
import { useQueryParamValue, useSetQueryParam } from "../../../hooks/use-query-param";
import Button from "../../Button";
import CouponCode from "../../CouponCode";
import Portal from "../../Portal";
import useClx from "../../../hooks/use-clx";
import Switch from "../../Switch";
import DiscountAlert from "../DiscountAlert";
import STAR_ICON from "../../../assets/icons/star-icon.svg";
// import usePriceCalculator from "../../../hooks/use-price-calculator/use-price-calculator";
import clxs from "../Breakdown/breakdown.module.css";
import Modal, { useShowModal } from "../../../hooks/use-modal";
import IneligibleCriteria from "../IneligibleCriteria";
import useBookingPriceCalculator from "../../../hooks/use-booking-price-calculator/use-booking-price-calculator";
import TaxTooltip from "../TaxTooltip";
import BookingBreakdownSkeleton from "./BookingBreakdownSkeleton";
import useOnce from "../../../hooks/use-once";
// import Loader from "../../Loader";

interface BookingBreakdownProps {
  bookingSlugs: string[];
  viewLayout: string;
  className?: string;
  children?: ReactNode;
  loyaltyPointsBalance?: number;
  formId: string;
  couponCodeSearchKey: string;
  availLoyaltyPointsSearchKey: string;
  calculatorId: string;
  propertySlug: string;
  checkinDate: Date | null;
  checkoutDate: Date | null;
  adultCount: number;
  childCount: number;
  couponCode: string;
  configs: PropertyConfigArg[];
  totalNights: number;
  totalGuests: number;
  availLoyaltyPoints?: boolean;
  configMap: Record<number, PropertyConfig>;
  eligibilityPointsObj?: any;
  isDisplayProtectWidget: boolean;
  refundableSearchKey: string;
  quoteIdSearchKey: string;
  isOptedRefundableBooking: boolean;
  // setRefundableWidgetHtml?: any;
  formRef?:React.RefObject<HTMLDivElement>;
  tier?: string;
  // onFormSubmitClick?: any;
}

function BookingBreakdown(props: BookingBreakdownProps) {
  const {
      viewLayout,
      formId,
      couponCodeSearchKey,
      availLoyaltyPointsSearchKey,
      isOptedRefundableBooking,
      refundableSearchKey,
      quoteIdSearchKey,
      className,
      children,
      calculatorId,
      propertySlug,
      checkinDate,
      checkoutDate,
      adultCount,
      childCount,
      configs,
      couponCode,
      totalNights,
      totalGuests,
      availLoyaltyPoints,
      // loyaltyPointsBalance,
      configMap,
      eligibilityPointsObj,
      // setRefundableWidgetHtml,
      isDisplayProtectWidget,
      formRef,
      // onFormSubmitClick,
      bookingSlugs,
      tier,
    } = props,
    eligiblePoints = eligibilityPointsObj?.eligible_points,
    inelgibilePoints = eligibilityPointsObj?.ineligible_points,
    inelgibleCriteria = eligibilityPointsObj?.criteria,
    isMultiConfig = viewLayout === "multi_config",
    setCouponCode = useSetQueryParam("string", couponCodeSearchKey),
    setAvailLoyaltyPoints = useSetQueryParam("boolean", availLoyaltyPointsSearchKey),
    setRefundableBooking = useSetQueryParam("boolean", refundableSearchKey),
    setProtectQuoteId = useSetQueryParam("string", quoteIdSearchKey),
    protectQuoteId = useQueryParamValue("string",quoteIdSearchKey),
    // [protectQuoteId, setProtectQuoteId] = useState<string>(""),
    { tentativePrice, isInvalidResponse, isLoading } = useBookingPriceCalculator(
      calculatorId,
      bookingSlugs,
      couponCode,
      availLoyaltyPoints,
      isDisplayProtectWidget,
      isOptedRefundableBooking,
      protectQuoteId,
      tier,
    ),
    { widget_details } = tentativePrice,
    // { protectHtml } = widget_details,
    { currency_symbol} = tentativePrice, 
    template = useMemo(
      () => {
        if (!tentativePrice) {
          return [];
        }

        setProtectQuoteId(widget_details.protectQuoteId);
        // setRefundableWidgetHtml(protectHtml);

        const { currency_symbol, property_configs } = tentativePrice, 
          {
            // gross_base_rate,
            deposit,
            extra_adults_fare,
            taxes,
            // discount,
            coupon_or_tier_discount,
            rental_charges,
            total,
            discount_method,
            loyalty_points_discount_percentage,
            loyalty_points_discount_percentage_value,
            loyalty_points_redeemed_value,
            premium,
            gross_base_rate_without_extras,
            tax_breakdown,
          } = tentativePrice.breakdown,
          discountNode = loyalty_points_discount_percentage_value
            ? {
              label: `${discount_method} Tier (${loyalty_points_discount_percentage}%)`,
              value: `- ${currency_symbol} ${coupon_or_tier_discount.toLocaleString("en-IN")}`,
              labelClx: clxs.llpLabel,
              valueClx: clxs.llpValue,
            }
            : coupon_or_tier_discount
              ? {
                label: "Discount",
                value: `- ${currency_symbol} ${coupon_or_tier_discount.toLocaleString("en-IN")}`,
                labelClx: clxs.heavyLabel,
                valueClx: clxs.heavyValue,
              } : null,
          configBreakdownItems = isMultiConfig
            ? property_configs.reduce(
              (compiled, each) => {
                const {
                  required_rooms,
                  property_config_id,
                  price,
                } = each;

                if (!required_rooms) {
                  return compiled;
                }

                const { room_name = "" } = configMap[property_config_id] ?? {},
                  configTemplate = {
                    label: `${room_name}`,
                    value: `${required_rooms} x ${currency_symbol} ${price.toLocaleString("en-IN")}`,
                    labelClx: clxs.lightLabel,
                    valueClx: clxs.lightValue,
                  };

                return [
                  ...compiled,
                  configTemplate,
                ];
              },
              [] as BreakdownRenderTemplate[],
            ) : [],
          template = [
            !isMultiConfig && {
              label: "Charges",
              value: `${currency_symbol} ${gross_base_rate_without_extras.toLocaleString("en-IN")}`,
              labelClx: clxs.lightLabel,
              valueClx: clxs.lightValue,
            },
            ...configBreakdownItems,
            extra_adults_fare ? {
              label: "Additional Guest Charges",
              value: `${currency_symbol} ${extra_adults_fare.toLocaleString("en-IN")}`,
              labelClx: clxs.lightLabel,
              valueClx: clxs.lightValue,
            } : null,
            discountNode,
            loyalty_points_redeemed_value ? {
              label: "Loyalty Points",
              value: `- ${currency_symbol} ${loyalty_points_redeemed_value.toLocaleString("en-IN")}`,
              labelClx: clxs.heavyLabel,
              valueClx: clxs.heavyValue,
            } : null,
            (isOptedRefundableBooking && premium) && {
              label: "Refundable booking fee",
              value: `${currency_symbol} ${premium.toLocaleString("en-IN")}`,
              labelClx: clxs.lightLabel,
              valueClx: clxs.lightValue,
            },
            {
              label: "Applicable taxes",
              value: `${currency_symbol} ${taxes.toLocaleString("en-IN")}`,
              labelClx: clxs.lightLabel,
              valueClx: clxs.lightValue,
              data: tax_breakdown,
            },
            {
              label: (
                <>
                  Total rental charges
                  <div className={clxs.subtitle}>
                    {`(for ${totalGuests} guests and ${totalNights} nights)`}
                  </div>
                </>
              ),
              value: `${currency_symbol} ${rental_charges.toLocaleString("en-IN")}`,
              labelClx: clxs.heavyDistLabel,
              valueClx: clxs.heavyDistValue,
            },
            {
              label: "Security deposit (Refundable)",
              value: `${currency_symbol} ${deposit.toLocaleString("en-IN")}`,
              labelClx: clxs.heavyLabel,
              valueClx: clxs.heavyValue,
            },
            {
              label: "Total payable",
              value: `${currency_symbol} ${total.toLocaleString("en-IN")}`,
              labelClx: clxs.heavyDistLabel,
              valueClx: clxs.heavyDistValue,
            },
          ].filter(Boolean) as BreakdownRenderTemplate[];

        return template;
      },
      [
        viewLayout,
        tentativePrice,
        totalNights,
        totalNights,
        configMap,
      ],
    ),
    earningContent = useMemo(
      () => {
        if (!tentativePrice) {
          return null;
        }

        const { breakdown: { loyalty_points_earning } } = tentativePrice;

        if (!loyalty_points_earning) {
          return null;
        }

        const formatted = loyalty_points_earning.toLocaleString("en-IN");

        return `You will earn ${formatted} points on this booking`;
      },
      [tentativePrice],
    ),
    actionText = useMemo(
      () => {
        if (!tentativePrice) {
          return "Make Payment";
        }

        const { currency_symbol } = tentativePrice,
          { total } = tentativePrice.breakdown;

        return `${currency_symbol} ${total.toLocaleString("en-IN")} - Make Payment`;
      },
      [tentativePrice],
    ),
    eligiblePointsContent = useMemo(
      () => {
        if (!eligiblePoints) {
          return null;
        }

        const points = eligiblePoints.toLocaleString("en-IN");

        return `${points} Redeem Infinity Points`;
      },
      [eligiblePoints],
    ),
    // inelgibilePointsContent = useMemo(
    //   () => {
    //     if (!inelgibilePoints) {
    //       return null
    //     }

    //     const points = inelgibilePoints.toLocaleString("en-IN");

    //     return `${points} Points Redemption Unavailable`;
    //   },
    //   [inelgibilePoints],
    // ),
    ccx = useClx(clxs.container, className),
    handleAvailLoyaltyPoints = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget as HTMLInputElement;

      setAvailLoyaltyPoints(target.checked);
    },
    handleCouponCodeChange = (
      couponCode: string,
      replace: boolean = false,
    ) => setCouponCode(couponCode, { replace: replace }),
    handleAutoApplyCoupon = () => {
      if (!tentativePrice) {
        return false;
      }

      if (couponCode === "NONE") {
        return true;
      }

      // const { coupon_code: cc } = tentativePrice.breakdown;

      handleCouponCodeChange(couponCode || "", true);

      return true;
    },
    showCriteria = useShowModal(CRITERIA_MODAL_ID),
    handleKnowMore = async (inelgibilePoints: number) => {
      const props = {
        points: inelgibilePoints,
      };

      const criteria = await showCriteria(props as any);

      if(!criteria) {
        return;
      }

    },
    handleOptedInChange = (e: any) => {
      const detailObj = e.detail;
      const isOptedIn = detailObj.sold;
      setRefundableBooking(isOptedIn);
    },
    addOptedInEventChange = () => {
      // if(isDisplayProtectWidget) {
      //   const itemContainer = document.getElementById("protect_container");
      //   itemContainer && itemContainer.addEventListener("pg-change", handleOptedInChange);
      // }
      if(isDisplayProtectWidget) {
        //const htmlString = PROTECT_HTML.__html;
        //const protectScriptIndex = htmlString.search("<script nonce");

        //if(protectScriptIndex > 0) {
          //const protectScript = htmlString.substring(protectScriptIndex,htmlString.length);
          //console.log(protectScript);
          // const executeScript = new Function(protectScript);
          // executeScript();
        //}

        // if(!isOptedRefundableBooking) {
        //   console.log("Default false");
        // }
        
        document.addEventListener("pg-change", handleOptedInChange);
      }
    },
    scrollIntoForm = () => {
      // onFormSubmitClick();
      formRef?.current?.scrollIntoView({ behavior: "smooth",block: "center", inline: "start"});
    };
    // getReferralPoints = () => {
    //   if (!user) {
    //     return null;
    //   }
    // };

  useOnce(
    handleAutoApplyCoupon,
    [tentativePrice],
  );

  useEffect(
    () => {
      addOptedInEventChange();
    },[],
  );

  //Retina coupon code if the response is invalid.
  useEffect(() => {
    if(isInvalidResponse) {
      const { coupon_code } = tentativePrice.breakdown;
      setCouponCode(coupon_code as string, { replace: true });
    }
  },[
    isInvalidResponse,
  ]);

  return (
    <>
     {isLoading ? <BookingBreakdownSkeleton/> :
     <>
      {earningContent && (
        <div className={clxs.earningsDesktop}>
          <img
            src={STAR_ICON}
            alt="star"
          />
          {earningContent}
        </div>
      )}
      <div className={ccx}>
        <DiscountAlert
          method={tentativePrice?.breakdown.discount_method}
          discountMessage={tentativePrice?.breakdown.discount_message}
          discountAmount={
            tentativePrice?.breakdown.loyalty_points_discount_percentage_value
          }
          currencySymbol={tentativePrice?.currency_symbol}
          className={clxs.discountAlert}
        />
        {eligiblePointsContent && (
          <Switch
            name="avail_loyalty_points"
            className={clxs.llpSwitch}
            onChange={handleAvailLoyaltyPoints}
            checked={availLoyaltyPoints}
          >
            <div className={clxs.llpSwitchContent}>
              <img
                src={LLP_STAR}
                alt="loyalty points"
              />
              {eligiblePointsContent}
            </div>
          </Switch>
        )}
        {(availLoyaltyPoints && eligiblePoints && !inelgibilePoints) && (
          <div className={clxs.pointsSuccess}>
            <img
              src={CHECK_GREEN}
              alt="Points Success"
            />
            <span>
              <b>Congratulations!</b> Points Successfully redeemed
            </span>
          </div>
        )}
        {inelgibilePoints > 0 && (
          <>
            <div className={clxs.ineligiblePointsContent}>
              <img
                src={EXCLAMATION_RED}
                alt="Ineligible Points"
              />
              <span>
                <b>{inelgibilePoints}</b> Points Redemption Unavailable
              </span>

              <button
                type="button"
                className={clxs.knowMoreBtn}
                onClick={() => handleKnowMore(inelgibilePoints)}
              >
                Know More
              </button>
            </div>
            <Modal id={CRITERIA_MODAL_ID}>
              <IneligibleCriteria 
                criteria={inelgibleCriteria} 
                points={inelgibilePoints}
              />
            </Modal>
          </>
        )}

        {template.map((each, key) => (
          <Fragment key={key}>
            {each.label === "Applicable taxes" ? (
              <>
                <div className={each.labelClx}>
                   {each.label}
                   {each.data &&  
                    <TaxTooltip 
                      tax_data={each.data} 
                      currency_symbol= {currency_symbol}
                    />}
                </div>
                <div className={each.valueClx}>{each.value}</div>
              </>
            ) : (
              <>
                <div className={each.labelClx}>{each.label}</div>
                <div className={each.valueClx}>{each.value}</div>
              </>
            )}
          </Fragment>
        ))}
 
        <CouponCode
          value={couponCode}
          propertySlug={propertySlug}
          configs={configs}
          checkinDate={checkinDate?.toISOString()}
          checkoutDate={checkoutDate?.toISOString()}
          adultCount={adultCount || undefined}
          childCount={childCount || undefined}
          onChange={handleCouponCodeChange}
          className={clxs.couponCode}
        />
        {children}
        <Button
          className={clxs.submitDesktop}
          type="submit"
          form={formId}
          onClick={scrollIntoForm}
        >
          {actionText}
        </Button>
        {/* <Loader isLoading={isLoading}></Loader> */}
      </div>
      {earningContent && (
        <div className={clxs.earningsMobile}>
          <img
            src={STAR_ICON}
            alt="star"
          />
          {earningContent}
        </div>
      )}
      <Portal className={clxs.submitMobilePortal}>
        <Button
          className={clxs.submitMobile}
          type="submit"
          form={formId}
          onClick={scrollIntoForm}
        >
          {actionText}
        </Button>
      </Portal>
     </> 
     }
    </>
  );
}

export default BookingBreakdown;

type BreakdownRenderTemplate = {
  label: ReactNode;
  value: ReactNode;
  labelClx: string;
  valueClx: string;
  data?: any;
};

const CRITERIA_MODAL_ID = "ineligible-offer";
