import React, { ReactNode, useEffect, useRef, useState } from "react";
import clxs from "./tooltip.module.css";
import INFO_ICON from "../../assets/icons/info.svg";
import CLOSE_ICON from "../../assets/icons/close.svg";
import useClx from "../../hooks/use-clx";

interface TooltipProps {
  content: ReactNode; // Can accept any content
  iconSrc?: string;   
  iconAlt?: string;  
  iconClass?: string;
  showCloseIcon?:boolean;
}

function Tooltip(props: TooltipProps) {
  const { content, iconSrc = INFO_ICON, iconAlt = "Info", iconClass, showCloseIcon } = props;

  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  // Toggle tooltip visibility on click
  const handleClick = () => {
    setIsOpen((prev) => {
      return !prev
    })
  };

  const tcx = useClx(
    clxs.tooltiptext,
    isOpen ? clxs.show : "",
  );

  // Close tooltip when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div 
      className={clxs.tooltip} 
      ref={tooltipRef}
    >
    <img
      loading="lazy"
      src={iconSrc}
      alt={iconAlt}
      className={iconClass ? iconClass : clxs.icon}
      onClick={handleClick} // Click to toggle tooltip
    />
    <span className={tcx}>
      <div 
        className={showCloseIcon ?clxs.closeContainer : clxs.hideIcon }
        onClick={handleClick}
      >
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
        />
      </div>
      <div className={clxs.tooltipContent}>{content}</div>
    </span>
  </div>
  );
}

export default Tooltip;
