import add from "date-fns/add";
import differenceInMonths from "date-fns/differenceInMonths";
import startOfMonth from "date-fns/startOfMonth";
import { useCallback, useEffect, useMemo, useState } from "react";

function useCalendarCursor (
  max: number = 12,
  min: number = 0,
  monthCount: number = 1,
  initialDate = TODAY_MONTH,
  onMonthChange?: MonthChangeHandler,
  isMobile?: boolean,
) {
  const [cursor, setCursor] = useState(
      () => {
        const initialCursor = differenceInMonths(initialDate, TODAY_MONTH);

        return initialCursor;
      },
    ),
    [prevMonths, setPrevMonths] = useState<Date[]>([]),
    handleMonthChange = useCallback(
      (month: Date) => {
        if (!onMonthChange) {
          return;
        }

        onMonthChange(month);
      },
      [onMonthChange],
    ),
    handleScroll = useCallback(
      (calculateNextCursor: CalculateNextCursor) => {
        const nextCursor = calculateNextCursor(cursor, min, max);

        if (cursor === nextCursor) {
          return;
        }

        const monthScroll = Math.max(0, nextCursor);

        const withOffset = add(initialDate, { months: monthScroll });

        handleMonthChange(withOffset);

        setCursor(nextCursor);
      },
      [setCursor, handleMonthChange, min, max, cursor],
    ),
    handlePrevious = useCallback(
      () => handleScroll(
        (cursor, min) => Math.max(min, cursor - 1),
      ),
      [handleScroll],
    ),
    handleNext = useCallback(
      () => handleScroll(
        (cursor, min, max) => Math.min(max, cursor + 1),
      ),
      [handleScroll],
    ),
    handleToday = useCallback(
      () => {
        setCursor(0);

        handleMonthChange(initialDate);
      },
      [setCursor, initialDate, handleMonthChange],
    ),
    handleLoadMore = useCallback(
      () => handleScroll(
        (cursor, min, max) => Math.min(max, cursor + 3),
      ),
      [handleScroll],
    );
    const meta: {months: Date[]} = useMemo<{months: Date[]}>(
      () => {
        const referenceMonth = TODAY_MONTH;
        const existingMonths: Date[] = prevMonths || [];
        const newMonths: Date[] = Array.from(
          { length: monthCount }, // Use monthCount to determine array length
          (_, monthOffset) => add(
            referenceMonth,
            { months: monthOffset + cursor }, // Adds months offset from today + cursor position
          ),
        );

        // Combine existing and new months, removing duplicates by converting to ISO strings
        if(isMobile){
        const uniqueMonths = [...existingMonths, ...newMonths]
          .map(date => date.toISOString())
          .filter((date, index, self) => self.indexOf(date) === index)
          .map(dateStr => new Date(dateStr))
          .sort((a, b) => a.getTime() - b.getTime());

        setPrevMonths(uniqueMonths);
        
        return { months: uniqueMonths };
        } else {
          return { months: newMonths };
        }
      },
      [monthCount, cursor],
    );

  useEffect(
    () => handleMonthChange(initialDate),
    [handleMonthChange],
  );

  return {
    ...meta,
    cursor: cursor,
    handlePrevious: handlePrevious,
    handleNext: handleNext,
    handleToday: handleToday,
    handleLoadMore:handleLoadMore,
  };
}

export default useCalendarCursor;

export type MonthChangeHandler = (month: Date) => void;

const TODAY_MONTH = startOfMonth(new Date());

type CalculateNextCursor = (
  cursor: number,
  min: number,
  max: number,
) => number;