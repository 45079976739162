import React from "react";
import useClx from "../../../hooks/use-clx";
import fwclxs from "../../../styles/full-width.module.css";
import clxs from "./search-header.module.css";
import LOHONO_LOGO from "../../../assets/logo/lohono-logo.svg";
import LOHONO_LOGO_DARK from "../../../assets/logo/lohono-logo-dark.svg";
import MENU_ICON from "../../../assets/icons/menu.svg";
import MENU_WHITE_ICON from "../../../assets/icons/menuWhite.svg";
import CHEVRON_LEFT_ICON from "../../../assets/icons/chevron-left-grey.svg";
import Login from "../../Login";
import useToggle from "../../../hooks/use-toggle";
import { DRAWER_ID } from "../../Header";
import Searchbar from "../SearchBar";
import useIsMobile from "../../../hooks/use-is-mobile";
import ContextMenu from "../../ContextMenu";
import { Track } from "../../AnalyticsManager";
import Anchor from "../../Header/Anchor";
import PropertySearch from "../PropertySearch";
import ShareMenu from "../../PropertyDetail/LohonoLayout/ShareMenu";
import usePathParam from "../../../hooks/use-path-params";

interface SearchHeaderProps {
  className?: string;
  path: string;
  scrollShowClassName?: string;
  scrollHideClassName?: string;
  isPopUp?: boolean;
  onShare?: () => Promise<boolean>;
  isShowShareIcon?: boolean;
  detailPageSearchClassname?: string;
  value?: string;
  onChange?: (nextDirection: string) => void;
  isHeaderTransparent?: boolean;
  propertyDetails?: Property;
}

function SearchHeader(props: SearchHeaderProps) {
  const {
    className,
    isHeaderTransparent,
    path,
    scrollShowClassName,
    scrollHideClassName,
    isPopUp,
    isShowShareIcon,
    onShare,
    detailPageSearchClassname,
    value,
    onChange,
    propertyDetails,
  } = props,
    { isMobile } = useIsMobile(),
    ccx = useClx(
      fwclxs.container,
      fwclxs.containerPadding,
      clxs.container,
      className,
      isHeaderTransparent ? clxs.transparent : clxs.solid,
    ),
    { handleToggle } = useToggle(DRAWER_ID),
    location = usePathParam(PATH, "location"),
    destination = usePathParam(PATH, "destination"),
    locationUrl = "/villas/" + destination + "/" + location.trim().toLowerCase().replace(/\s+/g, "");

  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault();
  
    if (propertyDetails) {
      window.location.href = locationUrl;
    } else {
      window.location.href = "/";
    }
  };

  return (
    <>
      <nav className={isPopUp ? `${ccx} ${clxs.hideShadow}` : ccx}>
        <div className={clxs.logoContainer}>
          <a
            className={clxs.backButton}
            onClick={handleBack}
            title="Go back"
          >
            <img
              src={CHEVRON_LEFT_ICON}
              alt="back"
              className={clxs.leftChevron}
            />
          </a>
          <a
            href="/"
            className={clxs.logoLink}
            title="Lohono Stays"
          >
            {(isHeaderTransparent && !isMobile) ? (
              <img
                className={`${clxs.logo} ${isMobile ? scrollHideClassName : ""}`}
                src={LOHONO_LOGO_DARK}
                alt="Lohono"
              />
            ) : (
              <img
                className={`${clxs.logo} ${isMobile ? scrollHideClassName : ""}`}
                src={LOHONO_LOGO}
                alt="Lohono"
              />
            )}
          </a>
        </div>
        {isMobile ? (
          <PropertySearch
            path={path}
            className={` ${clxs.propertySearch} ${scrollShowClassName}`}
            filterMobileClassName={` ${clxs.propertySearchMobile} ${scrollShowClassName} ${detailPageSearchClassname}`}
            filterDesktopClassName={` ${clxs.propertySearch} ${scrollShowClassName}`}
            sort={value}
            onSortChange={onChange}
            propertyDetails={propertyDetails}
          />
        ) : isPopUp ? null : (
          <Searchbar
            className={`${clxs.propertySearchHeaderContainer} ${scrollShowClassName}`}
            path={path}
          />
        )}
        {onShare && isShowShareIcon && isMobile && (
          <ShareMenu onShare={onShare}></ShareMenu>
        )}
        <Login
          className={clxs.login}
          isHeaderTransparent={isHeaderTransparent}
        />
        <img
          src={MENU_ICON}
          alt="menu"
          className={`${clxs.mobileMenu} ${isMobile ? scrollHideClassName : ""
            }`}
          onClick={handleToggle}
        />
        <ContextMenu
          title="More"
          expandIcon={(isHeaderTransparent && !isMobile) ? MENU_WHITE_ICON : MENU_ICON}
          collapseIcon={(isHeaderTransparent && !isMobile) ? MENU_WHITE_ICON : MENU_ICON}
          className={clxs.headerDropdown}
          menuClassName={clxs.headerDropdownMenu}
          menu={
            <>
              <Track
                key="https://blog.lohono.com"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "Lohono Blog",
                  header: "Blog",
                  href: "https://blog.lohono.com",
                }}
              >
                <Anchor
                  title="Blog"
                  href="https://blog.lohono.com"
                  className={clxs.drawerLink}
                />
              </Track>
              <Track
                key="/about-us"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "About Lohono",
                  header: "About us",
                  href: "/about-us",
                }}
              >
                <Anchor
                  title="About us"
                  href="/about-us"
                  className={clxs.drawerLink}
                />
              </Track>
              <Track
                key="/lohono-experience"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "Lohono Experience",
                  header: "Lohono Experience",
                  href: "/lohono-experience",
                }}
              >
                <Anchor
                  title="Lohono Experience"
                  href="/lohono-experience"
                  className={clxs.drawerLink}
                />
              </Track>
              <Track
                key="/corporate-stays"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "Corporate Retreats",
                  header: "Corporate Retreats",
                  href: "/corporate-stays",
                }}
              >
                <Anchor
                  title="Corporate Retreats"
                  href="/corporate-stays"
                  className={clxs.drawerLink}
                />
              </Track>
              <Track
                key="/homeowner"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "List your property",
                  header: "List your property",
                  href: "/homeowner",
                }}
              >
                <Anchor
                  title="List your property"
                  href="/homeowner"
                  className={clxs.drawerLink}
                />
              </Track>
            </>
          }
        >
          &nbsp;
        </ContextMenu>
      </nav>
    </>
  );
}

export default SearchHeader;

const PATH = "/villas/:destination/:location/:property";
