import usePropertyService from "./use-property-service";
import { useAtomState } from "./use-atom";
import { useEffect, useState } from "react";
import { useSession } from "./use-session";
import { OFFERS_LISTING_KEY } from "../../core/constants";

function useOffersListing(
  location_slug: string,
) {
  const { service } = usePropertyService(),
  [isLoading, setIsLoading] = useState<boolean>(false),
    session = useSession(),
    [offers, setOffers] = useAtomState<ListingOffersType[]>(OFFERS_LISTING_KEY,[]),
    handleGetOffers = async (
      session: string,
      location_slug: string,
    ) => {

      setIsLoading(true);

      const { error, response} = await service.getOffersList(
        location_slug,
        session,
      );

      setIsLoading(false);

      if (error) {
        console.error(error);
      }

      if (error || !response) {
        return;
      }

      setOffers(response.offers)

    };

  useEffect(
    () => {
        handleGetOffers(
        session,
        location_slug,
      );
    },
    [
      session,
      location_slug,
    ],
  );

  return {
    offers: offers,
    isLoading: isLoading,
  };
}

export default useOffersListing;
