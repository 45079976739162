import React, { ForwardedRef, forwardRef, useMemo } from "react";
import useClx from "../../../../hooks/use-clx";
import clxs from "./room-options.module.css";
import RoomOptionItem from "./RoomOptionItem";
import { usePropertyConfigValues } from "../../../../hooks/use-property-config";
import { useQueryParamValue } from "../../../../hooks/use-query-param";
import differenceInDays from "date-fns/differenceInDays";

interface RoomOptionsProps {
  checkinDateKey: string;
  checkoutDateKey: string;
  propertySlug: string;
  configs: PropertyConfig[],
  tentativePrice: TentativePrice;
  className?: string,
  onViewRoomDetails?: (config: PropertyConfig) => void;
}

const RoomOptions = forwardRef(
  function RoomOptionsCore(
    props: RoomOptionsProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const {
        checkinDateKey,
        checkoutDateKey,
        className,
        propertySlug,
        configs,
        onViewRoomDetails,
        tentativePrice,
      } = props,
      checkinDate = useQueryParamValue("date", checkinDateKey),
      checkoutDate = useQueryParamValue("date", checkoutDateKey),
      {
        configValueMap,
        setPropertyConfigValue,
      } = usePropertyConfigValues(propertySlug, configs),
      pcTentativePriceMap = useMemo(
        () => {
          const tentativePriceMap = tentativePrice.property_configs.reduce(
            (compiled, configBreakdown) => {
              const { property_config_id } = configBreakdown;

              compiled[property_config_id] = configBreakdown;

              return compiled;
            },
            {} as Record<string, PropertyConfigBreakdown>,
          );

          return tentativePriceMap;
        },
        [tentativePrice],
      ),
      totalNights = useMemo(
        () => {
          if (!checkinDate || !checkoutDate) {
            return 0;
          }

          const diff = differenceInDays(checkoutDate, checkinDate);

          return diff;
        },
        [checkinDate, checkoutDate],
      ),
      { currency_symbol } = tentativePrice,
      ccx = useClx(clxs.container, className),
      handleChange = (config: PropertyConfig, value: string) => {
        setPropertyConfigValue(config, value);
      };

    return (
      <div
        className={ccx}
        ref={ref}
      >
        <div className={clxs.title}>
          Bedroom Details
        </div>
        {configs.map(
          (each) => {
            const value = configValueMap[each.config_id]?.toString() ?? "";

            const breakdown = pcTentativePriceMap[each.config_id] ?? {};

            const price = breakdown.price ?? 0;

            const discountedPrice = breakdown.discounted_price ?? 0;

            const discountMethod = breakdown.discount_method;

            //const discountPercentage = breakdown.loyalty_points_discount_percentage;

            const discountValue = breakdown.discount_value;

            const discountType = breakdown.discount_type;

            const disabled = !checkinDate || !checkinDate;

            return (
              <RoomOptionItem
                key={each.config_id}
                config={each}
                currencySymbol={currency_symbol}
                value={value}
                price={price}
                disabled={disabled}
                discountedPrice={discountedPrice}
                discountType={discountType}
                discountMethod={discountMethod}
                noOfNights={totalNights}
                discountValue={discountValue}
                onChange={handleChange}
                onViewRoomDetails={onViewRoomDetails}
              />
            );
          },
        )}
      </div>
    );
  },
)

export default RoomOptions;