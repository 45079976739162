import useReservationService from "./use-reservation-service";
import { useEffect, useState } from "react";

function useValidateCoupons(
  coupon_code: string,
  property_configs: PropertyConfigArg[],
  property_slug: string,
  check_in: string,
  check_out: string,
) {
  const { service } = useReservationService(),
    [coupon, setCoupon] = useState<ValidatedCoupon | null>(null),
    handleGetCoupon = async (coupon_code: string) => {
      if (!coupon_code) {
        setCoupon(null);
        return;
      }

      if (coupon_code === "NONE") {
        setCoupon(null);
        return;
      }

      if (coupon?.code === coupon_code) {
        return;
      }

      const { error, response } = await service.validateCoupon(
        coupon_code as string,
        property_configs as any[],
        property_slug,
        check_in as string,
        check_out as string,
      );

      if (error || !response) {
        setCoupon(null);
        return;
      }

      setCoupon(response);
    };

  useEffect(() => {
    handleGetCoupon(coupon_code);
  }, [coupon_code]);

  return { coupon: coupon };
}

export default useValidateCoupons;
