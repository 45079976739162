import React, { useCallback } from "react";
import useClx from "../../../hooks/use-clx";
import { useStorageBoolean } from "../../../hooks/use-storage";
import Portal from "../../Portal";
import clxs from "./app-store-alert.module.css";
import APP_PRIMARY_LIGHT_LOGO from "../../../assets/logo/primary-light.svg";
import CLOSE_PRIMARY from "../../../assets/icons/close-primary.svg";

interface AppStoreAlertProps {
  className?: string;
}

function AppStoreAlert (props: AppStoreAlertProps) {
  const { value, setValue } = useStorageBoolean("localStorage", APP_STORE_ALERT_KEY);

  const handleDismiss = useCallback(
    () => setValue(true),
    [setValue],
  );

  const { className } = props;

  const ccx = useClx(clxs.container, className);

  if (value) {
    return null;
  }

  return (
    <Portal>
      <div className={ccx}>
        <img
          src={APP_PRIMARY_LIGHT_LOGO}
          alt="logo"
        />
        {CONTENT}
        <a
          href="/app-store"
          className={clxs.link}
        >
          Download App
        </a>
        <img
          src={CLOSE_PRIMARY}
          alt="close"
          onClick={handleDismiss}
        />
      </div>
    </Portal>
  );
}

export default AppStoreAlert;

const CONTENT = "Exclusive 8% discount on bookings via app";

const APP_STORE_ALERT_KEY = "APP_STORE_ALERT";
