import React from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./scroller.module.css";
import CHEVRON_LEFT_GREY from "../../assets/icons/chevron-left-grey.svg";
import CHEVRON_RIGHT_GREY from "../../assets/icons/chevron-right-grey.svg";

interface ScrollerProps {
  onScrollLeft: () => void;
  onScrollRight: () => void;
  isLeftDisabled?: boolean;
  isRightDisabled?: boolean;
  className?: string;
  isSmallIcon? : boolean;
}

function Scroller(props: ScrollerProps) {
  const { className, onScrollLeft, onScrollRight , isSmallIcon, isLeftDisabled, isRightDisabled} = props,
    ccx = useClx(clxs.scroller, className);

  return (
    <div className={ccx}>
      <img
        src={CHEVRON_LEFT_GREY}
        alt="scroll-left"
        className={isSmallIcon ? clxs.similarPropIcon : clxs.scrollIcon}
        onClick={onScrollLeft}
        style={{ opacity: isLeftDisabled ? 0.5 : 1, pointerEvents: isLeftDisabled ? "none" : "auto" }}
      />
      <img
        src={CHEVRON_RIGHT_GREY}
        alt="scroll-right"
        className={isSmallIcon ? clxs.similarPropIcon : clxs.scrollIcon}
        onClick={onScrollRight}
        style={{ opacity: isRightDisabled ? 0.5 : 1, pointerEvents: isRightDisabled ? "none" : "auto" }}
      />
    </div>
  );
}

export default Scroller;
