import format from "date-fns/format";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPropertySearchUrl } from "../../../../utils/property-search";
import useClx from "../../../hooks/use-clx";
import Modal, { useShowModal } from "../../../hooks/use-modal";
import usePropertyLocations from "../../../hooks/use-property-locations";
import { useQueryParamValue, useQuerySearch } from "../../../hooks/use-query-param";
import useToggle from "../../../hooks/use-toggle";
import Button from "../../Button";
import DateRangeInput from "../../DateRangeInput";
import { DRAWER_ID } from "../../Header";
import OccupancySelect from "../../Occupancy/Select";
import Select from "../../Select";
// import Sort from "../Sort/Mobile";
import fwclxs from "../../../styles/full-width.module.css";
import clxs from "./collection-property-search.module.css";
import MENU_ICON from "../../../assets/icons/menu.svg";
import LOCATION_INPUT_PIN_ICON from "../../../assets/icons/location-input-pin.svg";
import CHEVRON_DOWN_LIGHT from "../../../assets/icons/chevron-down-light.svg";
import CHEVRON_LEFT_ICON from "../../../assets/icons/chevron-left-grey.svg";
import EVENT_ICON from "../../../assets/calendar-icon.svg";
import ADD_GUESTS_ICON from "../../../assets/occupancy-icon.svg";
import Sort from "../../PropertyListing/Sort/Mobile";
import CollectionPropertySearchMobile from "../CollectionPropertySearchMobile";
import LOCATION_DESKTOP_ICON from "../../../assets/icons/location-desktop-icon.svg";
import Img from "../../Img";
import { useStorageDate, useStorageNumber, useStorageString } from "../../../hooks/use-storage";

interface PropertySearchProps {
  sort: string;
  onSortChange: (nextDirection: string) => void;
  className?: string;
  filterDesktopClassName?: string;
  filterMobileClassName?: string;
  propertyCount: number;
  activeCollection: StaticDataCollection;
}

function CollectionPropertySearch(props: PropertySearchProps) {
  const [search, setQuerySearch] = useQuerySearch(),
    push = useNavigate(),
    showPropertySearchModal = useShowModal(PROPERTY_SEARCH_MODAL_ID),
    { handleToggle } = useToggle(DRAWER_ID),
    {
      // path,
      className,
      filterDesktopClassName,
      filterMobileClassName,
      activeCollection,
      sort,
      onSortChange,
    } = props,
    // { slug } = activeCollection,
    { propertyLocations } = usePropertyLocations(),
    [showDateDialog, setShowDateDialog] = useState<boolean>(false),
    [showOccupancyDialog, setShowOccupancyDialog] = useState<boolean>(false),
    [showDateOnScroll, setShowDateOnScroll] = useState<boolean>(false),
    locationOptions = useMemo(
      () => {
        const compiledLocations = propertyLocations.reduce(
        (compiled, each) => {
          const { name, slug, coming_soon, country } = each;
          if (coming_soon) {
            return compiled;
          }

          if(country != "India") {
            return compiled;
          }

          const option = {
            label: name,
            value: slug,
          };

          return [
            ...compiled,
            option,
          ];
        },
        [] as { label: string; value: string }[],
      );  

       compiledLocations.unshift({label: "All locations", value: ""})

      return compiledLocations
    }
      ,
      [propertyLocations],
    ),
    destination = useQueryParamValue("string", "destination"),
    location = useQueryParamValue("string", "location"),
    location_slug = useMemo(
      () => `india-${location}`,
      [destination, location],
    ),
    checkin_date = useQueryParamValue("date", "checkin_date"),
    checkout_date = useQueryParamValue("date", "checkout_date"),
    adult_count = useQueryParamValue("integer", "adult_count"),
    child_count = useQueryParamValue("integer", "child_count"),
    userSearch = useQueryParamValue("string", "search"),
    [searchText, setSearchText] = useState<string>(() => userSearch),
    {
      setValue: setDestination,
    } = useStorageString("sessionStorage", "destination"),{
      setValue: setCheckinDate,
    } = useStorageDate("sessionStorage", "checkin_date"), {
      setValue: setCheckoutDate,
    } = useStorageDate("sessionStorage", "checkout_date"), {
      setValue: setAdultCount,
    } = useStorageNumber("sessionStorage", "adult_count"), {
      setValue: setChildCount,
    } = useStorageNumber("sessionStorage", "child_count"),
    occupancy = useMemo(
      () => {
        return {
          adult_count: adult_count,
          child_count: child_count,
        };
      },
      [adult_count, child_count],
    ),
    destinationFriendly = useMemo(
      () => {
        const found = locationOptions.find(each => each.value === location);

        return found?.label ?? "All Locations";
      },
      [locationOptions, location],
    ),
    stayFriendly = useMemo(
      () => {
        const friendly: string[] = [];

        if (checkin_date) {
          friendly.push(
            format(checkin_date, "dd/MM/yy"),
          );
        }

        if (checkout_date) {
          friendly.push(
            format(checkout_date, "dd/MM/yy"),
          );
        }

        return friendly.join(" - ");
      },
      [checkin_date, checkout_date],
    ),
    guestsFriendly = useMemo(
      () => {
        let totalGuests = 0;

        if (adult_count) {
          totalGuests += adult_count;
        }

        if (child_count) {
          totalGuests += child_count;
        }

        if (!totalGuests) {
          return "";
        }

        return `${totalGuests} guests`;
      },
      [adult_count, child_count],
    ),
    defaultValue = useMemo(
      () => {
        return {
          location: location,
          stay: {
            start: checkin_date,
            end: checkout_date,
          },
          occupancy: {
            adult_count: adult_count,
            child_count: child_count,
          },
          price_package: "",
        };
      },
      [
        location_slug,
        checkin_date, checkout_date,
        adult_count, child_count,
      ],
    ),
    mhcx = useClx(fwclxs.container, clxs.mobileHeader, filterMobileClassName),
    ccx = useClx(fwclxs.container, clxs.collectionContainer, className),
    // fdcx = useClx(clxs.filters, clxs.filtersDesktop, filterDesktopClassName),
    fcdcx = useClx(clxs.filters, clxs.collectionfiltersDesktop, filterDesktopClassName),
    cmx = useClx(clxs.mobileContainer, filterMobileClassName),
    fmcx = useClx(clxs.filters, clxs.filtersMobile),
    // wocx = useClx(fwclxs.container, clxs.webOffer),
    handleLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target,
        location = target.value,
        value = {
           location: [location],
        },
        url = getPropertySearchUrl(destination),
        params = new URLSearchParams(search as unknown as Record<string, string>);

        //If Check in date not present auto invoke the date selector.
        if(!checkin_date) {
          setShowDateDialog(true);
        }

        setDestination(location);
        setShowDateOnScroll(true)

      if (activeCollection) {
        return setQuerySearch(
          (params) => ({ ...params, ...value }),
        );
      }  
      return push({ pathname: url, search: params.toString() });
    },
    handleStayChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement,
        { start: checkin_date, end: checkout_date } = target.value as any,
        value = {
          checkin_date: [checkin_date?.toISOString()],
          checkout_date: [checkout_date?.toISOString()],
        };

        if(checkin_date && checkout_date) { 
          setShowOccupancyDialog(true);
        }

      setCheckinDate(checkin_date);
      setCheckoutDate(checkout_date);

      return setQuerySearch(
        (params) => ({ ...params, ...value }),
      );
    },
    handleOccupancyChange = (e: ChangeEvent<any>) => {
      const target = e.target,
        { adult_count, child_count } = target.value,
        value = {
          adult_count: [adult_count],
          child_count: [child_count],
        };

        setAdultCount(adult_count);
        setChildCount(child_count);

      setQuerySearch(
        (params) => ({ ...params, ...value }),
      );
    },
    handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target,
        { value } = target;

      setSearchText(value);
    },
    handleSearch = () => {
      setQuerySearch(
        (params) => ({ ...params, search: [searchText] }),
      );
    },
    handleChange = async () => {
      const values: any = await showPropertySearchModal(
        { defaultValue: defaultValue },
      );

      if (!values) {
        return;
      }

      const {
        location,
        stay = { start: null, end: null },
        occupancy = { adult_count: 0, child_count: 0 },
        price_package = "",
      } = values,
      queryValues = {
        location: [location],
        checkin_date: [stay.start?.toISOString()],
        checkout_date: [stay.end?.toISOString()],
        adult_count: [occupancy.adult_count],
        child_count: [occupancy.child_count],
        price_package: [price_package],
      }
        
      setQuerySearch(
        (params) => ({ ...params, ...queryValues }),
      );
    },
    reOpenDailog=()=>{
      setShowDateDialog(false);
    };

  useEffect(() => setSearchText(userSearch), [userSearch]);

  return (
    <>
      <div className={mhcx}>
        <img
          src={MENU_ICON}
          alt="menu"
          className={clxs.mobileMenu}
          onClick={handleToggle}
        />
        <div
          className={clxs.mobileHeaderContent}
          onClick={handleChange}
        >
          <a
            href="/"
            className={clxs.leftChevron}
          >
            <img
              src={CHEVRON_LEFT_ICON}
              alt="back"
            />
          </a>
          <div className={clxs.mobileHeaderContentIcon}>
            <img
              className={clxs.locationIcon}
              src={LOCATION_INPUT_PIN_ICON}
              alt="location"
            />
            {destinationFriendly}
            <img
              className={clxs.caretIcon}
              src={CHEVRON_DOWN_LIGHT}
              alt="expand"
            />
          </div>
        </div>
        <div className={cmx}>
          <div
            className={fmcx}
            onClick={handleChange}
          >
            <div
              className={clxs.friendly}
              data-populated={Boolean(stayFriendly).valueOf()}
            >
              <img
                className={clxs.eventIcon}
                src={EVENT_ICON}
                alt="eventIcon"
              />
              {stayFriendly || "Check In - Check out"}
            </div>
            <div
              className={clxs.friendly}
              data-populated={Boolean(guestsFriendly).valueOf()}
            >
              <img
                className={clxs.addGuestsIcon}
                src={ADD_GUESTS_ICON}
                alt="addGuestsIcon"
              />
              {guestsFriendly || "Add guests"}
            </div>
          </div>
          <Sort
            value={sort}
            onChange={onSortChange}
          />
        </div>
      </div>
      <div className={ccx}>
        <Img
          loading="lazy"
          src={activeCollection.mobile_banner_image}
          mobileSrc={activeCollection.mobile_banner_image}
          desktopSrc={activeCollection.desktop_banner_image}
          alt="banner"
          className={clxs.background}
        />
        <div className={clxs.overlay}>&nbsp;</div>
          <div className={clxs.title}>
          { activeCollection.name }
          </div>
          <h1 className={clxs.subtitle}>
            { activeCollection.description }
          </h1>
      </div>
      <div className={fcdcx}>
        <Select
          options={locationOptions}
          value={location}
          defaultValue={destinationFriendly}
          viewlayout={true}
          placeholder="Select Your Destination"
          className={clxs.destination}
          onChange={handleLocationChange}
          iconDesktop={LOCATION_DESKTOP_ICON}
        />
        <DateRangeInput
          className={clxs.stay}
          start={checkin_date}
          end={checkout_date}
          onOpenDialog={reOpenDailog}
          onChange={handleStayChange}
          labels={["Check In", "Check Out"]}
          placeholder={["Add Date", "Add Date"]}
          calendarDialogProps={{ monthCount: 2 }}
          isShowDialog={showDateDialog}
          isShowDateOnScroll={showDateOnScroll}
        />
        <OccupancySelect
          value={occupancy}
          label="Guests"
          occupancySuccessText="Apply & Search"
          className={clxs.occupancy}
          isShowOccupancyDialog={showOccupancyDialog}
          onChange={handleOccupancyChange}
        />
        <div className={clxs.search}>
          <input
            type="text"
            placeholder="🔍 Search by name"
            value={searchText}
            onChange={handleSearchChange}
          />
        </div>
        <Button
          className={clxs.searchButton}
          onClick={handleSearch}
        >
          Search
        </Button>
      </div>
      <Modal
        id={PROPERTY_SEARCH_MODAL_ID}
        scrollBlock={false}
      >
        <CollectionPropertySearchMobile
          viewLayout="standard"
          formId={PROPERTY_SEARCH_MOBILE_FORM_ID}
          hidden={HIDDEN_FIELDS}
          maxAdultCount={24}
          maxChildCount={8}
          configs={[]}
        />
      </Modal>
    </>
  );
}

export default CollectionPropertySearch;

const PROPERTY_SEARCH_MODAL_ID = "property-search-modal";

const PROPERTY_SEARCH_MOBILE_FORM_ID = "property-search-mobile";

const HIDDEN_FIELDS: any = ["price_package"];