import React, { MouseEventHandler } from "react";
import useClx from "../../../hooks/use-clx";
import Img from "../../Img";
import SendRequest from "../../SendRequestNeedHelp";
import clxs from "./need-help.module.css";
import NEED_HELP_BACKGROUND_DESKTOP from "../../../assets/need-help-banner-desktop.jpg";
import NEED_HELP_BACKGROUND_MOBILE from "../../../assets/need-help-banner-mobile.jpg";
//import SendRequestForm from "../../SendRequestForm";
//import useSendRequest from "../../../hooks/use-send-request";
//import Button from "../../Button";

interface NeedHelpProps {
  className?: string;
  onContactUs?: MouseEventHandler;
}

function NeedHelp(props: NeedHelpProps) {
  const { className, onContactUs } = props,
    ccx = useClx(clxs.container, className);

    // const { sendRequest } = useSendRequest();

    // const defaultValue = {
    //   interest: "rental",
    //   location: "goa",
    // };

  return (
    <div className={ccx}>
      <Img
        alt="Need help"
        className={clxs.background}
        src={NEED_HELP_BACKGROUND_DESKTOP}
        desktopSrc={NEED_HELP_BACKGROUND_DESKTOP}
        mobileSrc={NEED_HELP_BACKGROUND_MOBILE}
      />
      <div className={clxs.title}>
        <span className={clxs.emphasis}>{"Experiences "}</span>
        {" crafted by seasoned experts "}
        {" , tailored to your "}
        <span className={clxs.emphasis}>
          {"preferences. "}
        </span>
      </div>
      <SendRequest
        title="Need help?"
        subtitle="Please share your name and number so our team can connect with you"
        className={clxs.needHelp}
        interest="events_and_experience"
        isThemeTwo={true}
      />
      {/* <SendRequestForm
        id="contact-us-event"
        className={clxs.needHelp}
        titleNode={(
          <div className={clxs.sendRequestTitle}>
            Send a request
          </div>
        )}
        defaultValue={defaultValue}
        isClearOnSubmit={true}
        onSubmit={sendRequest}
      >
        <Button
          className={clxs.submit}
          type="submit"
        >
          Submit
        </Button>
      </SendRequestForm> */}
      <div
        className={clxs.contactUs}
        onClick={onContactUs}
      >
        Contact Us
      </div>
    </div>
  );
}

export default NeedHelp;
