import { useAtomState } from "./use-atom";

class _DefaultPropertyService implements IPropertyService {
  async getProperties(): Promise<FetchResult<PropertiesListingResult>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getBanner(): Promise<FetchResult<BannerResult>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getOffersList(): Promise<FetchResult<OffersResult>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getLocationAndVilla(): Promise<FetchResult<PropertyLocationAndVilla[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getProperty(): Promise<FetchResult<Property>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getRoomDetails(): Promise<FetchResult<RoomDetails>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getPropertyLocations(): Promise<FetchResult<PropertyLocation[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getSimilarProperties(): Promise<FetchResult<PropertyItem[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getTrendingProperties(): Promise<FetchResult<PropertyItem[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async addToWishlist(): Promise<FetchResult<void>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getWishlist(): Promise<FetchResult<PropertyItem[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getPropertyCalendar (): Promise<FetchResult<PropertyCalendar>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getPointsEligibility (): Promise<FetchResult<PointsEligibility>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getMealPlanOptions (): Promise<FetchResult<MealOptionList>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getPropertyReviews (): Promise<FetchResult<Reviews[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }
  
}

const DefaultPropertyService = new _DefaultPropertyService();

export const PROPERTY_SERVICE_KEY = "PROPERTY_SERVICE";

function usePropertyService() {
  const [service, setService] =
    useAtomState<IPropertyService>(
      PROPERTY_SERVICE_KEY,
      DefaultPropertyService,
    );

  return {
    service: service,
    setService: setService,
  };
}

export default usePropertyService;
