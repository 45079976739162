import React from "react";
import clxs from "./guest-review-modal.module.css";
import CLOSE_ICON from "../../../../assets/icons/close-room-details.svg";
import ReviewItem from "../ReviewItem";
import Stars from "../Stars";
import useIsMobile from "../../../../hooks/use-is-mobile";

interface GuestReviewModalProps {
    open?: boolean;
    onSubmit?: (out: any) => void;
    reviews?: Reviews[];
    ratings_count?: number;
    average_ratings?: number;
    title: string;
  }

function GuestReviewModal(props : GuestReviewModalProps) {

    const {onSubmit,ratings_count,average_ratings,title} = props,
    { isMobile } = useIsMobile();

    return ( 
    <div className={clxs.container}> 
      <div className={clxs.header}>
        <div className={clxs.titleContainer} >
          {title}
          <img
            src={CLOSE_ICON}
            alt="close"
            className={clxs.close}
            onClick={onSubmit?.bind(null, null)}
          />
        </div>
      </div>
      <div className={clxs.ratingReviewsContainer}>
          {average_ratings ? 
          <div className={clxs.avgRating}>{average_ratings}</div>
          : null }

          {average_ratings ? 
          <Stars 
            className={clxs.stars} 
            average_ratings={average_ratings}
            iconClassName={clxs.guestReviewIcon}
            isShowEmptyStar={true}
          /> 
          : null } 
          
          {ratings_count && isMobile ?
          <div className={clxs.ratingCount}>{ratings_count} Reviews</div> 
          : null }
      </div>

      <div className={clxs.divider}></div>
      <ReviewItem 
        className={clxs.reviewItem} 
      />
    </div>
    );
}

export default GuestReviewModal;