import usePropertyService from "./use-property-service";
import usePrefetch from "./use-prefetch";
import { useAtomState } from "./use-atom";
import { PROPERTY_DETAIL_KEY } from "../../core/constants";
import { useEffect, useState } from "react";

function usePropertyDetail(
  propertySlug: string,
  checkin_date?: Date | null,
  checkout_date?: Date | null,
) {
  const { service } = usePropertyService(),
    [isLoading, setIsLoading] = useState<boolean>(false),
    [isInitialDataLoaded, setIsDataLoaded] = useState<boolean>(false),
    { hasPrefetchKey, removePrefetch } = usePrefetch(),
    [propertyDetail, setPropertyDetail] = useAtomState<Property | null>(PROPERTY_DETAIL_KEY, null),
    handleGetPropertyDetail = async (
      propertySlug: string,
      checkin_date?: Date | null,
      checkout_date?: Date | null,
    ) => {
      if (hasPrefetchKey(PROPERTY_DETAIL_KEY)) {
        removePrefetch(PROPERTY_DETAIL_KEY);
        setIsDataLoaded(true);
        return;
      }

      setIsLoading(true);

      const { error, response } = await service.getProperty(
        propertySlug,
        checkin_date?.toISOString(),
        checkout_date?.toISOString(),
      );

      if (error || !response) {
        setIsDataLoaded(true);
        return;
      }

      setPropertyDetail(response);
      setIsLoading(false);
      setIsDataLoaded(true);
    };

  useEffect(
    () => {
      handleGetPropertyDetail(propertySlug, checkin_date, checkout_date);
    },
    [propertySlug, checkin_date, checkout_date],
  );

  return {
    propertyDetail: propertyDetail,
    isLoading: isLoading,
    isInitialDataLoaded: isInitialDataLoaded,
  };
}

export default usePropertyDetail;
