import React, {
  ChangeEventHandler,
  FocusEventHandler,
  HTMLAttributes,
  LabelHTMLAttributes,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import Occupancy, { OccupancyProps, OccupancyValue } from "../index";
import useClx from "../../../hooks/use-clx";
import ExpandIcon from "../../Select/ExpandIcon";
import clxs from "./occupancy-select.module.css";
import GUEST_ICON from "../../../assets/icons/person.svg";

export interface OccupancySelectProps extends Omit<OccupancyProps, "onChange"> {
  name?: string;
  onChange?: ChangeEventHandler<any>;
  onFocus?: FocusEventHandler<any>;
  onBlur?: FocusEventHandler<any>;
  containerProps?: Omit<HTMLAttributes<HTMLDivElement>, "className">;
  label?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  error?: string;
  isShowOccupancyDialog?: boolean;
  occupancySuccessText?: string;
  handleDoneClick?: (e: React.ChangeEvent<any>) => void;
  onOpenDialog?: () => void;
  isPriceCalculator?: boolean;
}

function OccupancySelect(props: OccupancySelectProps) {
  const {
    className,
    onChange,
    onFocus,
    onBlur,
    containerProps = {},
    label,
    labelProps = {},
    error,
    value: _value,
    isShowOccupancyDialog,
    occupancySuccessText,
    handleDoneClick,
    isPriceCalculator,
    onOpenDialog,
    ...rest
  } = props,
    { name, defaultValue = { adult_count: 2, child_count: 0 } } = rest,
    [focused, setFocused] = useState<boolean>(false),
    [mouseOver, setMouseOver] = useState<boolean>(false),
    containerRef = useRef<HTMLDivElement>(null),
    [value, setValue] = useState<OccupancyValue>(
      () => ({
        adult_count: ((_value && _value.adult_count > 2) ? _value.adult_count : defaultValue.adult_count),
        child_count: _value?.child_count ?? defaultValue.child_count,
      }),
    ),
    placeholder = useMemo(
      () => {
        const { adult_count, child_count } = value;

        const placeholder: string[] = [];

        if (adult_count) {
          placeholder.push(`${adult_count} ${adult_count > 1 ? "Adults" : "Adult"}`);
        }

        if (child_count) {
          placeholder.push(`${child_count} ${child_count > 1 ? "Children" : "Child"}`);
        }

        if (!placeholder.length) {
          placeholder.push("Select guests");
        }

        return placeholder.join(", ");
      },
      [value],
    ),
    { className: _lcx } = labelProps,
    ccx = useClx(clxs.container, className),
    lcx = useClx(clxs.label, "label", _lcx),
    icx = useClx(clxs.input, "input"),
    ecx = useClx(clxs.expandIcon, "expand"),
    handleValueChange = (value: OccupancyValue) => {

      const updatedValue = {
        ...value,
        adult_count: (value.adult_count > 2 ? value.adult_count : 2), // Ensure minimum 2 adults
      };

      setValue(updatedValue);

      if (!onChange) {
        return;
      }

      const target = { name: name, value: value },
        payload: any = { target: target, currentTarget: target };

      onChange(payload);
    },
    handleFocus = () => {
      setFocused(true);

      if (!onFocus) {
        return;
      }

      const target = { name: name, value: value, focused: true },
        payload: any = { target: target, currentTarget: target };

      onFocus(payload);
    },
    handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      setFocused(true);
      if (onOpenDialog) {
        onOpenDialog();
      }
    },
    handleDone = (e: MouseEvent<HTMLButtonElement>) => {
      handleDoneClick && handleDoneClick(e);

      e.stopPropagation();

      setFocused(false);

      return handleValueChange(value);
    },
    handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    },
    handleLabelClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      setFocused(true);

      if (onOpenDialog) {
        onOpenDialog();
      }
    },
    handleClickOutside = (e: globalThis.MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        setFocused(false);
        if (onBlur) {
          const target = { name: name, value: value, focused: true },
            payload: any = { target: target, currentTarget: target };
          onBlur(payload);
        }
      }
    },
    handleBlur = () => {
      if (!mouseOver) {
        setFocused(false);
        if (onBlur) {
          const target = { name: name, value: value, focused: true },
            payload: any = { target: target, currentTarget: target };
          onBlur(payload);
        }
      }
    };

  useEffect(() => {
    if (!_value) {
      return;
    }

    setValue(_value);
  }, [_value]);

  useEffect(() => {
    if (isShowOccupancyDialog) {
      setTimeout(() => {
        handleFocus();
        setFocused(true);
      }, 0);
    } else {
      setFocused(false);
    }
  }, [isShowOccupancyDialog]);

  useEffect(() => {
    if (focused) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [focused, handleClickOutside]);

  return (
    <div
      ref={containerRef}
      {...containerProps}
      data-focus={focused}
      data-price-calculator={isPriceCalculator}
      className={ccx}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleClick}
    >
      {label && (
        <label
          {...labelProps}
          htmlFor={name}
          className={lcx}
          onClick={handleLabelClick}
        >
          {label}
        </label>
      )}
      <div
        tabIndex={1}
        className={icx}
        data-error={Boolean(error).valueOf()}
        data-value={placeholder}
        suppressHydrationWarning={true}
      >
        {isPriceCalculator &&
          <img
            loading="lazy"
            src={GUEST_ICON}
            alt="Lohono"
            className={clxs.personIcon}
          />
        }
        <div className="">{placeholder}</div>
      </div>
      {error && <div className={clxs.error}>{error}</div>}
      <ExpandIcon className={ecx} />
      {focused && (
        <div
          className={clxs.optionsContainer}
          onMouseDown={handleMouseDown}
          onMouseEnter={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
        >
          <Occupancy
            {...rest}
            value={value}
            onChange={handleValueChange}
          />
          <div className={clxs.separator}></div>
          <button
            className={clxs.done}
            onClick={handleDone}
            type="button"
          >
            {occupancySuccessText ? occupancySuccessText : "Done"}
          </button>
        </div>
      )}
    </div>
  );
}

export default OccupancySelect;
