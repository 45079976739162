

import React from "react";
import clxs from "./code-applied-message.module.css";
import MSG_ICON from "../../../assets/icons/coupon-applied-msg.svg";
import useClx from "../../../hooks/use-clx";

interface CodeAppliedMessageProps {
  className?: string;
  selectedCoupon?: Coupon;
  onSubmit?: (out: any) => void;
}

function CodeAppliedMessage(props: CodeAppliedMessageProps) {
  const { selectedCoupon, className, onSubmit } = props,
    ccx = useClx(clxs.couponCard, className);
  return (
    <div className={ccx}>
      <div className={clxs.couponHeader}>
        <img
          src={MSG_ICON}
          alt="close"
          className={clxs.msgImg}
        />
        <div className={clxs.validity}>{selectedCoupon?.description}</div>
        <div className={clxs.couponCode}>{selectedCoupon?.code || selectedCoupon?.name} Code Applied</div>
      </div>
      <a  
        onClick={onSubmit?.bind(null, null)}
        className={clxs.gotIt}
      >Got it</a>
    </div>
  );
}

export default CodeAppliedMessage;

type Coupon = {
  name: string;
  code: string;
  description: string;
  terms: string[];
};
