import React, { ForwardedRef, forwardRef, useMemo } from "react";
import clxs from "./meal-plan-options.module.css";
// import ILLUSTRATION from "../../../../assets/meal-illustration.svg";
import MealOptions from "../MealOptions";
import MealPlanMenu from "./MealPlanMenu";
import VEG_ICON from "../../../../assets/icons/veg-icon.svg";
import NON_VEG_ICON from "../../../../assets/icons/non-veg-icon.svg";
import PreBookMeals from "./PreBookMeals";
import useIsMobile from "../../../../hooks/use-is-mobile";
// import MOBILE_ILLUSTRATION from "../../../../assets/icons/meal-illustration-mobile.png"
// import Img from "../../../Img";

interface MealPlanOptionsProps {
  mealDescription?: string;
  mealOptions: MealOptions;
  id: string;
  mealOptionList: MealOptionList;
}

const queryKey = "pre_book_meals";

const MealPlanOptions = forwardRef(
  function MealPlanOptionsCore(
    props: MealPlanOptionsProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const { mealDescription, mealOptions, id, mealOptionList } = props,
      { isMobile } = useIsMobile();

    const isVegNull = mealOptionList.veg.length == 0;
    const isNonVegNull = mealOptionList.non_veg.length == 0;
    const isVegNonVegNull = mealOptionList.veg_non_veg.length == 0;
    const isMenuClass = useMemo(() => {
      if (!isNonVegNull && !isVegNull) {
        return clxs.menuContainer;
      }
      return clxs.vegMenuContainer;
    }, []);

    return (
      <>
        <div
          className={clxs.container}
          ref={ref}
        >
          {/* <Img 
          src={ILLUSTRATION}
          className={clxs.illustration} 
          desktopSrc={ILLUSTRATION} 
          mobileSrc={MOBILE_ILLUSTRATION}
        /> */}
          <div className={clxs.title}>Pre-book your perfect meal plan!</div>
          <div className={clxs.description}>
            {isMobile ? (
              // <p>
              //   Discover exquisite dining at Lohono! Savor authentic delicacies
              //   and elevate your culinary journey with our meal plans.
              // </p>
              <p>
                Check out our meal plans and elevate your culinary journey!
              </p>
            ) : (
              <p>
                Indulge in an exquisite dining experience at Lohono, featuring our
                authentic delicacies. Check out our meal plans and elevate your
                culinary journey!
              </p>
            )}
            {mealDescription}
          </div>

          {isVegNonVegNull ?
            <div className={isMenuClass}>
              {/* veg menu list in meal plan   */}
              {isVegNull ? null :
                <div>
                  <MealPlanMenu
                    className={clxs.vegMenu}
                    menu={mealOptionList.veg}
                    menuType="Veg"
                    icon={VEG_ICON}
                    id={id}
                  />
                </div>}
              {/* if 0 non veg dish added then show null */}
              {isNonVegNull ? null :
                <div>
                  <MealPlanMenu
                    className={clxs.vegNonMenu}
                    menu={mealOptionList.non_veg}
                    menuType="Non-Veg"
                    icon={NON_VEG_ICON}
                    id={id}
                  />
                </div>
              }
            </div>
            :
            <div className={clxs.vegNonVegMenuContainer}>
              {/* Veg and NonVeg menu list in meal plan  */}
              {isVegNonVegNull ? null :
                <div>
                  <MealPlanMenu
                    className={clxs.vegNonVegMenu}
                    menu={mealOptionList.veg_non_veg}
                    menuType="vegNonVeg"
                    id={id}
                  />
                </div>}
            </div>
          }
          {/* <div className={clxs.gst}>*All the prices are excluding GST</div> */}
          <MealOptions
            mealOptions={mealOptions}
            theme="mealPlan"
          />
          {/* {isMobile ? null : */}
          <PreBookMeals
            queryKey={queryKey}
            className={clxs.preBookMeals}
          />
          {/* } */}
        </div>
      </>
    );
  },
)

export default MealPlanOptions;