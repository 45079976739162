import React, { useMemo } from "react";
import useClx from "../../../hooks/use-clx";
import { useUser } from "../../../hooks/use-session";
import clxs from "./loyalty-point-ticker.module.css";
// import LLP_STAR from "../../../assets/icons/loyalty-point-star-dark.svg";
import INFINITY_LOGO from "../../../assets/refer-n-earn/refer-logo.svg";
import SILVER_LOGO from "../../../assets/icons/silver.svg";
import GOLD_LOGO from "../../../assets/icons/gold.svg";
import PLATINUM_LOGO from "../../../assets/icons/platinum.svg";

interface LoyaltyPointTickerProps {
  className?: string;
  method?: string;
  showForMethodCoupon?: boolean;
  visible?: boolean;
}

function LoyaltyPointTicker (props: LoyaltyPointTickerProps) {
  const user = useUser();

  const points = useMemo(
    () => user?.loyalty_point_metadata.points_balance ?? 0,
    [user],
  );

  const content = useMemo(
    () => (
      <>
        Avail your{" "}
        <span style={{ fontWeight: 600 }}>{points.toLocaleString("en-IN")}</span> points on checkout
      </>
    ),
    [points],
  );

  const { className, method = "", showForMethodCoupon = false, visible = true } = props;

  const ccx = useClx(clxs.container, className);

  const imageSrc = useMemo(() => {
    switch (method) {
      case "silver":
        return SILVER_LOGO;
      case "platinum":
        return PLATINUM_LOGO;
      case "gold":
        return GOLD_LOGO;
      default:
        return INFINITY_LOGO;
    }
  }, [method]);

  const isHidden = useMemo(
    () => {
      return (
        !visible
        || !points
        || (!showForMethodCoupon && method === "coupon")
      );
    },
    [visible, method, points],
  );

  if (isHidden) {
    return null;
  }

  return (
    <div
      data-discount-type={method}
      className={ccx}
    >
      <img
        src={imageSrc}
        alt="loyalty points"
      />
      {content}
    </div>
  );
}

export default LoyaltyPointTicker;
