import { useMemo } from "react";
import usePropertyLocations from "../../../hooks/use-property-locations";

function usePropertyLocationAnchorTemplate() {
  const { propertyLocations } = usePropertyLocations("",true);

  const template = useMemo(
    () => {
      const domesticLocations = propertyLocations.filter(
        each => each.slug.includes("india"),
      );

      const internationalLocations = propertyLocations.filter(
        each => !each.slug.includes("india"),
      );

      return [
        {
          title: "Domestic Properties",
          locations: domesticLocations,
        },
        {
          title: "International Properties",
          locations: internationalLocations,
        },
      ];
    },
    [propertyLocations],
  );

  return template;
}

export default usePropertyLocationAnchorTemplate;