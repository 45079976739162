import clxs from "./search-bar.module.css";
import SEARCH_WHITE_ICON from "../../../assets/icons/whiteSearchIcon.svg";
import Modal, { useShowModal } from "../../../hooks/use-modal";
// import { getPropertySearchUrl } from "../../../../utils/property-search";
// import urlSearchParamsFromAny from "../../../../utils/url-search-params-from-any";
import { useNavigate } from "react-router-dom";
import { useQueryParamValue } from "../../../hooks/use-query-param";
import usePathParams from "../../../hooks/use-path-params";
// import usePropertyLocations from "../../../hooks/use-property-locations";
import React, { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import useClx from "../../../hooks/use-clx";
import useIsMobile from "../../../hooks/use-is-mobile";
import SearchBarPopUp from "../SearchBarPopUp/SearchBarPopUp";
import calendarIcon from "../../../assets/icons/calendar_month_selected.svg";
import locationIcon from "../../../assets/icons/location_on_selected.svg";
import personIcon from "../../../assets/icons/person_selected.svg";
import calendarIconNotSelected from "../../../assets/icons/calendar_month_not_selected.svg";
import locationIconNotSelected from "../../../assets/icons/location_on_not_selected.svg";
import personIconNotSelected from "../../../assets/icons/person_not_selected.svg";
import useLocationAndVillaListing from "../../../hooks/use-location-and-villa-listing";

interface SearchbarProps {
  className?: string;
  path: string;
}

function Searchbar(props: SearchbarProps) {
  const { className, path} = props,
    ccx = useClx(clxs.container, className),
    push = useNavigate(),
    userSearch = useQueryParamValue("string", "search"),
    showPropertySearchModal = useShowModal(SEARCH_MODAL_ID),
    [selectedBox, setSelectedBox] = useState<string>(""),
    [searchText, setSearchText] = useState<string>(() => userSearch),
    // { propertyLocations } = usePropertyLocations(),
    {isMobile} = useIsMobile(),
    // locationOptions = useMemo(
    //   () =>
    //     propertyLocations.reduce((compiled, each) => {
    //       const { name, slug, coming_soon } = each;
    //       if (coming_soon) {
    //         return compiled;
    //       }

    //       const option = {
    //         label: name,
    //         value: slug,
    //       };

    //       return [...compiled, option];
    //     }, [] as { label: string; value: string }[]),
    //   [propertyLocations],
    // ),
    { propertyLocationsAndVilla: locationAndVilla } = useLocationAndVillaListing(searchText),

    locationOptions = useMemo(
      () => locationAndVilla.reduce(
        (compiled, each) => {
          const { name, slug, type, address, location_value } = each;
          // if (coming_soon) {
          //   return compiled;
          // }

          const option = {
            label: name,
            value: slug,
            type: type,
            address: address,
            location_value: location_value,
          };

          return [
            ...compiled,
            option,
          ];
        },
        [] as { label: string; value: string, type: string, address: string, location_value: string }[],
      ),
      [locationAndVilla],
    ),
    destination = usePathParams(path, "destination"),
    location = usePathParams(path, "location"),
    property = usePathParams(path, "property"),
    location_slug = useMemo(
      () => `${destination}-${location}`,
      [destination, location],
    ),
    // { isEH } = useIsEH(location_slug),
    checkin_date = useQueryParamValue("date", "checkin_date"),
    checkout_date = useQueryParamValue("date", "checkout_date"),
    adult_count = useQueryParamValue("integer", "adult_count"),
    child_count = useQueryParamValue("integer", "child_count"),
    destinationFriendly = useMemo(() => {
      const found = locationOptions.find(each => {
        if (property) {
          return each.value === property;
        } else {
          return each.value === location_slug;
        }
        
      });
      return found?.label ?? "";
    }, [locationOptions, location_slug]),
    stayFriendly = useMemo(() => {
      if (!checkin_date || !checkout_date) return "";
    
      const checkinDay = format(checkin_date, "d");
      const checkoutDay = format(checkout_date, "d");
      const checkinMonth = format(checkin_date, "LLL");
      const checkoutMonth = format(checkout_date, "LLL");
      const checkinYear = format(checkin_date, "yy");
      const checkoutYear = format(checkout_date, "yy");
    
      // Case 1: Same month and year → "3-7 Jun"
      if (checkinMonth === checkoutMonth && checkinYear === checkoutYear) {
        return `${checkinDay}-${checkoutDay} ${checkinMonth}`;
      }
    
      // Case 2: Different month but same year → "30 Jun - 7 Jul"
      if (checkinYear === checkoutYear) {
        return `${checkinDay} ${checkinMonth} - ${checkoutDay} ${checkoutMonth}`;
      }
    
      // Case 3: Different years → "27 Dec, 25 - 1 Jan, 26"
      return `${checkinDay} ${checkinMonth}, ${checkinYear} - ${checkoutDay} ${checkoutMonth}, ${checkoutYear}`;
    }, [checkin_date, checkout_date]),
    guestsFriendly = useMemo(() => {
      let totalGuests = 0;

      if (adult_count) {
        totalGuests += adult_count;
      }

      if (child_count) {
        totalGuests += child_count;
      }

      if (!totalGuests) {
        return "";
      }

      return `${totalGuests} guests`;
    }, [adult_count, child_count]),
    defaultValue = useMemo(() => {
      return {
        destination: location_slug,
        stay: {
          start: checkin_date,
          end: checkout_date,
        },
        occupancy: {
          adult_count: adult_count,
          child_count: child_count,
        },
        price_package: "",
      };
    }, [location_slug, checkin_date, checkout_date, adult_count, child_count]),
    handleOpenPopup = async (selectedValue:string) => {
      setSelectedBox(selectedValue)
      const values: any = await showPropertySearchModal({
        defaultValue: defaultValue,
      });

      if (!values) {
        setSelectedBox("")
        return;
      }

      // const {
      //     destination,
      //     stay = { start: null, end: null },
      //     occupancy = { adult_count: 0, child_count: 0 },
      //     price_package = "",
      //   } = values,
      //   path = getPropertySearchUrl(destination),
      //   { start: checkin_date, end: checkout_date } = stay,
      //   { adult_count, child_count } = occupancy,
      //   search = urlSearchParamsFromAny({
      //     checkin_date: checkin_date,
      //     checkout_date: checkout_date,
      //     adult_count: adult_count,
      //     child_count: child_count,
      //     price_package: price_package,
      //   }).toString();

      return push(values);
    };

    useEffect(() => {
      setSearchText(userSearch);
    },[]);

  return (
   <>
    <div className={ccx}>
      <div 
        className={clxs.propertySearchHeader} 
      >
        <div
          className={`${clxs.friendly} ${clxs.destination}`}
          data-populated={Boolean(destinationFriendly).valueOf()}
          onClick={() => handleOpenPopup("destination")}
        >
          <img
            src={Boolean(stayFriendly).valueOf() ? locationIcon : locationIconNotSelected}
            alt="Lohono"
            className={clxs.icons}
          />
         <div className=""> {destinationFriendly},{destination}</div>
        </div>
        <div
          className={`${clxs.friendly} ${clxs.Dates}`}
          data-populated={Boolean(stayFriendly).valueOf()}
          onClick={() => handleOpenPopup("dateRange")}
        >
          <img
            src={Boolean(stayFriendly).valueOf() ?calendarIcon : calendarIconNotSelected}
            alt="Lohono"
            className={clxs.icons}
          />
         <div className="">{stayFriendly || "Select Dates"}</div>
        </div>

        <div
          className={`${clxs.friendly} ${clxs.guests}`}
          data-populated={Boolean(guestsFriendly).valueOf()}
          onClick={() => handleOpenPopup("adultCount")}
        >
          {isMobile && <span className={clxs.dot}>&#x2022;</span>}
          <img
            src={Boolean(guestsFriendly).valueOf() ?personIcon : personIconNotSelected}
            alt="Lohono"
            className={clxs.icons}
          />
          {guestsFriendly || "Add guests"}
        </div>
       {!isMobile && 
        <img
          src={SEARCH_WHITE_ICON}
          alt="Lohono"
          className={clxs.searchIcon}
          onClick={() => handleOpenPopup("")}
        />
       }
      </div>
    </div>
   <Modal
     id={SEARCH_MODAL_ID}
     scrollBlock={false}
   >
    <SearchBarPopUp
      path={path}
      selectedValue={selectedBox}
    />
   </Modal>
   </>
  );
}

export default Searchbar;

const SEARCH_MODAL_ID = "search-bar-modal";


