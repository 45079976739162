import React from "react";
import clxs from "./search-bar-pop-up.module.css";
import PropertySearch from "../PropertySearch";
import SearchHeader from "../SerachHeader";

interface SearchBarPopUpProps {
    path:string;
    selectedValue: string;
    onSubmit?: (values: any) => void;
  }
function SearchBarPopUp(props : SearchBarPopUpProps) {
    const {path, onSubmit,selectedValue} = props;
    return ( 
    <div className={clxs.container}>
        <SearchHeader
          path={path}
          isPopUp ={true}
        />
     
        <PropertySearch
          path={path}
          className={clxs.search}
          filterDesktopClassName={clxs.searchDesktop}
          onSearchClose= {onSubmit}
          selectedValue= {selectedValue}
        />
    </div> );
}

export default SearchBarPopUp;