import { useSnackbar } from "../use-snackbar";
import useReservationService from "../use-reservation-service";
import useAnalytics from "../use-analytics";
import { useSession } from "../use-session";
import { useAtomState } from "../use-atom";
import { useCallback, useEffect, useState } from "react";

function useBookingPriceCalculator(
  id: string,  
  booking_slugs: string[],
  coupon_code: string,
  avail_loyalty_points?: boolean,
  display_protect_widget?: boolean,
  is_refundable_opted?: boolean,
  protect_quote_id?: string,
  tier?: string,
) {
  const enqueueSnackbar = useSnackbar(),
    { service } = useReservationService(),
    { track } = useAnalytics(),
    session = useSession(),
    [isLoading, setIsLoading] = useState<boolean>(false),
    [isInvalidResponse, setIsInvalidResponse] = useState<boolean>(false),
    [tentativePrice, setTentativePrice] = useAtomState(id, DEFAULT_TENTATIVE_PRICE),
    handleGetTentativePrice = useCallback(
      (
        booking_slugs: string[],
        coupon_code: string,
        avail_loyalty_points?: boolean,
        display_protect_widget?: boolean,
        is_refundable_opted?: boolean,
        protect_quote_id?: string,
        tier?: string,
      ) => {
        let cancelled = false;

        (async () => {
          const trackingPayload: any = {
            coupon_code: coupon_code,
            avail_loyalty_points: avail_loyalty_points,
            booking_slugs: booking_slugs,
            display_protect_widget: display_protect_widget,
            is_refundable_opted: is_refundable_opted,
            protect_quote_id: protect_quote_id,
            tier: tier,
          };

        // Send tier level in coupon code if no coupon code is used.
        // const tierLevel = tier ? tier : "";
        // if((tierLevel.length && tierLevel != "no_tier") && (!coupon_code || coupon_code === "NONE")) {
        //   trackingPayload.coupon_code = tierLevel.toUpperCase();
        //   coupon_code = tierLevel.toUpperCase();
        // }

        //No coupon code is eligible for that villa. Send empty coupon_code
        if(coupon_code && coupon_code === "NO_AVAIL") {
          trackingPayload.coupon_code = "";
          coupon_code = "";
        }

          setIsLoading(true);
          const { error, response } = await service.addCouponLoyaltyPoints(
            {
              coupon_code: coupon_code,
              points_applied: avail_loyalty_points ? true : false,
              booking_slugs: booking_slugs,
              display_protect_widget: display_protect_widget ? false : false,
              isProtectOpted: is_refundable_opted ? false : false,
              protect_quote_id: protect_quote_id,
            },
            session,
          );
          setIsLoading(false);

          if (cancelled) {
            return;
          }

          if (error) {
            enqueueSnackbar(error.message, "error");
            setIsInvalidResponse(true);

            track(
              "rate_calculator_config_change",
              {
                ...trackingPayload,
                error: error.message,
              },
            );
            // setTentativePrice(DEFAULT_TENTATIVE_PRICE);

            return;
          }

          if (!response) {
            // setTentativePrice(DEFAULT_TENTATIVE_PRICE);

            track(
              "rate_calculator_config_change",
              {
                ...trackingPayload,
                error: "unknown error",
              },
            );

            return;
          }

          track(
            "rate_calculator_config_change",
            {
              ...trackingPayload,
              ...response,
            },
          );
          setIsInvalidResponse(false);
          setTentativePrice(response);
        })();

        return () => {
          cancelled = true;
        };
      },
      [enqueueSnackbar, setTentativePrice, session],
    );

  useEffect(
    () => {
      return handleGetTentativePrice(
        booking_slugs,
        coupon_code,
        avail_loyalty_points,
        display_protect_widget,
        is_refundable_opted,
        protect_quote_id,
        tier,
      );
    },
    [
      coupon_code,
      avail_loyalty_points,
      is_refundable_opted,
      tier,
    ],
  );

  return {
    tentativePrice: tentativePrice,
    isLoading: isLoading,
    isInvalidResponse: isInvalidResponse,
  };
}

export default useBookingPriceCalculator;

const DEFAULT_TENTATIVE_PRICE: TentativePrice = {
  breakdown: {
    average_base_rate:0,
    average_non_discounted_rate:0,
    discount_value:0,
    gross_base_rate: 0,
    gross_base_rate_without_extras: 0,
    taxes: 0,
    convenience_fee: 0,
    deposit: 0,
    extra_adults: 0,
    extra_adults_fare: 0,
    rental_charges: 0,
    discount: 0,
    coupon_or_tier_discount:0,
    discount_message: "",
    total: 0,
    discount_method: "",
    discount_type: "",
    coupon_code: "",
    loyalty_points_discount_percentage: 0,
    loyalty_points_discount_percentage_value: 0,
    loyalty_points_redeemed_value: 0,
    loyalty_points_earning: 0,
    premium: 0,
  },
  widget_details: {
    protectHtml: "",
    protectQuoteId: "",
  },
  coupon_discount:0,
  tax_percentage: 0,
  total_taxes:0,
  average_base_rate:0,
  coupon_or_tier_discount:0,
  average_non_discounted_rate:0,
  discount_value:0,
  discount:0,
  tier_discount_percentage: 0,
  tier_discount: 0,
  currency_code: "",
  currency_symbol: "",
  discounted_total: 0,
  property_configs: [],
  sold_out: false,
  status: "",
  total: 0,
};
