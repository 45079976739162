import React from "react";
import useClx from "../../../hooks/use-clx";
import clxs from "./villa-tag.module.css";
import highDemandIcon from "../../../assets/icons/high-demand.svg";

interface VillaTagProps {
    className?: string;
    tag?: string;
    icon?:string;
}
function VillaTag (props: VillaTagProps) {
    const {className, tag ,icon} = props;
    const ccx = useClx(clxs.villaTagClassName, className);

    return (
        <div className={ccx}>
             <div className={clxs.tagIconContainer}>
            <img 
              src={icon ? icon :highDemandIcon} 
              alt="icon" 
              className={clxs.tagIcon} 
            />
            </div>
            <span>{tag}</span>
        </div>
    );
}

export default VillaTag;