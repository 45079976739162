import { useMemo } from "react";
import { useAtomState } from "./use-atom";
import { LOCATION_AND_VILLA_LISTING_KEY } from "../../core/constants";

function useLocationAndVillaListing(query: string) {
  const [propertyLocationsAndVilla] = useAtomState<PropertyLocationAndVilla[]>(LOCATION_AND_VILLA_LISTING_KEY, []);

  // Filter the initialized data based on query
  const filteredLocations = useMemo(() => {
    if (!query) return propertyLocationsAndVilla;
    
    const searchQuery = query.toLowerCase();
    return propertyLocationsAndVilla.filter(location => 
      location.name.toLowerCase().includes(searchQuery) ||
      location.address.toLowerCase().includes(searchQuery),
    );
  }, [propertyLocationsAndVilla, query]);

  return { propertyLocationsAndVilla: filteredLocations };
}

export default useLocationAndVillaListing;
