import React, { useMemo, MouseEvent, useState } from "react";
import useClx from "../../../../hooks/use-clx";
import clxs from "./sort.module.css";
import SORT_ICON from "../../../../assets/icons/sort-grey-icon.svg";
import CHEVRON_DOWN_LIGHT from "../../../../assets/icons/expand-more-black-Icon.svg";

interface SortProps {
  className?: string,
  value: string;
  onChange: (nextDirection: string) => void;
}

function Sort (props: SortProps) {
  const { value, onChange, className } = props;
  const [visible, setVisible] = useState<boolean>(false),
  bcx = useClx(clxs.button, className),
  handleToggle = () => setVisible(visible => !visible),
  handleValueChange = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const target = e.target as HTMLElement;

    const { value } = target.dataset;

    if (!value) {
      return;
    }

    setVisible(false);

    onChange(value);
  },
    text = useMemo(
      () => {
        if (value === "desc") {
          return "Price high to low";
        }

        if (value === "asc") {
          return "Price low to high";
        }

        return "Popularity";
      },
      [value],
    );

  return (
    <button
      className={bcx}
      color="inherit"
      onClick={handleToggle}
      title="Sort by"
    >
    <div className={clxs.sortByContainer}>
      <img
        src={SORT_ICON}
        alt="sort by"
        className={clxs.sortIcon}
      /> 
      <div className={clxs.sortByText}><span style={{fontWeight:400}}> Sort By : </span>{text}</div>
       <img
         src={CHEVRON_DOWN_LIGHT}
         alt={text}
         className={clxs.sortIcon}
       />
    </div> 

      <div
        className={clxs.menu}
        data-visible={visible}
        onClick={handleValueChange}
      >
        <div
          className={clxs.item}
          data-value="desc"
          data-selected={value === "desc"}
        >
          Price high to low
        </div>
        <div
          className={clxs.item}
          data-value="asc"
          data-selected={value === "asc"}
        >
          Price low to high
        </div>
        <div
          className={clxs.item}
          data-value="popularity"
          data-selected={value === "popularity"}
        >
          Popularity
        </div>
      </div>
    </button>
  );
}

export default Sort;
