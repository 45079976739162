import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAnalytics from "../../hooks/use-analytics";
// import useAccountIcon from "../../hooks/use-account-icon";
import useClx from "../../hooks/use-clx";
import useIsMobile from "../../hooks/use-is-mobile";
import { useLoginModal } from "../../hooks/use-login-modal";
import { useLogout } from "../../hooks/use-session";
import { useSnackbar } from "../../hooks/use-snackbar";
import useUserWithInitials from "../../hooks/use-user-with-initials";
import { Track } from "../AnalyticsManager";
import ContextMenu from "../ContextMenu";
import Anchor from "../Header/Anchor";
import PointTag from "../PointTag";
import clxs from "./login.module.css";
import ACCOUNT_PRIMARY_ICON from "../../assets/icons/person-primary.svg";
import { useQueryParamValue } from "../../hooks/use-query-param";
import getSessionFromContext from "../../../server/utils/get-session-from-context";
import SILVER_TIER_PERSON from "../../assets/icons/silver-tier-person.svg";
import GOLD_TIER_PERSON from "../../assets/icons/gold-tier-person.svg";
import PLATINUM_TIER_PERSON from "../../assets/icons/platinum-tier-person.svg";
import NO_TIER_PERSON from "../../assets/icons/no-tier-person.svg";
import RESPONSIVE_SILVER_TIER_PERSON from "../../assets/icons/responsive-silver-tier-person.svg";
import RESPONSIVE_GOLD_TIER_PERSON from "../../assets/icons/responsive-gold-tier-person.svg";
import RESPONSIVE_PLATINUM_TIER_PERSON from "../../assets/icons/responsive-platinum-tier-person.svg";
import RESPONSIVE_NO_TIER_PERSON from "../../assets/icons/responsive-no-tier-person.svg";
import CHEVRON_RIGHT_ICON from "../../assets/icons/chevron-right-grey.svg";
import LOGIN_ICON from "../../assets/icons/filled-person.svg";
interface LoginProps {
  className?: string;
  isHeaderTransparent?: boolean;
  isDrawerMenu?: boolean;
  handleCloseDrawer?: (isMobile: boolean) => void;
}

function Login(props: LoginProps) {
  const { className, isDrawerMenu, handleCloseDrawer } = props;

  const { track } = useAnalytics();

  const enqueueSnackbar = useSnackbar();

  // const ACCOUNT_ICON = useAccountIcon();

  const showLoginFlowModal = useLoginModal();

  const { isMobile } = useIsMobile();

  const handleLogin = useCallback(() => {
      if (isDrawerMenu && handleCloseDrawer) {
        handleCloseDrawer(isMobile);
      }
      showLoginFlowModal({});
    },
    [showLoginFlowModal, isDrawerMenu, handleCloseDrawer, isMobile],
  );

  const session = getSessionFromContext();

  const referralCode = useQueryParamValue("string", REFERRAL_CODE_QUERY_KEY);

  const logout = useLogout();

  const handleLogout = useCallback(
    async () => {
      if (!confirm("Are you sure?")) {
        return;
      }

      const err = await logout();

      if (err) {
        track("logout_unsuccessful", { error: err.message });

        enqueueSnackbar(err.message, "error");

        return;
      }

      track("logout_successful");

      push({ pathname: "/" }, { replace: true });
    },
    [logout],
  );

  const push = useNavigate();

  const { user, initials, firstName, tier } = useUserWithInitials();

  const handleAvatarClicked = useCallback(
    () => {
      if (!user) {
        return;
      }

      push(isMobile ? "/profile" : "/profile/edit");

      if (isMobile && handleCloseDrawer) {
        handleCloseDrawer(isMobile);
      }
    },
    [push, user, isMobile],
  );

  const { pathname } = useLocation();


  const setSessionStorage = () => {
    sessionStorage.setItem(LOGIN_MANDATORY_KEY, "1");
  };

  /**
   * This method shows login popup after 5 seconds only if the user is not logged in, 
   * is on home page and once per session.
   */
  const showLogin20Sec = () => {
    const isHomePage = pathname == "/";
    const isModalAlreadyShown = sessionStorage.getItem(LOGIN_MANDATORY_KEY) == "1";
    const sessionId = localStorage.getItem(SESSION_KEY);
    if (!user && !sessionId?.length && isHomePage && !isModalAlreadyShown) {
      setTimeout(() => {
        const currentTarget = window.location.pathname,
          //checking if path got changed
          samePath = currentTarget === pathname;

        if (samePath) {
          setSessionStorage();
          track("login_desktop_clicked");
          showLoginFlowModal({});
        } else {
          return
        }

      }, 20e3)
    }
  };

  useEffect(() => {
    if (referralCode && referralCode.length) {
      showLoginFlowModal({});
    } else {
      showLogin20Sec();
    }
  }, [referralCode, session]);

  const tierClasses: Record<string, string> = {
    silver: clxs.silverContainer,
    gold: clxs.goldContainer,
    platinum: clxs.platinumContainer,
    no_tier: clxs.noTierContainer,
  };
  
  const tierTextClasses: Record<string, string> = {
    silver: clxs.silverUserText,
    gold: clxs.goldUserText,
    platinum: clxs.platinumUserText,
  };
  
  const tierClass = useClx(className, "headerLogin", tier ? tierClasses[tier] || clxs.container : clxs.container);
  const tierTextClass = useClx(clxs.userStatus, tier ? tierTextClasses[tier] || "" : "");
  
  const tierIcon = useMemo(() => {
    const tierIcons: Record<string, string> = {
      silver: SILVER_TIER_PERSON,
      gold: GOLD_TIER_PERSON,
      platinum: PLATINUM_TIER_PERSON,
      no_tier: NO_TIER_PERSON,
    };
  
    return tier ? tierIcons[tier] || NO_TIER_PERSON : NO_TIER_PERSON;
  }, [tier]);
  
  const responsiveTierIcon = useMemo(() => {
    const tierIcons: Record<string, string> = {
      silver: RESPONSIVE_SILVER_TIER_PERSON,
      gold: RESPONSIVE_GOLD_TIER_PERSON,
      platinum: RESPONSIVE_PLATINUM_TIER_PERSON,
      no_tier: RESPONSIVE_NO_TIER_PERSON,
    };
  
    return tier ? tierIcons[tier] || RESPONSIVE_NO_TIER_PERSON : RESPONSIVE_NO_TIER_PERSON;
  }, [tier]);
  
  
  return (
    <>
      {
        isDrawerMenu ?
          <div className={clxs.mobileContainer}>
            {user ? (
              <>
                {initials ? (
                  <Track event="header_profile_clicked">
                    <img
                      src={responsiveTierIcon}
                      alt={`responsive-${tier}-tier-person`}
                      className={clxs.userAvatarImage}
                      onClick={handleAvatarClicked}
                    />
                  </Track>
                ) : (
                  <Track event="header_login_clicked">
                    <img
                      src={ACCOUNT_PRIMARY_ICON}
                      alt="profile"
                      className={clxs.userAvatarImage}
                      onClick={handleAvatarClicked}
                    />
                  </Track>
                )}

                <div
                  className={clxs.userInfo}
                  onClick={handleAvatarClicked}
                >
                  <span className={clxs.userName}>{firstName}</span>
                  {tier === "no_tier" ? null :
                    <div className={tierTextClass}>{`${tier} MEMBER`}</div>
                  }
                </div>
                <img
                  src={CHEVRON_RIGHT_ICON}
                  alt="profile"
                  className={clxs.rightForward}
                  onClick={handleAvatarClicked}
                />
              </>
            ) : (
              <>
                <Track event="login_mobile_clicked">
                  <button
                    className={clxs.loginButton}
                    type="button"
                    onClick={handleLogin}
                  >
                    {/* {isHeaderTransparent ? */}
                    <img
                      src={LOGIN_ICON}
                      alt="Lohono"
                      className={clxs.loginIcon}
                    />
                    {/* : null} */}
                    Login / Sign Up

                    <img
                      src={CHEVRON_RIGHT_ICON}
                      alt="profile"
                      className={clxs.rightForward}
                      onClick={handleAvatarClicked}
                    />
                  </button>
                </Track>
              </>
            )}
          </div>
          :
          <div className={tierClass}>
            {user ? (
              <>
                {initials ? (
                  <Track event="header_profile_clicked">
                    <img
                      src={tierIcon}
                      alt={`${tier}-tier-person`}
                      className={clxs.userAvatarImage}
                      onClick={handleAvatarClicked}
                    />
                  </Track>
                ) : (
                  <Track event="header_login_clicked">
                    <img
                      src={ACCOUNT_PRIMARY_ICON}
                      alt="profile"
                      className={clxs.userAvatarImage}
                      onClick={handleAvatarClicked}
                    />
                  </Track>
                )}

                <ContextMenu
                  title="Profile"
                  // className={isHeaderTransparent ? `${clxs.userName} ${clxs.isWhiteName}` : clxs.userName}
                  className={clxs.userName}
                  menuClassName={clxs.userMenu}
                  // isWhiteIcon={isHeaderTransparent}
                  menu={(
                    <>
                      <Track
                        event="header_profile_my_trips_clicked"
                        payload={{
                          type: "page",
                          title: "My Trips",
                          header: "My Trips",
                          href: "/profile/trips",
                        }}
                      >
                        <Anchor
                          title="My Trips"
                          href="/profile/trips"
                          className={clxs.userOption}
                        />
                      </Track>
                      <Track
                        event="header_profile_edit_clicked"
                        payload={{
                          type: "page",
                          title: "My Profile",
                          header: "My Profile",
                          href: "/profile/edit",
                        }}
                      >
                        <Anchor
                          title="My Profile"
                          href="/profile/edit"
                          className={clxs.userOption}
                        />
                      </Track>
                      <Track
                        event="header_profile_loyalty_points_clicked"
                        payload={{
                          type: "page",
                          title: "Rewards",
                          header: "Rewards",
                          href: "/loyalty-points",
                        }}
                      >
                        <Anchor
                          title="Rewards"
                          href="/loyalty-points"
                          className={clxs.rewards}
                        >
                          <PointTag />
                        </Anchor>
                      </Track>
                      <Track
                        event="header_logout_clicked"
                        payload={{
                          type: "action",
                          title: "Logout",
                          header: "Logout",
                          href: "/profile/edit",
                        }}
                      >
                        <div
                          title="My Profile"
                          className={clxs.userOption}
                          onClick={handleLogout}
                        >
                          Logout
                        </div>
                      </Track>
                    </>
                  )}
                  isUserLogin={true}
                >
                  <div className={clxs.userInfo}>
                    {firstName}
                    {tier === "no_tier" ? null :
                      <div className={tierTextClass}>{`${tier} MEMBER`}</div>
                    }
                  </div>
                </ContextMenu>
              </>
            ) : (
              <Track event="login_desktop_clicked">
                <button
                  className={clxs.loginButton}
                  type="button"
                  onClick={handleLogin}
                >
                  {/* {isHeaderTransparent ? */}
                  <img
                    src={LOGIN_ICON}
                    alt="Lohono"
                    className={clxs.loginIcon}
                  />
                  {/* : null} */}
                  Login / Sign Up
                </button>
              </Track>
            )}
          </div>
      }
    </>
  );
}

export default Login;

const REFERRAL_CODE_QUERY_KEY = "referral_code";

const LOGIN_MANDATORY_KEY = "login_mandatory_shown";
const SESSION_KEY = "SESSION";