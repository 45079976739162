import Fetch from "../../utils/fetch";
import cached from "../../utils/cached";

class PropertyService implements IPropertyService {
  private readonly fetch: Fetch<ApiResult>;

  constructor(fetch: Fetch<ApiResult>) {
    this.fetch = fetch;
  }

  // @cached()
  getPropertyLocations(collection_slug?: string, latitude?: number, longitude?: number): Promise<FetchResult<PropertyLocation[]>> {
    const url = new URL("/api/property/locations", window.location.origin);

    if (collection_slug) {
      url.searchParams.set("collection_slug", collection_slug);
    }

    if (latitude && longitude) {
      url.searchParams.set("lat", latitude.toString());
      url.searchParams.set("long", longitude.toString());
    }
    console.log("Request URL:", url.toString());
    return this.fetch.GET(url.toString());
  }

  getBanner(
    location_slug: string,
  ): Promise<FetchResult<BannerResult>> {
    const url = new URL("/api/property/banners", window.location.origin);

    if (location_slug) {
      url.searchParams.set("location_slug", location_slug);
    }

    return this.fetch.GET(
      url.toString(),
    );
  }

  getOffersList(
    location_slug: string,
  ): Promise<FetchResult<OffersResult>> {
    const url = new URL("/api/property/offers", window.location.origin);

    if (location_slug) {
      url.searchParams.set("location_slug", location_slug);
    }
    
    return this.fetch.GET(
      url.toString(),
    );
  }

  getLocationAndVilla(
    query: string,
  ): Promise<FetchResult<PropertyLocationAndVilla[]>> {
    const url = new URL("/api/property/search_villas", window.location.origin);

    if (query) {
      url.searchParams.set("query", query);
    }

    return this.fetch.GET(
      url.toString(),
    );
  }

  getProperties(
    location_slug: string,
    mood?: string,
    checkin_date?: string,
    checkout_date?: string,
    adult_count?: number,
    child_count?: number,
    search?: string,
    sort?: string,
    page?: number,
    session?: string,
    collection_slug?: string,
  ): Promise<FetchResult<PropertiesListingResult>> {
    const url = new URL("/api/property", window.location.origin);

    if (location_slug) {
      url.searchParams.set("location_slug", location_slug);
    }

    if (mood) {
      url.searchParams.set("mood", mood);
    }

    if (checkin_date) {
      url.searchParams.set("checkin_date", checkin_date);
    }

    if (checkout_date) {
      url.searchParams.set("checkout_date", checkout_date);
    }

    if (adult_count) {
      url.searchParams.set("adult_count", adult_count.toString());
    }

    if (child_count) {
      url.searchParams.set("child_count", child_count.toString());
    }

    if (search) {
      url.searchParams.set("search", search);
    }

    if (sort) {
      url.searchParams.set("sort", sort);
    }

    if (page) {
      url.searchParams.set("page", page.toString());
    }

    if (collection_slug) {
      url.searchParams.set("collection_slug", collection_slug);
    }

    return this.fetch.GET(
      url.toString(),
      { headers: { session: session ?? "" } },
    );
  }

  @cached()
  getProperty(
    property_slug: string,
    checkin_date?: string,
    checkout_date?: string,
  ): Promise<FetchResult<Property>> {
    const url = new URL(`/api/property/${property_slug}`, window.location.origin);

    if (checkin_date) {
      url.searchParams.set("checkin_date", checkin_date);
    }

    if (checkout_date) {
      url.searchParams.set("checkout_date", checkout_date);
    }

    return this.fetch.GET(url.toString());
  }

  getRoomDetails(
    property_slug: string,
    config_id: string,
  ): Promise<FetchResult<RoomDetails>> {
    const url = new URL(`/api/property/${property_slug}/${config_id}`, window.location.origin);

    return this.fetch.GET(url.toString());
  }

  getMealPlanOptions(
    property_slug: string,
  ): Promise<FetchResult<MealOptionList>> {
    const url = new URL(
      `/api/property/${property_slug}/meal`,
      window.location.origin,
    );

    return this.fetch.GET(url.toString());
  }

  getPropertyReviews(
    property_slug: string,
    page?: number,
  ): Promise<FetchResult<Reviews[]>> {
    const url = new URL(
      `/api/property/${property_slug}/reviews`,
      window.location.origin,
    );

    if (page) {
      url.searchParams.set("page", page.toString());
    }

    return this.fetch.GET(url.toString());
  }

  getSimilarProperties(
    property_slug: string,
    is_eh?: boolean,
    adult_count?: number,
    child_count?: number,
    checkinDate?: string,
    checkoutDate?: string,
    session?: string,
  ): Promise<FetchResult<PropertyItem[]>> {
    const url = new URL(
      `/api/property/${property_slug}/similar`,
      window.location.origin,
    );

    if (is_eh !== undefined) {
      url.searchParams.set("eh", is_eh.toString());
    }

    if (adult_count) {
      url.searchParams.set("adult_count", adult_count.toString());
    }

    if (child_count) {
      url.searchParams.set("child_count", child_count.toString());
    }

    if (checkinDate) {
      url.searchParams.set("checkin_date", checkinDate);
    }

    if (checkoutDate) {
      url.searchParams.set("checkout_date", checkoutDate);
    }

    return this.fetch.GET(
      url.toString(),
      { headers: { session: session ?? "" } },
    );
  }

  getTrendingProperties(session?: string): Promise<FetchResult<PropertyItem[]>> {
    const url = new URL("/api/property/trending", window.location.origin);
    
    return this.fetch.GET(
      url.toString(),
      { headers: { session: session ?? "" } },
    );
  }

  addToWishlist(
    session: string,
    property_slug: string,
    is_eh: boolean,
  ): Promise<FetchResult<void>> {
    const payload = {
        property_slug: property_slug,
        is_eh: is_eh,
      },
      url = new URL("/api/property/wishlist", window.location.origin);

    return this.fetch.POST(
      url.toString(),
      payload, {
        headers: { session: session },
      },
    );
  }

  getWishlist(session: string): Promise<FetchResult<PropertyItem[]>> {
    const url = new URL("/api/property/wishlist", window.location.origin);
    return this.fetch.GET(
      url.toString(), {
        headers: { session: session },
      },
    );
  }

  getPropertyCalendar (
    property_slug: string,
    start_date: string,
    month_count?: number,
  ): Promise<FetchResult<PropertyCalendar>> {
    const url = new URL(
      `/api/property/${property_slug}/calendar`,
      window.location.origin,
    );

    url.searchParams.set("start_date", start_date);

    if (month_count) {
      url.searchParams.set("month_count", month_count.toString());
    }

    return this.fetch.GET(url.toString());
  }

  getPointsEligibility(
    checkin_date: Date | null,
    checkout_date: Date | null,
    property_slug: string,
    property_configs: PropertyConfigArg[],
    session: string,
  ): Promise<FetchResult<PointsEligibility>> {
    const url = new URL("/api/property/pointsEligibility", window.location.origin);

    const payload = {
      check_in: checkin_date,
      check_out: checkout_date,
      slug: property_slug,
      property_configs: property_configs,
    }

    return this.fetch.POST(
      url.toString(),
      payload,
      { headers: { session: session ?? "" } },
    );
  }
}

export default PropertyService;
