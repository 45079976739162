import fmt from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isEqual from "date-fns/isEqual";
import {
  ChangeEventHandler,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { localEquivalent } from "../../../utils/date-tz";

function useDateRangeInputState (
  open: boolean,
  placeholder: [string, string],
  format: string = "d LLL yyyy",
  parentNode: HTMLDivElement | null,
  allowPastSelection?: boolean,
  startValue?: DateLike,
  startDefaultValue?: DateLike,
  endValue?: DateLike,
  endDefaultValue?: DateLike,
  name?: string,
  onChange?: ChangeEventHandler,
  onVerifySelection?: OnVerifySelection,
) {
  const [start, setStart] = useState<Date | null>(
    inferInitialValue(startValue, startDefaultValue),
  );

  const [end, setEnd] = useState<Date | null>(
    inferInitialValue(endValue, endDefaultValue),
  );

  useEffect(
    () => syncLocalValue(setStart, startValue),
    [setStart, startValue],
  );

  useEffect(
    () => syncLocalValue(setEnd, endValue),
    [setEnd, endValue],
  );

  const handleClearStart = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      e.preventDefault();

      setStart(null);

      if (!onChange) {
        return;
      }

      const event = constructFakeEvent(name, null, end);

      onChange(event);
    },
    [setStart, name, end],
  );

  const handleClearEnd = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      e.preventDefault();

      setEnd(null);

      if (!onChange) {
        return;
      }

      const event = constructFakeEvent(name, start, null);

      onChange(event);
    },
    [setEnd, name, start],
  );

  const handleClear = useCallback(
    () => {
      setStart(null);

      setEnd(null);

      if (!onChange) {
        return;
      }

      const event = constructFakeEvent(name, null, null);

      onChange(event);
    },
    [setStart, setEnd, name],
  );

  const handleDateSelected = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      const target = e.target as HTMLElement,
        actionNode = target.closest("[data-action='clear']"),
        dateNode = target.closest("[data-date]") as HTMLElement | undefined;

      if (actionNode) {
        handleClear();

        return;
      }

      if (!dateNode) {
        return;
      }

      const { date, tense, selectable } = dateNode.dataset;

      const canNotSelect = selectable === "false";

      if (canNotSelect) {
        return;
      }

      if (!date) {
        return;
      }

      if (tense === "past" && !allowPastSelection) {
        return;
      }

      const parsedDate = new Date(date);

      const nextValues = calculateNextValues(
          parsedDate,
          start,
          end,
        ),
        safeNextValue = onVerifySelection?.(
          start,
          end,
          nextValues.start,
          nextValues.end,
        ) ?? nextValues,
        { start: nextStart, end: nextEnd } = safeNextValue;

      setStart(nextStart);

      setEnd(nextEnd);

      if (!onChange) {
        return;
      }

      const event = constructFakeEvent(name, nextStart, nextEnd);

      onChange(event);
    },
    [
      setStart,
      setEnd,
      handleClear,
      parentNode,
      allowPastSelection,
      start,
      end,
      name,
      onChange,
      onVerifySelection,
    ],
  );

  const {
    hasStart,
    startFormatted,
    hasEnd,
    endFormatted,
  } = useMemo(
    () => {
      const hasStart = Boolean(start).valueOf(),
        startFormatted = formatAdjustedDate(start, format) ?? placeholder[0],
        hasEnd = Boolean(end).valueOf(),
        endFormatted = formatAdjustedDate(end, format) ?? placeholder[1];

      return {
        hasStart: hasStart,
        startFormatted: startFormatted,
        hasEnd: hasEnd,
        endFormatted: endFormatted,
      };
    },
    [format, start, end],
  );

  return {
    hasStart: hasStart,
    start: start,
    startFormatted: startFormatted,
    hasEnd: hasEnd,
    end: end,
    endFormatted: endFormatted,
    handleDateSelected: handleDateSelected,
    handleClearStart: handleClearStart,
    handleClearEnd: handleClearEnd,
  };
}

export default useDateRangeInputState;

function inferInitialValue (value?: DateLike, defaultValue?: DateLike) {
  return () => {
    if (value) {
      return new Date(value);
    }

    if (defaultValue) {
      return new Date(defaultValue);
    }

    return null;
  };
}

function calculateNextValues (
  parsedDate: Date,
  start: Date | null,
  end: Date | null,
) {
  if (!start && !end) {
    return {
      start: parsedDate,
      end: null,
    };
  }

  if (!start && end) {
    if (isEqual(parsedDate, end)) {
      return {
        start: null,
        end: end,
      };
    }

    if (isAfter(parsedDate, end)) {
      return {
        start: end,
        end: parsedDate,
      };
    }

    return {
      start: parsedDate,
      end: end,
    };
  }

  if (start && !end) {
    if (isEqual(parsedDate, start)) {
      return {
        start: start,
        end: null,
      };
    }

    if (isBefore(parsedDate, start)) {
      return {
        start: parsedDate,
        end: start,
      };
    }

    return {
      start: start,
      end: parsedDate,
    };
  }

  if (start && end) {
    return {
      start: parsedDate,
      end: null,
    };
  }

  return {
    start: start,
    end: end,
  };
}

function constructFakeEvent (
  name: string | undefined,
  start: Date | null,
  end: Date | null,
) {
  const fakeTarget = { name: name, id: name, value: { start, end } };

  const event: any = { target: fakeTarget, currentTarget: fakeTarget };

  return event;
}

function syncLocalValue (
  dispatch: Dispatch<SetStateAction<Date | null>>,
  value: DateLike | undefined,
) {
  if (typeof value === "string") {
    return;
  }

  dispatch(value ?? null);
}

function formatAdjustedDate (date: Date | null, format: string) {
  if (!date) {
    return null;
  }

  const adjusted = localEquivalent(date);

  const formatted = fmt(adjusted, format);

  return formatted;
}

export type OnVerifySelection = (
  start: Date | null,
  end: Date | null,
  nextStart: Date | null,
  nextEnd: Date | null,
) => ({
  start: Date | null,
  end: Date | null,
});

export type DateLike = string | Date | null;
