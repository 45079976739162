import React, { ChangeEventHandler, useEffect, useMemo, useRef, useState } from "react";
import useAnchorPosition from "../../hooks/use-anchor-position";
import useClx from "../../hooks/use-clx";
import Portal from "../Portal";
import CalendarDialog, { CalendarDialogProps } from "./CalendarDialog";
import useDialogToggle from "../../hooks/use-dialog-toggle";
import useCalendarDialogPosition from "./use-calendar-dialog-position";
import useDateRangeInputState, { DateLike, OnVerifySelection } from "./use-date-range-input-state";
import clxs from "./date-range-input.module.css";
import DATE_ICON from "../../assets/icons/eventDateIcon.svg";
import useIsMobile from "../../hooks/use-is-mobile";

export interface DateRangeInputProps {
  inputFormat?: string;
  labels?: [string, string];
  className?: string;
  start?: DateLike;
  end?: DateLike;
  startDefaultValue?: DateLike;
  endDefaultValue?: DateLike;
  placeholder?: [string, string];
  allowPastSelection?: boolean;
  calendarDialogProps?: BaseCalendarDialogProps;
  onChange?: ChangeEventHandler;
  onVerifySelection?: OnVerifySelection;
  name?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  onOpenDialog?: () => void;
  onSelectClicked?: () => void;
  handleDateDoneClicked?: () => void;
  isShowDialog?: boolean;
  isShowDateOnScroll?: boolean;
  isTwoMonthMobileDialog?: boolean;
  isPriceCalculator?: boolean;
}

function DateRangeInput(props: DateRangeInputProps) {
  const {
    placeholder = ["dd/mm/yyyy", "dd/mm/yyyy"],
    className,
    labels,
    inputFormat,
    start,
    startDefaultValue,
    end,
    endDefaultValue,
    onOpenDialog,
    onSelectClicked,
    allowPastSelection = false,
    name,
    onChange,
    onVerifySelection,
    calendarDialogProps = {},
    helperText,
    error,
    disabled = false,
    isShowDialog,
    isShowDateOnScroll,
    isTwoMonthMobileDialog,
    isPriceCalculator,
    handleDateDoneClicked,
  } = props,
    { isMobile } = useIsMobile(),
    [isDoneClicked, setIsDoneClicked] = useState<boolean>(false),
    [focused, setFocused] = useState<boolean>(false),
    ref = useRef<HTMLDivElement>(null),
    { anchorPosition: desktopPosition } = useAnchorPosition(ref),
    { open, handleOpen, handleCloseOnScroll, handleClose } = useDialogToggle(ref, disabled, onOpenDialog, isDoneClicked, setIsDoneClicked, onSelectClicked),
    {
      start: startValue,
      hasStart,
      startFormatted,
      end: endValue,
      hasEnd,
      endFormatted,
      handleDateSelected,
    } = useDateRangeInputState(
      open,
      placeholder,
      inputFormat,
      ref.current,
      allowPastSelection,
      start,
      startDefaultValue,
      end,
      endDefaultValue,
      name,
      onChange,
      onVerifySelection,
    ),

    /**
     * Handles Select date on click of done button
     */
    handleDoneClicked = (isDoneClicked: boolean) => {
      if (isDoneClicked) {
        if (handleDateDoneClicked) {
          handleDateDoneClicked();
        }
        setIsDoneClicked(true);
        setFocused(false);
        handleClose();
        // Force close after a small delay to ensure all state updates are processed
        setTimeout(() => {
          handleClose();
        }, 0);
      }
    },

    /**
     * Handles Clearing date on click of clear button
     */
    handleClear = (isClearClicked: boolean) => {
      if (onChange && isClearClicked) {
        setIsDoneClicked(true)
        const event = {
          target: {
            name,
            value: {
              start: null,
              end: null,
            },
          },
        } as unknown as React.ChangeEvent<HTMLInputElement>;
        onChange(event);
        handleClose();
      }
    },

    /**
     * Handles click events for the input field.
     */
    handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (!disabled) {
        e.stopPropagation();
        setFocused(true);
        handleOpen();
        if (onOpenDialog) {
          onOpenDialog();
        }
        handleDateSelected(e);
      }
    },

    /**
     * This method handles focus events for the input field.
     */
    handleFocus = () => {
      if (!disabled) {
        setFocused(true);
      }
    },

    safeInitialDate = useMemo(
      () => {
        return startValue ?? endValue ?? calendarDialogProps.initialDate;
      },
      [calendarDialogProps.initialDate, startValue, endValue],
    ),
    { styles: dialogStyles } = useCalendarDialogPosition(desktopPosition, ref, open),
    ccx = useClx(clxs.container, className),
    mcx = useClx(clxs.container, clxs.mobileSelectContainer, className),
    vcx = useClx(clxs.value, "value"),
    llx = useClx(clxs.label, "label"),
    ltx = useClx(clxs.labeltwo, "labeltwo"),
    edx = useClx(clxs.eventDateContainer, "eventDateContainer"),
    dcx = useClx(calendarDialogProps.className, clxs.dialog),
    mdx = useClx(calendarDialogProps.className, clxs.dialog, clxs.mobileDialog);

    useEffect(() => {
      if (!open) return;
    
     /**
     * This method handles blur events for the input field.
     */
      const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (!ref.current || ref.current.contains(event.target as Node)) return;
    
        // Ensure clicks inside the CalendarDialog are ignored
        if (event.target instanceof HTMLElement) {
          const isClickInsideCalendar = event.target.closest("[data-component='dialog']") ||
            event.target.closest("[data-component='calendar']") ||
            event.target.closest("[data-component='calendar-day']") ||
            event.target.closest("[data-component='calendar-header']") ||
            event.target.closest("[data-component='calendar-body']") ||
            event.target.closest("[data-component='month']") ||
            event.target.closest("[data-action='previous']") ||
            event.target.closest("[data-action='next']") ||
            event.target.closest("[data-action='clear']") ||
            event.target.closest("[data-action='done']");
    
          if (isClickInsideCalendar) {
            //console.log("✅ Click inside CalendarDialog, ignoring...");
            return; // Do nothing if click is inside the calendar
          }
        }
    
        //console.log("❌ Clicked outside, closing calendar.");
        setFocused(false);
        handleClose();
      };
    
      // Use capture phase to ensure it runs before other handlers (fixes iOS issues)
      document.addEventListener("mousedown", handleClickOutside, true);
      document.addEventListener("touchstart", handleClickOutside, true);
    
      return () => {
        document.removeEventListener("mousedown", handleClickOutside, true);
        document.removeEventListener("touchstart", handleClickOutside, true);
      };
    }, [open, handleClose]);
    
  useEffect(
    () => {
      if (isShowDialog) {
        handleOpen();
        setFocused(true);
      } else {
        handleClose();
        setFocused(false);
      }
    },
    [isShowDialog],
  );

  useEffect(() => {
    if (!open) {
      setFocused(false);
    }

    if (open) {
      setFocused(true);
    }
  }, [open]);

  useEffect(() => {
    const handleScroll = () => {
      //isShowDateOnScroll is true when we need to show hide data on scroll.
      isShowDateOnScroll && handleCloseOnScroll();
    };
    if (isShowDateOnScroll) {

      // Add a scroll event listener to the window object
      window.addEventListener("scroll", handleScroll);

      // Cleanup function: This ensures that the scroll event listener is removed
      // when the component is unmounted or dependencies change, preventing memory leaks.
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
    return;

  }, [handleCloseOnScroll, startValue, endValue, isShowDateOnScroll]);

  return (
    <div
      className={isTwoMonthMobileDialog ? mcx : ccx}
      ref={ref}
      onClick={isMobile ? handleDateSelected :handleClick}
     // onBlur={handleBlur}
      onFocus={handleFocus}
      tabIndex={0}
      data-focus={focused}
      data-error={error}
      data-disabled={disabled}
      data-price-calculator={isPriceCalculator}
    >
      {helperText && (
        <div className={clxs.helperText}>
          {helperText}
        </div>
      )}
      {labels?.length === 2 && (
        <>
          <div className={llx}>
            {labels[0]}
          </div>
          <div className={ltx}>
            {labels[1]}
          </div>
        </>
      )}
      <div className={edx}>
        <div 
          className={clxs.horizontalDivider}
          data-focus={focused}
        ></div>
        <img
          src={DATE_ICON}
          alt="Lohono"
          className={clxs.eventDate}
        />
      </div>
      <div
        className={vcx}
        tabIndex={1}
        data-placeholder={!hasStart}
        data-action="open"
        onClick={handleClick}
      >
        {startFormatted}
        {/* {hasStart && (
          <div
            className="clear"
            onClickCapture={handleClearStart}
            data-action="ignore"
          >
            ×
          </div>
        )} */}
      </div>
      <div
        className={vcx}
        data-placeholder={!hasEnd}
        tabIndex={1}
        data-action="open"
        onClick={handleClick}
      >
        {endFormatted}
        {/* {hasEnd && (
          <div
            className="clear"
            onClickCapture={handleClearEnd}
            data-action="ignore"
          >
            ×
          </div>
        )} */}
      </div>
      <Portal>
        <CalendarDialog
          {...calendarDialogProps}
          style={dialogStyles}
          open={open}
          startDate={startValue}
          endDate={endValue}
          initialDate={safeInitialDate}
          position={desktopPosition}
          className={isTwoMonthMobileDialog ? mdx : dcx}
          monthClassName={isTwoMonthMobileDialog ? clxs.monthClassname : ""}
          dayNameClassname={isTwoMonthMobileDialog ? clxs.dayNameContainer : ""}
          isTwoMonthMobileDialog={isTwoMonthMobileDialog}
          handleDoneClicked={handleDoneClicked}
          handleClear={handleClear}
        />
      </Portal>
    </div>
  );

}

export default DateRangeInput;

type BaseCalendarDialogProps = Omit<CalendarDialogProps,
  | "open"
  | "position"
  | "startDate"
  | "endDate"
  | "style">;