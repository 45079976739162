import React, { Fragment } from "react";
import decodeLocationSlug from "../../../../utils/decode-location-slug";
import { getPropertySearchUrl } from "../../../../utils/property-search";
import useClx from "../../../hooks/use-clx";
import useSendRequest from "../../../hooks/use-send-request";
import { Track } from "../../AnalyticsManager";
import clxs from "./property-location.module.css";
import LocationAnchor from "../../LocationAnchor";
import usePropertyLocationAnchorTemplate from "./use-property-location-anchor-template";
import MAP_LOCATION_ICON from "../../../assets/icons/map-location-icon.svg";
import { useStorageString } from "../../../hooks/use-storage";
import usePropertyLocations from "../../../hooks/use-property-locations";
import useIsIosMobile from "../../../hooks/use-is-ios-mobile";
import useIsLocationDenied from "../../../hooks/use-is-location-denied";

interface PropertyLocationProps {
  className: string;
  titleClassName: string;
  subtitleClassName: string;
  containerClassName: string;
}

function PropertyLocation (props: PropertyLocationProps) {
  const template = usePropertyLocationAnchorTemplate(),
   {isIosMobile} = useIsIosMobile(),
   isLocationDenied = useIsLocationDenied(),
   { requestLocationPermission , closeClickCount} = usePropertyLocations("",true),
    {
      className,
      titleClassName,
      subtitleClassName,
      containerClassName,
    } = props,
    { value: userLocationObj } = useStorageString("sessionStorage", "user_location"),
    ccx = useClx(clxs.container, containerClassName),
    { showSendRequestModal } = useSendRequest(),
    showNearbyLocations = () => {
      // console.log("Nearby locations");
      requestLocationPermission();
    },
    handleComingSoon = (location: PropertyLocation) => {
      if (!location.coming_soon) {
        return;
      }

      return showSendRequestModal(
        { interest: "rent", location: location.slug },
      );
    };

  return (
    <div className={className}>
      <a
        id="locations"
        className={clxs.anchor}
      >
      </a>
      <div className={clxs.headerTitle}>
        <h2 className={titleClassName}>
          Explore Lohono
        </h2>

        {/* Hiding the show nearby location option if the device is IOS. Since in IOS we cannot ignore the location permission for safari/chrome browsers.*/}
        { !userLocationObj && closeClickCount <= 2 && !isIosMobile && !isLocationDenied && (
          <div 
            className={clxs.nearbyLocationButton}
            onClick={showNearbyLocations}
          >
            <img 
              src={MAP_LOCATION_ICON} 
              alt="icon" 
            />
            <span className={clxs.locationText}>
              Show Nearby Locations
            </span>
          </div>
        )
        }
      </div>
      <div className={subtitleClassName}>
        Exclusive homes for rent spread across pristine destinations, pick your nest of luxury
      </div>
      {template.map((each, key) => {
        const { title, locations } = each;

        return (
          <Fragment key={key}>
            <div className={clxs.propertyTitle}>
              {title}
            </div>
            <div className={ccx}>
              {locations.map((property, index) => {
                const {
                    name,
                    slug,
                    thumbnail,
                    property_count,
                    coming_soon,
                    country,
                  } = property,
                  url = getPropertySearchUrl(slug),
                  decodedLocation = decodeLocationSlug(slug);

                return (
                  <Track
                    event="home_location_clicked"
                    payload={{
                      ...each,
                      ...decodedLocation,
                      href: url,
                      index: index,
                    }}
                    key={slug}
                  >
                    <LocationAnchor
                      name={name}
                      slug={slug}
                      property_count={property_count}
                      coming_soon={coming_soon}
                      country={country}
                      thumbnail={thumbnail}
                      key={slug}
                      onClick={handleComingSoon.bind(null, property)}
                    />
                  </Track>
                );
              })}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}

export default PropertyLocation;
