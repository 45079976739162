import { useEffect, useState } from "react";

export function useIsLocationDenied() {
  const [isLocationDenied, setIsLocationDenied] = useState(false);

  useEffect(() => {
    let permissionStatus: PermissionStatus;

    const checkPermission = async () => {
      try {
        // Check current permission status
        permissionStatus = await navigator.permissions.query({ name: "geolocation" });
        
        // Update initial state
        setIsLocationDenied(permissionStatus.state === "denied");

        // Watch for changes in real-time
        permissionStatus.addEventListener("change", () => {
          setIsLocationDenied(permissionStatus.state === "denied");
        });

      } catch (error) {
        // Fallback: try to get location to check if denied
        navigator.geolocation.getCurrentPosition(
          () => setIsLocationDenied(false),
          (error) => setIsLocationDenied(error.code === error.PERMISSION_DENIED),
          { timeout: 5000 },
        );
      }
    };

    checkPermission();

    // Cleanup listener
    return () => {
      if (permissionStatus) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        permissionStatus.removeEventListener("change", () => {});
      }
    };
  }, []);

  return isLocationDenied;
}

export default useIsLocationDenied;