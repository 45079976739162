import React, { ChangeEvent, useMemo } from "react";
import useClx from "../../../../../hooks/use-clx";
import Carousel from "../../../../Carousel";
import Item from "../../../../Carousel/Item";
import ScrollCrumb from "../../../../Home/PropertyCarousel/ScrollCrumb";
import CHEVRON_RIGHT_PRIMARY1 from "../../../../../assets/icons/black-right-arrow-icon.svg";
import LoyaltyPointShortTicker from "../../../../LoyaltyPointShortTicker";
import Select from "../../../../Select";
import clxs from "./room-option-item.module.css";
import useIsMobile from "../../../../../hooks/use-is-mobile";

interface RoomOptionItemProps {
  config: PropertyConfig;
  currencySymbol: string;
  discountValue?: number;
  price: number;
  discountedPrice: number;
  discountMethod: string;
  discountType?:string;
  noOfNights: number;
  value: string;
  label?: string;
  disabled?: boolean;
  onChange?: (config: PropertyConfig, value: string) => void;
  onViewRoomDetails?: (config: PropertyConfig) => void;
}

function RoomOptionItem(props: RoomOptionItemProps) {
  const {
      config,
      currencySymbol,
      discountValue,
      price,
      discountedPrice,
      discountMethod,
      discountType,
      // noOfNights,
      value,
      disabled = false,
      onChange,
      onViewRoomDetails,
    } = props,
    {
      room_name,
      available_inventory,
      sold_out,
    } = config,
    {
      carouselId,
      topAmenities,
      topImages,
      roomOccupancyFriendly,
    } = useMemo(
      () => {
        const {
            config_id,
            max_occupancy,
            bed_type,
            images,
            amenities,
          } = config,
          topAmenities = amenities.slice(0, 3),
          topImages = images.slice(0, 4),
          roomOccupancyFriendly = `Fits ${max_occupancy} (${bed_type})`,
          firstImageUrl = topImages[0]?.url ?? "";

        return {
          carouselId: `room-option-carousel-${config_id}`,
          topAmenities: topAmenities,
          topImages: topImages,
          firstImageUrl: firstImageUrl,
          roomOccupancyFriendly: roomOccupancyFriendly,
        };
      },
      [
        config,
      ],
    ),
    {
      priceFriendly,
      discountedPriceFriendly,
      // isShowDiscountedPrice,
    } = useMemo(() => {
        const priceFriendly = price
            ? `${currencySymbol} ${price.toLocaleString("en-IN")}`
            : "",
          discountedPriceFriendly = discountedPrice
            ? `${currencySymbol} ${discountedPrice.toLocaleString("en-IN")}`
            : priceFriendly,
          isShowDiscountedPrice = (discountedPrice > 0) && (price >0) && (discountedPrice < price);  

        return {
          priceFriendly: priceFriendly,
          discountedPriceFriendly: discountedPriceFriendly,
          isShowDiscountedPrice: isShowDiscountedPrice,
        };
      },
      [currencySymbol, price, discountedPrice],
    ),
    options = useMemo(
      () => {
        const { available_inventory } = config;

        const options = [
          {
            label: "Select Room",
            value: "0",
          },
          ...Array.from(
            { length: available_inventory },
            (_, offset) => {
              const count = offset + 1;

              const label = `${count} Room`;

              return {
                value: count.toString(),
                label: label,
              };
            },
          ),
        ];

        return options;
      },
      [config],
    ),
    inventoryStatusContent = useMemo(
      () => {
        if (disabled) {
          return "Select travel dates and group size";
        }

        return `Only ${available_inventory} Rooms left`;
      },
      [disabled, available_inventory],
    ),
    // nightContent = useMemo(
    //   () => noOfNights ? "/ night" : " / night",
    //   [noOfNights],
    // ),
    { isMobile } = useIsMobile(),
    cscx = useClx(clxs.carousel, "carousel"),
    // routable = isMobile,
    handleViewRoomDetails = () => {
      onViewRoomDetails?.(config);
    },
    // rootElement = useMemo(
    //   () => {
    //     if (routable) {
    //       return "a";
    //     }
    //     return "div";
    //   },
    //   [routable],
    // ),
    // cProps = useMemo(
    //   () => {
    //     const baseProps = {
    //       onClick: handleViewRoomDetails,
    //     };

    //     if (routable) {
    //       return baseProps;
    //     }

    //     return null;
    //   },
    //   [
    //     routable,
    //     handleViewRoomDetails,
    //   ],
    // ),
    handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget as HTMLInputElement;

      const value = target.value;

      onChange?.(config, value);
    };

  return <div 
    className={clxs.room}
    data-selected={value !== "0"}
         >
      <div className={clxs.carouselContainer}>
        <Carousel
          className={cscx}
          id={carouselId}
          threshold={0.8}
        >
          {topImages.map((each, key) => {
            const {
              url,
              alt,
            } = each;
            return (
              <Item
                className={clxs.imageItem}
                key={key}
              >
                <img
                  src={url}
                  alt={alt}
                  className={clxs.imgSelect}
                />
              </Item>
            );
          })}
        </Carousel>
        <ScrollCrumb
          carouselId={carouselId}
          isShowArrows={true}
          isFilledArrows={true}
          count={topImages.length}
          className={clxs.scrollCrumb}
        />
      </div>
      <div className={clxs.roomHeader}>
        <div className={clxs.roomNameContainer}>
          <span className={clxs.roomName}>{room_name}</span>
          <span className={clxs.bedType}>{roomOccupancyFriendly}</span>
        </div>
      </div>
      <div className={clxs.amenityContainer}>
          {topAmenities.map((eachAmenity, i) => {
            return (
              <div
                key={i}
                className={clxs.amenity}
              >
                <img
                  className={clxs.amenityIcon}
                  alt={eachAmenity.name}
                  src={eachAmenity.icon}
                />
                <span className={clxs.amenityName}>
                  {eachAmenity.name}
                </span>

              </div>
            );
          })}
          {isMobile && <div>+2</div>}
        </div>
      <div className={clxs.roomInventory}>
        {sold_out ? (
          <div className={clxs.inventoryStatusSoldOut}>
            Sold Out
          </div>
        ) : (
          <div className={clxs.inventoryStatusAvailable}>
            {inventoryStatusContent}
          </div>
        )}
      </div>
      <div
        className={clxs.viewRoomDetails}
        onClick={handleViewRoomDetails}
      >
         {isMobile ?
          <img
            src={CHEVRON_RIGHT_PRIMARY1}
            alt="jump"
            className={clxs.viewRoomDetailIcon}
          />
          :
          <span> View Room Details</span>
         }
      </div>
      <div className={clxs.divider}></div>
      <div className={clxs.roomPrice}>
            {discountValue ?
            <div className={clxs.startFrom}>
              <LoyaltyPointShortTicker
                discountType={discountType}
                discountPercentage={discountValue}
                method={discountMethod}
                className={clxs.llpTicker}
                currencySymbol={currencySymbol}
              />
              {priceFriendly ? (
                <span className={clxs.strike}>
                  {priceFriendly}
                </span>
              ) : null}
            </div> 
            : null
           }
            <div 
              className={clxs.price}
              data-sold-out={sold_out}
            >
              <span className={clxs.amount}>
                {discountedPriceFriendly} {isMobile && <span className={clxs.excTax}>{"/ night excluding taxes*"}</span>}
              </span>
            </div>
            { !isMobile && <span className={clxs.excTax}>{"Per night excluding taxes*"}</span>}
      </div>
      {/* <div className={clxs.mobileRoomPrice}>
        <div className={clxs.startFrom}>
          Start from
          {(priceFriendly && isShowDiscountedPrice) ? (
            <span className={clxs.strike}>
              {priceFriendly}
            </span>
          ) : null}
        </div>
        <div className={clxs.price}>
          <span className={clxs.amount}>
            {discountedPriceFriendly}
          </span>
          {nightContent}
          <sup>*</sup>
        </div>
        <div className={clxs.excTax}>
          <LoyaltyPointShortTicker
            discountPercentage={tierDiscountPercentage}
            method={discountMethod}
            className={clxs.llpTicker}
          />
          <div>
            (* Exclusive of taxes)
          </div>
        </div>
      </div> */}
      
      <Select
        options={options}
        className={clxs.roomSelect}
        placeholder="Select Rooms"
        searchable={false}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>;
}

export default RoomOptionItem;
