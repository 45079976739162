import PropertyConfig, {
  PropertyConfigProps,
  PropertyConfigValue,
  GenerateKeyPrefix,
} from "./PropertyConfig";

export default PropertyConfig;

export type {
  PropertyConfigProps,
  PropertyConfigValue,
  GenerateKeyPrefix,
};
