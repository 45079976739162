import React, { useRef } from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./view-all-offers-list.module.css";
import CLOSE_ICON from "../../assets/icons/close.svg";
import Modal, { useShowModal } from "../../hooks/use-modal";
import ExclusiveCouponTNC from "../ExclusiveCouponTNC";

interface ViewAllOffersListProps {
  listingCoupon?: ListingOffersType[];
  onSubmit?: (out: any) => void;
}

function ViewAllOffersList(props: ViewAllOffersListProps) {
  const { listingCoupon, onSubmit } = props,
    ccx = useClx(clxs.couponCard),
    showTnCModal = useShowModal(EXCLUSIVE_COUPON_TNC_MODAL_ID),
    termsListRef = useRef<HTMLDivElement>(null),
    /**
     * Handles pop-up close functionality
     */
    onCloseParentModal = () => {
      if (onSubmit) {
        onSubmit(null);
      }
    },

    /**
     * Function to handle viewing Terms & Conditions in a modal
     */
    handleViewTnC = async (offer: ListingOffersType) => {
      await showTnCModal({
        listingCoupon: offer,
        onCloseParentModal: onCloseParentModal,
      });
    };

  return (
    <>
      <div 
        className={ccx}
        ref={termsListRef}
      >
        <div className={clxs.couponHeader}>
          <div className={clxs.couponCode}>Offers and Discounts</div>
          <img
            src={CLOSE_ICON}
            alt="close"
            className={clxs.close}
            onClick={onCloseParentModal}
          />
        </div>
        <div
          className={clxs.termsListConatiner}
        >
          {listingCoupon && listingCoupon.map((offer, index) => (
            <div
              key={index}
              className={clxs.contentContainer}
            >
              <img
                src={offer.image}
                alt={offer.title}
                className={clxs.offerImg}
              />
              <div className={clxs.content}>
                <div className={clxs.title}>{offer.title}</div>
                <div className={clxs.description}>{offer.description}</div>
              </div>
              <div className={clxs.termsContainer}>
                {offer.discount_text ?
                  <div className={clxs.discountBox}>
                    {offer.discount_text}
                  </div>
                  : <div />
                }

                {offer.terms.length > 0 ?
                  <a
                    className={clxs.tncLink}
                    onClick={() => handleViewTnC(offer)}
                  >
                    T&C Apply
                  </a>
                  : null
                }
              </div>
              <div
                className={
                  index + 1 === (listingCoupon.length)
                    ? clxs.hideDivider
                    : clxs.divider
                }
              ></div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        id={EXCLUSIVE_COUPON_TNC_MODAL_ID}
        isPopupOverlapped={true}
      >
        <ExclusiveCouponTNC
          parentHeight={termsListRef.current?.clientHeight}
        />
      </Modal>
    </>
  );
}

export default ViewAllOffersList;

const EXCLUSIVE_COUPON_TNC_MODAL_ID = "dialogue-exclusive-coupon-tnc";


