import React, { useEffect, useRef, useState } from "react";
import clxs from "./review-item.module.css";
import useClx from "../../../../hooks/use-clx";
import MembershipStatus from "../MembershipStatus";
import usePathParams from "../../../../hooks/use-path-params";
import usePropertyReviews from "../../../../hooks/use-property-reviews";
import useIsMobile from "../../../../hooks/use-is-mobile";
// import Stars from "../Stars";

interface ReviewItemProps {
  className?: string;
  ratingReviews?: Reviews[];
}

function ReviewItem(props: ReviewItemProps) {
  const { className, ratingReviews } = props,
    [scrollRatio, setScrollRatio] = useState(0),
    { isMobile } = useIsMobile(),
    scrollDemoRef = useRef(null),
    property_slug = usePathParams(PATH, "property"),
    { reviews, getNextPage } = ratingReviews ? { reviews: ratingReviews, getNextPage: () => Promise<void> } : usePropertyReviews(property_slug),
    [lengthId, setLengthId] = useState<number>(0),

    handleScroll = () => {
      if (scrollDemoRef.current) {
        const { scrollTop } = scrollDemoRef.current;
        const { scrollHeight, clientHeight } = scrollDemoRef.current;
        const scrolled = clientHeight + scrollTop;

        const ratio = scrolled / scrollHeight;

        setScrollRatio(ratio);
      }
    },

    ccx = useClx(clxs.container, className),

    handleRatioChange = (ratio: number) => {
      if (ratio < 0.6) {
        return;
      }

      if (lengthId === reviews.length) {
        return;
      }

      setLengthId(reviews.length);
    };

  // Function to convert check_out date to "Month YYYY" format
  const formatCheckOutDate = (dateString: string) => {
    const [year, month] = dateString.split("-");
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];
    const monthIndex = parseInt(month, 10) - 1;
    return `${monthNames[monthIndex]} ${year}`;
  };

  useEffect(() => handleRatioChange(scrollRatio), [scrollRatio]);

  useEffect(() => {
    if (!lengthId) {
      return;
    }

    getNextPage();
  }, [lengthId]);

  return (
    <div
      className={ccx}
      ref={scrollDemoRef}
      onScroll={handleScroll}
    >
      {/* <div className={clxs.guestsLikedContainer}>
        <div className={clxs.guestsLikedTitle}>Guests liked</div>
        <div className={clxs.likedItems}>
          <div className={clxs.likedItem}>Impeccable service</div>
          <div className={clxs.likedItem}>Great view</div>
          <div className={clxs.likedItem}>Good food</div>
        </div>
      </div> */}

      {
        reviews?.map((each: Reviews, i: number) => {
          const { name, tier_code, check_out, rating, review } = each;

          // Function to get initials from the guest's name
          const getInitials = () => {
            const [first = "", last = ""] = name.split(" ");
            return `${first.substring(0, 1)}${last.substring(0, 1)}`;
          };

          return (
            <div
              className={clxs.reviews}
              key={i}
            >
              <div className={clxs.nameInitials}>{getInitials()}</div>
              <div className={clxs.name}>
                <div className={clxs.guestName}> <div>{name}</div>
                  <MembershipStatus
                    tier={tier_code}
                    className={clxs.membershipStatus}
                  />
                </div>
                <div className={clxs.stayDate}>
                  Stayed in {formatCheckOutDate(check_out)}
                  <div className={clxs.rating}>
                    Rating :  &nbsp;<span className={clxs.ratingNumber}> {rating}</span>
                    {/* <div className={clxs.starsContainer}>
                  <Stars
                    className={clxs.ratingIcon}
                    average_ratings={rating}
                    iconClassName={clxs.ratingIcon}
                    isShowEmptyStar={false}
                  />
                  </div> */}
                  </div>
                </div>
              </div>
              <div className={clxs.reviewContent}>{review}</div>
              {/* Conditionally render a divider */}
              {isMobile && 
              <div
                className={ i + 1 == reviews.length  ? clxs.hideDivider : clxs.divider}
              >
              </div>
              }
            </div>
          );
        })}
    </div>
  );
}

export default ReviewItem;

const PATH = "/villas/:destination/:location/:property";