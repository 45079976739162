import React from "react";
import useClx from "../../hooks/use-clx";
import Img from "../Img";
import Button from "../Button";
import useSendRequest from "../../hooks/use-send-request";
import BANNER_DESKTOP from "../../assets/sneha_desu_banner.jpg";
import BANNER_MOBILE from "../../assets/sneha_desu_banner_mobile.jpg";
import WELLNESS_IMAGE from "../../assets/wellness_image.jpg";
import DATE_ICON from "../../assets/fitness-retreat-date-icon.svg";
import VENUE_ICON from "../../assets/fitness-retreat-venue-icon.svg";
import PRICE_ICON from "../../assets/fitness-retreat-price-icon.svg";
import PERSON_ICON from "../../assets/fitness-retreat-guest-icon.svg";
import FOCUS from "../../assets/mind.png";
import BALANCE from "../../assets/body.png";
import RESTORE from "../../assets/soul.png";
import SUSTAIN from "../../assets/nourish.png";
import BROWN_BORDER from "../../assets/mind-border.svg";
import OCHRE_BORDER from "../../assets/body-border.svg";
import BLUE_BORDER from "../../assets/soul-border.svg";
import YELLOW_BORDER from "../../assets/nourish-border.svg";
import DAY_BORDER from "../../assets/day-border.svg";
import INCLUSION_BANNER from "../../assets/inclusion-banner.png";
import CUTTLERY from "../../assets/cuttlery-icon.svg";
import AIRPORT_TRANSFER from "../../assets/valet-icon.svg";
import VILLA from "../../assets/villa-icon.svg";
import CALL from "../../assets/plus-icon.svg";
import SNEHA_IMAGE from "../../assets/sneha.png";
import VILLA_BRANCO_1 from "../../assets/villa-branco-1.png";
import VILLA_BRANCO_2 from "../../assets/villa-branco-2.png";
import VILLA_BRANCO_3 from "../../assets/villa-branco-3.png";
import VILLA_BRANCO_4 from "../../assets/villa-branco-4.png";
import INCLUSION_BANNER_MOBILE from "../../assets/inclusion-villa-mobile.png";
import ITINERARY_BACKGROUND from "../../assets/itinerary-banner.jpg";
import fwclxs from "../../styles/full-width.module.css";
import clxs from "./fitness-retreat.module.css";

function FitnessRetreat() {
  const { showSendRequestModal } = useSendRequest();

  const handleEnquire = (clickedBy: string) => showSendRequestModal({
    interest: "rental",
    location: "india-goa",
    clickedBy: clickedBy,
    brand: 1,
    message: "Please share details for Lohono Wellness Experience",
  });

  const bccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.banner);

  const bcx = useClx(fwclxs.container, clxs.bannerImage);

  const wccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.wellness);

  const dccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.venue);

  const iccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.inclusion);

  const itcx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.itineraryContainer);

  const tccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.tnc);

  return (
    <>
      <div className={bccx}>
        <Img
          src={BANNER_DESKTOP}
          desktopSrc={BANNER_DESKTOP}
          mobileSrc={BANNER_MOBILE}
          alt="banner"
          className={bcx}
        />
        <h1 className={clxs.bannerTitle}>
          {"Renew your mind, body\nand soul"}
        </h1>
        <Button
          className={clxs.bannerEnquire}
          onClick={() => handleEnquire("Enquire Now")}
        >
          Enquire Now
        </Button>
      </div>
      <div className={wccx}>
        <div className={clxs.wellnessTitle}>
          {"A Lohono Wellness\nExperience"}
        </div>
        <img
          src={WELLNESS_IMAGE}
          alt="wellness image"
          className={clxs.wellnessImage}
        />
        <div className={clxs.wellnessDescription}>
          {WELLNESS_DESCRIPTION.map((each, key) => (
            <p key={key}>
              {each}
            </p>
          ))}
        </div>
      </div>
      <div className={dccx}>
        <div className={clxs.venueTitle}>
          Details
        </div>
        <div className={clxs.detailsContainer}>
          {DETAILS_TEMPLATE.map(
            (each, key) => (
              <div
                className={clxs.details}
                key={key}
              >
                <img
                  src={each.icon}
                  alt={each.title}
                  className={clxs.detailIcon}
                />
                <div className={clxs.detailTitle}>
                  {each.title}
                </div>
                <div className={clxs.detailSubtitle}>
                  {each.subtitle}
                </div>
              </div>
            ),
          )}
        </div>
        <Button
          className={clxs.detailsBookNow}
          onClick={() => handleEnquire("Enquire Now")}
        >
          Enquire Now
        </Button>
      </div>
      <div className={clxs.retreat}>
        <div className={clxs.retreatTitle}>
          Retreat Highlights
        </div>
        <div className={clxs.retreatFeatures}>
          {RETREAT_TEMPLATE.map(
            (each, key) => (
              <div key={key}>
                <img
                  src={each.image}
                  alt={each.title}
                  className={clxs.featureImage}
                />
                <div className={clxs.featureTitle}>
                  {each.title}
                </div>
                <img
                  src={each.border}
                  alt={each.title}
                  className={clxs.borderIcon}
                />
                <div className={clxs.featureContent}>
                  {each.content}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
      <div className={iccx}>
        <div className={clxs.inclusionContent}>
          <div className={clxs.inclusionTitle}>
            Inclusions
          </div>
          {INCLUSION_TEMPLATE.map(
            (each, key) => (
              <div
                className={clxs.inclusionItem}
                key={key}
              >
                <img
                  src={each.icon}
                  alt={each.content}
                />
                {each.content}
              </div>
            ),
          )}
          <Button
            className={clxs.inclusionBookNow}
            onClick={() => handleEnquire("Enquire Now")}
          >
            Enquire Now
          </Button>
        </div>
        <Img
          src={INCLUSION_BANNER}
          desktopSrc={INCLUSION_BANNER}
          mobileSrc={INCLUSION_BANNER_MOBILE}
          alt="inclusion banner"
          className={clxs.inclusionBanner}
        />
      </div>
      <div className={wccx}>
        <div className={clxs.aboutSectionTitle}>
          About Sneha
        </div>
        <img
          src={SNEHA_IMAGE}
          alt="about section"
          className={clxs.aboutSectionImage}
        />
        <div className={clxs.about}>
          {ABOUT_TEMPLATE}
        </div>
      </div>
      <div className={itcx}>
        <img
          src={ITINERARY_BACKGROUND}
          alt="background"
          className={clxs.itineraryBg}
        />
        <div className={clxs.itineraryTitle}>Itinerary</div>
        <div className={clxs.itineraryItemContainer}>
          {ITINERARY_TEMPLATE.map(
            (each, key) => (
              <div
                key={key}
                className={clxs.itineraryItem}
              >
                <div className={clxs.itineraryItemTitle}>
                  {each.day}
                </div>
                <img
                  src={DAY_BORDER}
                  alt="border"
                  className={clxs.itineraryItemBorderIcon}
                />
                {each.plan.map(
                  (each, key) => (
                    <div
                      key={key}
                      className={clxs.itineraryPlan}
                    >
                      <span>{each.time}</span>
                      {each.activity}
                    </div>
                  ),
                )}
              </div>
            ),
          )}
        </div>
        <Button
          className={clxs.itineraryBookNow}
          onClick={() => handleEnquire("Enquire Now")}
        >
          Enquire Now
        </Button>
      </div>
      <div className={clxs.villaBranco}>
        <div className={clxs.villaBrancoTitle}>
          Villa Branco Goa
        </div>
        <div className={clxs.villaBrancoDescription}>
          {VILLA_BRANCO_DESCRIPTION}
        </div>
        <div className={clxs.villaBrancoImages}>
          {VILLA_BRANCO_IMAGE_TEMPLATE.map(
            (each, key) => (
              <img
                src={each}
                alt={`villa-branco-goa-${key}`}
                key={key}
                className={clxs.villaBrancoImage}
              />
            ),
          )}
        </div>
      </div>
      <div className={tccx}>
        <div className={clxs.tncTitle}>
          {"Terms & Conditions"}
        </div>
        {TNC_TEMPLATE.map(
          (each, key) => (
            <div key={key}>{each}</div>
          ),
        )}
      </div>
    </>
  );
}

export default FitnessRetreat;

const WELLNESS_DESCRIPTION = [
  "Immerse yourself in a world of wellness with Lohono. A combination of an expert-led approach and a tranquil environment that promotes total rejuvenation makes for the ultimate holistic retreat.",
  "Designed to help optimise your energy, boost concentration and improve clarity of thought, our holistic fitness retreat with Dr. Sneha Desu, will allow you to strike that elusive balance between a composed mind and a healthy body. The program aims at improving physical and mental health through a series of unique activities like trapeze yoga, beach workouts, pottery and animal flow conducted in the blissful environs of Villa Branco, Goa.",
  "Come solo or with friends, family and colleagues. Whether you’re a seasoned fitness enthusiast or a beginner, the retreat is sure to transform the way you approach your daily life.",
]

const DETAILS_TEMPLATE = [
  {
    icon: DATE_ICON,
    title: "Date",
    subtitle: "22 - 25 September 2022",
  },
  {
    icon: VENUE_ICON,
    title: "Venue",
    subtitle: "Villa Branco, North Goa",
  },
  {
    icon: PRICE_ICON,
    title: "Price",
    subtitle: "INR 75,000/person(ex taxes)",
  },
  {
    icon: PERSON_ICON,
    title: "Expert",
    subtitle: "Dr. Sneha Desu",
  },
];

const RETREAT_TEMPLATE = [
  {
    image: FOCUS,
    title: "FOCUS",
    border: BROWN_BORDER,
    content: "The mind is always active. We can improve our focus with meditation, goal-setting, and journaling. The pottery activity also aids in soothing the mind, while you work in harmonious symphony with your hands.",
  },
  {
    image: BALANCE,
    title: "BALANCE",
    border: OCHRE_BORDER,
    content: "A fit, healthy body is key to holistic well-being. And our program has been curated with activities designed to let your body move, stretch and emerge feeling healthier. Think trapeze yoga, functional workouts, HIIT and animal flow led by our expert.",
  },
  {
    image: RESTORE,
    title: "RESTORE",
    border: BLUE_BORDER,
    content: "Complete your holistic journey and connect with your innermost being through our group discussions, goal setting as well as activities like nature cycling, surrounded by verdant foliage that will soothe weary souls.",
  },
  {
    image: SUSTAIN,
    title: "SUSTAIN",
    border: YELLOW_BORDER,
    content: "Our retreats are designed to nourish all your senses. From protein smoothies to wholesome meals, we offer a spread of clean and delicious meals that have been consciously crafted, for your betterment.",
  },
];

const INCLUSION_TEMPLATE = [
  {
    icon: VILLA,
    content: "3-night stay at Villa Branco, North Goa on double\n occupancy basis",
  },
  {
    icon: CUTTLERY,
    content: "Nutritious and protein-rich meals freshly prepared with\n locally sourced ingredients",
  },
  {
    icon: CALL,
    content: "All sessions with Dr. Sneha Desu",
  },
  {
    icon: AIRPORT_TRANSFER,
    content: "Airport Transfers",
  },
];

const ABOUT_TEMPLATE = "Sneha Desu is a doctor turned Certified Personal Trainer (CPT) with a passion for promoting physical and mental wellness. She is driven by a desire to help people lead improved lifestyles and does this by combining her knowledge of medicine with her expertise on all things fitness. So much so that she won the title of the ‘Fittest Woman in the Country’ 2015-2016, for the Devil’s Circuit. \nThis woman of many talents is also a certified mental health first aider, animal flow instructor, muay thai fitness instructor, specialist in sport & clinical nutrition and double certified in pre-postnatal exercise. Her motto is to find a balance between fitness, nutrition and sound mental health. Under her expert guidance, this holistic retreat is guaranteed to become a truly transformative experience.";

const VILLA_BRANCO_IMAGE_TEMPLATE = [
  VILLA_BRANCO_1,
  VILLA_BRANCO_2,
  VILLA_BRANCO_3,
  VILLA_BRANCO_4,
];

const VILLA_BRANCO_DESCRIPTION = "Located in Anjuna, Goa, this 5-bedroom luxury villa boasts a charming Mediterranean design style. The villa features striking elements like refurbished doors from erstwhile royalty, mirrored accents and a sea green private pool, surrounded by beautifully carved screens.";

const TNC_TEMPLATE = [
  "* Prices are exclusive of taxes",
  "* 100% payment is to be made while booking to confirm your place",
  "* Package price is based on double occupancy",
  "* Single occupancy at ₹95,000 (+ taxes)is available upon request and is subject to availability",
];

const ITINERARY_TEMPLATE = [
  {
    day: "DAY 1",
    plan: [
      { time: "14:00", activity: "Check - In" },
      { time: "15:30", activity: "Tea/ Coffee - Ice breaking session" },
      { time: "17:00", activity: "Fitness Assessment - Animal Flow Basics" },
      { time: "18:30", activity: "Protein Smoothie" },
      { time: "19:30", activity: "Introduction to Meditation" },
      { time: "20:00", activity: "Dinner" },
      { time: "20:30", activity: "Goal Setting" },
    ],
  },
  {
    day: "DAY 2",
    plan: [
      { time: "7:30", activity: "Functional Workout" },
      { time: "8:15", activity: "Recovery yoga" },
      { time: "9:30", activity: "Breakfast" },
      { time: "11:00", activity: "Meditation - mindfulness with silence" },
      { time: "13:00", activity: "Lunch" },
      { time: "15:00", activity: "Mental activity - group discussion" },
      { time: "17:00", activity: "Nature cycling" },
      { time: "18:30", activity: "Protein smoothie" },
      { time: "20:00", activity: "Dinner" },
      { time: "20:30", activity: "Goal setting" },
    ],
  },
  {
    day: "DAY 3",
    plan: [
      { time: "7:30", activity: "Animal flow" },
      { time: "9:30", activity: "Breakfast" },
      { time: "11:00", activity: "Meditation" },
      { time: "12:00", activity: "Trapeze yoga at shala" },
      { time: "13:30", activity: "Lunch" },
      { time: "16:00", activity: "Mindfulness with pottery" },
      { time: "17:30", activity: "HIIT workout" },
      { time: "18:30", activity: "Protein smoothie" },
      { time: "20:00", activity: "Dinner" },
      { time: "20:30", activity: "Mental activity - group discussion" },
    ],
  },
];
