import React, { useEffect, useState } from "react";
import clxs from "./share-menu.module.css";
import SHARE_ICON from "../../../../assets/icons/ios_share_black.svg";
import COPY_ICON from "../../../../assets/icons/copy_circle_icon.svg";
import WHATSAPP_ICON from "../../../../assets/icons/whatsapp_circle_icon.svg";
import EMAIL_ICON from "../../../../assets/icons/email_circle_icon.svg";
import useClx from "../../../../hooks/use-clx";
import useIsMobile from "../../../../hooks/use-is-mobile";

interface ShareMenuProps {
  onShare: () => Promise<boolean>;
  buttonClassName?:string;
  className?:string;
}

function ShareMenu(props: ShareMenuProps) {
  const {buttonClassName,onShare ,className} = props;
  const ccx = useClx(clxs.shareContainer,className);
  const bcx = useClx(clxs.shareButton,buttonClassName);
  const [isOpen, setIsOpen] = useState(false);
  const {isMobile} = useIsMobile();
  const [shareText, setShareText] = useState<string>("Copy Link")
  // const shareUrl = window.location.href;

  const toggleMenu = () => {
    if(isMobile){
      onShare();
      return;
    }
    setIsOpen(!isOpen);
  };

  // const mobileShareLink = async () => {
  //   const result = await onShare();

  //   if (result) {
  //     return;
  //   }

  //   await new Promise(resolve => setTimeout(resolve, 1500));
  // };

  const copyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    setShareText("Copied!");
  };

  const shareEmail = () => {
    window.location.href = `mailto:?subject=Check out this villa&body=${window?.location.href}`;
  };

  const shareWhatsApp = () => {
    window.open(
      `https://wa.me/?text=${encodeURIComponent(window?.location.href)}`,
      "_blank",
    );
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (isOpen && !document.querySelector(`.${clxs.shareMenu}`)?.contains(event.target as Node)) {
      toggleMenu();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={ccx}>
      <button
        className={bcx}
        onClick={toggleMenu}
      >
        <img
          src={SHARE_ICON}
          alt="share icon"
        />
        <div className={clxs.shareVilla}>Share Villa</div>
      </button>
      {isOpen && (
        <div className={clxs.shareMenu}>
          <div className={clxs.shareHeader}>
            Share This With Your Loved Ones!
          </div>
          <div
            className={clxs.shareItem}
            onClick={copyLink}
          >
            <img
              src={COPY_ICON}
              alt="Copy icon"
            />
            {shareText}
          </div>
          <div
            className={clxs.shareItem}
            onClick={shareEmail}
          >
            <img
              src={EMAIL_ICON}
              alt="email icon"
            />
            Email
          </div>
          <div
            className={clxs.shareItem}
            onClick={shareWhatsApp}
          >
            <img
              src={WHATSAPP_ICON}
              alt="whatsapp icon"
            />
            WhatsApp
          </div>
        </div>
      )}
    </div>
  );
}
export default ShareMenu;
