import React, { ChangeEvent, useEffect, useState } from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./exclusivecouponoffers.module.css";
// import { useCoupons } from "../../hooks/use-coupons";
import Modal, { useShowModal } from "../../hooks/use-modal";
import ExclusiveCouponTNC from "../ExclusiveCouponTNC";
import CLOSE_ICON from "../../assets/icons/close.svg";
import useReservationService from "../../hooks/use-reservation-service";
// import { usePropertyConfigValues } from "../../hooks/use-property-config";
import LinearLoader from "../LinearLoader";
// import useValidateCoupons from "../../hooks/use-validate-coupon";
import Skeleton from "../Skeleton";

interface ExclusiveCouponOffersProps {
  className?: string;
  value: string;
  propertySlug?: string;
  checkinDate?: string;
  checkoutDate?: string;
  onChange: (couponCode: string) => void;
  selected_configs: PropertyConfigArg[];
  coupons: Coupon[];
}

function ExclusiveCouponOffers(props: ExclusiveCouponOffersProps) {
  const {
    className,
    propertySlug,
    checkinDate,
    checkoutDate,
    onChange,
    value,
    selected_configs,
    coupons,
  } = props,

    { service } = useReservationService(),
    [couponError, setCouponError] = useState<string>(""),
    [manual, setManual] = useState<string>(""),
    [isValidCouponApplied, setValidCouponApplied] = useState<boolean>(false),
    // [inValidCouponApplied, setInValidCouponApplied] = useState<boolean>(false),
    [isValidatingCoupon, setIsValidatingCoupon] = useState<boolean>(false),
    [selectedCoupon, setSelectedCoupon] = useState<string>(""),
    ccx = useClx(clxs.exclusiveCouponOffersContainer, className),
    inputClassName = useClx(
      clxs.manual,
      ((selectedCoupon && couponError.length > 0) && !isValidCouponApplied) && clxs.invalidCouponApplied,
      (selectedCoupon && isValidCouponApplied) && clxs.validCouponApplied,
      (selectedCoupon) && clxs.couponApplied,
    ),
    /**
     * Hook to show Terms and Conditions modal
     */
    showTnCModal = useShowModal(EXCLUSIVE_COUPON_TNC_MODAL_ID);
    /**
     * Fetch property configurations
     */
    // { configArgs } = usePropertyConfigValues(propertySlug ? propertySlug : "", configs),
    // selected_configs = configArgs.filter(each => each.required_rooms > 0),

    /**
     * Hook to validate the coupon based on property and dates
     */
    // { coupon } = useValidateCoupons(
    //   selectedCoupon,
    //   selected_configs,
    //   propertySlug ? propertySlug : "",
    //   checkinDate ? checkinDate : "",
    //   checkoutDate ? checkoutDate : "",
    // ),

    /**
     * Fetch available coupons for the selected property and dates
     */
    // { coupons } = useCoupons(
    //   propertySlug,
    //   checkinDate,
    //   checkoutDate,
    //   adultCount,
    //   childCount,
    //   selected_configs,
    // );

  /**
   * Function to validate the entered coupon
   */
  const checkIfCouponValid = async (coupon_code: string) => {
    const normalizedCode = coupon_code.toUpperCase();
    setIsValidatingCoupon(true);
    const { error, response } = await service.validateCoupon(
      normalizedCode,
      selected_configs,
      propertySlug ? propertySlug : "",
      checkinDate ? checkinDate : "",
      checkoutDate ? checkoutDate : "",
    );
    let isCouponValid: boolean = false;

    setIsValidatingCoupon(false);

    if (!response) {
      isCouponValid = false;
    }

    if (error) {
      isCouponValid = false;
      setCouponError(error.message ? error.message : "Coupon code entered is invalid");
    }

    if (response && response.valid) {
      isCouponValid = true;
    }

    return isCouponValid;
  };

  /**
   * Function to handle coupon selection and validation
   */
  const handleCouponSelected = async (couponCode: string, isManual: boolean) => {
    const normalizedCode = couponCode.toUpperCase();

    if (selectedCoupon === normalizedCode) {
      return
    }

    if (isManual) {
      setSelectedCoupon(couponCode);
    } else {
      setSelectedCoupon(normalizedCode);
      setManual("");
    }

    const isValidCoupon = await checkIfCouponValid(normalizedCode);

    if (isValidCoupon) {
      onChange(normalizedCode);
      setValidCouponApplied(true);
    } else {
      setValidCouponApplied(false);
    }
  };

  /**
   * Function to handle viewing Terms & Conditions in a modal
   */
  const handleViewTnC = (offer: Coupon) => {
    showTnCModal({
      detailCoupon: offer,
    });
  };

  /**
   * Handle user input for coupon
   */
  const handleCouponChange = (e: ChangeEvent<HTMLInputElement>) => {
    setManual(e.target.value);
  };

  /**
   * Handle coupon removal
   */
  const handleRemove = () => {
    setManual("");
    setValidCouponApplied(false);
    onChange("NONE");
    setSelectedCoupon("");
    setCouponError("");
  };

  useEffect(() => {
    if (manual.length > 0 && manual) {
      setValidCouponApplied(false);
    }
  }, [manual]);

  useEffect(() => {
    if (value && value !== "NONE") {
      const normalizedCode = value.toUpperCase();
      handleCouponSelected(normalizedCode, false);
      // setSelectedCoupon(normalizedCode);
      // setValidCouponApplied(true);
    }
  }, [value]);

  return (
    <>
      <div className={ccx}>
        <LinearLoader isLoading={isValidatingCoupon} />
        <div className={clxs.header}>Exclusive Offers</div>
        <div className={clxs.couponContainer}>

          <div className={clxs.manualContainer}>

            {isValidatingCoupon ?
              <Skeleton height={48} />
              :
              <>
                <input
                  type="text"
                  className={inputClassName}
                  name="coupon_code"
                  placeholder="Enter Coupon code"
                  value={selectedCoupon || manual}
                  onChange={handleCouponChange}
                />
                {selectedCoupon ? (
                  <div
                    className={clxs.closeApplied}
                    onClick={handleRemove}
                  >
                    <img
                      src={CLOSE_ICON}
                      alt="close"
                      className={clxs.close}
                    />
                  </div>
                ) : (
                  <button
                    className={`${clxs.manualApply} ${manual ? clxs.greenManualApply : ""
                      }`}
                    onClick={() => handleCouponSelected(manual, true)}
                    disabled={!manual}
                  >
                    Apply
                  </button>
                )}
                {(selectedCoupon && isValidCouponApplied) ? (
                  <div className={clxs.codeApplied}>Promocode Applied!</div>
                ) : null}

                {((selectedCoupon && couponError.length > 0) && !isValidCouponApplied) ? (
                  <div className={clxs.invalidCodeApplied}>
                    {couponError}
                  </div>
                ) : null}
              </>}
          </div>
          {coupons.length > 0 && (
            <div className={clxs.couponList}>
              {coupons.map((each, i) => {
                const { name, code, description, saveUpto } = each;
                return (
                  <div
                    key={i}
                    className={clxs.couponItem}
                    onClick={() => handleCouponSelected(code, false)}
                  >
                    <input
                      type="radio"
                      name="coupon"
                      className={clxs.radioButton}
                      checked={selectedCoupon === code}
                      value={code}
                      readOnly
                    />
                    <div className={clxs.couponDetails}>
                      <div className={clxs.couponCode}>{code || name}</div>
                      <div className={clxs.couponDiscount}>{description}</div>
                      {saveUpto &&
                        <div className={selectedCoupon === code ? `${clxs.savings} ${clxs.greenSavings}` : clxs.savings}>You save ₹{saveUpto?.toLocaleString("en-IN")}</div>
                      }
                      {each.terms &&
                        <a
                          className={clxs.tncLink}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleViewTnC(each);
                          }}
                        >T&C Apply</a>}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <Modal id={EXCLUSIVE_COUPON_TNC_MODAL_ID}>
        <ExclusiveCouponTNC />
      </Modal>
    </>
  );
}

export default ExclusiveCouponOffers;

const EXCLUSIVE_COUPON_TNC_MODAL_ID = "exclusive-coupon-tnc";
