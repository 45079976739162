import React, { MouseEvent, useEffect, useRef } from "react";
import clx from "classnames";
import { useCarouselScroller } from "../../../../hooks/use-carousel";
import useClx from "../../../../hooks/use-clx";
import clxs from "./scroll-crumb.module.css";
import RIGHT_ICON from "../../../../assets/icons/right-arrow.svg";
import LEFT_ICON from "../../../../assets/icons/left-arrow.svg";
import FILLED_RIGHT_ICON from "../../../../assets/icons/filled-right-arrow.svg";
import FILLED_LEFT_ICON from "../../../../assets/icons/filled-left-arrow.svg";

interface ScrollCrumbProps {
  carouselId: string;
  count: number;
  className: string;
  isShowArrows?: boolean;
  themeBlack?:boolean;
  isFilledArrows?:boolean;
}

function ScrollCrumb(props: ScrollCrumbProps) {
  const { carouselId,themeBlack } = props,
    containerRef = useRef<HTMLDivElement>(null),
    { visibleSet, scrollTo, scrollRight, scrollLeft, leftCount,rightCount  } = useCarouselScroller(carouselId),
    { count, className, isShowArrows,isFilledArrows } = props,
    // [currentIndex, setCurrentIndex] = useState<number>(0),
    ccx = useClx(themeBlack ? clxs.CenteredContainer : clxs.container, className),
    handleScrollTo = (e: MouseEvent<HTMLElement>) => {
      const target = e.currentTarget as HTMLElement;

      const index = Number.parseInt(target.dataset.index ?? "");

      if (Number.isNaN(index)) {
        return;
      }

      // setCurrentIndex(index);

      scrollTo(index);
    };
    // leftScroll = () => {
      // if (containerRef && containerRef.current) {
      //   containerRef.current.scrollLeft -= 16;
      // }
      // scrollLeft();
      // if(rightCount > 1) {
      // containerRef?.current && handleHorizantalScroll(containerRef.current, 50, 14, -14);
      // }
    // },
    // rightScroll = () => {
      // if (containerRef && containerRef.current) {
      //   containerRef.current.scrollLeft += 16;
      // }
      // scrollRight();
      // if(leftCount > 1) {
      //   containerRef?.current && handleHorizantalScroll(containerRef.current, 50, 14, 14);
      // }
    // }
    // handleHorizantalScroll = (element: HTMLDivElement, speed: number, distance: number, step: number) => {
    //   let scrollAmount = 0;
    //   const slideTimer = setInterval(() => {
    //     // element.children[0].animate([{transform: "translate(" + step + "px)"}], {duration: 1000});
    //     if (!step) {
    //       element.scrollLeft = distance;
    //     } else {
    //       element.scrollLeft += step;
    //     }
    //     scrollAmount += Math.abs(step);
    //     if (scrollAmount >= distance) {
    //       clearInterval(slideTimer);
    //     }
    //   }, speed);
    // };

  if (!count) {
    return null;
  }

  useEffect(() => {
    const selectedIndex = [...visibleSet][0];

    //Check for the scrolled index and accordingly scroll to the selected index.
    if(selectedIndex) {
      let distanceToScroll = 0
      if(selectedIndex > 2) {
        distanceToScroll = 14 * (selectedIndex - 2);
      }
      
      if(containerRef?.current) {
        const element = containerRef.current;
        element.scrollTo(distanceToScroll,0);
      }
    }
  },[visibleSet]);

  return (
    <>
    <div 
      className={ccx} 
      ref={containerRef}
    >
      {Array.from(
        { length: count },
        (_, index) => {
          const active = visibleSet.has(index),
            icx = clx(clxs.item, active && clxs.itemActive);

          return (
            <div
              key={index}
              className={icx}
              data-index={index}
              onClick={handleScrollTo}
            >
              <div className={themeBlack ? clxs.outerBlack : clxs.outer}>
                &nbsp;
              </div>
              <div className={themeBlack ? clxs.innerBlack : clxs.inner}>
                &nbsp;
              </div>
            </div>
          );
        },
      )}
    </div>
    {
      (isShowArrows && leftCount > 0) ?
      <img
        src={isFilledArrows ?FILLED_LEFT_ICON : LEFT_ICON}
        alt="icon"
        className={`${clxs.leftArrowIcon } ${isFilledArrows ? clxs.filledLeftArrowIcon : ""}`}
        onClick={scrollLeft}
      /> : null
    }
    {
      (isShowArrows && rightCount > 0) ?
      <img
        src={isFilledArrows ? FILLED_RIGHT_ICON :RIGHT_ICON }
        alt="icon"
        className={`${clxs.rightArrowIcon} ${isFilledArrows ? clxs.filledRightArrowIcon : ""}`}
        onClick={scrollRight}
      /> : null
    }
  </>
  );
}

export default ScrollCrumb;
