import { useAtomState } from "./use-atom";

class _DefaultReservationService implements IReservationService {
  async getTentativePrice(): Promise<FetchResult<TentativePrice>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async addCouponLoyaltyPoints(): Promise<FetchResult<TentativePrice>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async deleteCoupon(): Promise<FetchResult<TentativePrice>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async addReservation(): Promise<FetchResult<Reservation>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async confirmReservation(): Promise<FetchResult<string | string[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getReservation(): Promise<FetchResult<ReservationDetail>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getCoupons(): Promise<FetchResult<Coupon[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: [],
    };
  }

  async validateCoupon(): Promise<FetchResult<ValidatedCoupon>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getConfirmedReservations(): Promise<FetchResult<ConfirmedReservation[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: [],
    };
  }

  async getBookingDetail(): Promise<FetchResult<Property>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getCoupon(): Promise<FetchResult<Coupon>>{
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getFeedbackArgs(): Promise<FetchResult<FeedbackArgs>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async saveFeedback(): Promise<FetchResult<void>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getPaymentStatus(): Promise<FetchResult<PaymentStatus>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async createBooking(): Promise<FetchResult<BookingResponse>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async updateBooking(): Promise<FetchResult<BookingResponse>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async bookingPayment(): Promise<FetchResult<BookingPaymentResponse>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }
}

const DefaultReservationService = new _DefaultReservationService();

export const RESERVATION_SERVICE_KEY = "RESERVATION_SERVICE";

function useReservationService() {
  const [service, setService] =
    useAtomState<IReservationService>(
      RESERVATION_SERVICE_KEY,
      DefaultReservationService,
    );

  return {
    service: service,
    setService: setService,
  };
}

export default useReservationService;
