import React, { useMemo} from "react";
import DateRangeInput, { DateRangeInputProps } from "../../../DateRangeInput";
import {useField} from "../../../../hooks/use-form";

interface DateRangeProps extends DateRangeInputProps {
  form: string;
  name: string;
  handlechangeparent?: (e: React.ChangeEvent<any>) => void;
  isShowCalendarDialog?: boolean;
  isShowDateOnScroll?: boolean;
  onOpenDialog?:()=>void;
  onSelectClicked?:()=>void,
  className?:string;
  isTwoMonthMobileDialog?:boolean;
}

const DateRange = function DateRange (props: DateRangeProps) {
  const {
      form,
      name,
      isShowCalendarDialog,
      onOpenDialog,
      onSelectClicked,
      handlechangeparent,
      isShowDateOnScroll,
      isTwoMonthMobileDialog,
      className,
      ...rest
    } = props, {
      value,
      error: err,
      handleChange,
    } = useField(form, name),
    { error, hasError } = useMemo(
      () => {
        if (!err) {
          return {
            error: "",
            hasError: false,
          };
        }

        // noinspection SuspiciousTypeOfGuard
        if (typeof err === "string") {
          return {
            error: err,
            hasError: err.length > 0,
          };
        }

        const { start, end } = err as any,
          errors: string[] = [];

        if (start) {
          errors.push(start);
        }

        if (end) {
          errors.push(end);
        }

        return {
          hasError: errors.length > 0,
          error: errors.join(", "),
        };
      },
      [err],
    ),
    handleChangeDate = (e: React.ChangeEvent<any>) => {
      handleChange(e);
      handlechangeparent && handlechangeparent(e);
    };

  return (
    <DateRangeInput
      {...rest}
      name={name}
      className={className}
      isShowDialog={isShowCalendarDialog}
      isShowDateOnScroll={isShowDateOnScroll}
      start={value?.start}
      onOpenDialog={onOpenDialog}
      onSelectClicked={onSelectClicked}
      end={value?.end}
      helperText={error}
      error={hasError}
      onChange={handleChangeDate}
      isTwoMonthMobileDialog={isTwoMonthMobileDialog}
    />
  );
};

export default DateRange;
