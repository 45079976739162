const INVESTOR_REPORTS = [
  {
    title: "Annual Return for the FY 2023-24",
    href: "/Annual_Returns_23_24_IHPL.pdf",
  },
  {
    title: "Financial Statements – IHPL – FY 2023-24",
    href: "/Financial_statements_23_24_IHPL.pdf",
  },
  {
    title: "Annual Return for the FY 2022-23",
    href: "/Annual_Returns_22_23_IHPL.pdf",
  },
  {
    title: "Financial Statements – IHPL – FY 2022-23",
    href: "/Financial_statements_22_23_IHPL.pdf",
  },
  {
    title: "Annual Return for the FY 2021-22",
    href: "/Form_MGT_7_IHPL_21-22.pdf",
  },
  {
    title: "Financial Statements – IHPL – FY 2021-22",
    href: "/Annual Report_IHPL_FY 2021-22 - Website.pdf",
  },
  {
    title: "Notice of Extra Ordinary General Meeting – 5th March 2021",
    href: "/Notice of EGM_IHPL_For Website.pdf",
  },
  {
    title: "Notice of Annual General Meeting for FY 2020-21",
    href: "/AGM_notice_IHPL_2021.pdf",
  },
  {
    title: "Annual Return for the FY 2020-21",
    href: "/Annual Return 20_21 - Lohono.pdf",
  },
  {
    title: "Notice of Extra Ordinary General Meeting – 5th August 2021",
    href: "/EGM_notice_IHPL_2021.pdf",
  },
  {
    title: "Notice of Annual General Meeting for FY 2019-20",
    href: "/AGM_notice_IHPL_2020.pdf",
  },
  {
    title: "Extract of Annual Return in Form MGT-9 as on period ended 31st March, 2020",
    href: "/MGT 9_IHPL.pdf",
  },
  {
    title: "Annual Return for the financial year ending on March 31, 2020",
    href: "/Annual Return 19_21 - Lohono.pdf",
  },
  {
    title: "Notice of NCLT convened meeting of Equity Shareholders",
    href: "/Notice to Equity Shareholders_IHPL.pdf",
  },
  {
    title: "Notice served on Unsecured Creditors of IHPL as per NCLT Order",
    href: "/Notice to Unsecured Creditor_IHPL.pdf",
  },
];

export default INVESTOR_REPORTS;