import React from "react";
import useClx from "../../../../hooks/use-clx";
import clxs from "./service-details.module.css";
import CLOSE_ICON from "../../../../assets/icons/close.svg";
import useIsMobile from "../../../../hooks/use-is-mobile";

interface ServiceDetailsProps {
  activeInvillaServices?: services;
  className?: string;
  onSubmit?: (out: any) => void;
}

function ServiceDetails(props: ServiceDetailsProps) {
  const { className, onSubmit, activeInvillaServices } = props;
  const ccx = useClx(clxs.serviceContainer, className),
    { isMobile } = useIsMobile();

  return (
    <div className={ccx}>
      <div className={clxs.serviceHeader}>
        {isMobile && (
          <div className={clxs.servicesNameMobile}>
            {activeInvillaServices?.name}
          </div>
        )}
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          onClick={onSubmit?.bind(null, null)}
        />
      </div>
      <div className={clxs.detailsContainer}>
        <div className={clxs.serviceImgContainer}>
          <img
            src={activeInvillaServices?.image}
            alt="service"
            className={clxs.serviceImg}
          />
        </div>
        <div className={clxs.detailListContainer}>
          <div className={activeInvillaServices?.price ? clxs.scrollableDetailList : `${clxs.scrollableDetailList} ${clxs.scrollableDetailListMaxHeight}`}>
            <div className={clxs.additionalInfo}>
              {!isMobile && (
                <div className={clxs.servicesName}>
                  {activeInvillaServices?.name}
                </div>
              )}
              <div className={clxs.servicesDescription}>
                {activeInvillaServices?.description}
              </div>
            </div>

            {/* Rendering Additional Description */}
            {activeInvillaServices?.additional_description.map((each, i) => {
              const { title, subtitle } = each;
              return (
                <div
                  key={i}
                  className={clxs.additionalInfo}
                >
                  <div className={clxs.servicesName}>{title}</div>
                  <div className={clxs.servicesDescription}>{subtitle}</div>
                </div>
              );
            })}
          </div>

          {activeInvillaServices?.price && 
          <div className={clxs.startsFrom}>
            Starts From -{" "}
            <span style={{ fontWeight: 600 }}>{activeInvillaServices.price}</span>
          </div> }
        </div>
        <div className={clxs.availablePost}>
          Available post-booking. Our specialist will help you curate the best
          experience for you and your family
        </div>
      </div>
    </div>
  );
}

export default ServiceDetails;
