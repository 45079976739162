import React from "react";
import useClx from "../../hooks/use-clx";
import useSendRequest from "../../hooks/use-send-request";
import Button  from "../Button";
import Img from "../Img";
import fwclxs from "../../styles/full-width.module.css";
import clxs from "./yoga-experiences.module.css";
import STAY_AZUL from "../../assets/yoga-retreat/stay-azul.svg";
import AIRPORT_TRANSFER from "../../assets/yoga-retreat/airport-transfer.svg";
import ALL_MEALS from "../../assets/yoga-retreat/all-meals.svg";
import BANNER_DESKTOP from "../../assets/yoga-retreat/yoga-retreat-banner-desktop.jpg";
import BANNER_MOBILE from "../../assets/yoga-retreat/yoga-retreat-banner-mobile.jpg";
import LOCATION_PIN from "../../assets/icons/location-pin.svg";
import WELLNESS_ILLUSTRATION from "../../assets/yoga-retreat/yoga-retreat-wellness-illustration.svg";
import INCLUSION_BANNER from "../../assets/yoga-retreat/inclusion-banner.png";
import VILLA_AZUL_1 from "../../assets/yoga-retreat/villa-azul-1.png";
import VILLA_AZUL_2 from "../../assets/yoga-retreat/villa-azul-2.png";
import VILLA_AZUL_3 from "../../assets/yoga-retreat/villa-azul-3.png";
import VILLA_AZUL_4 from "../../assets/yoga-retreat/villa-azul-4.png";

function YogaExperience () {
  const { showSendRequestModal } = useSendRequest();

  const handleEnquire = (clickedBy: string) => showSendRequestModal({
    interest: "rental",
    location: "india-goa",
    brand: 1,
    message: "Please share more details on Yoga Retreat",
    clickedBy: clickedBy,
  });

  const bccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.banner);

  const bcx = useClx(fwclxs.container, clxs.bannerImage);

  const wccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.wellness);

  const iccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.inclusion);

  const tccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.tnc);

  return (
    <>
      <div className={bccx}>
        <Img
          src={BANNER_DESKTOP}
          desktopSrc={BANNER_DESKTOP}
          mobileSrc={BANNER_MOBILE}
          alt="yoga banner"
          className={bcx}
        />
        <h1 className={clxs.bannerTitle}>
          {"Yoga Retreat with\nIra Trivedi"}
          <div className={clxs.bannerTitle2}>
            25th - 28th August
          </div>
        </h1>
        <div className={clxs.bannerSubtitle}>
          <img
            src={LOCATION_PIN}
            alt="location"
          />
          {"Vagator, Goa"}
        </div>
        <Button
          className={clxs.bannerEnquire}
          onClick={() => handleEnquire("Enquire Now")}
        >
          Enquire Now
        </Button>
      </div>
      <div className={wccx}>
        <div className={clxs.wellnessTitle}>
          A Lohono Wellness Experience
        </div>
        <img
          src={WELLNESS_ILLUSTRATION}
          alt="wellness illustration"
          className={clxs.wellnessIllustration}
        />
        <div className={clxs.wellnessDescription}>
          {WELLNESS_DESCRIPTION}
        </div>
      </div>
      <div className={clxs.venue}>
        <div className={clxs.venueTitle}>
          Details
        </div>
        <div className={clxs.venueItem}>
          <div className={clxs.venueItemTitle}>
            Venue
          </div>
          <div className={clxs.venueItemDescription}>
            Located in North Goa, the idyllic Villa Azul by Lohono Stays exudes Mediterranean charm and a tranquil
            atmosphere. With a private pool, lawn, courtyard and spacious verandah, you can make the most of the
            tropical weather as you immerse yourself in the serene
            surroundings.
          </div>
          <a
            className={clxs.venueItemDirection}
            href="https://goo.gl/maps/krdWoeE98c2PfkyJ9"
            target="_blank"
            rel="noreferrer noopener"
          >
            Get Directions
          </a>
        </div>
        <div className={clxs.venueItem}>
          <div className={clxs.venueItemTitle}>
            Price
          </div>
          <div className={clxs.earlyBirdContainer}>
            <div className={clxs.earlyBirdTitle}>
              Early-Bird
            </div>
            <div className={clxs.earlyBirdPriceContainer}>
              <div className={clxs.earlyBirdStrike}>
                {"₹ 58,500/person"}
              </div>
              {"₹ 53,000/person"}
            </div>
            (25th Jul - 05th Aug)
          </div>
          <Button
            className={clxs.venueItemEnquire}
            onClick={() => handleEnquire("Book Now")}
          >
            Book Now
          </Button>
        </div>
      </div>
      <div className={iccx}>
        <div className={clxs.inclusionContent}>
          <div className={clxs.inclusionTitle}>
            Inclusion
          </div>
          {INCLUSION_TEMPLATE.map(
            (each, key) => (
              <div
                className={clxs.inclusionItem}
                key={key}
              >
                <img
                  src={each.icon}
                  alt={each.content}
                />
                {each.content}
              </div>
            ),
          )}
          <button
            className={clxs.inclusionEnquire}
            onClick={() => handleEnquire("Enquire Now")}
          >
            Enquire Now
          </button>
        </div>
        <img
          src={INCLUSION_BANNER}
          alt="inclusion banner"
          className={clxs.inclusionBanner}
        />
      </div>
      <div className={clxs.villaAzul}>
        <div className={clxs.villaAzulTitle}>
          Villa Azul
        </div>
        <div className={clxs.villaAzulImages}>
          {VILLA_AZUL_IMAGE_TEMPLATE.map(
            (each, key) => (
              <img
                src={each}
                alt={`villa-azul-${key}`}
                key={key}
                className={clxs.villaAzulImage}
              />
            ),
          )}
        </div>
      </div>
      <div className={tccx}>
        <div className={clxs.tncTitle}>
          {"Terms & Conditions"}
        </div>
        {TNC_TEMPLATE.map(
          (each, key) => (
            <div key={key}>{each}</div>
          ),
        )}
      </div>
    </>
  );
}

export default YogaExperience;

const WELLNESS_DESCRIPTION = "This August, We invite you to join us at our first holistic yoga retreat in Goa with celebrity yoga teacher, Ira Trivedi. Along with being a yogi, Ira is also author, the founder of yoga love and has featured in BBD’s 100 Most Influential Women list Look forward to a truly transformative experience with her over the 4-day retreat, hosted at the gorgeous Villa Azul";

const INCLUSION_TEMPLATE = [
  {
    icon: STAY_AZUL,
    content: "*3-nights stay at Villa Azul (Double Occupancy)",
  },
  {
    icon: AIRPORT_TRANSFER,
    content: "Airport Transfers",
  },
  {
    icon: ALL_MEALS,
    content: "All Meals (Vegetarian)",
  },
];

const VILLA_AZUL_IMAGE_TEMPLATE = [
  VILLA_AZUL_1,
  VILLA_AZUL_2,
  VILLA_AZUL_3,
  VILLA_AZUL_4,
];

const TNC_TEMPLATE = [
  "* Prices are exclusive of taxes",
  "* 100% payment is to be made while booking to confirm your places",
  "* Package price is based on double occupancy",
  "* Single occupancy at ₹70,000(+ taxes is available upon request and is subject to availability)",
];