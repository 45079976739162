import { useMemo } from "react";
import { useUser } from "./use-session";

function useUserWithInitials () {
  const user = useUser();

  const meta = useMemo(
    () => {
      const fullName = user?.full_name || "";

      const currentTier = user?.loyalty_point_metadata.tier || "no_tier";

      if (!fullName) {
        return {
          initials: "",
          firstName: "Profile",
          fullName: "Lohono Guest",
        };
      }

      const [first = "", last = ""] = fullName.split(" ");

      return {
        initials: `${first.substring(0, 1)}${last.substring(0, 1)}`,
        firstName: first,
        fullName: fullName,
        tier: currentTier,
      };
    },
    [user?.full_name],
  );

  return {
    user: user,
    ...meta,
  };
}

export default useUserWithInitials;
