import React, {useMemo} from "react";
import clxs from "./meal-plan-item.module.css";
import { usePriceCalculatorValue } from "../../../../../../hooks/use-price-calculator";
import RIGHT_ICON from "../../../../../../assets/icons/right_arrow_back.svg";

interface MealPlanItemProps {
  menu: MealOptionItem;
  id: string;
  menuType :string;
}

function MealPlanItem(props: MealPlanItemProps) {
  const {  menu, id,menuType} = props,
    { tentativePrice } = usePriceCalculatorValue(id),
    {
      meal_plan_name,
      sub_preference,
      preferences,
      offering_name,
      adult_price,
      child_price,
      brochure_link,
    } = menu,
    currency = useMemo(
      () => tentativePrice?.currency_symbol || "",
      [tentativePrice],
    ),
    containerClass = useMemo(() => {

      if (menuType === "Non-Veg") {
        return (clxs.redContainer);
      }

      if(menuType=="vegNonVeg"){
      return (clxs.vegNonVegContainer);
      }

      return (clxs.container);

    }, [menuType]),
    cleanPreference = (str: string) => str.toLowerCase().replace(/[\s-_]/g, "");

  return (
    <a 
      className={containerClass}
      href={brochure_link || "#"}
      target={brochure_link ? "_blank" : "_self"}
    >
      <div 
        title={meal_plan_name} 
        className={clxs.mealName}
      >
        <div className={clxs.meal}>
          {meal_plan_name}
          {sub_preference && (cleanPreference(sub_preference) === cleanPreference(preferences)
            ? null
            : ` - ${sub_preference}`)}
        </div>
        <div className={clxs.mealType}>{offering_name}</div>
      </div>
      <div className={clxs.mealPrice}>
        {currency} {adult_price.toLocaleString("en-IN")}*
      </div>
      <div className={clxs.mealPrice}>
        {/* if child_price is 0 the show free otherwise show price */}
        {!child_price ? (
          <span style={{ color: "#2DA40E", fontWeight: 600 }}>FREE</span>
        ) : (
          `${currency} ${child_price.toLocaleString("en-IN")}*`
        )}
      </div>
        <img
          src={RIGHT_ICON}
          alt="icon"
          className={clxs.arrowIcon}
        //   onClick={scrollRight}
        />
    </a>
  );
}

export default MealPlanItem;
