import React from "react";
import clxs from "./lohono-corporate.module.css";
import Banner from "./Banner";
import Brands from "./Brands";
import CorporateRetreat from "./CorporateRetreat";
import LohonoStory from "./LohonoStory";
import Inclusions from "./Inclusions";
import Testimonials from "./Testimonials";
import CorporateVillas from "./CorporateVillas";
import useSendRequest from "../../hooks/use-send-request";

function LohonoCorporate() {
  const { showSendRequestModal } = useSendRequest(),
    handleEnquireNow = async ( clickedBy: string) => {
      await showSendRequestModal({
        interest: "rental",
        location: "india-goa",
        brand: 1,
        isShowGuestField: true,
        guests: "2 - 10",
        clickedBy: clickedBy,
      });
    }
  return (
    <div className={clxs.container}>
      <Banner onEnquireNow={() => handleEnquireNow("Enquire Now")}/>
      <Brands />
      <CorporateRetreat />
      <LohonoStory />
      <Inclusions />
      <Testimonials />
      <CorporateVillas />
    </div>
  );
}

export default LohonoCorporate;