import React, { useMemo, useState } from "react";
import useIsMobile from "../../../../hooks/use-is-mobile";
import useClx from "../../../../hooks/use-clx";
import Collapse from "../../../Collapse";
import clxs from "./amenities.module.css";
import CHECK_ICON from "../../../../assets/icons/check-circle.svg";

interface AmenitiesProps {
  amenities: Amenity[]
  className?: string;
}

function Amenities(props: AmenitiesProps) {
  const { isMobile } = useIsMobile(),
    { amenities } = props,
    primary = useMemo(
      () => amenities.slice(0, 8),
      [amenities, isMobile],
    ),
    secondary = useMemo(
      () => amenities.slice(8),
      [amenities, isMobile],
    ),
    { className } = props,
    [collapsed, setCollapsed] = useState<boolean>(false),
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div className={clxs.title}>
        Amenities
      </div>
      <div className={clxs.responsiveTitle}>
        Every stay includes
      </div>
      {primary.map(
        (each, i) => {
          const { icon, name } = each,
            cx = icon ? clxs.icon : clxs.generic,
            borderClass = i % 2 === 0 ? clxs.centerBorder : ""; // Apply only on even index

          return (
            <div
              title={name}
              className={`${clxs.amenity} ${borderClass}`}
              key={i}
            >
              <img
                src={icon || CHECK_ICON}
                alt={name}
                className={cx}
              />
              {name}
            </div>
          );
        },
      )}
      <Collapse
        expanded={collapsed}
        className={clxs.collapseInner}
        outerClassName={clxs.collapse}
      >
        {secondary.map(
          (each, i) => {
            const { icon, name } = each,
              cx = icon ? clxs.icon : clxs.generic,
              borderClass = i % 2 === 0 ? clxs.centerBorder : ""; // Apply only on even index

            return (
              <div
                className={`${clxs.amenity} ${borderClass}`}
                key={i}
              >
                <img
                  src={icon || CHECK_ICON}
                  alt={name}
                  className={cx}
                />
                {name}
              </div>
            );
          },
        )}
      </Collapse>
      <div
        className={clxs.viewAll}
        onClick={setCollapsed.bind(null, !collapsed)}
      >
        {collapsed ? "Hide Amenities" : "View All Amenities"}
      </div>
    </div>
  );
}

export default Amenities;
