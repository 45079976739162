import React from "react";
import clxs from "./In-villa-services.module.css";
import Carousel from "../../Carousel";
import Scroller from "../../Scroller";
import useClx from "../../../hooks/use-clx";
import Item from "../../Carousel/Item";
import inVillaArrowIcon from "../../../assets/icons/in-villa-arrow.svg";
import { useCarouselScroller } from "../../../hooks/use-carousel";
import Modal, { useShowModal } from "../../../hooks/use-modal";
import ServiceDetails from "./ServiceDetails";

interface InVillaServicesProps {
  carouselId: string;
  activeInvillaServices: services[];
  title: string;
  subtitle?: string;
  className?: string;
}
function InVillaServices(props: InVillaServicesProps) {
  const { carouselId, title, subtitle, className, activeInvillaServices } =
      props,
    scx = useClx(clxs.section, className),
    { scrollLeft, scrollRight, leftCount, rightCount } =
      useCarouselScroller(carouselId),
      showServiceDetailsModal = useShowModal(DETAILS_MODAL_ID),
      handleShowServiceDetail = (activeInvillaServices : services) => {showServiceDetailsModal({ activeInvillaServices: activeInvillaServices ?? [] })};

  return (
    <>
    <div className={scx}>
      <div className={clxs.title}>{title}</div>
      {subtitle && <div className={clxs.subtitle}>{subtitle}</div>}
      <Carousel
        id={carouselId}
        className={clxs.carousel}
        threshold={0.9}
        adjust={true}
      >
        {activeInvillaServices.map((each, i) => {
          const { image, name, description } = each;

          return (
            <Item 
              key={i} 
              className={clxs.item}
            >
              <div className={clxs.activeInvillaServicesContainer}>
                <div className={clxs.servicesImageContainer}>
                  <img
                    loading="lazy"
                    src={image}
                    alt="Lohono"
                    className={clxs.servicesImage}
                  />
                </div>
                <div 
                  className={clxs.contentContainer}
                  onClick={()=>handleShowServiceDetail(each)}
                >
                  <div className={clxs.servicesName}>{name}</div>
                  <img
                    loading="lazy"
                    src={inVillaArrowIcon}
                    alt="Lohono"
                    className={clxs.inVillaArrowIcon}
                  />
                  <div className={clxs.servicesDescription}>{description}</div>
                </div>
              </div>
            </Item>
          );
        })}
      </Carousel>
      {activeInvillaServices.length > 3 && (
        <Scroller
          className={clxs.scroller}
          onScrollLeft={scrollLeft}
          onScrollRight={scrollRight}
          isLeftDisabled={leftCount === 0}
          isRightDisabled={rightCount === 0}
          isSmallIcon={true}
        />
      )}
    </div>
    <Modal id={DETAILS_MODAL_ID}>
      <ServiceDetails/>
    </Modal>
    </>
  );
}

export default InVillaServices;

const DETAILS_MODAL_ID = "in-villa-services-details";
