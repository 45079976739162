import React, { useEffect, useMemo, useState } from "react";
import decodeLocationSlug from "../../../utils/decode-location-slug";
import useAnalytics from "../../hooks/use-analytics";
import usePathParams from "../../hooks/use-path-params";
import usePropertyListing from "../../hooks/use-property-listing";
import usePropertyLocations from "../../hooks/use-property-locations";
import { useQueryParam, useQueryParamValue } from "../../hooks/use-query-param";
import { useScroll } from "../../hooks/use-scroll";
import { Track } from "../AnalyticsManager";
import PropertyItem from "../PropertyItem";
import SecurePayment from "../SecurePayment";
import SendRequest from "../SendRequestNeedHelp";
import NearbyLocations from "./NearbyLocations";
import Outro from "./Outro";
import PropertySearch from "./PropertySearch";
import PropertyMultiConfigItem from "../PropertyMultiConfigItem";
import clxs from "./property-listing.module.css";
import NotFound from "../NotFound";
import OffersAndDiscounts from "../OffersAndDiscounts";
import useBannerListing from "../../hooks/use-banner-listing";
import Banners from "./Banners/ListingBanner";
import SortDesktop from "./Sort/Desktop";
import useIsMobile from "../../hooks/use-is-mobile";
import useClx from "../../hooks/use-clx";
import SearchHeader from "./SerachHeader";
import LinearLoader from "../LinearLoader";
import useOffersListing from "../../hooks/use-offers-listing";

function PropertyListing() {
  const { track } = useAnalytics(),
    { propertyLocations } = usePropertyLocations(),
    location = usePathParams(PATH, "location"),
    destination = usePathParams(PATH, "destination"),
    checkin_date = useQueryParamValue("date", "checkin_date"),
    checkout_date = useQueryParamValue("date", "checkout_date"),
    adult_count = useQueryParamValue("integer", "adult_count"),
    child_count = useQueryParamValue("integer", "child_count"),
    search = useQueryParamValue("string", "search"),
    [sort, setSort] = useQueryParam("string", "sort"),
    { scroll } = useScroll(),
    { isMobile } = useIsMobile(),

    // Generate location slug from destination and location
    location_slug = useMemo(() => {
      //handled exception for north-goa location
      if (location === "north-goa") {
        return `${destination}-goa`
      } else {
        return `${destination}-${location}`
      }
    }, [destination, location]),

    // Fetch property listings based on filters
    {
      properties,
      propertyCount,
      getNextPage,
      isLoading: isPropertyLoading,
      // discount,
    } = usePropertyListing(
      location_slug,
      undefined,
      checkin_date?.toISOString(),
      checkout_date?.toISOString(),
      adult_count > 2 ? adult_count : 2, // Ensure minimum 2 adults
      child_count,
      search,
      sort,
    ),

    // Fetch banner data
    { banners, isLoading } = useBannerListing(location_slug),

    // Fetch available offers and discounts
    { offers } = useOffersListing(location_slug),

    // isDomestic = destination === "india",
    { ratio } = useScroll(),
    [lengthId, setLengthId] = useState<number>(0),

    // Find location name
    destinationFriendly = useMemo(
      () => {
        const friendly = propertyLocations
          .find(each => each.slug === location_slug)?.name || "";

        return friendly;
      },
      [propertyLocations, location_slug],
    ),

    // Subtitle based on the location
    subtitle = useMemo(() => {
      //handled exception for north-goa location
      if (location === "north-goa") {
        return "Luxury villas in North Goa"
      } else {
        return `Luxury villas in ${destinationFriendly}`
      }
    }, [destinationFriendly, propertyCount]),

    scrollNotVisible = useMemo(
      () => (isMobile ? scroll >= 50 : scroll >= 155),
      [scroll, isMobile],
    ),
    fcx = useClx(
      clxs.search,
      scrollNotVisible ? clxs.floatingInvisible : clxs.floatingVisible,
    ),
    mcx = useClx(
      clxs.searchMobile,
      scrollNotVisible ? clxs.floatingInvisible : clxs.floatingVisible,
    ),
    hcx = useClx(
      scrollNotVisible ? clxs.searchVisible : clxs.searchInVisible,
    ),
    sscx = useClx(
      scrollNotVisible ? clxs.searchDisplayNon : clxs.searchVisible,
    ),

    handleRatioChange = (ratio: number) => {
      if (ratio < 0.6) {
        return;
      }

      if (lengthId === properties.length) {
        return;
      }

      setLengthId(properties.length);
    },

    /**
     * Handle sorting changes and trigger tracking event
     */
    handleSortChange = (nextDirection: string) => {
      track(
        "property_listing_sort_clicked",
        { sort: nextDirection },
      );

      return setSort(nextDirection);
    };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location, destination]);


  useEffect(
    () => handleRatioChange(ratio),
    [ratio],
  );

  useEffect(
    () => {
      if (!lengthId) {
        return;
      }

      getNextPage();
    },
    [lengthId],
  );

  // Check if the location_slug is valid, if not return NotFound
  const locationExists = propertyLocations.some(each => each.slug === location_slug);

  if (!locationExists) {
    return <NotFound />; // Return NotFound if location is not listed
  }

  return (
    <>
      <LinearLoader isLoading={isPropertyLoading} />

      <SearchHeader
        path={PATH}
        scrollShowClassName={hcx}
        scrollHideClassName={sscx}
        value={sort}
        onChange={handleSortChange}
        isHeaderTransparent={!scrollNotVisible && !isMobile}
      />

      <div className={clxs.container}>
        <Banners
          className={clxs.banners}
          banners={banners}
          isLoadingBanner={isLoading}
        />
        <PropertySearch
          path={PATH}
          className={fcx}
          filterMobileClassName={mcx}
          filterDesktopClassName={fcx}
          propertyCount={propertyCount}
          sort={sort}
          onSortChange={handleSortChange}
        />

        <div className={clxs.titleContainer}>
          {propertyCount == 0 ?
            <p>
              No villas found.
            </p>
            :
            <div className={clxs.title}>
              <div className={clxs.subtitle}>{subtitle}</div>
              <div className={clxs.propertyCount}> {propertyCount} {propertyCount > 1 ? "Properties" : "Property"} found</div>
            </div>
          }
          <SortDesktop
            className={clxs.sortDesktop}
            value={sort}
            onChange={handleSortChange}
          />
        </div>
        <div className={clxs.sendRequestContainer}>
          <OffersAndDiscounts
            className={clxs.offersAndDiscounts}
            offersData={offers}
          />
          <SendRequest
            className={clxs.sendRequest}
            interest="rental"
            isThemeTwo={true}
          />
          <SecurePayment className={clxs.securePayment} />
        </div>
        <div className={clxs.properties}>

          {properties.map(
            (each, i) => {
              const decodedLocation = decodeLocationSlug(each.location_slug),
                { location, destination } = decodedLocation,
                propertySlug = each.property_slug,
                //handled exception for north-goa location
                href = `/villas/${destination}/${location === "north-goa" ? "goa" : location}/${propertySlug}`,
                payload: any = {
                  ...each,
                  ...decodedLocation,
                  href: href,
                  index: i,
                };

              return (
                <Track
                  key={i}
                  event="property_listing_property_clicked"
                  payload={payload}
                >
                  {each.view_layout === "multi_config" ? (
                    <PropertyMultiConfigItem property={each} />
                  ) : (
                    <PropertyItem property={each} />
                  )}
                </Track>
              );
            },
          )}
          <NearbyLocations
            locationSlug={location_slug}
            checkinDate={checkin_date}
            checkoutDate={checkout_date}
            adultCount={adult_count}
            childCount={child_count}
            className={clxs.nearbyLocations}
            title="Nearby Locations"
          />
          <Outro path={PATH} />
        </div>
      </div>
    </>
  );
}

export default PropertyListing;

const PATH = "/villas/:destination/:location";