import React from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./offersanddiscounts.module.css";
import useIsMobile from "../../hooks/use-is-mobile";
import Carousel from "../Carousel";
import ScrollCrumb from "../Home/PropertyCarousel/ScrollCrumb";
import Item from "../Carousel/Item";
import Modal, { useShowModal } from "../../hooks/use-modal";
import ExclusiveCouponTNC from "../ExclusiveCouponTNC";
import ViewAllOffersList from "../ViewAllOffersList";

interface OffersAndDiscountsProps {
  className?: string;
  offersData?: ListingOffersType[];
}

function OffersAndDiscounts(props: OffersAndDiscountsProps) {
  const { className, offersData } = props,
    ccx = useClx(clxs.offersAndDiscounts, className),
    showTnCModal = useShowModal(EXCLUSIVE_COUPON_TNC_MODAL_ID),
    showViewAllModal = useShowModal(VIEW_ALL_OFFERS_MODAL_ID),

    cscx = useClx(clxs.carousel, "carousel"),
    { isMobile } = useIsMobile(),

    /**
     * Function to handle viewing Terms & Conditions in a modal
     */
    handleViewTnC = (offer: ListingOffersType, tncClicked?: boolean) => {
      showTnCModal({
        listingCoupon: offer,
        tncClicked: tncClicked,
      });
    },

    handleViewAll = (offersList: ListingOffersType[]) => {
      showViewAllModal({
        listingCoupon: offersList,
      });
    };

  // Slice the offersData outside JSX to limit to 3 items
  const limitedOffers = (offersData && offersData.length > 0) ? offersData.slice(0, 3) : [];

  const responsiveLimitedOffers = (offersData && offersData.length > 0) ? offersData.slice(0, 4) : [];

  return (
    <>
      {(offersData && offersData.length > 0) ?
        <div className={ccx}>
          {/* Mobile view: Display offers in a carousel */}
          {isMobile ? (
            <>
              <div className={clxs.header}>
                Offers and discounts
                <div
                  className={clxs.viewAllBtn}
                  onClick={() => handleViewAll(offersData)}
                >
                  View All
                </div>
              </div>
              <Carousel
                id={CAROUSEL_ID}
                className={cscx}
                threshold={0.98}
              >
                {responsiveLimitedOffers.map((each, index) => {
                  const { title, image, description } = each;

                  return (
                    <Item
                      key={index}
                      className={clxs.item}
                      title={title}
                    >
                      <div className={clxs.contentContainer}>
                        <img
                          src={image}
                          alt={title}
                          className={clxs.offerImg}
                        />
                        <div className={clxs.content}>
                          <div className={clxs.title}>
                            {title}
                            {each.terms.length > 0 ?
                              <a
                                onClick={() => handleViewTnC(each , true)}
                                className={clxs.tncLink}
                              >
                                T&C Apply
                              </a>
                              : null
                            }
                          </div>
                          <div className={clxs.description}>{description}</div>
                        </div>
                      </div>
                    </Item>
                  );
                })}
              </Carousel>
              <ScrollCrumb
                carouselId={CAROUSEL_ID}
                count={responsiveLimitedOffers.length}
                className={clxs.scrollCrumb}
                themeBlack={true}
              />
            </>
          ) : (
            /* Desktop view: Display offers in a list */
            <>
              <div className={clxs.header}>
                Offers and discounts
                <div
                  className={clxs.viewAllBtn}
                  onClick={() => handleViewAll(offersData)}
                >
                  View All
                </div>
              </div>
              <div className={clxs.container}>
                {(limitedOffers ? limitedOffers : offersData).map((offer, index) => (
                  <div
                    key={index}
                    className={clxs.contentContainer}
                  >
                    <img
                      src={offer.image}
                      alt={offer.title}
                      className={clxs.offerImg}
                    />
                    <div className={clxs.content}>
                      <div className={clxs.title}>{offer.title}</div>
                      <div className={clxs.description}>{offer.description}</div>
                    </div>
                    {offer.terms.length > 0 ?
                      <a
                        className={clxs.tncLink}
                        onClick={() => {
                          handleViewTnC(offer ,true)
                         }
                        }
                      >
                        T&C Apply
                      </a>
                      : null
                    }
                    <div
                      className={
                        index + 1 === (limitedOffers ? limitedOffers.length : offersData.length)
                          ? clxs.hideDivider
                          : clxs.divider
                      }
                    ></div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        : null}

      <Modal id={VIEW_ALL_OFFERS_MODAL_ID}>
        <ViewAllOffersList />
      </Modal>

      <Modal id={EXCLUSIVE_COUPON_TNC_MODAL_ID}>
        <ExclusiveCouponTNC />
      </Modal>
    </>
  );
}

export default OffersAndDiscounts;

const CAROUSEL_ID = "offers-and-discounts";

const EXCLUSIVE_COUPON_TNC_MODAL_ID = "exclusive-coupon-tnc";

const VIEW_ALL_OFFERS_MODAL_ID = "view-all-offers";