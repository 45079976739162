import React, { Fragment, useCallback, useMemo, useState } from "react";
import useCountryCode from "../../hooks/use-country-code";
import useLogo from "../../hooks/use-logo";
import { Track } from "../AnalyticsManager";
import { useAppStoreModal } from "../AppStoreModal";
import useToggle from "../../hooks/use-toggle";
import useClx from "../../hooks/use-clx";
import ContextMenu from "../ContextMenu";
import Drawer from "../Drawer";
import Anchor from "./Anchor";
import { usePageValue } from "../../hooks/use-page";
import { useLocation } from "react-router-dom";
import useIsHeaderHidden from "./use-is-header-hidden";
import Collapse from "../Collapse";
import Login from "../Login";
import {
  DOMESTIC_PROPERTY_LOCATIONS,
  INTERNATIONAL_PROPERTY_LOCATIONS,
} from "../../core/locations";
import fwclxs from "../../styles/full-width.module.css";
import clxs from "./header.module.css";
import MENU_ICON from "../../assets/icons/menu.svg";
import CLOSE_ICON from "../../assets/icons/close.svg";
import GET_APP_ICON from "../../assets/icons/get-app.svg";
import LOCATION_PIN from "../../assets/icons/pin_drop.svg";
import EXPAND_MORE_ICON from "../../assets/icons/expand-more-grey.svg";
import EXPAND_LESS_ICON from "../../assets/icons/expand-less-grey.svg";
import CHEVRON_RIGHT from "../../assets/icons/chevron-right-dark-grey.svg";
import LOYALTY_POINTS from "../../assets/icons/loyalty-point-star-light.svg";
import LOHONO_INFINITY_LOGO from "../../assets/loyalty-points/lohono-infinity-logo.svg";
import LOHONO_LOGO from "../../assets/logo/lohono-logo.svg";
import { BRAND_LOGO_KEY } from "../../../core/constants";
import { INFINITY_LOGO_KEY } from "../../../core/constants";
import useIsMobile from "../../hooks/use-is-mobile";
//import useStaticCollections from "../Home/Collections/use-static-collections";
import { COLLECTIONS_LIST } from "../../core/services/collection-list";



function Header() {
  const
    //COLLECTIONS_LIST = useStaticCollections(),
    { page } = usePageValue(),
    { pathname } = useLocation(),
    { countryCode } = useCountryCode(),
    { toggle, setToggle } = useToggle(DRAWER_ID),
    brandLogo = useLogo(BRAND_LOGO_KEY, LOHONO_LOGO),
    infinityLogo = useLogo(INFINITY_LOGO_KEY, LOHONO_INFINITY_LOGO),
    ccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.container),
    showAppStoreModal = useAppStoreModal(),
    hideHeader = useIsHeaderHidden(page, pathname),
    { isMobile } = useIsMobile();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [collectionExpanded, setCollectionExpanded] = useState<boolean>(false);

  const handleToggle = useCallback(
    () => setExpanded((expanded) => !expanded),
    [setExpanded],
  );

  const handleColllectionToggle = useCallback(
    () => setCollectionExpanded((collectionExpanded) => !collectionExpanded),
    [setCollectionExpanded],
  );

  const groupedDomesticProperties = useMemo(() => {
    return Array.from(
      { length: Math.ceil(DOMESTIC_PROPERTY_LOCATIONS.length / 8) },
      (_, i) => {
        return DOMESTIC_PROPERTY_LOCATIONS.slice(8 * i, 8 * (i + 1));
      },
    );
  }, [DOMESTIC_PROPERTY_LOCATIONS]);

  // const collestionsList = useMemo(() => {
  //   return COLLECTIONS_LIST || [];
  // }, [COLLECTIONS_LIST])

  const handleCloseDrawer = (isMobileValue : boolean) =>{
    if(isMobileValue){
      setToggle(false)
    }
  };

  const isHomeHighlighted = pathname === "/";

  const isHighlighted = (route:string) => pathname.includes(route);

  return (
    <>
      <nav
        className={ccx}
        data-hidden={hideHeader}
        suppressHydrationWarning={true}
      >
        <Track event="header_home_clicked">
          <a
            href="/"
            className={clxs.logoContainer}
            title="Lohono Stays"
          >
            <img
              className={clxs.logo}
              src={brandLogo}
              alt="Lohono"
            />
          </a>
        </Track>
        <Track
          key="/"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "Home",
            header: "Home",
            href: "/",
          }}
        >
          <Anchor
            title="Home"
            href="/"
            data-highlight={isHomeHighlighted}
            className={clxs.header}
          />
        </Track>
        <ContextMenu
          title="Villas on rent"
          className={clxs.headerDropdown}
          menuClassName={clxs.headerDropdownMenu}
          menu={
            <>
              <div className={clxs.propertyGroup}>
                <div className={clxs.domesticProperties}>
                  <div className={clxs.locationTitle}>Domestic Properties</div>
                  {groupedDomesticProperties.map((group, key) => {
                    return (
                      <div
                        className={clxs.groupedDomesticProperties}
                        key={key}
                      >
                        {group.map((each, key) => (
                          <Fragment key={key}>
                            <img
                              src={LOCATION_PIN}
                              alt="location"
                              className={clxs.location}
                            />
                            <Track
                              key={each.path}
                              event="header_item_clicked"
                              payload={{
                                ...each,
                                ...each.meta,
                                index: key,
                                title: each.header,
                                header: each.header,
                                href: each.path,
                              }}
                            >
                              <Anchor
                                title={each.header}
                                href={each.path}
                                className={clxs.anchor}
                              />
                            </Track>
                          </Fragment>
                        ))}
                      </div>
                    );
                  })}
                </div>
                {countryCode === "IN" && (
                  <div className={clxs.internationalProperties}>
                    <div className={clxs.locationTitle}>
                      International Properties
                    </div>
                    {INTERNATIONAL_PROPERTY_LOCATIONS.map((each, key) => (
                      <Fragment key={key}>
                        <img
                          src={LOCATION_PIN}
                          alt="location"
                          className={clxs.location}
                        />
                        <Track
                          key={each.path}
                          event="header_item_clicked"
                          payload={{
                            ...each,
                            ...each.meta,
                            index: key,
                            title: each.header,
                            header: each.header,
                            href: each.path,
                          }}
                        >
                          <Anchor
                            title={each.header}
                            href={each.path}
                            className={clxs.anchor}
                          />
                        </Track>
                      </Fragment>
                    ))}
                  </div>
                )}
              </div>
            </>
          }
        >
          Villas on rent
        </ContextMenu>
        <ContextMenu
          title="Collections"
          className={clxs.headerDropdown}
          data-highlight={isHighlighted("/villas/collections/")}
          menuClassName={clxs.headerDropdownCollectionsMenu}
          menu={
            <>
              {COLLECTIONS_LIST?.map((each, key) => (
                <Fragment key={key}>
                  <Track
                    key={each.slug}
                    event="header_item_clicked"
                    payload={{
                      ...each,
                      index: key,
                      title: each.name,
                      header: each.name,
                      href: `villas/collections/${each.slug}`,
                    }}
                  >
                    <Anchor
                      title={each.name}
                      href={`/villas/collections/${each.slug}`}
                      className={clxs.anchor}
                    />
                  </Track>
                </Fragment>
              ))}
            </>
          }
        >
          Collections
        </ContextMenu>
        <ContextMenu
          title="Rewards"
          className={clxs.headerDropdown}          
          data-highlight={isHighlighted("/loyalty-points") || isHighlighted("/refer") }
          menuClassName={clxs.headerDropdownRewardsMenu}
          menu={
            <>
              <Track
                key="/loyalty-points"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "Lohono Infinity Programme",
                  header: "Lohono Infinity Programme",
                  href: "/loyalty-points",
                }}
              >
                {!isMobile ? (
                  <Anchor
                    title="Lohono Infinity Programme"
                    href="/loyalty-points"
                    className={clxs.anchor}
                  />
                ) : (
                  <Anchor
                    href="/loyalty-points"
                    className={clxs.headerLoyaltyPoints}
                  >
                    <img
                      src={infinityLogo}
                      alt="loyalty points"
                    />
                  </Anchor>
                )}
              </Track>
              <Track
                key="/refer"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "Referral Programme",
                  header: "Referral Programme",
                  href: "/refer",
                }}
              >
                <Anchor
                  title="Referral Programme"
                  href="/refer"
                  data-highlight={isHighlighted("/refer")}
                  className={clxs.anchor}
                />
                {/* {!isMobile ?
                    (
                      <Anchor
                        title="Referral Programme"
                        href="/refer"
                        className={clxs.header}
                      />
                    ) :
                    (
                      <Anchor
                        href="/refer"
                        className={clxs.headerLoyaltyPoints}
                      >
                        <img
                          src={infinityLogo}
                          alt="referral-programme"
                        />
                      </Anchor>
                    )
                  } */}
              </Track>
            </>
          }
        >
          Rewards
        </ContextMenu>
        <Track
          key="/experiences-and-events"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "Experiences and Events",
            header: "Experiences and Events",
            href: "/experiences-and-events",
          }}
        >
          <Anchor
            title="Experiences and Events"
            href="/experiences-and-events"
            data-highlight={isHighlighted("/experiences-and-events")}
            className={clxs.header}
          />
        </Track>
        <Track
          key="/contact-us"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "Contact us",
            header: "Contact us",
            href: "/contact-us",
          }}
        >
          <Anchor
            title="Contact us"
            href="/contact-us"
            data-highlight={isHighlighted("/contact-us")}
            className={clxs.header}
          />
        </Track>
        <a
          href="/app-store"
          className={clxs.getAppMobile}
        >
          <img
            src={GET_APP_ICON}
            alt="Get app"
          />
        </a>
        <img
          src={GET_APP_ICON}
          alt="get app"
          className={clxs.getAppDesktop}
          onClick={showAppStoreModal}
        />
        {!isMobile ?
          <Login className={clxs.login} />
          : null}
        <ContextMenu
          title="More"
          expandIcon={MENU_ICON}
          collapseIcon={MENU_ICON}
          className={clxs.headerDropdown}
          menuClassName={clxs.headerDropdownMenu}
          menu={
            <>
              <Track
                key="https://blog.lohono.com"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "Lohono Blog",
                  header: "Blog",
                  href: "https://blog.lohono.com",
                }}
              >
                <Anchor
                  title="Blog"
                  href="https://blog.lohono.com"
                  className={clxs.drawerLink}
                />
              </Track>
              <Track
                key="/about-us"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "About Lohono",
                  header: "About us",
                  href: "/about-us",
                }}
              >
                <Anchor
                  title="About us"
                  href="/about-us"
                  className={clxs.drawerLink}
                />
              </Track>
              <Track
                key="/lohono-experience"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "Lohono Experience",
                  header: "Lohono Experience",
                  href: "/lohono-experience",
                }}
              >
                <Anchor
                  title="Lohono Experience"
                  href="/lohono-experience"
                  className={clxs.drawerLink}
                />
              </Track>
              {/* <Track
                key="https://www.isprava.com/our-homes/"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "Home for sale",
                  header: "Home for sale",
                  href: "https://www.isprava.com/our-homes/",
                }}
              >
                <Anchor
                  title="Home for sale"
                  href="https://www.isprava.com/our-homes/"
                  className={clxs.drawerLink}
                />
              </Track> */}
              <Track
                key="/corporate-stays"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "Corporate Retreats",
                  header: "Corporate Retreats",
                  href: "/corporate-stays",
                }}
              >
                <Anchor
                  title="Corporate Retreats"
                  href="/corporate-stays"
                  className={clxs.drawerLink}
                />
              </Track>
              <Track
                key="/homeowner"
                event="header_item_clicked"
                payload={{
                  type: "page",
                  title: "List your property",
                  header: "List your property",
                  href: "/homeowner",
                }}
              >
                <Anchor
                  title="List your property"
                  href="/homeowner"
                  className={clxs.drawerLink}
                />
              </Track>
            </>
          }
        >
          &nbsp;
        </ContextMenu>
        <img
          src={MENU_ICON}
          alt="menu"
          className={clxs.menu}
          onClick={setToggle.bind(null, !toggle)}
        />
      </nav>
      <Drawer
        open={toggle}
        onClose={setToggle.bind(null, false)}
        className={clxs.drawer}
      >
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          data-dismiss={true}
        />

        <Login
          className={clxs.login}
          isDrawerMenu={true}
          handleCloseDrawer = {handleCloseDrawer}
        />

        <div className={clxs.divider}/>

        <Track
          key="/"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "Home",
            header: "Home",
            href: "/",
          }}
        >
          <Anchor
            key="/"
            title="Home"
            href="/"
            className={clxs.drawerLink}
            data-dismiss={true}
          />
        </Track>
        <div className={clxs.collapsibleContainer}>
          <div
            className={clxs.summary}
            onClick={handleToggle}
          >
            Villas on rent
            <img
              src={expanded ? EXPAND_LESS_ICON : EXPAND_MORE_ICON}
              alt="expand"
              className={clxs.expand}
            />
          </div>
          <Collapse
            expanded={expanded}
            className={clxs.content}
          >
            <div className={clxs.decoration}>&nbsp;</div>
            <div className={clxs.group}>
              {DOMESTIC_PROPERTY_LOCATIONS.map((each, key) => (
                <Track
                  key={each.path}
                  event="header_item_clicked"
                  payload={{
                    ...each,
                    ...each.meta,
                    index: key,
                    title: each.header,
                    header: each.header,
                    href: each.path,
                  }}
                >
                  <Anchor
                    title={each.header}
                    href={each.path}
                    className={clxs.link}
                  >
                    <img
                      src={CHEVRON_RIGHT}
                      alt="jump"
                      className={clxs.jump}
                    />
                  </Anchor>
                </Track>
              ))}
              {countryCode === "IN" && (
                <>
                  <div className={clxs.collapsibleDivider}>&nbsp;</div>
                  {INTERNATIONAL_PROPERTY_LOCATIONS.map((each, key) => (
                    <Track
                      key={each.path}
                      event="header_item_clicked"
                      payload={{
                        ...each,
                        ...each.meta,
                        index: key,
                        title: each.header,
                        header: each.header,
                        href: each.path,
                      }}
                    >
                      <Anchor
                        title={each.header}
                        href={each.path}
                        className={clxs.link}
                      >
                        <img
                          src={CHEVRON_RIGHT}
                          alt="jump"
                          className={clxs.jump}
                        />
                      </Anchor>
                    </Track>
                  ))}
                </>
              )}
            </div>
          </Collapse>
        </div>
        <div className={clxs.collapsibleContainer}>
          <div
            className={clxs.summary}
            onClick={handleColllectionToggle}
          >
            Collections
            <img
              src={collectionExpanded ? EXPAND_LESS_ICON : EXPAND_MORE_ICON}
              alt="expand"
              className={clxs.expand}
            />
          </div>
          <Collapse
            expanded={collectionExpanded}
            className={clxs.content}
          >
            <div className={clxs.decoration}>&nbsp;</div>
            <div className={clxs.group}>
              {COLLECTIONS_LIST?.map((each, key) => (
                <Track
                  key={each.slug}
                  event="header_item_clicked"
                  payload={{
                    ...each,
                    index: key,
                    title: each.name,
                    header: each.name,
                    href: `villas/collections/${each.slug}`,
                  }}
                >
                  <Anchor
                    title={each.name}
                    href={`/villas/collections/${each.slug}`}
                    className={clxs.link}
                  >
                    <img
                      src={CHEVRON_RIGHT}
                      alt="jump"
                      className={clxs.jump}
                    />
                  </Anchor>
                </Track>
              ))}
            </div>
          </Collapse>
        </div>
        <Track
          key="/loyalty-points"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "Lohono Infinity",
            header: "Lohono Infinity",
            href: "/loyalty-points",
          }}
        >
          <Anchor
            href="/loyalty-points"
            className={clxs.drawerLinkLoyaltyPoints}
          >
            <>
              Lohono Infinity
              <img
                src={LOYALTY_POINTS}
                alt="loyalty points"
              />
            </>
          </Anchor>
        </Track>
        <Track
          key="/refer"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "Referral Programme",
            header: "Referral Programme",
            href: "/refer",
          }}
        >
          <Anchor
            title="Referral Programme"
            href="/refer"
            className={clxs.drawerLink}
          />
        </Track>
        <Track
          key="/corporate-stays"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "Corporate Stays",
            header: "Corporate Stays",
            href: "/corporate-stays",
          }}
        >
          <Anchor
            title="Corporate Retreats"
            href="/corporate-stays"
            className={clxs.drawerLink}
          />
        </Track>
        <Track
          key="/experiences-and-events"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "Experiences and Events",
            header: "Experiences And Events",
            href: "/experiences-and-events",
          }}
        >
          <Anchor
            title="Experiences and Events"
            href="/experiences-and-events"
            className={clxs.drawerLink}
          />
        </Track>
        <Track
          key="/contact-us"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "Contact us",
            header: "Contact us",
            href: "/contact-us",
          }}
        >
          <Anchor
            title="Contact us"
            href="/contact-us"
            className={clxs.drawerLink}
          />
        </Track>
        <Track
          key="https://blog.lohono.com"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "Lohono Blog",
            header: "Blog",
            href: "https://blog.lohono.com",
          }}
        >
          <Anchor
            title="Blog"
            href="https://blog.lohono.com"
            className={clxs.drawerLink}
          />
        </Track>
        <Track
          key="/about-us"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "About Lohono",
            header: "About us",
            href: "/about-us",
          }}
        >
          <Anchor
            title="About us"
            href="/about-us"
            className={clxs.drawerLink}
          />
        </Track>
        <Track
          key="/homeowner"
          event="header_item_clicked"
          payload={{
            type: "page",
            title: "List your property",
            header: "List your property",
            href: "/homeowner",
          }}
        >
          <Anchor
            title="List your property"
            href="/homeowner"
            className={clxs.drawerLink}
          />
        </Track>
      </Drawer>
    </>
  );
}

export default Header;

export const DRAWER_ID = "lohono-drawer";
