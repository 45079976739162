import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import CLOSE_ICON from "../../../assets/icons/close.svg";
// import { useCoupons } from "../../../hooks/use-coupons";
import clxs from "./exclusive-coupon-suggestion.module.css";
import { useQueryParamValue } from "../../../hooks/use-query-param";
import { useLocation } from "react-router-dom";
import useReservationService from "../../../hooks/use-reservation-service";
import Modal, { useShowModal } from "../../../hooks/use-modal";
import ExclusiveCouponTNC from "../../ExclusiveCouponTNC";

interface ExclusiveCouponSuggestionProps {
  propertySlug?: string;
  checkinDate?: string;
  checkoutDate?: string;
  adultCount?: number;
  childCount?: number;
  onSubmit?: (coupon: Coupon | null) => void;
  selected_configs?: PropertyConfigArg[];
  coupons?: Coupon[];
}

function ExclusiveCouponSuggestion(props: ExclusiveCouponSuggestionProps) {
  const [manual, setManual] = useState<string>(""),
    [couponState, setCouponState] = useState<string>(""),
    [couponError, setCouponError] = useState<string>(""),
    [isValidCouponApplied, setValidCouponApplied] = useState<boolean>(false),
    [isInValidCouponApplied, setisInValidCouponApplied] = useState<boolean>(false),
    termsListRef = useRef<HTMLDivElement>(null),

    tier = useQueryParamValue("string", "tier"),
    { pathname } = useLocation(),
    isCheckoutPage = pathname?.includes("checkout"),
    { service } = useReservationService(),

    {
      propertySlug,
      checkinDate,
      checkoutDate,
      // adultCount,
      // childCount,
      selected_configs,
      onSubmit,
      coupons,
    } = props,

    /** 
     * Fetch available coupons
     */
    // { coupons } = useCoupons(
    //   propertySlug,
    //   checkinDate,
    //   checkoutDate,
    //   adultCount,
    //   childCount,
    //   selected_configs,
    // ),

    tierCoupon = useMemo(() => {
      if (!tier || !isCheckoutPage || tier == "no_tier") return null;

      const coupon: Coupon = {
        code: tier.toUpperCase(),
        description: "Apply " + tier.toUpperCase() + " benefits.",
        name: tier.toUpperCase() + " tier coupon.",
        terms: [""],
      };

      return coupon;
    }, [tier, isCheckoutPage]),
    /**
     * Handles pop-up close functionality
     */
    onCloseParentModal = () => {
      if (onSubmit) {
        onSubmit(null);
      }
    },
    showTnCModal = useShowModal(EXCLUSIVE_COUPON_TNC_MODAL_ID),
     handleViewTnC = (offer: Coupon) => {
      showTnCModal({
        detailCoupon: offer,
        onCloseParentModal:onCloseParentModal,
      });
    },

    /** 
     * Handle manual coupon input change
     */
    handleManualChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement,
        { value } = target;

      setManual(value);
    },

    /**
     * Validate coupon using the reservation service
     */
    checkIfCouponValid = async (coupon_code: string) => {
      const { error, response } = await service.validateCoupon(
        coupon_code,
        selected_configs ? selected_configs : [],
        propertySlug ? propertySlug : "",
        checkinDate ? checkinDate : "",
        checkoutDate ? checkoutDate : "",
      );
      let isCouponValid: boolean = false;

      if (!response) {
        isCouponValid = false;
      }

      if (error) {
        isCouponValid = false;
        setCouponError(error.message ? error.message : "Coupon code entered is invalid");
      }

      if (response && response.valid) {
        isCouponValid = true;
      }

      return {
        isValidCoupon: isCouponValid,
        coupon: response,
      };
    },

    /** 
     * Apply manually entered coupon
     */
    handleManualCoupon = async (code: string) => {
      const { isValidCoupon, coupon } = await checkIfCouponValid(code);
      setValidCouponApplied(isValidCoupon);
      if (code && isValidCoupon) {
        setisInValidCouponApplied(isValidCoupon);
        setCouponState(code);
        setManual("");
        return onSubmit?.(coupon);
      } else {
        setisInValidCouponApplied(!isValidCoupon);
      }
    },

    /**
     * Handle selection of a suggested coupon
     */
    handleCouponSelected = async (value: Coupon) => {
      const { code } = value,

        { isValidCoupon } = await checkIfCouponValid(code);

      if (isValidCoupon) {
        setisInValidCouponApplied(isValidCoupon);
        setCouponState(code);
        setManual("");
        return onSubmit?.(value);
      } else {
        setisInValidCouponApplied(!isValidCoupon);
      }
    };

  useEffect(() => {
    if (manual.length > 0) {
      setisInValidCouponApplied(false);
    }
    if (!manual) {
      setValidCouponApplied(false);
    }
  }, [manual]);

  return (
    <>
    <div 
      className={clxs.container}
      ref={termsListRef}
    >
      <div className={clxs.title}>
        Exclusive Offers
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          onClick={() => {
            setManual(""); // Clear the input
            onSubmit?.(null);
          }}
        />
      </div>
      <div className={clxs.manualContainer}>
        <input
          type="text"
          className={`${clxs.manual} ${isValidCouponApplied ? clxs.manualCouponApplied : ""
            }  ${isInValidCouponApplied && manual ? clxs.invalidCouponApplied : ""
            }`}
          name="coupon_code"
          placeholder="Enter Coupon code"
          value={manual}
          onChange={handleManualChange}
        />
        <button
          className={`${clxs.manualApply} ${manual ? clxs.greenManualApply : ""
            }`}
          onClick={handleManualCoupon.bind(null, manual)}
          data-disabled={!isValidCouponApplied}
        >
          Apply
        </button>

        {couponState && isValidCouponApplied && !manual ? (
          <div className={clxs.codeApplied}>Promocode Applied!</div>
        ) : null}

        {isInValidCouponApplied && manual ? (
          <div className={clxs.invalidCodeApplied}>
            {couponError}
          </div>
        ) : null}
      </div>
      <div className={clxs.couponContainer}>
        {coupons?.length ? (
          coupons.map((each, i) => {
            const { name, code, description,saveUpto } = each;

            return (
              <div
                key={i}
                className={clxs.coupon}
              >
                <div className={clxs.couponTitle}>{code || name}</div>
                <button
                  className={clxs.couponApply}
                  onClick={handleCouponSelected.bind(null, each)}
                >
                  Apply
                </button>
                <div className={clxs.couponDescription}>
                  <div className="">{description}</div>
                  {saveUpto && 
                  <div className={clxs.savings}>You save ₹{saveUpto.toLocaleString("en-IN")}</div>
                  }
                </div>
                {(each.terms && each.terms.length) ?
                 <a  
                   className={clxs.tncLink} 
                   onClick={(e) => { e.stopPropagation(); 
                   handleViewTnC(each); }}
                 >T&C Apply</a> : ""}
              </div>
            );
          })
        ) : (
          <></>
        )}

        {tierCoupon ? (
          <div
            className={clxs.coupon}
            data-discount-type={tier}
          >
            <div className={clxs.couponTitle}>
              {tierCoupon?.name || tierCoupon?.code}
            </div>
            <button
              className={clxs.couponApply}
              onClick={handleCouponSelected.bind(null, tierCoupon)}
            >
              Apply
            </button>
            <div>{tierCoupon?.description}</div>
          </div>
        ) : (
          <></>
        )}

        {!tierCoupon && !coupons?.length ? (
          <div className={clxs.noOffer}>
            There are no offers available for this villa on the selected dates.
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
      <Modal
        id={EXCLUSIVE_COUPON_TNC_MODAL_ID}
        isPopupOverlapped={true}
      >
        <ExclusiveCouponTNC
          parentHeight={termsListRef.current?.clientHeight}
        />
      </Modal>
    </>
  );
}

export default ExclusiveCouponSuggestion;

const EXCLUSIVE_COUPON_TNC_MODAL_ID = "exclusive-coupon-tnc";

