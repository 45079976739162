import React from "react";
import clxs from "./exclusive-coupon-offers-mobile.module.css";
import useClx from "../../hooks/use-clx";
import CouponIcon from "../../assets/icons/couponIcon.svg";
import GreenBackArrow from "../../assets/icons/arrow_back_green.svg";
import Modal, { useShowModal } from "../../hooks/use-modal";
import ExclusiveCouponSuggestion from "./ExclusiveCouponSuggestion";
import CodeAppliedMessage from "./CodeAppliedMessage";
import useValidateCoupons from "../../hooks/use-validate-coupon";
// import { usePropertyConfigValues } from "../../hooks/use-property-config";

interface ExclusiveCouponOffersMobileProps {
  value: string;
  className?: string;
  propertySlug: string;
  checkinDate?: string;
  checkoutDate?: string;
  adultCount?: number;
  childCount?: number;
  onChange: (couponCode: string) => void;
  selected_configs: PropertyConfigArg[];
  configs: PropertyConfig[];
  couponCount: number;
  coupons: Coupon[];
}

function ExclusiveCouponOffersMobile(props: ExclusiveCouponOffersMobileProps) {
  const {
    value,
    onChange,
    propertySlug,
    selected_configs,
    checkinDate,
    checkoutDate,
    className,
    adultCount,
    childCount,
    couponCount,
    coupons,
    ...suggestionProps
  } = props,
    // { coupon } = useCoupon(value),
    ccx = useClx(clxs.container, className),

    /** 
     * Retrieve property configuration values
     */
    // { configArgs } = usePropertyConfigValues(propertySlug ? propertySlug : "", configs),
    // selected_configs = configArgs.filter(each => each.required_rooms > 0),

    /**
     * Validate coupon based on selected property configurations and booking details
     */
    { coupon } = useValidateCoupons(
      value,
      selected_configs,
      propertySlug ? propertySlug : "",
      checkinDate ? checkinDate : "",
      checkoutDate ? checkoutDate : "",
    ),
    // [codeApplied,setCodeApplied] = useState(),
    showCouponCodeSuggestions = useShowModal(COUPON_CODE_MODAL_ID),
    showCodeAppliedMessage = useShowModal(COUPON_APPLIED_CODE_MODAL_ID),

    /** 
     * Handle the process of viewing and applying a coupon
     */
    handleViewOffer = async () => {
      const coupon = await showCouponCodeSuggestions(
        suggestionProps as any,
      );

      if (!coupon) {
        return;
      }

      const { code } = coupon;

      const CodeAppliedMessage = await showCodeAppliedMessage({
        selectedCoupon: coupon,
      });

      onChange(code || "");
      if (!CodeAppliedMessage) {
        return;
      }

    };

  return (
    <>
      <div className={ccx}>
        <div
          className={
            coupon
              ? `${clxs.couponContainer} ${clxs.couponApplied}`
              : clxs.couponContainer
          }
        >
          <div className={clxs.viewCouponContainer}>
            <img
              src={CouponIcon}
              alt="Lohono"
              className={clxs.couponIcon}
            />
            <span className={clxs.viewCoupon}>
              {coupon
                ? `${coupon.code} - Promocode Applied!`
                : "View All Coupon code"}
            </span>
          </div>

          {coupon ? (
            <div
              className={clxs.offerRemove}
              onClick={onChange.bind(null, "NONE")}
            >
              Remove
            </div>
          ) : (
            <div
              className={clxs.offerContainer}
              onClick={handleViewOffer}
            >
              <span className={clxs.offerCount}>{couponCount ? `${couponCount} offers`: "Add coupon" }</span>
              <img
                src={GreenBackArrow}
                alt="Lohono"
                className={clxs.couponIcon}
              />
            </div>
          )}
        </div>
      </div>
      <Modal id={COUPON_CODE_MODAL_ID}>
        <ExclusiveCouponSuggestion
          propertySlug={propertySlug}
          checkinDate={checkinDate}
          checkoutDate={checkoutDate}
          adultCount={adultCount}
          childCount={childCount}
          selected_configs={selected_configs}
          coupons={coupons}
        />
      </Modal>
      <Modal id={COUPON_APPLIED_CODE_MODAL_ID}>
        <CodeAppliedMessage />
      </Modal>
    </>
  );
}

export default ExclusiveCouponOffersMobile;

const COUPON_CODE_MODAL_ID = "exclusive-coupon-suggestions";

const COUPON_APPLIED_CODE_MODAL_ID = "code-applied-message";
