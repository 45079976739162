import React, { Fragment, MouseEvent, useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import useClx from "../../hooks/use-clx";
import clxs from "./property-config.module.css";
import ADD_CIRCLE_GREY_ICON from "../../assets/icons/add-circle-dark.svg";
import REMOVE_CIRCLE_GREY_ICON from "../../assets/icons/remove-circle-dark.svg";
import { generatePropertyConfigKey, usePropertyConfigValues } from "../../hooks/use-property-config";

export interface PropertyConfigProps {
  propertySlug: string;
  configs: PropertyConfig[];
  defaultValue?: PropertyConfigArg[];
  value?: PropertyConfigValue;
  onChange?: (value: PropertyConfigValue) => void;
  name?: string;
  maxCount?: any;
  className?: string;
  disabled?: boolean;
  error?: string;
}

function PropertyConfig(props: PropertyConfigProps) {
  const {
    propertySlug,
    configs,
    // defaultValue: configArgs,
    value: _value,
    onChange,
    className,
    maxCount,
    disabled,
    error,
  } = props,
    ccx = useClx(clxs.container, className),
    {
      configArgs,
    } = usePropertyConfigValues(propertySlug, configs),
    mergedArray = configs?.map((item) => {
      const match = configArgs?.find((config) => config.property_config_id === item.config_id);
      return {
        ...item,
        required_rooms: match ? match.required_rooms : 0,
      };
    }),
    generateKeyPrefix = useCallback(
      (configId: string) => {
        return generatePropertyConfigKey(propertySlug, configId);
      },
      [propertySlug],
    ),
    buildValueFromAny = useCallback(
      (value: any) => {
        const updatedValue = mergedArray.reduce(
          (compiled, each) => {
            const { config_id, required_rooms } = each;

            const key = generateKeyPrefix(config_id);

            return {
              ...compiled,
              [key]: value?.[key] || required_rooms || 0,
            };
          },
          {} as PropertyConfigValue,
        );

        return updatedValue;
      },
      [generateKeyPrefix, mergedArray],
    ),
    getMaxCount = useCallback(
      (configKey: any) => {
        return maxCount?.[configKey] ?? maxCount ?? 100;
      },
      [maxCount, generateKeyPrefix],
    ),
    [value, setValue] = useState<PropertyConfigValue>(
      () => buildValueFromAny(_value),
    ),
    handleChange = (value: PropertyConfigValue) => {
      if (!onChange) {
        return;
      }

      _dOnChange(onChange, value);
    },
    handleAdd = (e: MouseEvent<HTMLElement>) => {
      if (disabled) {
        return;
      }

      const target = e.currentTarget as HTMLElement;

      const name = target.dataset.name ?? "";

      if (!name) {
        return;
      }

      const count = value[name] ?? 0;

      const updatedCount = Math.min(getMaxCount(name), count + 1);

      if (count === updatedCount) {
        return;
      }

      const updatedValue = {
        ...value,
        [name]: updatedCount,
      };

      setValue(updatedValue);

      handleChange(updatedValue);
    },
    handleSubtract = (e: MouseEvent<HTMLElement>) => {
      if (disabled) {
        return;
      }

      const target = e.currentTarget as HTMLElement;

      const name = target.dataset.name ?? "";

      if (!name) {
        return;
      }

      const count = value[name] ?? 0;

      const updatedCount = Math.max(0, count - 1);

      if (count === updatedCount) {
        return;
      }

      const updatedValue = {
        ...value,
        [name]: updatedCount,
      };

      setValue(updatedValue);

      handleChange(updatedValue);
    },
    handleValueChange = (value?: PropertyConfigValue) => {
      if (!value) {
        return;
      }

      const updatedValue = buildValueFromAny(value);

      setValue(updatedValue);
      handleChange(updatedValue);
    };

  useEffect(
    () => handleValueChange(_value),
    [_value],
  );



  return (
    <div className={ccx}>
      {configs.map(
        (each) => {
          const {
            config_id,
            room_name,
            max_occupancy,
            bed_type,
          } = each;

          const key = generateKeyPrefix(config_id);

          const title = room_name;

          const subtitle = `Fits ${max_occupancy} (${bed_type})`;

          const count = value[key] ?? 0;

          const hoverContent = `${title}\n${subtitle}`;

          return (
            <Fragment key={key}>
              <div
                className={clxs.title}
                title={hoverContent}
              >
                {title}
              </div>
              <button
                type="button"
                className={clxs.button}
                data-count={count}
                data-name={key}
                onClick={handleSubtract}
              >
                <img
                  src={REMOVE_CIRCLE_GREY_ICON}
                  alt="subtract"
                />
              </button>
              <div
                className={clxs.number}
                suppressHydrationWarning={true}
              >
                {count.toLocaleString("en-IN")}
              </div>
              <button
                type="button"
                className={clxs.button}
                data-name={key}
                onClick={handleAdd}
              >
                <img
                  src={ADD_CIRCLE_GREY_ICON}
                  alt="add"
                />
              </button>
              <div
                className={clxs.subtitle}
                title={hoverContent}
              >
                {subtitle}
              </div>
              <div className={clxs.separator}>&nbsp;</div>
            </Fragment>
          );
        },
      )}
      {error && <div className={clxs.error}>{error}</div>}
    </div>
  );
}

export default PropertyConfig;

const _dOnChange = debounce(
  (cb: (value: PropertyConfigValue) => void, value: PropertyConfigValue) => cb(value),
  500,
);

export type PropertyConfigValue = Record<string, number>;

export type GenerateKeyPrefix = (configId: string) => string;
